/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { Link } from 'react-router-dom'
import {
  allCrudNames,
  allPages,
  checkPageAccess
} from '../../../lib/AllPageNames'
import NotFound from '../../NotFound/NotFound'
import { setPageTitle } from '../../redux/slice/pageTitleSlice'
import AddSeller from './AddSeller'

const ManageSeller = () => {
  const { pageAccess } = useSelector((state) => state.user)
  const [activeToggle, setActiveToggle] = useState('seller')
  const dispatch = useDispatch()

  useEffect(() => {
    dispatch(setPageTitle('Manage Seller'))
  }, [])

  return checkPageAccess(
    pageAccess,
    allPages?.manageSeller,
    allCrudNames?.read
  ) ? (
    <>
      {checkPageAccess(
        pageAccess,
        allPages?.manageSeller,
        allCrudNames?.read
      ) && (
        <div className='card'>
          <div className='card-body p-0'>
            <div className='nav-tabs-horizontal nav nav-tabs'>
              <Link
                onClick={() => setActiveToggle('seller')}
                data-toggle='tab'
                className={`nav-link fw-semibold ${
                  activeToggle === 'seller' ? 'active show' : ''
                }`}
              >
                <span className='nav-span'>
                  Seller
                  <hr />
                </span>
              </Link>
            </div>

            <div className='tab-content p-3'>
              <div
                id='seller'
                className={`tab-pane fade ${
                  activeToggle === 'seller' ? 'active show' : ''
                }`}
              >
                {activeToggle === 'seller' && <AddSeller />}
              </div>
            </div>
          </div>
        </div>
      )}
    </>
  ) : (
    <NotFound />
  )
}

export default ManageSeller
