import React, { useEffect, useState } from 'react'
import Accordion from 'react-bootstrap/Accordion'
import {
  Link,
  useLocation,
  useNavigate,
  useSearchParams
} from 'react-router-dom'
import Select from 'react-select'
import SearchBox from '../../components/Searchbox'
import NotFound from '../../pages/NotFound/NotFound'

// import ListGroup from 'react-bootstrap/ListGroup'
import {
  Button,
  ButtonGroup,
  Dropdown,
  DropdownButton,
  Table
} from 'react-bootstrap'
import ReactPaginate from 'react-paginate'
import { useDispatch, useSelector } from 'react-redux'
import Swal from 'sweetalert2'
import { useImmer } from 'use-immer'
import ArchieveIcon from '../../components/AllSvgIcon/ArchieveIcon'
import EditIcon from '../../components/AllSvgIcon/EditIcon'
import InfoRoundedIcon from '../../components/AllSvgIcon/InfoRoundedIcon'
import Previewicon from '../../components/AllSvgIcon/Previewicon'
import HKBadge from '../../components/Badges'
import { customStyles } from '../../components/customStyles'
import Loader from '../../components/Loader'
import CustomToast from '../../components/Toast/CustomToast'
import { allCrudNames, allPages, checkPageAccess } from '../../lib/AllPageNames'
import axiosProvider from '../../lib/AxiosProvider'
import { _SwalDelete, _exception } from '../../lib/exceptionMessage'
import {
  callApi,
  pageRangeDisplayed,
  prepareDisplayCalculationData,
  productStatus,
  showToast
} from '../../lib/GetBaseUrl'
import { _productImg_ } from '../../lib/ImagePath'
import { setPageTitle } from '../redux/slice/pageTitleSlice'
import BulkUpload from './BulkUpload'
import ProductDetail from './ProductDetail'

const ManageProduct = () => {
  const [totalSellertList, setTotalSellerList] = useState()
  const [loading, setLoading] = useState(false)
  const [allState, setAllState] = useState()
  const [data, setData] = useState()
  const [toast, setToast] = useState({
    show: false,
    text: null,
    variation: null
  })
  const navigate = useNavigate()
  const [totalSaleModalShow, setTotalSaleModalShow] = useState(false)
  const [previewOffcanvasShow, setPreviewOffcanvasShow] = useState(false)
  const [mounted, setMounted] = useState(false)
  const [searchParams] = useSearchParams()
  const id = searchParams.get('id')
  const [quickUpdate, setQuickUpdate] = useState({
    id: null,
    show: false,
    isDataUpdated: false
  })
  const [filterDetails, setFilterDetails] = useImmer({
    sellerId: '',
    brandId: '',
    categoryId: '',
    live: '',
    pageIndex: 1,
    pageSize: 10,
    status: '',
    searchText: ''
  })
  const location = useLocation()
  const { userInfo, pageAccess } = useSelector((state) => state.user)
  const [modalShow, setModalShow] = useState(false)

  useEffect(() => {
    dispatch(setPageTitle('Product'))
    setMounted(true)
  }, [])

  const dispatch = useDispatch()

  const handlePageClick = (event) => {
    setFilterDetails((draft) => {
      draft.pageIndex = event.selected + 1
    })
    fetchPageData({ ...filterDetails, pageIndex: event.selected + 1 })
  }

  const handleArchive = async (data) => {
    try {
      setLoading(true)
      const response = await axiosProvider({
        method: 'PUT',
        endpoint: 'Product/ArchiveProduct',
        queryString: `?productId=${data?.productId}&sellerProductId=${data?.sellerProductId}`,
        location: location.pathname,
        userId: userInfo?.userId
      })
      setLoading(false)

      if (response?.data?.code === 200) {
        fetchPageData(filterDetails)
      }

      showToast(toast, setToast, response)
    } catch {
      setLoading(false)
      showToast(toast, setToast, {
        data: {
          message: _exception?.message,
          code: 204
        }
      })
    }
  }

  const fetchAllGenericData = async (apiUrls) => {
    try {
      const responseArray = await Promise.all(
        apiUrls.map((url) => callApi(url.endpoint, url.queryString, url.state))
      )

      return responseArray.filter((response) => response !== undefined)
    } catch (error) {}
  }

  useEffect(() => {
    if (id) {
      getOrderDetail(id)
    }
  }, [id])

  useEffect(() => {
    fetchPageData(filterDetails)

    if (totalSaleModalShow) {
      fetchData(`Product/ById?productId=${quickUpdate?.id}`, (res) => {
        setTotalSellerList({
          ...res?.data?.data,
          filteredSellerProducts: res?.data?.data?.sellerProducts
        })
      })
    }
  }, [quickUpdate?.isDataUpdated])

  useEffect(() => {
    let apiUrls = [
      {
        endpoint: 'MasterProductList/getProductCounts',
        queryString: '',
        state: 'productCount'
      },
      {
        endpoint: 'SellerData/bindAllSeller',
        queryString: '',
        state: 'sellerDetails'
      },
      {
        endpoint: 'Brand',
        queryString: '?pageIndex=0&pageSize=0',
        state: 'brand'
      },
      {
        endpoint: 'MainCategory/getEndCategory',
        queryString: '',
        state: 'category'
      }
    ]
    const fetchData = async () => {
      let category, sellerDetails, productCount, brand
      const responses = await fetchAllGenericData(apiUrls)
      responses.forEach((response) => {
        switch (response.state) {
          case 'productCount':
            productCount = response.data
            break

          case 'sellerDetails':
            sellerDetails = response?.data
            break

          case 'brand':
            brand = response?.data
            break

          case 'category':
            category = response?.data
            break

          default:
            break
        }
      })

      setAllState({
        ...allState,
        productCount,
        sellerDetails,
        brand,
        category
      })
    }
    fetchData()
  }, [])

  const fetchData = async (endpoint, setterFunc) => {
    setLoading(true)
    const response = await axiosProvider({
      method: 'GET',
      endpoint
    })
      .then((res) => {
        setLoading(false)
        if (res.status === 200) {
          return setterFunc(res)
        }
      })
      .catch((err) => {
        setLoading(false)
      })
  }

  const fetchPageData = async (filterDetails) => {
    try {
      setLoading(true)

      const response = await axiosProvider({
        method: 'GET',
        endpoint: `MasterProductList/getChild?PageIndex=${filterDetails?.pageIndex}&PageSize=${filterDetails?.pageSize}&categoryId=${filterDetails?.categoryId}&brandId=${filterDetails?.brandId}&sellerId=${filterDetails?.sellerId}&status=${filterDetails?.status}&live=${filterDetails?.live}&searchText=${filterDetails?.searchText}`
      })

      setLoading(false)

      if (response?.status === 200) {
        setData(response)
      }
    } catch {
      setLoading(false)
      showToast(toast, setToast, {
        data: {
          message: _exception?.message,
          code: 204
        }
      })
    }
  }

  const fetchCalculation = async (endpoint, data, setterFunc) => {
    // setLoading(true)
    const response = await axiosProvider({
      method: 'POST',
      endpoint,
      data
    })
      .then((res) => {
        // setLoading(false)
        if (res.status === 200) {
          return setterFunc(res?.data)
        }
      })
      .catch((err) => {
        // setLoading(false)
      })
  }

  const getOrderDetail = async (id) => {
    try {
      setLoading(true)
      const response = await axiosProvider({
        method: 'GET',
        endpoint: `Product/ById?productId=${id}&isDeleted=false&isArchive=false`
      })
      setLoading(false)

      if (response?.data?.code === 200) {
        setPreviewOffcanvasShow(!previewOffcanvasShow)
        let preview = response?.data?.data
        setAllState({
          ...allState,
          preview
        })
      }
    } catch {
      setLoading(false)
      showToast(toast, setToast, {
        data: {
          message: _exception?.message,
          code: 204
        }
      })
    }
  }

  return checkPageAccess(pageAccess, allPages?.product, allCrudNames?.read) ? (
    mounted && (
      <div className='card'>
        <div className='card-body'>
          <div className='d-flex gap-4 flex-column'>
            {loading && !totalSaleModalShow && <Loader />}
            {toast?.show && (
              <CustomToast text={toast?.text} variation={toast?.variation} />
            )}
            <div className='row w-100 pv-information-main justify-content-between align-items-center'>
              <div className='col-md-8 pv-info-innercol'>
                <div className='row pv-info-innerbox'>
                  {/* Total Products Count */}
                  <div className='col active-numbers'>
                    <div
                      style={{ borderColor: '#0062D6' }}
                      className='pv-innerbox-col d-flex align-items-center'
                    >
                      {/* <i className='m-icon m-icon--exclamationmark'></i> */}
                      <InfoRoundedIcon
                        tooltipText={
                          'Total count excludes archived/deleted products.'
                        }
                      />
                      <h3>
                        {allState?.productCount?.total
                          ? allState?.productCount?.total
                          : 0}
                      </h3>
                      <p style={{ color: '#0062D6' }}>Total</p>
                    </div>
                  </div>

                  {/* Active Products Count */}
                  <div className='col active-numbers'>
                    <div
                      style={{ borderColor: '#23A423' }}
                      className='pv-innerbox-col d-flex align-items-center'
                    >
                      {/* <i className='m-icon m-icon--exclamationmark'></i> */}
                      <InfoRoundedIcon
                        tooltipText={
                          "Active products include live and 'Active' status."
                        }
                      />
                      <h3>
                        {allState?.productCount?.active
                          ? allState?.productCount?.active
                          : 0}
                      </h3>
                      <p style={{ color: '#23A423' }}>Active</p>
                    </div>
                  </div>

                  {/* Inactive Products Count */}
                  <div className='col active-numbers'>
                    <div
                      style={{ borderColor: '#31BFBF' }}
                      className='pv-innerbox-col d-flex align-items-center'
                    >
                      {/* <i className='m-icon m-icon--exclamationmark'></i> */}
                      <InfoRoundedIcon
                        tooltipText={
                          'Inactive products are not active or live.'
                        }
                      />
                      <h3>
                        {allState?.productCount?.inactivate
                          ? allState?.productCount?.inactivate
                          : 0}
                      </h3>
                      <p style={{ color: '#31BFBF' }}>Inactive</p>
                    </div>
                  </div>

                  {/* Bulk Upload Products Count */}
                  <div className='col active-numbers'>
                    <div
                      style={{ borderColor: '#7831BF' }}
                      className='pv-innerbox-col d-flex align-items-center'
                    >
                      {/* <i className='m-icon m-icon--exclamationmark'></i> */}
                      <InfoRoundedIcon
                        tooltipText={'Bulk Uploaded Products Count'}
                      />
                      <h3>
                        {allState?.productCount?.inBulkUpload
                          ? allState?.productCount?.inBulkUpload
                          : 0}
                      </h3>
                      <p style={{ color: '#7831BF' }}>Bulk Upload</p>
                    </div>
                  </div>
                </div>
              </div>
              <div className='col-md-3 pv-info-innercol align-items-center p-0'>
                <div className='d-flex flex-wrap gap-3 justify-content-end'>
                  {/* <div className="col-md-7"> */}
                  {/* <Link to='/manage-product/add-existing-product'>
                  <Button
                    variant='outline-primary'
                    className='d-flex align-items-center ms-auto gap-2 fw-semibold btn-outline-lightblue'
                  >
                    <svg
                      xmlns='http://www.w3.org/2000/svg'
                      width='20px'
                      height='20px'
                      viewBox='0 0 24 24'
                    >
                      <path
                        fill='none'
                        stroke='#4067BC'
                        strokeWidth='2'
                        d='M12,22 L12,2 M2,12 L22,12'
                      />
                    </svg>
                    Add In Existing Product
                  </Button>
                </Link> */}
                  {/* </div>
                <div className="col"> */}
                  {checkPageAccess(
                    pageAccess,
                    allPages?.product,
                    allCrudNames?.write
                  ) && (
                    <Dropdown as={ButtonGroup}>
                      <Link to='/manage-product/add-product'>
                        <Button
                          variant='warning'
                          className='fw-600 d-flex align-items-center gap-2'
                        >
                          <i className='m-icon m-icon--plusblack'></i>
                          Add New Product
                        </Button>
                      </Link>
                      {checkPageAccess(
                        pageAccess,
                        [allPages?.addInExisting, allPages?.archiveProduct],
                        allCrudNames?.read
                      ) && (
                        <Dropdown.Toggle
                          split
                          variant='warning'
                          id='dropdown-split-basic'
                        />
                      )}

                      <Dropdown.Menu>
                        {/* {checkPageAccess(
                          pageAccess,
                          allPages?.addInExisting,
                          allCrudNames?.read
                        ) && (
                          <Dropdown.Item>
                            <Link to='/manage-product/add-existing-product'>
                              Add In Existing Product
                            </Link>
                          </Dropdown.Item>
                        )} */}
                        {checkPageAccess(
                          pageAccess,
                          allPages?.archiveProduct,
                          allCrudNames?.read
                        ) && (
                          <Dropdown.Item>
                            <Link to='/manage-product/archive-products'>
                              Archive products
                            </Link>
                          </Dropdown.Item>
                        )}
                        {/* <Dropdown.Item>
                          <Link to='/manage-product/deleted-products'>
                            Deleted products
                          </Link>
                        </Dropdown.Item> */}
                        {checkPageAccess(
                          pageAccess,
                          allPages?.product,
                          allCrudNames?.read
                        ) && (
                          <Dropdown.Item
                            onClick={() => setModalShow(!modalShow)}
                          >
                            <span>Bulk Upload</span>
                          </Dropdown.Item>
                        )}
                      </Dropdown.Menu>
                    </Dropdown>
                  )}
                  {/* <Link to='/manage-product/add-product'>
                  <Button
                    variant='primary'
                    className='d-flex align-items-center ms-auto gap-2 fw-semibold btn-yellow-light'
                  >
                    <i className='m-icon m-icon--plusblack'></i>
                    Add New Product
                  </Button>
                </Link> */}
                </div>
                {/* </div> */}
              </div>
            </div>
            {/* <div className="pv-filterproduct-main">
            <Accordion defaultActiveKey="0">
              <Accordion.Item eventKey="0">
                <Accordion.Header>Filter Products</Accordion.Header>
                <Accordion.Body>
                  <Formik>
                    <Form>
                    </Form>fetchAllGenericData
                  </Formik>
                </Accordion.Body>
              </Accordion.Item>
            </Accordion>
          </div> */}

            {/* <Offcanvas
              className='pv-offcanvas'
              placement='end'
              show={totalSaleModalShow}
              backdrop='static'
              onHide={() => {
                setTotalSaleModalShow(!totalSaleModalShow)
                setTotalSellerList()
              }}
            >
              <Offcanvas.Header closeButton>
                <Offcanvas.Title className='bold'>Total Seller</Offcanvas.Title>
              </Offcanvas.Header>
              <Offcanvas.Body>
                <div className='d-flex flex-wrap mb-3'>
                  {toast?.show && (
                    <CustomToast
                      text={toast?.text}
                      variation={toast?.variation}
                    />
                  )}
                  {loading && <Loader />}
                  <div className='col-md-4 mt-2'>
                    <div className='d-flex align-content-center'>
                      <span className='bold'>Product Name:&nbsp;</span>
                      <span>{totalSellertList?.productName}</span>
                    </div>
                  </div>
                  <div className='col-md-4 mt-2'>
                    <div className='d-flex align-content-center'>
                      <span className='bold'>Brand Name:&nbsp;</span>
                      <span>
                        {totalSellertList?.sellerProducts[0]?.brandName}
                      </span>
                    </div>
                  </div>
                  <div className='col-md-4 mt-2'>
                    <div className='d-flex align-content-center'>
                      <span className='bold'>Product Code:&nbsp;</span>
                      <span>{totalSellertList?.companySKUCode}</span>
                    </div>
                  </div>
                  <div className='col-md-4 mt-2'>
                    <div className='d-flex align-content-center'>
                      <span className='bold'>Size:&nbsp;</span>
                      <span>2 Ton</span>
                    </div>
                  </div>

                  <div className='col-md-4 mt-2'>
                    <div className='d-flex align-content-center'>
                      <span className='bold'>Color:&nbsp;</span>
                      <span>
                        {totalSellertList?.productColorMapping[0]?.colorName}
                      </span>
                    </div>
                  </div>
                  <div className='col-md-4 mt-2'>
                    <div className='d-flex align-content-center'>
                      <span className='bold'>No. of Sellers:&nbsp;</span>
                      <span>{totalSellertList?.sellerProducts?.length}</span>
                    </div>
                  </div>
                </div>
                <div className='row'>
                  <div className='col-md-3'>
                    <Select
                      id='sellerID'
                      styles={customStyles}
                      menuPortalTarget={document.body}
                      isClearable
                      placeholder='Select Seller'
                      options={
                        totalSellertList?.sellerProducts?.length > 0 &&
                        totalSellertList?.sellerProducts?.map(
                          ({ sellerID, sellerName }) => ({
                            value: sellerID,
                            label: sellerName
                          })
                        )
                      }
                      onChange={(e) => {
                        let sellerProducts = totalSellertList?.sellerProducts
                        if (e?.value)
                          sellerProducts = sellerProducts?.filter(
                            (data) => data?.sellerID === e?.value
                          )
                        setTotalSellerList({
                          ...totalSellertList,
                          filteredSellerProducts: sellerProducts
                        })
                      }}
                    />
                  </div>
                  <div className='col-md-3'>
                    <SearchBox
                      placeholderText={'Search'}
                      searchclassnamewrapper={'searchbox-wrapper ms-auto'}
                      // value={searchText}
                      onChange={(e) => {
                        let sellerDetails = totalSellertList?.sellerProducts

                        let searchResult = searchArray(
                          sellerDetails,
                          e?.target?.value
                        )
                        setTotalSellerList({
                          ...totalSellertList,
                          filteredSellerProducts: searchResult
                        })
                      }}
                    />
                  </div>
                </div>
                {totalSellertList?.filteredSellerProducts?.length > 0 && (
                  <>
                    <Table className='align-middle table-list mt-4'>
                      <thead>
                        <tr>
                          <th>Seller Name</th>
                          <th>SKU Code</th>
                          <th>MRP</th>
                          <th>Selling Price</th>
                          <th>Qty</th>
                          <th>Status</th>
                          <th>Live</th>
                          {checkPageAccess(
                            pageAccess,
                            [allPages?.product, allPages?.archiveProduct],
                            [allCrudNames?.update, allCrudNames?.delete]
                          ) && <th>Action</th>}
                        </tr>
                      </thead>
                      <tbody>
                        {totalSellertList?.filteredSellerProducts?.length >
                        0 ? (
                          totalSellertList?.filteredSellerProducts?.map(
                            (data, index) => (
                              <tr
                                className='position-relative'
                                key={Math.floor(Math.random() * 10000000)}
                              >
                                <td>{data?.sellerName}</td>
                                <td>{data?.sellerSKU}</td>
                                <td>
                                  {Math.max(
                                    ...data?.productPrices?.map(
                                      (product) => product.mrp
                                    )
                                  )}
                                </td>
                                <td>
                                  {Math.max(
                                    ...data?.productPrices?.map(
                                      (product) => product.sellingPrice
                                    )
                                  )}
                                </td>
                                <td>
                                  {data?.productPrices?.reduce((curr, acc) => {
                                    return curr + acc.quantity
                                  }, 0)}
                                </td>
                                <td>
                                  <HKBadge
                                    badgesbgname={
                                      data?.status?.toLowerCase() === 'active'
                                        ? 'success'
                                        : 'danger'
                                    }
                                    badgesTxtname={data?.status}
                                    badgeClassname={''}
                                  />
                                </td>
                                <td>{data?.live ? 'Yes' : 'No'}</td>
                                {checkPageAccess(
                                  pageAccess,
                                  [allPages?.product, allPages?.archiveProduct],
                                  [allCrudNames?.update, allCrudNames?.delete]
                                ) && (
                                  <td className='text-center'>
                                    <DropdownButton
                                      align='end'
                                      // className="d-none"

                                      title={
                                        <i className='m-icon m-icon--dots'></i>
                                      }
                                      id='dropdown-menu-align-end'
                                      className='custom_dropdown'
                                    >
                                      {checkPageAccess(
                                        pageAccess,
                                        allPages?.product,
                                        allCrudNames?.update
                                      ) && (
                                        <Dropdown.Item
                                          eventKey='1'
                                          onClick={() => {
                                            setQuickUpdate({
                                              sellerId: data?.sellerID,
                                              id: data.productID,
                                              show: !quickUpdate.show
                                            })
                                          }}
                                        >
                                          <EditIcon />
                                          Edit
                                        </Dropdown.Item>
                                      )}
                                      {checkPageAccess(
                                        pageAccess,
                                        allPages?.archiveProduct,
                                        allCrudNames?.delete
                                      ) && (
                                        <Dropdown.Item
                                          eventKey='2'
                                          onClick={() => {
                                            Swal.fire({
                                              title:
                                                'Are you sure want to archive?',
                                              text: '',
                                              icon: _SwalDelete.icon,
                                              showCancelButton:
                                                _SwalDelete.showCancelButton,
                                              confirmButtonColor:
                                                _SwalDelete.confirmButtonColor,
                                              cancelButtonColor:
                                                _SwalDelete.cancelButtonColor,
                                              confirmButtonText: 'Archive',
                                              cancelButtonText:
                                                _SwalDelete.cancelButtonText
                                            }).then((result) => {
                                              if (result.isConfirmed) {
                                                handleArchive({
                                                  productId: data?.productID,
                                                  sellerProductId: data?.id
                                                })
                                              } else if (result.isDenied) {
                                              }
                                            })
                                          }}
                                        >
                                          <ArchieveIcon />
                                          Archive
                                        </Dropdown.Item>
                                      )}
                                    </DropdownButton>
                                  </td>
                                )}
                              </tr>
                            )
                          )
                        ) : (
                          <tr>
                            <td colSpan={8} className='text-center'>
                              {data?.data?.message}
                            </td>
                          </tr>
                        )}
                      </tbody>
                    </Table>
                  </>
                )}
              </Offcanvas.Body>
            </Offcanvas>

            {/* {quickUpdate?.show && (
              <QuickUpdate
                quickUpdate={quickUpdate}
                setQuickUpdate={setQuickUpdate}
                setToast={setToast}
                toast={toast}
              />
            )} */}

            {/* <Offcanvas
              className='pv-offcanvas'
              placement='end'
              show={SellerEdit}
              backdrop={'static'}
              onHide={() => setSellerEdit(false)}
            >
              <Offcanvas.Header closeButton>
                <Offcanvas.Title className='bold'>
                  Pricing Detail
                </Offcanvas.Title>
              </Offcanvas.Header>
              <Offcanvas.Body></Offcanvas.Body>
            </Offcanvas> */}

            {allState?.preview && (
              <ProductDetail
                data={data}
                allState={allState}
                setAllState={setAllState}
                previewOffcanvasShow={previewOffcanvasShow}
                setPreviewOffcanvasShow={setPreviewOffcanvasShow}
                fetchCalculation={fetchCalculation}
                prepareDisplayCalculationData={prepareDisplayCalculationData}
              />
            )}

            <div
              style={{ backgroundColor: 'var(--bs-white)' }}
              className='pv-product-table-main rounded'
            >
              <Accordion className='mb-4 pv_accordian'>
                <Accordion.Item eventKey='0'>
                  <Accordion.Header className='pv-product-filter-main'>
                    <svg
                      xmlns='http://www.w3.org/2000/svg'
                      fill='#000000'
                      width='18px'
                      height='18px'
                      viewBox='0 0 32 32'
                    >
                      <path d='M12,25l6.67,6.67a1,1,0,0,0,.7.29.91.91,0,0,0,.39-.08,1,1,0,0,0,.61-.92V13.08L31.71,1.71A1,1,0,0,0,31.92.62,1,1,0,0,0,31,0H1A1,1,0,0,0,.08.62,1,1,0,0,0,.29,1.71L11.67,13.08V24.33A1,1,0,0,0,12,25ZM3.41,2H28.59l-10,10a1,1,0,0,0-.3.71V28.59l-4.66-4.67V12.67a1,1,0,0,0-.3-.71Z' />
                    </svg>
                    Filter
                  </Accordion.Header>
                  <Accordion.Body>
                    <div className='row pv-filterprdct-main g-4 align-items-center'>
                      <div className='col-md-3'>
                        <Select
                          styles={customStyles}
                          id='sellerDetails'
                          isClearable
                          menuPortalTarget={document.body}
                          value={
                            filterDetails?.sellerId && {
                              label: filterDetails?.sellerName,
                              value: filterDetails?.sellerId
                            }
                          }
                          placeholder='Select Seller'
                          options={
                            allState?.sellerDetails &&
                            allState?.sellerDetails?.map(
                              ({ userID, fullName }) => ({
                                label: fullName,
                                value: userID
                              })
                            )
                          }
                          onChange={(e) => {
                            const fetchAllData = () => {
                              // setLoading(true)
                              Promise.all([
                                callApi(
                                  'AssignBrandToSeller/bySeller&BrandId',
                                  `?sellerId=${e?.value}`
                                )
                              ])
                                .then(([brandResp]) => {
                                  // setLoading(false)
                                  setAllState({
                                    ...allState,
                                    sellerBrand: brandResp ?? []
                                  })
                                })
                                .catch(() => {
                                  // setLoading(false)
                                })
                            }
                            if (e?.value) {
                              fetchAllData()
                            } else {
                              setAllState({
                                ...allState,
                                sellerBrand: null
                              })
                            }
                            setFilterDetails((draft) => {
                              draft.sellerId = e?.value ? e?.value : ''
                              draft.sellerName = e?.label ? e?.label : ''
                              draft.brandId = ''
                              draft.brandName = ''
                            })
                          }}
                        />
                      </div>
                      <div className='col-md-3'>
                        <Select
                          styles={customStyles}
                          id='brandId'
                          isClearable
                          menuPortalTarget={document.body}
                          value={
                            filterDetails?.brandId && {
                              label: filterDetails?.brandName,
                              value: filterDetails?.brandId
                            }
                          }
                          placeholder='Select Brand'
                          options={
                            allState?.sellerBrand
                              ? allState?.sellerBrand?.map(
                                  ({ brandId, brandName }) => ({
                                    label: brandName,
                                    value: brandId
                                  })
                                )
                              : allState?.brand?.map(({ id, name }) => ({
                                  value: id,
                                  label: name
                                }))
                          }
                          onChange={(e) => {
                            setFilterDetails((draft) => {
                              draft.brandId = e?.value ? e?.value : ''
                              draft.brandName = e?.label ? e?.label : ''
                            })
                          }}
                        />
                      </div>
                      <div className='col-md-3'>
                        <Select
                          styles={customStyles}
                          id='status'
                          menuPortalTarget={document.body}
                          isClearable
                          value={
                            filterDetails?.status && {
                              label: filterDetails?.status,
                              value: filterDetails?.status
                            }
                          }
                          placeholder='Product Status'
                          options={productStatus?.map(({ value, label }) => ({
                            value,
                            label
                          }))}
                          onChange={(e) => {
                            setFilterDetails((draft) => {
                              draft.status = e?.value ? e?.value : ''
                            })
                          }}
                        />
                      </div>
                      <div className='col-md-3'>
                        <Select
                          styles={customStyles}
                          id='live'
                          isClearable
                          menuPortalTarget={document.body}
                          placeholder='Live Status'
                          value={
                            filterDetails?.live && {
                              label:
                                filterDetails?.live?.toLowerCase() === 'true'
                                  ? 'Yes'
                                  : 'No',
                              value: filterDetails?.live
                            }
                          }
                          options={[
                            {
                              label: 'Yes',
                              value: 'true'
                            },
                            {
                              label: 'No',
                              value: 'false'
                            }
                          ]}
                          onChange={(e) => {
                            setFilterDetails((draft) => {
                              draft.live = e?.value ? e?.value : ''
                            })
                          }}
                        />
                      </div>
                      <div className='col-md-6'>
                        <Select
                          styles={customStyles}
                          id='categoryId'
                          menuPortalTarget={document.body}
                          placeholder='Select Category'
                          isClearable
                          value={
                            filterDetails?.categoryId && {
                              label: filterDetails?.categoryName,
                              value: filterDetails?.categoryId
                            }
                          }
                          options={
                            allState?.category &&
                            allState?.category?.map(
                              ({ id, pathNames, name }) => ({
                                value: id,
                                label: pathNames,
                                categoryName: name
                              })
                            )
                          }
                          onChange={(e) => {
                            setFilterDetails((draft) => {
                              draft.categoryId = e?.value ? e?.value : ''
                              draft.categoryName = e?.label ? e?.label : ''
                            })
                          }}
                        />
                      </div>
                      <div className='col-md-3'>
                        <SearchBox
                          placeholderText='Search'
                          searchclassnamewrapper={'searchbox-wrapper w-100'}
                          btnVariant={'secondary'}
                          isClearable
                          value={filterDetails?.searchText}
                          onChange={(e) => {
                            setFilterDetails((draft) => {
                              draft.searchText = e?.target?.value
                            })
                          }}
                        />
                      </div>

                      <div className='col-md-3 gap-2 d-flex align-items-center'>
                        <Button
                          variant='primary'
                          size='md'
                          onClick={async () => {
                            setFilterDetails((draft) => {
                              draft.pageIndex = 1
                            })
                            fetchPageData({
                              ...filterDetails,
                              pageIndex: 1
                            })
                          }}
                        >
                          Search
                        </Button>
                        <Button
                          variant='light'
                          size='md'
                          onClick={() => {
                            setFilterDetails((draft) => {
                              draft.sellerId = ''
                              draft.brandId = ''
                              draft.categoryId = ''
                              draft.live = ''
                              draft.status = ''
                              draft.searchText = ''
                              draft.pageIndex = 1
                              draft.pageSize = 10
                            })
                            fetchPageData({
                              sellerId: '',
                              brandId: '',
                              categoryId: '',
                              live: '',
                              status: '',
                              searchText: '',
                              pageIndex: 1,
                              pageSize: 10
                            })
                          }}
                        >
                          Reset
                        </Button>
                      </div>
                    </div>
                  </Accordion.Body>
                </Accordion.Item>
              </Accordion>

              <div className='d-flex align-items-center gap-3 justify-content-end'>
                <div className='d-flex align-items-center'>
                  <label className='me-1'>Show</label>
                  <select
                    styles={customStyles}
                    menuPortalTarget={document.body}
                    name='dataget'
                    id='parpageentries'
                    className='form-select me-1'
                    onChange={(e) => {
                      setFilterDetails((draft) => {
                        draft.pageSize = e?.target?.value
                        draft.pageIndex = 1
                      })
                      fetchPageData({
                        ...filterDetails,
                        pageSize: e?.target?.value,
                        pageIndex: 1
                      })
                    }}
                  >
                    <option value='10'>10</option>
                    <option value='25'>25</option>
                    <option value='50'>50</option>
                    <option value='100'>100</option>
                    <option value='200'>200</option>
                    <option value='500'>500</option>
                  </select>
                </div>
              </div>
              <Table
                /*style={{ borderCollapse: 'separate', borderSpacing: '0px 10px' }}*/
                className='align-middle mt-3 table-list manage-product-table'
              >
                <thead className='align-middle'>
                  <tr>
                    {/* <th>
                    <IpCheckbox
                      // checkboxLabel={'Size'}
                      checkboxid={'isAllowSize'}
                      value={'isAllowSize'}
                    />
                  </th> */}
                    {/* <th>&nbsp;</th> */}
                    <th>Product Details</th>
                    <th>Seller</th>
                    <th>Brand</th>
                    <th>Category</th>
                    {/* <th>Pricing Details</th>
                    <th>Quantity</th> */}
                    <th>Status</th>
                    <th>Live</th>
                    <th className='text-center'>Action</th>
                    {/* <th>&nbsp;</th> */}
                  </tr>
                </thead>
                <tbody
                  className='border border-1'
                  style={{ boxShadow: 'unset' }}
                >
                  {data?.data?.data?.length > 0 ? (
                    data?.data?.data?.map((data) => (
                      <React.Fragment
                        key={`${data.id}${Math.floor(
                          Math.random() * 10000000
                        )}`}
                      >
                        <tr
                          className='timeline'
                          key={`${data.id}${Math.floor(
                            Math.random() * 10000000
                          )}`}
                        >
                          {/* <td style={{ width: '0px' }}>
                          <IpCheckbox
                            checkboxid={'isAllowSize'}
                            value={'isAllowSize'}
                          />
                        </td> */}
                          {/* <td style={{ width: '0px' }}>

                        </td> */}
                          <td>
                            <div className='d-flex gap-3 align-items-center'>
                              <img
                                style={{
                                  width: '100px'
                                }}
                                className='img-thumbnail position-relative cp'
                                src={
                                  data?.image1 &&
                                  `${process.env.REACT_APP_IMG_URL}${_productImg_}${data?.image1}`
                                }
                                alt='...'
                                onClick={() => getOrderDetail(data?.id)}
                              />
                              <div>
                                <p
                                  className='pv-font-hard mb-1 cp'
                                  onClick={() => getOrderDetail(data?.id)}
                                >
                                  Name: {data?.productName}
                                </p>

                                <p className='mb-1'>
                                  Custom Name: <b>{data?.customeProductName}</b>
                                </p>
                                <small>
                                  Product Code: <b>{data?.id}</b>
                                </small>
                                {/* <Button
                                  onClick={() => handleRowClick(data?.id)}
                                  variant='outline-primary'
                                  className='btn-outline-lightblue  btn-sm d-flex align-items-center gap-1'
                                  style={{ lineHeight: '10px' }}
                                >
                                  Product Variants:{' '}
                                  <span>{data?.totalVariant}</span>
                                  <svg
                                    style={
                                      expandedRow === data?.id
                                        ? { transform: 'rotate(180deg)' }
                                        : { transform: 'rotate(0deg)' }
                                    }
                                    height='15'
                                    width='15'
                                    viewBox='0 0 20 20'
                                    aria-hidden='true'
                                    focusable='false'
                                    className='css-tj5bde-Svg'
                                  >
                                    <path d='M4.516 7.548c0.436-0.446 1.043-0.481 1.576 0l3.908 3.747 3.908-3.747c0.533-0.481 1.141-0.446 1.574 0 0.436 0.445 0.408 1.197 0 1.615-0.406 0.418-4.695 4.502-4.695 4.502-0.217 0.223-0.502 0.335-0.787 0.335s-0.57-0.112-0.789-0.335c0 0-4.287-4.084-4.695-4.502s-0.436-1.17 0-1.615z'></path>
                                  </svg>
                                </Button> */}
                              </div>
                            </div>
                          </td>
                          <td>{data?.sellerName}</td>
                          <td>{data?.brandName}</td>
                          <td>{data?.categoryName}</td>
                          {/* <td className='text-center'> - </td> */}
                          {/* <td className='text-center'>{data?.quantity}</td> */}
                          <td className='text-center'>
                            <HKBadge
                              badgesbgname={
                                data?.status?.toLowerCase() === 'active'
                                  ? 'success'
                                  : 'danger'
                              }
                              badgesTxtname={data.status}
                              badgeClassname={''}
                            />
                          </td>
                          <td className='text-center'>
                            {data?.live ? 'Yes' : 'No'}
                          </td>
                          <td className='text-center'>
                            <DropdownButton
                              align='end'
                              // className="d-none"
                              title={<i className='m-icon m-icon--dots'></i>}
                              id='dropdown-menu-align-end'
                              className='custom_dropdown'
                            >
                              {checkPageAccess(
                                pageAccess,
                                allPages?.product,
                                allCrudNames?.update
                              ) && (
                                <Dropdown.Item
                                  href={`/manage-product/add-product?id=${data?.id}&sellerId=${data?.sellerId}`}
                                  eventKey='1'
                                  style={{
                                    textDecoration: 'none',
                                    color: 'inherit'
                                  }}
                                >
                                  <EditIcon />
                                  Edit
                                </Dropdown.Item>
                              )}
                              {/* {checkPageAccess(
                                    pageAccess,
                                    allPages?.addInExisting,
                                    allCrudNames?.update
                                  ) && (
                                    <Dropdown.Item
                                      eventKey='2'
                                      onClick={() => {
                                        navigate(
                                          `add-existing-product?id=${expandedData?.id}`
                                        )
                                      }}
                                    >
                                      <AddInExistingIcon />
                                      Add in existing
                                    </Dropdown.Item>
                                  )} */}
                              <Dropdown.Item
                                eventKey='2'
                                onClick={() => getOrderDetail(data?.id)}
                              >
                                <Previewicon />
                                Preview
                              </Dropdown.Item>
                              {/* {checkPageAccess(
                                    pageAccess,
                                    allPages?.product,
                                    allCrudNames?.update
                                  ) && (
                                    <Dropdown.Item
                                      eventKey='2'
                                      onClick={() => {
                                        setQuickUpdate({
                                          sellerId: expandedData?.sellerId,
                                          id: expandedData.id,
                                          show: !quickUpdate.show
                                        })
                                        // fetchData(
                                        //   `Product/ById?productId=${expandedData?.id}`,
                                        //   (res) => {
                                        //     setTotalSellerList({
                                        //       ...res?.data?.data,
                                        //       filteredSellerProducts:
                                        //         res?.data?.data?.sellerProducts
                                        //     })
                                        //   }
                                        // )
                                      }}
                                    >
                                      <QuickEditIcon />
                                      Quick Edit
                                    </Dropdown.Item>
                                  )} */}
                              {checkPageAccess(
                                pageAccess,
                                allPages?.archiveProduct,
                                allCrudNames?.delete
                              ) && (
                                <Dropdown.Item
                                  eventKey='2'
                                  onClick={() => {
                                    Swal.fire({
                                      title: 'Are you sure want to archive?',
                                      text: '',
                                      icon: _SwalDelete.icon,
                                      showCancelButton:
                                        _SwalDelete.showCancelButton,
                                      confirmButtonColor:
                                        _SwalDelete.confirmButtonColor,
                                      cancelButtonColor:
                                        _SwalDelete.cancelButtonColor,
                                      confirmButtonText: 'Archive',
                                      cancelButtonText:
                                        _SwalDelete.cancelButtonText
                                    }).then((result) => {
                                      if (result.isConfirmed) {
                                        handleArchive({
                                          productId: data?.id,
                                          sellerProductId: data?.sellerProductId
                                        })
                                      } else if (result.isDenied) {
                                      }
                                    })
                                  }}
                                >
                                  <ArchieveIcon bg='bg' />
                                  Archieve
                                </Dropdown.Item>
                              )}
                            </DropdownButton>
                          </td>
                          {/* <td>&nbsp;</td> */}
                        </tr>
                      </React.Fragment>
                    ))
                  ) : (
                    <tr>
                      <td colSpan={7} className='text-center'>
                        {data?.data?.message}
                      </td>
                    </tr>
                  )}
                </tbody>
              </Table>
              <ReactPaginate
                className='list-inline m-cst--pagination d-flex justify-content-end gap-1'
                breakLabel='...'
                nextLabel=''
                onPageChange={handlePageClick}
                pageRangeDisplayed={pageRangeDisplayed}
                pageCount={data?.data?.pagination?.pageCount}
                previousLabel=''
                renderOnZeroPageCount={null}
                forcePage={filterDetails?.pageIndex - 1}
              />
            </div>
          </div>
        </div>
        {modalShow && (
          <BulkUpload
            modalShow={modalShow}
            setModalShow={setModalShow}
            setLoading={setLoading}
            allState={allState}
            setToast={setToast}
            toast={toast}
            filterDetails={filterDetails}
            fetchPageData={fetchPageData}
            setAllState={setAllState}
          />
        )}
      </div>
    )
  ) : (
    <NotFound />
  )
}

export default ManageProduct
