import React from 'react'
import { Badge } from 'react-bootstrap'

const HKBadge = ({badgesbgname, badgesTxtname, badgeClassname}) => {
  return (
    <div className='badges-wrraper'>
      <Badge bg={badgesbgname || "primary"} className={badgeClassname || "text-capitalize"}>{badgesTxtname || "Primary"}</Badge>
    </div>
  )
}

export default HKBadge
