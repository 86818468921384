import React, { useState } from 'react'
import varified from '../../images/varified.png'
import { Link, useNavigate, useParams } from 'react-router-dom'
import logo from '../../images/archioo-logo.png'
import HKbutton from '../../components/HKButton'
import IpTextbox from '../../components/IpTextbox'
import bagl from '../../images/bagl.png'
import * as Yup from 'yup'
import bagr from '../../images/bagr.png'
import location from '../../images/location.png'
import { ErrorMessage, Form, Formik } from 'formik'
import axiosProvider from '../../lib/AxiosProvider'
import Loader from '../../components/Loader'
import TextError from '../../components/TextError'
import axios from 'axios'
import { getBaseUrl, showToast } from '../../lib/GetBaseUrl'
import CustomToast from '../../components/Toast/CustomToast'
import FormikControl from '../../components/FormikControl'
import { _passwordRegex_ } from '../../lib/Regex'
import { _exception } from '../../lib/exceptionMessage'
const ResetPassword = () => {
  const { token, uid } = useParams()
  const [loading, setLoading] = useState(false)
  const [toast, setToast] = useState({
    show: false,
    text: null,
    variation: null
  })
  const validationSchema = Yup.object().shape({
    newPassword: Yup.string()
      .required('New password is required')
      .min(8, 'New password must be at least 8 characters long')
      .matches(
        _passwordRegex_,
        'New password must contain at least one uppercase letter, one lowercase letter, one number, and one special character'
      ),
    confirmPassword: Yup.string()
      .oneOf([Yup.ref('newPassword'), null], 'Passwords must match')
      .required('Confirm password is required')
  })

  const config = {
    headers: {
      'Content-Type': 'application/json'
    }
  }

  const navigate = useNavigate()

  const onSubmit = (values) => {
    setLoading(true)
    const response = axios
      .post(`${getBaseUrl()}/Account/Admin/ResetPassword`, values, config)
      .then((res) => {
        if (res?.status === 200) {
          if (res?.data?.code == 200) {
            setToast({
              show: true,
              text: res?.data?.message,
              variation: res?.data?.code === 200 ? 'success' : 'error'
            })
            setTimeout(() => {
              navigate('/login')
              setToast({ ...toast, show: false })
            }, 2000)
          } else {
            setToast({
              show: true,
              text: res?.data?.message,
              variation: res?.data?.code === 200 ? 'success' : 'error'
            })
            setTimeout(() => {
              setToast({ ...toast, show: false })
            }, 2000)
          }
          setLoading(false)
        }
      })
      .catch((err) => {
        setLoading(false)

        showToast(toast, setToast, {
          data: {
            message: _exception?.message,
            code: 204
          }
        })
      })
  }

  return (
    <div className='main_background'>
      {loading && <Loader />}
      {toast?.show && (
        <CustomToast text={toast?.text} variation={toast?.variation} />
      )}
      <div className='container_custom  pos-relative'>
        <div>
          <img src={bagl} className='left_bag' alt='' />
          <img src={bagr} className='right_bag' alt='' />
          <img src={location} className='location' alt='' />
        </div>
        <div className='card_custom'>
          <div className='row g-0 d-flex align-items-center py-5rem'>
            <div className='col-6'>
              <div className='card-body px-5'>
                <img src={logo} alt='' className='c-logo' />
                <Formik
                  initialValues={{
                    newPassword: '',
                    newPasswordVisible: false,
                    confirmPasswordVisible: false,
                    confirmPassword: '',
                    token,
                    uid
                  }}
                  validationSchema={validationSchema}
                  onSubmit={onSubmit}
                >
                  {({ values, setFieldValue }) => (
                    <Form>
                      <div className='input-wrapper pv-login-password mb-4 pos-relative'>
                        <FormikControl
                          label='New password'
                          type={values.newPasswordVisible ? 'text' : 'password'}
                          isRequired
                          control='input'
                          name='newPassword'
                          className='he-10'
                          placeholder='New password'
                          onChange={(e) => {
                            setFieldValue('newPassword', e?.target?.value)
                          }}
                          maxLength={50}
                          onBlur={(e) => {
                            let fieldName = e?.target?.name
                            setFieldValue(fieldName, values[fieldName]?.trim())
                          }}
                        />
                        <div
                          className='eye-absolute'
                          style={{
                            position: 'absolute',
                            top: '2.1rem',
                            right: '10px'
                          }}
                        >
                          {!values.newPasswordVisible ? (
                            <svg
                              xmlns='http://www.w3.org/2000/svg'
                              width='18'
                              height='18'
                              onClick={() => {
                                setFieldValue(
                                  'newPasswordVisible',
                                  !values.newPasswordVisible
                                )
                              }}
                              viewBox='0 0 24 24'
                              fill='none'
                              stroke='currentColor'
                              strokeWidth='2'
                              strokeLinecap='round'
                              strokeLinejoin='round'
                              className={`feather feather-eye link-icon toggle-password eye-icon w-75`}
                              data-password=''
                            >
                              <path d='M1 12s4-8 11-8 11 8 11 8-4 8-11 8-11-8-11-8z'></path>
                              <circle cx='12' cy='12' r='3'></circle>
                            </svg>
                          ) : (
                            <svg
                              xmlns='http://www.w3.org/2000/svg'
                              width='18'
                              height='18'
                              viewBox='0 0 24 24'
                              fill='none'
                              stroke='currentColor'
                              onClick={() => {
                                setFieldValue(
                                  'newPasswordVisible',
                                  !values.newPasswordVisible
                                )
                              }}
                              strokeWidth='2'
                              strokeLinecap='round'
                              strokeLinejoin='round'
                              className='feather feather-eye-off link-icon toggle-password eye-off-icon w-75'
                              data-password=''
                            >
                              <path d='M17.94 17.94A10.07 10.07 0 0 1 12 20c-7 0-11-8-11-8a18.45 18.45 0 0 1 5.06-5.94M9.9 4.24A9.12 9.12 0 0 1 12 4c7 0 11 8 11 8a18.5 18.5 0 0 1-2.16 3.19m-6.72-1.07a3 3 0 1 1-4.24-4.24'></path>
                              <line x1='1' y1='1' x2='23' y2='23'></line>
                            </svg>
                          )}
                        </div>
                      </div>

                      <div className='input-wrapper pv-login-password mb-4 pos-relative'>
                        <FormikControl
                          label='Confirm password'
                          type={
                            values.confirmPasswordVisible ? 'text' : 'password'
                          }
                          isRequired
                          control='input'
                          name='confirmPassword'
                          className='he-10'
                          placeholder='Confirm password'
                          onChange={(e) => {
                            setFieldValue('confirmPassword', e?.target?.value)
                          }}
                          maxLength={50}
                          onBlur={(e) => {
                            let fieldName = e?.target?.name
                            setFieldValue(fieldName, values[fieldName]?.trim())
                          }}
                        />
                        <div
                          className='eye-absolute'
                          style={{
                            position: 'absolute',
                            top: '2.1rem',
                            right: '10px'
                          }}
                        >
                          {!values.confirmPasswordVisible ? (
                            <svg
                              xmlns='http://www.w3.org/2000/svg'
                              width='18'
                              height='18'
                              onClick={() => {
                                setFieldValue(
                                  'confirmPasswordVisible',
                                  !values.confirmPasswordVisible
                                )
                              }}
                              viewBox='0 0 24 24'
                              fill='none'
                              stroke='currentColor'
                              strokeWidth='2'
                              strokeLinecap='round'
                              strokeLinejoin='round'
                              className={`feather feather-eye link-icon toggle-password eye-icon w-75`}
                              data-password=''
                            >
                              <path d='M1 12s4-8 11-8 11 8 11 8-4 8-11 8-11-8-11-8z'></path>
                              <circle cx='12' cy='12' r='3'></circle>
                            </svg>
                          ) : (
                            <svg
                              xmlns='http://www.w3.org/2000/svg'
                              width='18'
                              height='18'
                              viewBox='0 0 24 24'
                              fill='none'
                              stroke='currentColor'
                              onClick={() => {
                                setFieldValue(
                                  'confirmPasswordVisible',
                                  !values.confirmPasswordVisible
                                )
                              }}
                              strokeWidth='2'
                              strokeLinecap='round'
                              strokeLinejoin='round'
                              className='feather feather-eye-off link-icon toggle-password eye-off-icon w-75'
                              data-password=''
                            >
                              <path d='M17.94 17.94A10.07 10.07 0 0 1 12 20c-7 0-11-8-11-8a18.45 18.45 0 0 1 5.06-5.94M9.9 4.24A9.12 9.12 0 0 1 12 4c7 0 11 8 11 8a18.5 18.5 0 0 1-2.16 3.19m-6.72-1.07a3 3 0 1 1-4.24-4.24'></path>
                              <line x1='1' y1='1' x2='23' y2='23'></line>
                            </svg>
                          )}
                        </div>
                      </div>

                      <div className='row mb-3'>
                        <div className='col d-flex justify-content-end'>
                          <Link className='c_link' to='/login'>
                            <i className='m-icon m-icon--arrow_back'></i>
                            Back to log in
                          </Link>
                        </div>
                      </div>
                      <HKbutton
                        buttonText='Reset password'
                        customClass='login_btn'
                      />
                    </Form>
                  )}
                </Formik>
              </div>
            </div>
            <div className='col-6'>
              <img src={varified} alt='' className='w-100 custom_cart c_var' />
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}

export default ResetPassword
