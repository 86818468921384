import React from 'react'
import Footer from './components/Footer'
import { useNavigate, useLocation } from 'react-router-dom'
import Sidebar from './components/Sidebar'
import { useSelector } from 'react-redux'
import { useEffect } from 'react'
import AllRoutes from './AllRoutes'

function App() {
  const { user } = useSelector((state) => state)
  const navigate = useNavigate()

  const location = useLocation()

  useEffect(() => {
    if (!user.userToken) {
      navigate('/login')
    } else {
      if (
        location.pathname === '/login' ||
        location.pathname === '/forgot-password' ||
        location.pathname?.includes('reset') ||
        location.pathname === '/'
      ) {
        navigate('/dashboard')
      } else {
        navigate(location?.search ? `${location?.pathname}${location?.search}` : location?.pathname)
      }
    }
  }, [user])

  return (
    <div className='page'>
      <div className='page-main'>
        {/* <Header /> */}
        {location?.pathname !== '/login' &&
          location?.pathname !== '/forgot-password' &&
          !location?.pathname?.includes('reset') ? (
          <Sidebar />
        ) : (
          <></>
        )}
        <div
          className={
            location?.pathname !== '/login' &&
              location?.pathname !== '/forgot-password' &&
              !location?.pathname?.includes('reset')
              ? 'main-content app-content'
              : ''
          }
        >
          <div className='wrraper-app'>
            <AllRoutes />
          </div>
        </div>
      </div>
      {location?.pathname !== '/login' &&
        location?.pathname !== '/forgot-password' &&
        !location?.pathname?.includes('reset') && <Footer />}
    </div>
  )
}

export default App
