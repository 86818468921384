import React from 'react'
import DoubleImageContainer from './DoubleImageContainer'
import { useState } from 'react'
import axiosProvider from '../../lib/AxiosProvider'
import { useEffect } from 'react'

function Gridcolumnfour({
  layoutsInfo,
  section,
  handleDelete,
  setLayoutDetails,
  layoutDetails,
  setModalShow,
  modalShow,
  fromLendingPage,
  handleImgDelete
}) {
  const [data, setData] = useState()
  const prepareIdsData = (data) => {
    return data?.map((option) => option?.productId).join(',') ?? ''
  }

  const isRenderForProduct = section?.list_type
    ?.toLowerCase()
    ?.includes('product')
    ? true
    : false

  const categoryId = section?.category_id ? section?.category_id : 0
  const topProduct = section?.top_products ? section?.top_products : 0
  const productId =
    section?.columns?.left?.single?.length > 0
      ? prepareIdsData(section?.columns?.left?.single)
      : ''

  const fetchData = async (sectionId) => {
    const response = await axiosProvider({
      method: 'GET',
      endpoint: 'ManageHomePageSections/GetProductHomePageSection',
      queryString: `?categoryId=${!productId ? categoryId : 0}&topProduct=${
        !productId ? topProduct : 0
      }&productId=${productId}`
    })
      .then((res) => {
        if (res.status === 200) {
          setData({ ...res, sectionId })
        }
      })
      .catch((err) => {})
  }

  useEffect(() => {
    if (isRenderForProduct) {
      data?.sectionId !== section?.section_id && fetchData(section?.section_id)
    }
  }, [])

  return (
    <div className='grid_column_four'>
      <DoubleImageContainer
        type='top'
        data={
          isRenderForProduct
            ? data?.data?.data ?? []
            : section?.columns?.left?.single ?? []
        }
        layoutsInfo={layoutsInfo}
        layoutDetails={layoutDetails}
        setLayoutDetails={setLayoutDetails}
        handleDelete={handleDelete}
        section={section}
        setModalShow={setModalShow}
        modalShow={modalShow}
        fromLendingPage={fromLendingPage}
        handleImgDelete={handleImgDelete}
      />
    </div>
  )
}

export default Gridcolumnfour
