import React, { useState, useEffect } from 'react'
import { Table } from 'react-bootstrap'
import SearchBox from '../../components/Searchbox'
import axiosProvider from '../../lib/AxiosProvider'
import ReactPaginate from 'react-paginate'
import useDebounce from '../../lib/useDebounce'
import Loader from '../../components/Loader'
import { _weightSlabRegex_ } from '../../lib/Regex'
import moment from 'moment'
import { pageRangeDisplayed } from '../../lib/GetBaseUrl'
import { useImmer } from 'use-immer'
import { customStyles } from '../../components/customStyles'

const MainLogs = () => {
  const [data, setData] = useState()
  const [searchText, setSearchText] = useState()
  const [loading, setLoading] = useState(false)
  const debounceSearchText = useDebounce(searchText, 500)
  const [filterDetails, setFilterDetails] = useImmer({
    pageSize: 100,
    pageIndex: 1,
    searchText: ''
  })

  useEffect(() => {
    if (debounceSearchText) {
      setFilterDetails((draft) => {
        draft.searchText = debounceSearchText
        draft.pageIndex = 1
      })
    } else {
      setFilterDetails((draft) => {
        draft.searchText = ''
        draft.pageIndex = 1
      })
    }
  }, [debounceSearchText])

  useEffect(() => {
    fetchData()
  }, [filterDetails])

  const handlePageClick = (event) => {
    setFilterDetails((draft) => {
      draft.pageIndex = event.selected + 1
    })
  }

  const fetchData = async () => {
    setLoading(true)
    const response = await axiosProvider({
      method: 'GET',
      endpoint: 'Log/search',
      queryString: `?searchText=${filterDetails?.searchText}&pageIndex=${filterDetails?.pageIndex}&pageSize=${filterDetails?.pageSize}`
    })
      .then((res) => {
        setLoading(false)
        if (res.status === 200) {
          setData(res)
        }
      })
      .catch((err) => {
        setLoading(false)
      })
  }

  return (
    <>
      <div className='d-flex align-items-center mb-3 gap-3'>
        <div className='d-flex align-items-center'>
          <label className='me-1'>Show</label>
          <select
            styles={customStyles}
            menuPortalTarget={document.body}
            name='dataget'
            id='parpageentries'
            className='form-select me-1'
            value={filterDetails?.pageSize}
            onChange={(e) => {
              setFilterDetails((draft) => {
                draft.pageSize = e?.target?.value
                draft.pageIndex = 1
              })
            }}
          >
            <option value='10'>10</option>
            <option value='25'>25</option>
            <option value='50'>50</option>
            <option value='100'>100</option>
            <option value='200'>200</option>
            <option value='500'>500</option>
          </select>
        </div>
        <SearchBox
          placeholderText={'Search'}
          value={searchText}
          searchclassnamewrapper={'searchbox-wrapper'}
          onChange={(e) => {
            setSearchText(e?.target?.value)
          }}
          // btnVariant={"secondary"}
        />
      </div>
      {loading && <Loader />}

      <Table responsive className='align-middle table-list'>
        <thead>
          <tr>
            <th>User type</th>
            <th>Action</th>
            <th>Url</th>
            <th>Log title</th>
            {/* <th>Log Description</th> */}
            <th>Date</th>
          </tr>
        </thead>
        <tbody>
          {data?.data?.data?.length > 0 ? (
            data?.data?.data.map((data) => (
              <tr key={data.id}>
                <td>{data?.userType}</td>
                <td>{data.action}</td>
                <td>{data.url}</td>
                <td>{data?.logTitle}</td>
                {/* <td>{data?.logDescprition}</td> */}
                <td>{moment(data?.createdAt)?.format('DD-MM-YYYY HH:mm')}</td>
              </tr>
            ))
          ) : (
            <tr>
              <td colSpan={5} className='text-center'>
                {data?.data?.message}
              </td>
            </tr>
          )}
        </tbody>
      </Table>

      <ReactPaginate
        className='list-inline m-cst--pagination d-flex justify-content-end gap-1'
        breakLabel='...'
        nextLabel=''
        onPageChange={handlePageClick}
        pageRangeDisplayed={pageRangeDisplayed}
        pageCount={data?.data?.pagination?.pageCount ?? 0}
        previousLabel=''
        renderOnZeroPageCount={null}
        forcePage={filterDetails?.pageIndex - 1}
      />
    </>
  )
}

export default MainLogs
