import React, { useState } from 'react'
import { Link } from 'react-router-dom'
import SpecificationType from './specification/SpecificationType'
import SpecificationValue from './specification/SpecificationValue'
import SpeciTypeValue from './specification/SpeciTypeValue'

const SpecificationTab = () => {
  const [activeToggle, setActiveToggle] = useState('specificationtype')

  return (
    <>
      <div className='card overflow-hidden'>
        <div className='card-body p-0'>
          {/* Tabs Links */}
          <div className='nav-tabs-horizontal nav nav-tabs'>
            {/* <Link
              onClick={() => setActiveToggle('specificationvalue')}
              data-toggle='tab'
              className={`nav-link fw-semibold ${
                activeToggle === 'specificationvalue' ? 'active show' : ''
              }`}
            >
              <span className='nav-span'>
                Specification
                <hr />
              </span>
            </Link> */}
            <Link
              onClick={() => setActiveToggle('specificationtype')}
              // to="#secondcategory"
              data-toggle='tab'
              className={`nav-link fw-semibold ${
                activeToggle === 'specificationtype' ? 'active show' : ''
              }`}
            >
              <span className='nav-span'>
                Specification Type
                <hr />
              </span>
            </Link>
            <Link
              onClick={() => setActiveToggle('specificationtypevalue')}
              // to="#secondcategory"
              data-toggle='tab'
              className={`nav-link fw-semibold ${
                activeToggle === 'specificationtypevalue' ? 'active show' : ''
              }`}
            >
              <span className='nav-span'>
                Specification Value
                <hr />
              </span>
            </Link>
          </div>
          {/* Tabs Links End */}

          {/* Content Section Start */}

          <div className='tab-content p-3'>
            {/* <div
              id='specificationvalue'
              className={`tab-pane fade ${
                activeToggle === 'specificationvalue' ? 'active show' : ''
              }`}
            >
              {activeToggle === 'specificationvalue' && <SpecificationValue />}
            </div> */}

            <div
              id='specificationtype'
              className={`tab-pane fade ${
                activeToggle === 'specificationtype' ? 'active show' : ''
              }`}
            >
              {activeToggle === 'specificationtype' && <SpecificationType />}
            </div>

            <div
              id='specificationtypevalue'
              className={`tab-pane fade ${
                activeToggle === 'specificationtypevalue' ? 'active show' : ''
              }`}
            >
              {activeToggle === 'specificationtypevalue' && <SpeciTypeValue />}
            </div>
          </div>
        </div>
      </div>
    </>
  )
}

export default SpecificationTab
