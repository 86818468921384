import React, { useEffect, useState } from 'react'
import HKbutton from '../../components/HKButton'
import { Form, Formik } from 'formik'
import FormikControl from '../../components/FormikControl'
import * as Yup from 'yup'
import { _passwordRegex_ } from '../../lib/Regex'
import { useLocation, useNavigate } from 'react-router-dom'
import axiosProvider from '../../lib/AxiosProvider'
import { useDispatch, useSelector } from 'react-redux'
import Loader from '../../components/Loader'
import CustomToast from '../../components/Toast/CustomToast'
import Nookies from 'nookies'
import { setPageTitle } from '../redux/slice/pageTitleSlice'

const Changepassword = () => {
  const location = useLocation()
  const { userId } = useSelector((state) => state?.user?.userInfo)
  const [loading, setLoading] = useState(false)
  const dispatch = useDispatch()
  const [toast, setToast] = useState({
    show: false,
    text: null,
    variation: null
  })
  const initialValues = {
    currentPassword: '',
    newPassword: '',
    confirmNewPassword: '',
    newPasswordVisible: false,
    currentPasswordVisible: false,
    confirmNewPasswordVisible: false
  }

  useEffect(() => {
    dispatch(setPageTitle('Change password'))
  }, [])

  const navigate = useNavigate()

  const validationSchema = Yup.object().shape({
    currentPassword: Yup.string()
      .required('Current password is required')
      .matches(
        _passwordRegex_,
        'Password must be at least 6 characters with a mix of lowercase, uppercase, digit, and special characters.'
      ),
    newPassword: Yup.string()
      .required('New password is required')
      .notOneOf(
        [Yup.ref('currentPassword'), null],
        'New password must be different from the current password'
      )
      .matches(
        _passwordRegex_,
        'Password must be at least 6 characters with a mix of lowercase, uppercase, digit, and special characters.'
      ),
    confirmNewPassword: Yup.string()
      .required('Confirm password is required')
      .oneOf([Yup.ref('newPassword'), null], 'Passwords must match')
  })

  const onSubmit = async (values, { resetForm }) => {
    setLoading(true)
    const response = await axiosProvider({
      method: 'POST',
      endpoint: `Account/Admin/ChangePassword`,
      data: values,
      userId,
      location: location.pathname
    })
      .then((res) => {
        if (res?.data?.code === 200) {
          resetForm({ values: '' })
          Nookies.destroy(null, 'isRemember')
          Nookies.destroy(null, 'userName')
          Nookies.destroy(null, 'password')
        }
        setLoading(false)
        setToast({
          show: true,
          text: res?.data?.message,
          variation: res?.data?.code !== 200 ? 'error' : 'success'
        })

        setTimeout(() => {
          setToast({ ...toast, show: false })
          res?.data?.code === 200 && navigate('/dashboard')
        }, 2000)
      })
      .catch((err) => {
        setLoading(false)
      })
  }
  
  return (
    <div className='pv-edit-profile-main position-relative'>
      <div className='card w-50 m-auto py-3 position-absolute top-50 start-50'>
        {loading && <Loader />}
        {toast?.show && (
          <CustomToast text={toast?.text} variation={toast?.variation} />
        )}
        <div className='card-body w-75 m-auto'>
          <h3 className='font-h3 text-center'>Change Password</h3>

          <Formik
            validateOnBlur={true}
            validateOnChange={false}
            initialValues={initialValues}
            validationSchema={validationSchema}
            onSubmit={onSubmit}
          >
            {({ values, setFieldValue }) => (
              <Form className='pv-login-password'>
                <div className='input-wrapper pv-login-password mb-4 pos-relative'>
                  <FormikControl
                    isRequired
                    className='he-10'
                    control='input'
                    label='Current Password'
                    name='currentPassword'
                    value={values?.currentPassword}
                    placeholder='Enter Current Password'
                    onChange={(e) => {
                      setFieldValue('currentPassword', e?.target?.value)
                    }}
                    type={values.currentPasswordVisible ? 'text' : 'password'}
                    maxLength={50}
                    onBlur={(e) => {
                      let fieldName = e?.target?.name
                      setFieldValue(fieldName, values[fieldName]?.trim())
                    }}
                  />
                  <div
                    className='eye-absolute'
                    style={{
                      position: 'absolute',
                      top: '1.8rem',
                      right: '10px'
                    }}
                  >
                    {values.currentPasswordVisible ? (
                      <svg
                        xmlns='http://www.w3.org/2000/svg'
                        width='18'
                        height='18'
                        onClick={() => {
                          setFieldValue(
                            'currentPasswordVisible',
                            !values.currentPasswordVisible
                          )
                        }}
                        viewBox='0 0 24 24'
                        fill='none'
                        stroke='currentColor'
                        strokeWidth='2'
                        strokeLinecap='round'
                        strokeLinejoin='round'
                        className={`feather feather-eye link-icon toggle-password eye-icon w-75`}
                        data-password=''
                      >
                        <path d='M1 12s4-8 11-8 11 8 11 8-4 8-11 8-11-8-11-8z'></path>
                        <circle cx='12' cy='12' r='3'></circle>
                      </svg>
                    ) : (
                      <svg
                        xmlns='http://www.w3.org/2000/svg'
                        width='18'
                        height='18'
                        viewBox='0 0 24 24'
                        fill='none'
                        stroke='currentColor'
                        onClick={() => {
                          setFieldValue(
                            'currentPasswordVisible',
                            !values.currentPasswordVisible
                          )
                        }}
                        strokeWidth='2'
                        strokeLinecap='round'
                        strokeLinejoin='round'
                        className='feather feather-eye-off link-icon toggle-password eye-off-icon w-75'
                        data-password=''
                      >
                        <path d='M17.94 17.94A10.07 10.07 0 0 1 12 20c-7 0-11-8-11-8a18.45 18.45 0 0 1 5.06-5.94M9.9 4.24A9.12 9.12 0 0 1 12 4c7 0 11 8 11 8a18.5 18.5 0 0 1-2.16 3.19m-6.72-1.07a3 3 0 1 1-4.24-4.24'></path>
                        <line x1='1' y1='1' x2='23' y2='23'></line>
                      </svg>
                    )}
                  </div>
                </div>
                <div className='input-wrapper pv-login-password mb-4 pos-relative'>
                  <FormikControl
                    isRequired
                    className='he-10'
                    control='input'
                    label='New Password'
                    name='newPassword'
                    value={values?.newPassword}
                    placeholder='Enter New Password'
                    onChange={(e) => {
                      setFieldValue('newPassword', e?.target?.value)
                    }}
                    type={values.newPasswordVisible ? 'text' : 'password'}
                    maxLength={50}
                    onBlur={(e) => {
                      let fieldName = e?.target?.name
                      setFieldValue(fieldName, values[fieldName]?.trim())
                    }}
                  />
                  <div
                    className='eye-absolute'
                    style={{
                      position: 'absolute',
                      top: '1.8rem',
                      right: '10px'
                    }}
                  >
                    {values.newPasswordVisible ? (
                      <svg
                        xmlns='http://www.w3.org/2000/svg'
                        width='18'
                        height='18'
                        onClick={() => {
                          setFieldValue(
                            'newPasswordVisible',
                            !values.newPasswordVisible
                          )
                        }}
                        viewBox='0 0 24 24'
                        fill='none'
                        stroke='currentColor'
                        strokeWidth='2'
                        strokeLinecap='round'
                        strokeLinejoin='round'
                        className={`feather feather-eye link-icon toggle-password eye-icon w-75`}
                        data-password=''
                      >
                        <path d='M1 12s4-8 11-8 11 8 11 8-4 8-11 8-11-8-11-8z'></path>
                        <circle cx='12' cy='12' r='3'></circle>
                      </svg>
                    ) : (
                      <svg
                        xmlns='http://www.w3.org/2000/svg'
                        width='18'
                        height='18'
                        viewBox='0 0 24 24'
                        fill='none'
                        stroke='currentColor'
                        onClick={() => {
                          setFieldValue(
                            'newPasswordVisible',
                            !values.newPasswordVisible
                          )
                        }}
                        strokeWidth='2'
                        strokeLinecap='round'
                        strokeLinejoin='round'
                        className='feather feather-eye-off link-icon toggle-password eye-off-icon w-75'
                        data-password=''
                      >
                        <path d='M17.94 17.94A10.07 10.07 0 0 1 12 20c-7 0-11-8-11-8a18.45 18.45 0 0 1 5.06-5.94M9.9 4.24A9.12 9.12 0 0 1 12 4c7 0 11 8 11 8a18.5 18.5 0 0 1-2.16 3.19m-6.72-1.07a3 3 0 1 1-4.24-4.24'></path>
                        <line x1='1' y1='1' x2='23' y2='23'></line>
                      </svg>
                    )}
                  </div>
                </div>
                <div className='input-wrapper pv-login-password mb-4 pos-relative'>
                  <FormikControl
                    isRequired
                    className='he-10'
                    control='input'
                    label='Confirm Password'
                    name='confirmNewPassword'
                    onChange={(e) => {
                      setFieldValue('confirmNewPassword', e?.target?.value)
                    }}
                    type={
                      values.confirmNewPasswordVisible ? 'text' : 'password'
                    }
                    placeholder='Confirm Password'
                    maxLength={50}
                    onBlur={(e) => {
                      let fieldName = e?.target?.name
                      setFieldValue(fieldName, values[fieldName]?.trim())
                    }}
                  />
                  <div
                    className='eye-absolute'
                    style={{
                      position: 'absolute',
                      top: '1.8rem',
                      right: '10px'
                    }}
                  >
                    {values.confirmNewPasswordVisible ? (
                      <svg
                        xmlns='http://www.w3.org/2000/svg'
                        width='18'
                        height='18'
                        onClick={() => {
                          setFieldValue(
                            'confirmNewPasswordVisible',
                            !values.confirmNewPasswordVisible
                          )
                        }}
                        viewBox='0 0 24 24'
                        fill='none'
                        stroke='currentColor'
                        strokeWidth='2'
                        strokeLinecap='round'
                        strokeLinejoin='round'
                        className={`feather feather-eye link-icon toggle-password eye-icon w-75`}
                        data-password=''
                      >
                        <path d='M1 12s4-8 11-8 11 8 11 8-4 8-11 8-11-8-11-8z'></path>
                        <circle cx='12' cy='12' r='3'></circle>
                      </svg>
                    ) : (
                      <svg
                        xmlns='http://www.w3.org/2000/svg'
                        width='18'
                        height='18'
                        viewBox='0 0 24 24'
                        fill='none'
                        stroke='currentColor'
                        onClick={() => {
                          setFieldValue(
                            'confirmNewPasswordVisible',
                            !values.confirmNewPasswordVisible
                          )
                        }}
                        strokeWidth='2'
                        strokeLinecap='round'
                        strokeLinejoin='round'
                        className='feather feather-eye-off link-icon toggle-password eye-off-icon w-75'
                        data-password=''
                      >
                        <path d='M17.94 17.94A10.07 10.07 0 0 1 12 20c-7 0-11-8-11-8a18.45 18.45 0 0 1 5.06-5.94M9.9 4.24A9.12 9.12 0 0 1 12 4c7 0 11 8 11 8a18.5 18.5 0 0 1-2.16 3.19m-6.72-1.07a3 3 0 1 1-4.24-4.24'></path>
                        <line x1='1' y1='1' x2='23' y2='23'></line>
                      </svg>
                    )}
                  </div>
                </div>
                <div className='text-center'>
                  <HKbutton buttonText='Submit' />
                </div>
              </Form>
            )}
          </Formik>
        </div>
      </div>
    </div>
  )
}

export default Changepassword
