import { ErrorMessage, Form, Formik } from 'formik'
import React, { useEffect, useState } from 'react'
import { Button, Table } from 'react-bootstrap'
import ReactPaginate from 'react-paginate'
import { useSelector } from 'react-redux'
import { useLocation } from 'react-router-dom'
import Swal from 'sweetalert2'
import { useImmer } from 'use-immer'
import * as Yup from 'yup'
import DeleteIcon from '../../../../components/AllSvgIcon/DeleteIcon'
import EditIcon from '../../../../components/AllSvgIcon/EditIcon'
import ColorPicker from '../../../../components/ColorPicker'
import FormikControl from '../../../../components/FormikControl'
import Loader from '../../../../components/Loader'
import ModelComponent from '../../../../components/Modal'
import SearchBox from '../../../../components/Searchbox'
import TextError from '../../../../components/TextError'
import CustomToast from '../../../../components/Toast/CustomToast'
import {
  allCrudNames,
  allPages,
  checkPageAccess
} from '../../../../lib/AllPageNames'
import axiosProvider from '../../../../lib/AxiosProvider'
import { _SwalDelete } from '../../../../lib/exceptionMessage'
import { pageRangeDisplayed } from '../../../../lib/GetBaseUrl'
import useDebounce from '../../../../lib/useDebounce'

const ColorsAttribute = () => {
  const [searchText, setSearchText] = useState()
  const [data, setData] = useState()
  const [modalShow, setModalShow] = useState(false)
  const [loading, setLoading] = useState(false)
  const [toast, setToast] = useState({
    show: false,
    text: null,
    variation: null
  })
  const initVal = {
    name: '',
    code: '#000000'
  }
  const [initialValues, setInitialValues] = useState(initVal)
  const [filterDetails, setFilterDetails] = useImmer({
    pageSize: 10,
    pageIndex: 1,
    searchText: ''
  })
  const { pageAccess } = useSelector((state) => state.user)

  useEffect(() => {
    fetchData()
  }, [filterDetails])

  const { userId } = useSelector((state) => state?.user?.userInfo)
  const location = useLocation()
  const debounceSearchText = useDebounce(searchText, 500)

  useEffect(() => {
    if (debounceSearchText) {
      setFilterDetails((draft) => {
        draft.searchText = debounceSearchText
        draft.pageIndex = 1
      })
    } else {
      setFilterDetails((draft) => {
        draft.searchText = ''
        draft.pageIndex = 1
      })
    }
  }, [debounceSearchText])

  const fetchData = async () => {
    await axiosProvider({
      method: 'GET',
      endpoint: 'Color/search',
      queryString: `?searchText=${filterDetails?.searchText}&pageIndex=${filterDetails?.pageIndex}&pageSize=${filterDetails?.pageSize}`
    })
      .then((res) => {
        if (res.status === 200) {
          setData(res)
        }
      })
      .catch((err) => setLoading(false))
  }

  const validationSchema = Yup.object().shape({
    name: Yup.string()
      .min(1, 'Your Name must consist of at least 3 characters')
      .max(50, 'Your Name is to long')
      .required('Please enter name of color'),
    code: Yup.string().required('Please select specific color')
  })

  const handleDelete = async (id) => {
    setLoading(true)

    await axiosProvider({
      method: 'DELETE',
      endpoint: 'Color',
      queryString: `?Id=${id}`,
      userId,
      location: location.pathname
    })
      .then((res) => {
        if (res?.status === 200) {
          if (res?.data?.code === 200) {
            if (
              filterDetails?.pageIndex > 1 &&
              data?.data?.data?.length === 1
            ) {
              setFilterDetails((draft) => {
                draft.pageIndex = filterDetails?.pageIndex - 1
              })
            } else {
              fetchData()
            }
          }
        }
        setToast({
          show: true,
          text: res?.data?.message,
          variation: res?.data?.code !== 200 ? 'error' : 'success'
        })

        setTimeout(() => {
          setToast({ ...toast, show: false })
        }, 2000)
        setLoading(false)
      })
      .catch(() => {
        setLoading(false)
      })
  }

  const onSubmit = async (values, { resetForm }) => {
    let method = values?.id ? 'PUT' : 'POST'
    setLoading(true)
    await axiosProvider({
      method,
      endpoint: `Color`,
      data: values,
      oldData: initialValues,
      location: location?.pathname,
      userId
    })
      .then((res) => {
        if (res.status === 200) {
          setModalShow(false)
          resetForm({ values: '' })
          fetchData()
        }
        setLoading(false)
        setToast({
          show: true,
          text: res?.data?.message,
          variation: res?.data?.code !== 200 ? 'error' : 'success'
        })

        setTimeout(() => {
          setToast({ ...toast, show: false })
        }, 2000)
      })
      .catch((err) => {
        setLoading(false)
      })
  }

  const handlePageClick = (event) => {
    setFilterDetails((draft) => {
      draft.pageIndex = event.selected + 1
    })
  }

  return (
    <>
      <div className='d-flex align-items-center mb-3 gap-3 flex-row-reverse'>
        {checkPageAccess(pageAccess, allPages?.color, allCrudNames?.write) && (
          <Button
            variant='warning'
            className='d-flex align-items-center gap-2 fw-semibold btn btn-warning ms-auto'
            onClick={() => {
              setInitialValues(initVal)
              setModalShow(true)
            }}
          >
            <i className='m-icon m-icon--plusblack'></i>
            Create
          </Button>
        )}
        <div className='d-flex align-items-center me-2'>
          <label className='me-1'>Show</label>
          <select
            name='dataget'
            className='form-select me-1'
            onChange={(e) => {
              setFilterDetails((draft) => {
                draft.pageSize = e?.target?.value
                draft.pageIndex = 1
              })
            }}
          >
            <option value='10'>10</option>
            <option value='25'>25</option>
            <option value='50'>50</option>
            <option value='100'>100</option>
            <option value='200'>200</option>
            <option value='500'>500</option>
          </select>
        </div>

        <SearchBox
          placeholderText={'Search'}
          searchclassnamewrapper={'searchbox-wrapper'}
          value={searchText}
          onChange={(e) => {
            setSearchText(e?.target?.value)
          }}
        />
      </div>

      {toast?.show && (
        <CustomToast text={toast?.text} variation={toast?.variation} />
      )}

      <ModelComponent
        show={modalShow}
        modalsize={'md'}
        modalheaderclass={''}
        modeltitle={'Colors'}
        onHide={() => {
          setInitialValues(initVal)
          setModalShow(false)
        }}
        btnclosetext={''}
        closebtnvariant={''}
        backdrop={'static'}
        formbuttonid={'colors-name-code'}
      >
        <Formik
          initialValues={initialValues}
          validationSchema={validationSchema}
          onSubmit={onSubmit}
        >
          {({ values, setFieldValue }) => (
            <Form id='colors-name-code'>
              <div className='row align-items-center'>
                <div className='col-md-12'>
                  {loading && <Loader />}
                  <div className='input-wrapper mb-3'>
                    <FormikControl
                      isRequired
                      control='input'
                      label='Color name'
                      type='text'
                      name='name'
                      placeholder='Enter Color Name'
                    />
                  </div>
                </div>
                <div className='col-md-12'>
                  <div className='input-wrapper mb-3'>
                    <label htmlFor='' className='form-label'>
                      Code
                    </label>
                    <div className='input-group'>
                      <ColorPicker
                        value={values?.code}
                        onChange={(code) => {
                          setFieldValue('code', code)
                        }}
                      />
                    </div>
                    <ErrorMessage name='code' component={TextError} />
                  </div>
                </div>
              </div>
            </Form>
          )}
        </Formik>
      </ModelComponent>

      {loading && !modalShow && <Loader />}

      <Table responsive className='align-middle table-list'>
        <thead>
          <tr>
            <th>Name</th>
            <th>Color Code</th>
            {checkPageAccess(pageAccess, allPages?.color, [
              allCrudNames?.update,
              allCrudNames?.delete
            ]) && <th className='text-center'>Action</th>}
          </tr>
        </thead>
        <tbody>
          {data?.data?.data?.length > 0 ? (
            data?.data?.data?.map((color) => (
              <tr key={color.id}>
                <td className='text-capitalize'>{color.name}</td>
                {/* <td>{color.code}</td> */}
                <td>
                  {color?.code ? (
                    <>
                      <div className='d-flex align-items-center gap-2'>
                        <span
                          className='d-inline-block rounded cw-25 ch-25'
                          style={{ backgroundColor: `${color.code}` }}
                        ></span>
                        {color?.code}
                      </div>
                    </>
                  ) : (
                    '-'
                  )}
                </td>
                {checkPageAccess(pageAccess, allPages?.color, [
                  allCrudNames?.update,
                  allCrudNames?.delete
                ]) && (
                  <td className='text-center'>
                    <div className='d-flex gap-2 justify-content-center'>
                      {checkPageAccess(
                        pageAccess,
                        allPages?.color,
                        allCrudNames?.update
                      ) && (
                        <span
                          onClick={() => {
                            setInitialValues(color)
                            setModalShow(!modalShow)
                          }}
                        >
                          <EditIcon bg={'bg'} />
                        </span>
                      )}
                      {checkPageAccess(
                        pageAccess,
                        allPages?.color,
                        allCrudNames?.delete
                      ) && (
                        <span
                          onClick={() => {
                            Swal.fire({
                              title: _SwalDelete.title,
                              text: _SwalDelete.text,
                              icon: _SwalDelete.icon,
                              showCancelButton: _SwalDelete.showCancelButton,
                              confirmButtonColor:
                                _SwalDelete.confirmButtonColor,
                              cancelButtonColor: _SwalDelete.cancelButtonColor,
                              confirmButtonText: _SwalDelete.confirmButtonText,
                              cancelButtonText: _SwalDelete.cancelButtonText
                            }).then((result) => {
                              if (result.isConfirmed) {
                                handleDelete(color.id)
                              } else if (result.isDenied) {
                              }
                            })
                          }}
                        >
                          <DeleteIcon bg={'bg'} />
                        </span>
                      )}
                    </div>
                  </td>
                )}
              </tr>
            ))
          ) : (
            <tr>
              <td colSpan={4} className='text-center'>
                {data?.data?.message ? data?.data?.message : 'No Data Found'}
              </td>
            </tr>
          )}
        </tbody>
      </Table>

      <ReactPaginate
        className='list-inline m-cst--pagination d-flex justify-content-end gap-1'
        breakLabel='...'
        nextLabel=''
        onPageChange={handlePageClick}
        pageRangeDisplayed={pageRangeDisplayed}
        pageCount={data?.data?.pagination?.pageCount ?? 0}
        previousLabel=''
        renderOnZeroPageCount={null}
        forcePage={filterDetails?.pageIndex - 1}
      />
    </>
  )
}

export default ColorsAttribute
