import React, { useState, useEffect } from "react";
import { Link } from "react-router-dom";
import { setPageTitle } from "../redux/slice/pageTitleSlice";
import { useDispatch, useSelector } from "react-redux";
import {
  allCrudNames,
  allPages,
  checkPageAccess,
} from "../../lib/AllPageNames";
import NotFound from "../NotFound/NotFound";
import MainSubscribe from "./MainSubscribe";

const ManageSubscribe = () => {
  const dispatch = useDispatch();
  useEffect(() => {
    dispatch(setPageTitle("Manage Subscribe"));
  }, []);

  const { pageAccess } = useSelector((state) => state.user);

  const [activeToggle, setActiveToggle] = useState("subscribe");

  return checkPageAccess(
    pageAccess,
    allPages?.subscribe,
    allCrudNames?.read
  ) ? (
    <>
      <div className="card overflow-hidden">
        <div className="card-body p-0">
          <div className="nav-tabs-horizontal nav nav-tabs">
            {checkPageAccess(
              pageAccess,
              allPages?.subscribe,
              allCrudNames?.read
            ) && (
              <Link
                onClick={() => setActiveToggle("subscribe")}
                data-toggle="tab"
                className={`nav-link fw-semibold ${
                  activeToggle === "subscribe" ? "active show" : ""
                }`}
              >
                <span className="nav-span">
                  Subscribe
                  <hr />
                </span>
              </Link>
            )}
          </div>

          <div className="tab-content p-3">
            {checkPageAccess(
              pageAccess,
              allPages?.subscribe,
              allCrudNames?.read
            ) && (
              <div
                id="subscribe"
                className={`tab-pane fade ${
                  activeToggle === "subscribe" ? "active show" : ""
                }`}
              >
                {activeToggle === "subscribe" && <MainSubscribe />}
              </div>
            )}
          </div>
        </div>
      </div>
    </>
  ) : (
    <NotFound />
  );
};

export default ManageSubscribe;
