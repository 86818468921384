import { ErrorMessage, Form, Formik } from 'formik'
import React, { useEffect, useState } from 'react'
import {
  Button,
  Card,
  Col,
  Image,
  ListGroup,
  Row,
  Table
} from 'react-bootstrap'
import ReactPaginate from 'react-paginate'
import { useSelector } from 'react-redux'
import { useLocation } from 'react-router-dom'
import Select from 'react-select'
import { TagsInput } from 'react-tag-input-component'
import Swal from 'sweetalert2'
import { useImmer } from 'use-immer'
import * as Yup from 'yup'
import DeleteIcon from '../../../components/AllSvgIcon/DeleteIcon'
import EditIcon from '../../../components/AllSvgIcon/EditIcon'
import Previewicon from '../../../components/AllSvgIcon/Previewicon'
import HKBadge from '../../../components/Badges'
import ColorPicker from '../../../components/ColorPicker'
import { customStyles } from '../../../components/customStyles'
import FormikControl from '../../../components/FormikControl'
import Loader from '../../../components/Loader'
import ModelComponent from '../../../components/Modal'
import SearchBox from '../../../components/Searchbox'
import TextError from '../../../components/TextError'
import CustomToast from '../../../components/Toast/CustomToast'
import {
  allCrudNames,
  allPages,
  checkPageAccess
} from '../../../lib/AllPageNames'
import axiosProvider from '../../../lib/AxiosProvider'
import { _SwalDelete, _exception } from '../../../lib/exceptionMessage'
import {
  _status_,
  pageRangeDisplayed,
  showToast
} from '../../../lib/GetBaseUrl'
import { _categoryImg_ } from '../../../lib/ImagePath'
import useDebounce from '../../../lib/useDebounce'

function MainCategory() {
  const [detailsModalShow, setDetailsModalShow] = useState(false)
  const [detailsModalData, setDetailsModalData] = useState()
  const [searchText, setSearchText] = useState()
  const [data, setData] = useState()
  const [modalShow, setModalShow] = useState(false)
  const [loading, setLoading] = useState(false)
  const [toast, setToast] = useState({
    show: false,
    text: null,
    variation: null
  })
  const [filterDetails, setFilterDetails] = useImmer({
    pageSize: 10,
    pageIndex: 1,
    searchText: ''
  })
  const { userId } = useSelector((state) => state?.user?.userInfo)
  const { pageAccess } = useSelector((state) => state.user)
  const initVal = {
    name: '',
    filename: null,
    metaTitles: '',
    metaKeywords: [],
    metaDescription: '',
    status: '',
    color: '#000000',
    title: '',
    subTitle: '',
    description: ''
  }
  const [initialValues, setInitialValues] = useState(initVal)
  const SUPPORTED_FORMATS = ['image/jpg', 'image/jpeg', 'image/png']
  const debounceSearchText = useDebounce(searchText, 500)
  const location = useLocation()

  const fetchData = async () => {
    setLoading(true)
    await axiosProvider({
      method: 'GET',
      endpoint: 'MainCategory/search',
      queryString: `?searchText=${filterDetails?.searchText}&pageIndex=${filterDetails?.pageIndex}&pageSize=${filterDetails?.pageSize}`
    })
      .then((res) => {
        if (res?.status === 200) {
          setLoading(false)
          setData(res)
        }
      })
      .catch((err) => {
        setLoading(false)
      })
  }

  const handlePageClick = (event) => {
    setFilterDetails((draft) => {
      draft.pageIndex = event.selected + 1
    })
  }

  const validationSchema = Yup.object().shape(
    {
      name: Yup.string()
        .min(2, 'Your Name must consist of at least 2 characters')
        .max(50, 'Your Name is to long')
        .required('Please enter category name'),
      status: Yup.string()
        .test(
          'nonull',
          'Please select status',
          (value) => value !== 'undefined'
        )
        .required('Please select status'),
      filename: Yup.mixed().when('filename', {
        is: (value) => value?.name,
        then: (schema) =>
          schema
            .test(
              'fileFormat',
              'File formate is not supported, Please use .jpg/.png/.jpeg format support',
              (value) => value && SUPPORTED_FORMATS.includes(value.type)
            )
            .test('fileSize', 'File must be less than 2MB', (value) => {
              return value !== undefined && value && value.size <= 2000000
            }),
        otherwise: (schema) => schema.nullable()
      })
    },
    ['filename', 'filename']
  )

  const onSubmit = async (values, { resetForm }) => {
    let seokeywords = values.metaKeywords.toString()

    let dataofForm = {
      Name: values.name ?? '',
      MetaTitles: values.metaTitles ?? '',
      MetaDescription: values.metaDescription ?? '',
      MetaKeywords: seokeywords ?? '',
      FileName: values.filename ?? '',
      Status: values?.status ?? '',
      Color: values?.color ?? '',
      Description: values?.description ?? '',
      SubTitle: values?.subTitle ?? '',
      title: values?.title ?? '',
      IsImageAvailable: values?.filename || values?.image ? true : false
    }
    if (values?.id) {
      dataofForm = { ...dataofForm, Id: values?.id }
    }

    const submitFormData = new FormData()

    const keys = Object.keys(dataofForm)

    keys.forEach((key) => {
      submitFormData.append(key, dataofForm[key])
    })

    try {
      setLoading(true)
      let method = 'POST'
      if (values?.id) {
        method = 'PUT'
      }
      const response = await axiosProvider({
        method,
        endpoint: `MainCategory`,
        data: submitFormData,
        logData: values,
        location: location?.pathname,
        userId,
        oldData: initialValues
      })
      setLoading(false)

      if (response?.data?.code === 200) {
        setModalShow(!modalShow)
        fetchData()
      }
      showToast(toast, setToast, response)
    } catch {
      setLoading(false)
      showToast(toast, setToast, {
        data: {
          message: _exception?.message,
          code: 204
        }
      })
    }
  }

  const handleDelete = async (id) => {
    setLoading(true)
    await axiosProvider({
      method: 'DELETE',
      endpoint: `MainCategory?id=${id}`,
      userId,
      location: location.pathname
    })
      .then((res) => {
        if (res?.status === 200) {
          if (res?.data?.code === 200) {
            if (
              filterDetails?.pageIndex > 1 &&
              data?.data?.data?.length === 1
            ) {
              setFilterDetails((draft) => {
                draft.pageIndex = filterDetails?.pageIndex - 1
              })
            } else {
              fetchData()
            }
          }
        }
        setToast({
          show: true,
          text: res?.data?.message,
          variation: res?.data?.code !== 200 ? 'error' : 'success'
        })

        setTimeout(() => {
          setToast({ ...toast, show: false })
        }, 2000)
        setLoading(false)
      })
      .catch((err) => {
        setLoading(false)
      })
  }

  useEffect(() => {
    fetchData()
  }, [filterDetails])

  useEffect(() => {
    if (debounceSearchText) {
      setFilterDetails((draft) => {
        draft.searchText = debounceSearchText
        draft.pageIndex = 1
      })
    } else {
      setFilterDetails((draft) => {
        draft.searchText = ''
        draft.pageIndex = 1
      })
    }
  }, [debounceSearchText])

  return (
    <>
      <div className='d-flex align-items-center mb-3 gap-3 flex-row-reverse'>
        {checkPageAccess(
          pageAccess,
          allPages?.category,
          allCrudNames?.write
        ) && (
          <Button
            variant='warning'
            className='d-flex align-items-center gap-2 fw-semibold btn btn-warning ms-auto'
            onClick={() => {
              setInitialValues(initVal)
              setModalShow(true)
            }}
          >
            <i className='m-icon m-icon--plusblack'></i>
            Create
          </Button>
        )}

        <div className='d-flex align-items-center'>
          <label className='me-1'>Show</label>
          <select
            name='dataget'
            id='parpageentries'
            className='form-select me-1'
            onChange={(e) => {
              setFilterDetails((draft) => {
                draft.pageSize = e?.target?.value
                draft.pageIndex = 1
              })
            }}
          >
            <option value='10'>10</option>
            <option value='25'>25</option>
            <option value='50'>50</option>
            <option value='100'>100</option>
            <option value='200'>200</option>
            <option value='500'>500</option>
          </select>
        </div>

        <SearchBox
          placeholderText={'Search'}
          searchclassnamewrapper={'searchbox-wrapper'}
          value={searchText}
          onChange={(e) => {
            setSearchText(e?.target?.value)
          }}
        />
      </div>

      {toast?.show && (
        <CustomToast text={toast?.text} variation={toast?.variation} />
      )}

      <ModelComponent
        show={modalShow}
        modalsize={'xl'}
        modalheaderclass={''}
        modeltitle={'Main Category'}
        onHide={() => {
          setInitialValues(initVal)
          setModalShow(false)
        }}
        btnclosetext={''}
        closebtnvariant={''}
        backdrop={'static'}
        formbuttonid={'main-category'}
        submitname={!initialValues?.id ? 'Create' : 'Update'}
      >
        <Formik
          enableReinitialize
          initialValues={initialValues}
          validationSchema={validationSchema}
          onSubmit={onSubmit}
        >
          {({ values, setFieldValue }) => (
            <Form id='main-category'>
              <div className='row align-items-baseline'>
                {loading && <Loader />}

                <div className='col-md-2'>
                  <div className='input-file-wrapper m--cst-filetype mb-3'>
                    <label className='form-label d-block' htmlFor='image'>
                      Image
                    </label>
                    <input
                      id='image'
                      className='form-control'
                      name='image'
                      type='file'
                      accept='image/jpg, image/png, image/jpeg'
                      onChange={(event) => {
                        const objectUrl = URL.createObjectURL(
                          event.target.files[0]
                        )
                        if (event.target.files[0].type !== '') {
                          setFieldValue('image', objectUrl)
                        }
                        setFieldValue('filename', event.target.files[0])
                      }}
                      hidden
                    />
                    {values?.image ? (
                      <div className='position-relative m--img-preview d-flex rounded-2 overflow-hidden'>
                        <img
                          src={
                            values?.image?.includes('blob')
                              ? values?.image
                              : `${process.env.REACT_APP_IMG_URL}${_categoryImg_}${values?.image}`
                          }
                          alt='Preview Category'
                          title={values?.image ? values?.filename?.name : ''}
                          className='rounded-2'
                        ></img>
                        <span
                          onClick={(e) => {
                            setFieldValue('image', null)
                            setFieldValue('filename', null)
                            document.getElementById('image').value = null
                          }}
                        >
                          <i className='m-icon m-icon--close'></i>
                        </span>
                      </div>
                    ) : (
                      <>
                        <label
                          className='m__image_default d-flex align-items-center justify-content-center rounded-2'
                          htmlFor='image'
                        >
                          <i className='m-icon m-icon--defaultpreview'></i>
                        </label>
                      </>
                    )}
                    <ErrorMessage
                      name='filename'
                      component={TextError}
                      customclass={'cfz-12 lh-sm'}
                    />
                  </div>
                </div>
                <div className='col-md-10'>
                  <div className='row'>
                    <div className='col-md-12'>
                      <FormikControl
                        control='input'
                        label='Title'
                        type='text'
                        name='title'
                        placeholder='Enter title'
                        onBlur={(e) => {
                          let fieldName = e?.target?.name
                          setFieldValue(fieldName, values[fieldName]?.trim())
                        }}
                      />
                    </div>
                    <div className='col-md-12'>
                      <FormikControl
                        control='input'
                        label='Sub Title'
                        type='text'
                        name='subTitle'
                        placeholder='Enter sub title'
                        onBlur={(e) => {
                          let fieldName = e?.target?.name
                          setFieldValue(fieldName, values[fieldName]?.trim())
                        }}
                      />
                    </div>
                  </div>
                </div>

                <div className='col-md-12'>
                  <FormikControl
                    isRequired
                    control='input'
                    label='Category name'
                    type='text'
                    name='name'
                    placeholder='Enter category name'
                    onBlur={(e) => {
                      let fieldName = e?.target?.name
                      setFieldValue(fieldName, values[fieldName]?.trim())
                    }}
                  />
                </div>
                <div className='col-md-12'>
                  <FormikControl
                    as='textarea'
                    control='input'
                    label='Description'
                    type='text'
                    name='description'
                    placeholder='Enter description'
                    onBlur={(e) => {
                      let fieldName = e?.target?.name
                      setFieldValue(fieldName, values[fieldName]?.trim())
                    }}
                  />
                </div>

                <div className='col-md-12'>
                  <Row>
                    <Col md={6}>
                      <div className='input-wrapper mb-3'>
                        <label htmlFor='' className='form-label'>
                          Code
                        </label>
                        <div className='input-group'>
                          <ColorPicker
                            value={values?.color}
                            onChange={(color) => {
                              setFieldValue('color', color)
                            }}
                          />
                        </div>
                      </div>
                    </Col>
                    <Col md={6}>
                      {/* <FormikControl control='select' label="select" name="selectcategory" providedOptions={options} changeListener={setFieldValue("selectcategory", val.value)} /> */}
                      <div className='input-select-wrapper mb-3'>
                        <label className='form-label required'>
                          Select Status
                        </label>
                        <Select
                          id='status'
                          menuPortalTarget={document.body}
                          menuPosition={'fixed'}
                          value={
                            values?.status && {
                              value: values?.status,
                              label: values?.status
                            }
                          }
                          styles={customStyles}
                          options={_status_}
                          onChange={(e) => {
                            if (e) {
                              setFieldValue('status', e?.value)
                            }
                          }}
                        />

                        <ErrorMessage name='status' component={TextError} />
                      </div>
                    </Col>
                  </Row>
                </div>
                <span className='fs-4 fw-bold'> SEO Content</span>
                <div className='col-md-6'>
                  <div className='input-file-wrapper mb-3'>
                    <label className='form-label'>
                      Meta Keywords
                      {/* <small className="fst-italic cfz-12 text-danger ms-1">(Only SEO purpose)</small> */}
                    </label>
                    <TagsInput
                      value={values?.metaKeywords ?? []}
                      separators={['Enter', ',']}
                      onChange={(tags) => {
                        setFieldValue('metaKeywords', tags)
                      }}
                      name='metaKeywords'
                      placeHolder='Use , for separate your keywords...'
                    />
                  </div>
                </div>
                <div className='col-md-6'>
                  <FormikControl
                    control='input'
                    label='Meta title'
                    type='text'
                    name='metaTitles'
                    placeholder='Enter meta title'
                    onBlur={(e) => {
                      let fieldName = e?.target?.name
                      setFieldValue(fieldName, values[fieldName]?.trim())
                    }}
                  />
                </div>
                <div className='col-md-12'>
                  <FormikControl
                    as='textarea'
                    control='input'
                    label='Meta Description'
                    type='text'
                    name='metaDescription'
                    placeholder='Enter meta description'
                    onBlur={(e) => {
                      let fieldName = e?.target?.name
                      setFieldValue(fieldName, values[fieldName]?.trim())
                    }}
                  />
                </div>
              </div>
            </Form>
          )}
        </Formik>
      </ModelComponent>

      <ModelComponent
        show={detailsModalShow}
        modalsize={'xl'}
        modalheaderclass={''}
        modeltitle={'Main Category Details'}
        onHide={() => setDetailsModalShow(false)}
        btnclosetext={''}
        closebtnvariant={''}
        backdrop={'static'}
      >
        {detailsModalData &&
          detailsModalData?.map((data) => (
            <div className='rounded'>
              <h4 className='bold mb-2'>Category Description</h4>

              <Row className='w-100 border rounded m-auto py-3'>
                <Col md={3}>
                  <ListGroup className='mt-2'>
                    <ListGroup.Item className='border-0 p-0'>
                      <Card.Img
                        width={200}
                        variant='top'
                        src={
                          data?.image
                            ? `${process.env.REACT_APP_IMG_URL}${_categoryImg_}${data?.image}`
                            : 'https://placehold.jp/50x50.png'
                        }
                        alt='Category Image'
                        className='rounded'
                      />
                    </ListGroup.Item>
                  </ListGroup>
                </Col>
                <Col md={9} className='border-start'>
                  <Table className='align-middle table-view'>
                    <tbody>
                      {data?.name && (
                        <tr className='pv-productd-remhover'>
                          <th className='text-nowrap fw-normal w-0'>
                            Category Name
                          </th>
                          <td>
                            : <span className='bold'>{data?.name}</span>
                          </td>
                        </tr>
                      )}
                      {data?.title && (
                        <tr className='pv-productd-remhover'>
                          <th className='text-nowrap fw-normal w-0'>Titles</th>
                          <td>
                            : <span className='bold'>{data?.title}</span>
                          </td>
                        </tr>
                      )}
                      {data?.subTitle && (
                        <tr className='pv-productd-remhover'>
                          <th className='text-nowrap fw-normal w-0'>
                            Sub Title
                          </th>
                          <td>
                            : <span className='bold'>{data?.subTitle}</span>
                          </td>
                        </tr>
                      )}
                      {data?.description && (
                        <tr className='pv-productd-remhover'>
                          <th className='text-nowrap fw-normal w-0'>
                            Description
                          </th>
                          <td>
                            : <span className='bold'>{data?.description}</span>
                          </td>
                        </tr>
                      )}
                    </tbody>
                  </Table>
                </Col>
              </Row>

              <h4 className='bold my-3'>SEO Content</h4>

              <ListGroup className='mt-2'>
                {data?.metaKeywords && (
                  <ListGroup.Item>
                    <div className='d-flex '>
                      <Card.Text className='m-0 text-nowrap'>
                        Meta Keywords : &nbsp;
                      </Card.Text>
                      <Card.Text className='m-0 bold'>
                        {' '}
                        {data?.metaKeywords}
                      </Card.Text>
                    </div>
                  </ListGroup.Item>
                )}
                {data?.metaTitles && (
                  <ListGroup.Item>
                    <div className='d-flex '>
                      <Card.Text className='m-0 text-nowrap'>
                        Meta title : &nbsp;
                      </Card.Text>
                      <Card.Text className='m-0 bold'>
                        {' '}
                        {data?.metaTitles}
                      </Card.Text>
                    </div>
                  </ListGroup.Item>
                )}
                {data?.metaDescription && (
                  <ListGroup.Item>
                    <div className='d-flex '>
                      <Card.Text className='m-0 text-nowrap'>
                        Meta Description : &nbsp;
                      </Card.Text>
                      <Card.Text className='m-0 bold'>
                        {' '}
                        {data?.metaDescription}
                      </Card.Text>
                    </div>
                  </ListGroup.Item>
                )}
              </ListGroup>
            </div>
          ))}
        {/* </Col>
        </Row> */}
      </ModelComponent>

      {loading && !modalShow && <Loader />}

      <Table responsive className='align-middle table-list'>
        <thead>
          <tr>
            <th>Categories</th>
            <th>Color</th>
            <th>Status</th>
            <th className='text-center'>Action</th>
          </tr>
        </thead>
        <tbody>
          {data?.data?.data?.length > 0 ? (
            data?.data?.data?.map((category, index) => (
              <tr key={`category.name + ${Math.random()}`}>
                <td>
                  <div className='d-flex gap-2 align-items-center'>
                    <Image
                      src={
                        category?.image
                          ? `${process.env.REACT_APP_IMG_URL}${_categoryImg_}${category?.image}`
                          : 'https://placehold.jp/50x50.png'
                      }
                      className='rounded-1 img-object-fit-cov'
                      height='50px'
                      width='50px'
                    />
                    <span>{category.name}</span>
                  </div>
                </td>
                <td>
                  {category?.color ? (
                    <>
                      <div className='d-flex align-items-center gap-2'>
                        <span
                          className='d-inline-block rounded cw-25 ch-25 border border-1'
                          style={{ backgroundColor: `${category?.color}` }}
                        ></span>
                        {category?.color}
                      </div>
                    </>
                  ) : (
                    '-'
                  )}
                </td>
                <td>
                  <HKBadge
                    badgesbgname={
                      category.status === 'Active' ? 'success' : 'danger'
                    }
                    badgesTxtname={category.status}
                    badgeClassname={''}
                  />
                </td>
                <td className='text-center'>
                  <div className='d-flex gap-2 justify-content-center'>
                    <span
                      onClick={() => {
                        setDetailsModalShow(true)
                        setDetailsModalData([category])
                      }}
                    >
                      <Previewicon bg={'bg'} />
                    </span>
                    {checkPageAccess(
                      pageAccess,
                      allPages?.category,
                      allCrudNames?.update
                    ) && (
                      <span
                        onClick={() => {
                          setModalShow(!modalShow)
                          setInitialValues({
                            ...category,
                            metaKeywords: category?.metaKeywords
                              ? category?.metaKeywords?.split(',')
                              : []
                          })
                        }}
                      >
                        <EditIcon bg={'bg'} />
                      </span>
                    )}

                    {checkPageAccess(
                      pageAccess,
                      allPages?.category,
                      allCrudNames?.delete
                    ) && (
                      <span
                        onClick={() => {
                          Swal.fire({
                            title: _SwalDelete.title,
                            text: _SwalDelete.text,
                            icon: _SwalDelete.icon,
                            showCancelButton: _SwalDelete.showCancelButton,
                            confirmButtonColor: _SwalDelete.confirmButtonColor,
                            cancelButtonColor: _SwalDelete.cancelButtonColor,
                            confirmButtonText: _SwalDelete.confirmButtonText,
                            cancelButtonText: _SwalDelete.cancelButtonText
                          }).then((result) => {
                            if (result.isConfirmed) {
                              handleDelete(category.id)
                            } else if (result.isDenied) {
                            }
                          })
                        }}
                      >
                        <DeleteIcon bg={'bg'} />
                      </span>
                    )}
                  </div>
                </td>
              </tr>
            ))
          ) : (
            <tr>
              <td colSpan={3} className='text-center'>
                {data?.data?.message}
              </td>
            </tr>
          )}
        </tbody>
      </Table>

      <ReactPaginate
        className='list-inline m-cst--pagination d-flex justify-content-end gap-1'
        breakLabel='...'
        nextLabel=''
        onPageChange={handlePageClick}
        pageRangeDisplayed={pageRangeDisplayed}
        pageCount={data?.data?.pagination?.pageCount}
        previousLabel=''
        renderOnZeroPageCount={null}
        forcePage={filterDetails?.pageIndex - 1}
      />
    </>
  )
}

export default MainCategory
