import React, { useState, useEffect } from 'react'
import { Link } from 'react-router-dom'
import { setPageTitle } from '../../redux/slice/pageTitleSlice'
import { useDispatch, useSelector } from 'react-redux'
import {
  allCrudNames,
  allPages,
  checkPageAccess
} from '../../../lib/AllPageNames'
import NotFound from '../../NotFound/NotFound'
import MainDownload from './MainDownload'

const ManageDownload = () => {
  const dispatch = useDispatch()
  useEffect(() => {
    dispatch(setPageTitle('Download Information'))
  }, [])

  const { pageAccess } = useSelector((state) => state.user)

  const [activeToggle, setActiveToggle] = useState('bimcadDownload')

  return checkPageAccess(
    pageAccess,
    allPages?.downloadInformation,
    allCrudNames?.read
  ) ? (
    <>
      <div className='card overflow-hidden'>
        <div className='card-body p-0'>
          <div className='nav-tabs-horizontal nav nav-tabs'>
            {checkPageAccess(
              pageAccess,
              allPages?.downloadInformation,
              allCrudNames?.read
            ) && (
              <Link
                onClick={() => setActiveToggle('bimcadDownload')}
                data-toggle='tab'
                className={`nav-link fw-semibold ${
                  activeToggle === 'bimcadDownload' ? 'active show' : ''
                }`}
              >
                <span className='nav-span'>
                  Catalogue Downloads
                  <hr />
                </span>
              </Link>
            )}
          </div>

          <div className='tab-content p-3'>
            {checkPageAccess(
              pageAccess,
              allPages?.downloadInformation,
              allCrudNames?.read
            ) && (
              <div
                id='brand'
                className={`tab-pane fade ${
                  activeToggle === 'bimcadDownload' ? 'active show' : ''
                }`}
              >
                {activeToggle === 'bimcadDownload' && <MainDownload />}
              </div>
            )}
          </div>
        </div>
      </div>
    </>
  ) : (
    <NotFound />
  )
}

export default ManageDownload
