import axios from 'axios'
import { logout } from '../pages/redux/slice/userSlice'
import {
  getBaseUrl,
  getDeviceId,
  getRefreshToken,
  getUserToken
} from './GetBaseUrl'
import { handleLogout } from './HandleLogout'

const api = axios.create()

const getUserId = async () => {
  const { store } = await import('../pages/redux/store')
  const { userId } = store.getState().user?.userInfo
  return userId
}

const removeUserOutOfSystem = async () => {
  import('../pages/redux/store')
    .then((module) => {
      const { store } = module
      return store.dispatch(logout())
    })
    .then(() => {
      // showAlert()
    })
    .catch((error) => {
      console.error('Failed to load store:', error)
    })
}

api.interceptors.request.use(
  (config) => {
    const token = getUserToken()
    if (token) {
      config.headers.Authorization = `Bearer ${token}`
      return config
    } else {
      handleLogout()
      // showAlert()+
    }
  },
  (error) => {
    return Promise.reject(error)
  }
)

// Add a response interceptor
api.interceptors.response.use(
  (response) => {
    return response
  },
  async (error) => {
    const originalRequest = error.config
    let accessToken = getUserToken()
    let refreshToken = getRefreshToken()
    let deviceId = getDeviceId()
    let userId = await getUserId()

    // Check if the response status is 401 (Unauthorized)
    if (
      error.response.status === 401 &&
      (!originalRequest._retry || typeof originalRequest._retry === 'undefined')
    ) {
      originalRequest._retry = true

      // Check if a token refresh is already in progress
      if (!isRefreshing) {
        isRefreshing = true

        // Make the call to refresh the tokens
        try {
          const response = await axios.post(
            `${getBaseUrl()}/Account/Token/GetNewTokens`,
            {
              accessToken,
              refreshToken,
              deviceId,
              userId
            }
          )

          if (response?.data?.code === 200) {
            let newAccessToken = response?.data?.accessToken
            let newRefreshToken = response?.data?.refreshToken
            refreshToken = newRefreshToken
            localStorage.setItem('userToken', newAccessToken)
            localStorage.setItem('refreshToken', newRefreshToken)
            originalRequest.headers.Authorization = `Bearer ${newAccessToken}`
            isRefreshing = false

            return api(originalRequest)
          } else if (response?.data?.code === 204) {
            removeUserOutOfSystem()
            isRefreshing = false
          } else {
            handleLogout()
            // showAlert()
            isRefreshing = false
          }
        } catch (error) {
          handleLogout()
          // showAlert()
          isRefreshing = false
        }
      } else {
        // If a token refresh is already in progress, add the request to the queue
        return new Promise((resolve, reject) => {
          requestQueue.push({ config: originalRequest, resolve, reject })
        })
      }
    }

    return Promise.reject(error)
  }
)

let isRefreshing = false

const requestQueue = []

const retryRequest = async (newAccessToken) => {
  while (requestQueue.length > 0) {
    const { config, resolve, reject } = requestQueue.shift()
    config.headers.Authorization = `Bearer ${newAccessToken}`
    try {
      const response = await api(config)
      resolve(response)
    } catch (error) {
      reject(error)
    }
  }
}

async function axiosProvider(data) {
  let response = null
  let config = {
    ...data,
    headers: {
      ...data.headers,
      device_id: getDeviceId()
    }
  }
  let apiURL = `${getBaseUrl()}/${config.endpoint}`

  if (config && config.queryString) {
    apiURL += config.queryString
  }

  try {
    const lowerCaseApiURL = apiURL.toLowerCase()
    switch (config.method) {
      case 'GET':
        response = await api.get(apiURL, {
          params: config.params || {},
          headers: config.headers || {}
        })
        break

      case 'POST':
        if (
          lowerCaseApiURL.includes('displaycalculation') ||
          lowerCaseApiURL.includes('logout') ||
          lowerCaseApiURL.includes('skucode') ||
          lowerCaseApiURL.includes('notification') ||
          lowerCaseApiURL.includes('tempimage') ||
          lowerCaseApiURL.includes('calculatepackagingweight') ||
          lowerCaseApiURL.includes('checkproduct')
        ) {
          response = await api.post(apiURL, config.data, {
            params: config.params,
            headers: config.headers
          })
        } else {
          response = await api.post(apiURL, config.data, {
            params: config.params,
            headers: config.headers
          })
          await logApiCall('Insert', data)
        }
        break

      case 'PUT':
        if (
          lowerCaseApiURL.includes('displaycalculation') ||
          lowerCaseApiURL.includes('logout')
        ) {
          response = await api.put(apiURL, config.data, {
            params: config.params,
            headers: config.headers
          })
        } else {
          response = await api.put(apiURL, config.data, {
            params: config.params,
            headers: config.headers
          })
          await logApiCall('Update', data)
        }
        break

      case 'PATCH':
        response = await api.patch(apiURL, config.data, {
          params: config.params,
          headers: config.headers
        })
        break

      case 'DELETE':
        if (
          lowerCaseApiURL.includes('displaycalculation') ||
          lowerCaseApiURL.includes('logout')
        ) {
          response = await api.delete(apiURL, {
            params: config.params,
            headers: config.headers
          })
        } else {
          response = await api.delete(apiURL, {
            params: config.params,
            headers: config.headers
          })
          await logApiCall('Delete', data)
        }
        break

      default:
        break
    }

    return response ? response : []
  } catch (error) {
    return []
  }
}

async function logApiCall(method, response) {
  if (!response?.endpoint?.toLowerCase()?.includes('notification')) {
    let logDescription = {}
    if (method === 'Update') {
      logDescription = {
        old: response?.oldData ? response?.oldData : {},
        new: response?.logData ? response.logData : response?.data
      }
    } else {
      logDescription = {
        new: response?.logData ? response.logData : response?.data
      }
    }

    let logPayload = {
      userId: response?.userId,
      userType: 'Admin',
      url: response?.location,
      action: method,
      logTitle: `Data ${method} successfully `,
      logDescription: JSON.stringify(logDescription)
    }

    try {
      await axios.post(`${getBaseUrl()}/Log`, logPayload)
    } catch (error) {}
  }
}

export default axiosProvider
