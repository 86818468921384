import React, { useState } from 'react'
import { Link } from 'react-router-dom'
import ColorsAttribute from './ColorAttribute'

const MainColor = () => {
  const [activeToggle, setActiveToggle] = useState('color')

  return (
    <>
      <div className='card overflow-hidden'>
        <div className='card-body p-0'>
          <div className='nav-tabs-horizontal nav nav-tabs'>
            <Link
              onClick={() => setActiveToggle('color')}
              data-toggle='tab'
              className={`nav-link fw-semibold ${
                activeToggle === 'color' ? 'active show' : ''
              }`}
            >
              <span className='nav-span'>
                Color
                <hr />
              </span>
            </Link>
          </div>
          <div className='tab-content p-3'>
            <div
              id='color'
              className={`tab-pane fade ${
                activeToggle === 'color' ? 'active show' : ''
              }`}
            >
              {activeToggle === 'color' && <ColorsAttribute />}
            </div>
          </div>
        </div>
      </div>
    </>
  )
}

export default MainColor
