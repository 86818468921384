/* eslint-disable default-case */
import { ErrorMessage, Form, Formik } from 'formik'
import React, { useEffect, useState } from 'react'
import { Col, Image, Row, Table } from 'react-bootstrap'
import Button from 'react-bootstrap/Button'
import { useSelector } from 'react-redux'
import { useLocation } from 'react-router-dom'
import Select from 'react-select'
import Swal from 'sweetalert2'
import { useImmer } from 'use-immer'
import * as Yup from 'yup'
import { allCrudNames, allPages, checkPageAccess } from '../../lib/AllPageNames'
import axiosProvider from '../../lib/AxiosProvider'
import { _SwalDelete } from '../../lib/exceptionMessage'
import {
  callApi,
  splitStringOnCapitalLettersAndUnderscores
} from '../../lib/GetBaseUrl'
import { _homePageImg_, _lendingPageImg_ } from '../../lib/ImagePath'
import { _integerRegex_ } from '../../lib/Regex'
import DeleteIcon from '../AllSvgIcon/DeleteIcon'
import EditIcon from '../AllSvgIcon/EditIcon'
import HKBadge from '../Badges'
import { customStyles } from '../customStyles'
import FormikControl from '../FormikControl'
import IpCheckbox from '../IpCheckbox'
import Loader from '../Loader'
import ModelComponent from '../Modal'
import TextError from '../TextError'

const CategoryWidget = ({
  fetchHomePageData,
  layoutDetails,
  setLayoutDetails,
  setToast,
  toast,
  fromLendingPage = false
}) => {
  const [dropDownData, setDropDownData] = useImmer({
    category: [],
    brand: [],
    product: [],
    color: [],
    sizeType: [],
    collectionPage: [],
    country: [],
    state: [],
    city: [],
    landingPage: [],
    tableData: [],
    flashSale: []
  })
  const { pageAccess } = useSelector((state) => state.user)
  const SUPPORTED_FORMATS = ['image/jpg', 'image/jpeg', 'image/png']

  const initVal = {
    sectionId: layoutDetails?.sectionId,
    image: '',
    imageFile: '',
    imageAlt: '',
    sequence: '',
    redirectTo: '',
    categoryId: '',
    brandIds: '',
    sizeIds: '',
    specificationIds: '',
    colorIds: '',
    collectionId: null,
    productId: null,
    staticPageId: null,
    lendingPageId: null,
    customLinks: '',
    assignCountry: '',
    assignState: '',
    assignCity: '',
    status: 'Active',
    columns: layoutDetails?.columnNumber ? layoutDetails?.columnNumber : '',
    isTitleVisible: false,
    titlePosition: 'Left',
    title: '',
    subTitle: ''
  }
  const [initialValues, setInitialValues] = useState(initVal)

  const [loading, setLoading] = useState(false)
  const location = useLocation()
  const { userId } = useSelector((state) => state?.user?.userInfo)
  function convertIdsToArray(idsString) {
    if (!idsString) {
      return []
    }

    const idsArray = idsString
      .split(',')
      .map((id) => ({ id: parseInt(id, 10) }))
    return idsArray
  }
  const fetchAllGenericData = async (apiUrls) => {
    try {
      const responseArray = await Promise.all(
        apiUrls.map((url) => callApi(url.endpoint, url.queryString, url.state))
      )

      return responseArray
    } catch (error) {}
  }

  const fetchData = async () => {
    try {
      let endpoint = fromLendingPage
        ? 'LendingPageSectionDetails/layoutTypeDetailsId&sectionId'
        : 'ManageHomePageDetails/layoutTypeDetailsId&sectionId'
      let apiUrls = [
        {
          endpoint,
          queryString: `?layoutTypeDetailsId=${
            layoutDetails?.layoutTypeDetailsId ?? 0
          }&sectionId=${layoutDetails?.sectionId}`,
          state: 'tableData'
        }
      ]
      if (!dropDownData?.country?.length) {
        apiUrls.push({
          endpoint: 'Country',
          queryString: '?pageSize=0&pageIndex=0',
          state: 'country'
        })
      }
      const fetchData = async () => {
        let country, tableData

        setLoading(true)
        const responses = await fetchAllGenericData(apiUrls)
        responses.forEach((response) => {
          switch (response.state) {
            case 'country':
              country = response.data
              break
            case 'tableData':
              tableData = response.data
              break

            default:
              break
          }
        })
        if (country?.length) {
          setDropDownData((draft) => {
            draft.country = country
          })
        }
        setDropDownData((draft) => {
          draft.tableData = tableData
        })
        setLoading(false)
      }
      fetchData()
    } catch (error) {
      setLoading(false)
    }
  }

  useEffect(() => {
    fetchData()
  }, [])

  const validationSchema = Yup.object().shape({
    imageAlt: Yup.string()
      .matches(/^[a-zA-Z\s]+$/, 'Please enter a valid name (letters only)')
      .required('Please enter name'),
    sequence: Yup.string()
      .matches(_integerRegex_, 'Please enter a valid number')
      .required('Please enter sequence'),
    redirectTo: Yup.string().required('Redirect to required'),
    categoryId: Yup.string().when('redirectTo', {
      is: 'Product list',
      then: () => Yup.string().required('Please select category'),
      otherwise: () => Yup.string().nullable()
    }),
    lendingPageId: Yup.string().when('redirectTo', {
      is: 'Landing page',
      then: () => Yup.string().required('Please select category'),
      otherwise: () => Yup.string().nullable()
    }),
    staticPageId: Yup.string().when('redirectTo', {
      is: 'Static page',
      then: () => Yup.string().required('Please select static page'),
      otherwise: () => Yup.string().nullable()
    }),
    productId: Yup.string().when('redirectTo', {
      is: 'Specific product',
      then: () => Yup.string().required('Please select product'),
      otherwise: () => Yup.string().nullable()
    }),
    collectionId: Yup.string().when('redirectTo', {
      is: 'Collection',
      then: () => Yup.string().required('Please select collection'),
      otherwise: () => Yup.string().nullable()
    }),
    customLinks: Yup.string().when('redirectTo', {
      is: 'Custom link',
      then: () => Yup.string().required('Please enter custom link'),
      otherwise: () => Yup.string().nullable()
    }),
    title: Yup.string().when('isTitleVisible', {
      is: (value) => value,
      then: () => Yup.string().required('Title required'),
      otherwise: () => Yup.string().notRequired()
    }),
    linkPosition: Yup.string().when('isLinkRequired', {
      is: (value) => value,
      then: () => Yup.string().required('Link position required'),
      otherwise: () => Yup.string().notRequired()
    }),
    image: Yup.mixed()
      .test(
        'fileFormat',
        'File formate is not supported, Please use .jpg/.png/.jpeg format support',
        (value) => {
          if (typeof value === 'string') return true
          else {
            return value && SUPPORTED_FORMATS?.includes(value.type)
          }
        }
      )
      .test('fileSize', 'File must be less than 2MB', (value) => {
        if (typeof value === 'string') {
          return true
        } else return value !== undefined && value && value.size <= 2000000
      })
      .required('Image is required')
  })

  const prepareIdsData = (data) => {
    return data?.map((option) => option?.id).join(',')
  }

  const handleDelete = async (id) => {
    setLoading(true)
    let endpoint = fromLendingPage
      ? `LendingPageSectionDetails?id=${id}`
      : `ManageHomePageDetails?id=${id}`
    const response = await axiosProvider({
      method: 'DELETE',
      endpoint,
      userId,
      location: location.pathname
    })
      .then((res) => {
        if (res?.status === 200) {
          fetchData()
          // fetchHomePageData()
          setLayoutDetails({ ...layoutDetails, isDataUpdated: true })
        }
        setToast({
          show: true,
          text: res?.data?.message,
          variation: res?.data?.code !== 200 ? 'error' : 'success'
        })

        setTimeout(() => {
          setToast({ ...toast, show: false })
        }, 2000)
        setLoading(false)
      })
      .catch((err) => {
        setLoading(false)
      })
  }

  const onSubmit = async (values, { resetForm }) => {
    let method = 'POST'
    let dataofForm = {
      Image: values?.image?.name ? values?.image?.name : values?.image,
      ImageFile: values?.image?.name && values?.image,
      ImageAlt: values?.imageAlt,
      Sequence: values?.sequence,
      RedirectTo: values?.redirectTo ?? 0,
      CategoryId: values?.categoryId ?? 0,
      BrandIds:
        Array.isArray(values?.brandIds) && values?.brandIds?.length
          ? prepareIdsData(values?.brandIds)
          : '',
      SizeIds:
        Array.isArray(values?.sizeIds) && values?.sizeIds?.length
          ? prepareIdsData(values?.sizeIds)
          : '',
      SpecificationIds:
        Array.isArray(values?.specificationIds) &&
        values?.specificationIds?.length
          ? prepareIdsData(values?.specificationIds)
          : '',
      ColorIds:
        Array.isArray(values?.colorIds) && values?.colorIds?.length
          ? prepareIdsData(values?.colorIds)
          : '',
      CollectionId: values?.collectionId ?? 0,
      ProductId: values?.productId ?? 0,
      LendingPageId: values?.lendingPageId ?? 0,
      StaticPageId: values?.staticPageId ?? 0,
      CustomLinks: values?.customLinks ?? '',
      AssignCountry:
        Array.isArray(values?.assignCountry) && values?.assignCountry?.length
          ? prepareIdsData(values?.assignCountry)
          : '',
      AssignState:
        Array.isArray(values?.assignState) && values?.assignState?.length
          ? prepareIdsData(values?.assignState)
          : '',
      AssignCity:
        Array.isArray(values?.assignCity) && values?.assignCity?.length
          ? prepareIdsData(values?.assignCity)
          : '',
      Status: values?.status ? values?.status : '',
      Columns: values?.columns ? values?.columns : '',
      IsTitleVisible: values?.isTitleVisible,
      TitlePosition: values?.titlePosition,
      Title: values?.title,
      SubTitle: values?.subTitle
    }

    if (fromLendingPage) {
      dataofForm = {
        ...dataofForm,
        LendingPageSectionId: values?.lendingPageSectionId
          ? values?.lendingPageSectionId
          : values?.sectionId
      }
    } else {
      dataofForm = {
        ...dataofForm,
        SectionId: values?.sectionId
      }
    }

    if (values?.id) {
      dataofForm = { ...dataofForm, Id: values?.id }
      method = 'PUT'
    }
    if (layoutDetails?.layoutTypeDetailsId) {
      dataofForm = {
        ...dataofForm,
        LayoutTypeDetailsId: layoutDetails?.layoutTypeDetailsId
      }
    }

    const submitFormData = new FormData()

    const keys = Object.keys(dataofForm)

    keys.forEach((key) => {
      submitFormData.append(key, dataofForm[key])
    })

    let endpoint = fromLendingPage
      ? 'LendingPageSectionDetails'
      : 'ManageHomePageDetails'

    setLoading(true)
    const response = await axiosProvider({
      method,
      endpoint,
      data: submitFormData,
      userId,
      location: location?.pathname,
      logData: values
    })
      .then((res) => {
        const image = document.getElementById('image').value
        if (image) {
          document.getElementById('image').value = null
        }
        setInitialValues(initVal)
        resetForm({ values: { ...initVal } })
        if (res?.status === 200) {
          // fetchHomePageData()
          // setLayoutDetails({
          //   show: !layoutDetails?.show,
          //   sectionId: null
          // })
          fetchData()
          setLayoutDetails({ ...layoutDetails, isDataUpdated: true })
        }
        setToast({
          show: true,
          text: res?.data?.message,
          variation: res?.data?.code !== 200 ? 'error' : 'success'
        })

        setTimeout(() => {
          setToast({ ...toast, show: false })
        }, 2000)
        setLoading(false)
      })
      .catch((err) => {
        setLoading(false)
      })
  }

  const prepareEditData = (
    editData,
    brand = null,
    sizeType = null,
    color = null,
    country = null,
    state = null,
    city = null
  ) => {
    let data = editData
    if (editData?.brandIds) {
      let brandIds = editData?.brandIds?.split(',').map(Number)
      brandIds = brand
        ?.filter((item) => brandIds.includes(item.id))
        ?.map((item) => ({ name: item.name, id: item.id }))
      data = { ...data, brandIds: brandIds }
    }

    if (editData?.colorIds) {
      let colorIds = editData?.colorIds?.split(',').map(Number)
      colorIds = color
        ?.filter((item) => colorIds.includes(item.id))
        ?.map((item) => ({ name: item.name, id: item.id }))
      data = { ...data, colorIds: colorIds }
    }

    if (editData?.sizeIds) {
      let sizeIds = editData?.sizeIds?.split(',').map(Number)
      sizeIds = sizeType
        ?.filter((item) => sizeIds.includes(item.sizeId))
        ?.map((item) => ({ name: item.sizeName, id: item.sizeId }))
      data = { ...data, sizeIds: sizeIds }
    }

    if (editData?.assignCity) {
      let cityIds = editData?.assignCity?.split(',').map(Number)
      cityIds = city
        ?.filter((item) => cityIds.includes(item.id))
        ?.map((item) => ({ name: item.name, id: item.id }))
      data = { ...data, assignCity: cityIds }
    }

    if (editData?.assignCountry) {
      let countryIds = editData?.assignCountry?.split(',').map(Number)
      countryIds = country
        ?.filter((item) => countryIds.includes(item.id))
        ?.map((item) => ({ name: item.name, id: item.id }))
      data = { ...data, assignCountry: countryIds }
    }

    if (editData?.assignState) {
      let stateIds = editData?.assignState?.split(',').map(Number)
      stateIds = state
        ?.filter((item) => stateIds?.includes(item.id))
        ?.map((item) => ({ name: item.name, id: item.id }))
      data = { ...data, assignState: stateIds }
    }
    return data
  }

  const apiCallForEditData = async (data) => {
    let apiUrls = []
    if (!dropDownData?.country?.length) {
      apiUrls.push({
        endpoint: 'Country',
        queryString: '?pageSize=0&pageIndex=0',
        state: 'country'
      })
    }
    if (data?.assignCountry) {
      apiUrls.push({
        endpoint: 'State/byCountryIds',
        queryString: `?countryIds=${data?.assignCountry}&PageIndex=0&PageSize=0`,
        state: 'state'
      })
    }
    if (data?.assignState) {
      apiUrls.push({
        endpoint: 'City/byStateandCountryIds',
        queryString: `?pageSize=0&pageIndex=0&stateIds=${data?.assignState}`,
        state: 'city'
      })
    }

    switch (data?.redirectTo) {
      case 'Product list':
        !dropDownData?.category?.length &&
          apiUrls.push({
            endpoint: 'MainCategory/getEndCategory',
            queryString: '?pageSize=0&pageIndex=0',
            state: 'category'
          })

        if (data?.brandIds) {
          apiUrls.push({
            endpoint: 'Brand',
            queryString: '?pageSize=0&pageIndex=0',
            state: 'brand'
          })
        }
        const assignSpecification = await callApi(
          'AssignSpecificationToCategory/getByCatId',
          `?catId=${data?.categoryId}`
        )
        if (data?.sizeIds) {
          if (assignSpecification?.isAllowSize) {
            apiUrls.push({
              endpoint: 'AssignSizeValuesToCategory/byAssignSpecId',
              queryString: `?assignSpecId=${assignSpecification?.id}&pageindex=0&PageSize=0`,
              state: 'sizeType'
            })
          }
        }

        if (assignSpecification?.isAllowColors) {
          apiUrls.push({
            endpoint: 'color',
            queryString: '?pageindex=0&PageSize=0',
            state: 'color'
          })
        }
        break

      case 'Specific product':
        !dropDownData?.product?.length &&
          apiUrls.push({
            endpoint: 'Product/AllChildProducts',
            queryString: '',
            state: 'product'
          })
        break
      case 'Collection':
        !dropDownData?.collectionPage?.length &&
          apiUrls.push({
            endpoint: 'ManageCollection',
            queryString: '',
            state: 'collectionPage'
          })
        break

      case 'Static page':
        !dropDownData?.staticPage?.length &&
          apiUrls.push({
            endpoint: 'ManageStaticPages',
            queryString: '',
            state: 'staticPage'
          })
        break
      case 'Landing page':
        !dropDownData?.landingPage?.length &&
          apiUrls.push({
            endpoint: 'LendingPage',
            queryString: '',
            state: 'landingPage'
          })
        break
    }

    let brand, color, sizeType, country, city, state
    if (apiUrls?.length) {
      setLoading(true)
      const responses = await fetchAllGenericData(apiUrls)
      setLoading(false)

      responses.forEach((response) => {
        switch (response.state) {
          case 'state':
            state = response?.data
            setDropDownData((draft) => {
              draft.state = state
            })
            break

          case 'sizeType':
            sizeType = response?.data
            setDropDownData((draft) => {
              draft.sizeType = response.data
            })
            break

          case 'brand':
            brand = response?.data
            setDropDownData((draft) => {
              draft.brand = response.data
            })
            break

          case 'color':
            color = response?.data
            setDropDownData((draft) => {
              draft.color = response.data
            })
            break

          case 'city':
            city = response?.data
            setDropDownData((draft) => {
              draft.city = city
            })
            break

          case 'category':
            setDropDownData((draft) => {
              draft.category = response.data
            })
            break

          case 'country':
            country = response?.data
            setDropDownData((draft) => {
              draft.country = country
            })
            break

          case 'product':
            setDropDownData((draft) => {
              draft.product = response.data
            })
            break

          case 'staticPage':
            setDropDownData((draft) => {
              draft.staticPage = response.data
            })
            break

          case 'landingPage':
            setDropDownData((draft) => {
              draft.landingPage = response.data
            })
            break

          case 'collectionPage':
            setDropDownData((draft) => {
              draft.collectionPage = response.data
            })
            break

          default:
            break
        }
      })
    }

    setInitialValues(
      prepareEditData(
        data,
        brand,
        sizeType,
        color,
        country ? country : dropDownData?.country,
        state,
        city
      )
    )
  }

  useEffect(() => {
    if (layoutDetails?.sectionDetailsId && dropDownData?.tableData?.length) {
      const data = dropDownData?.tableData?.find(
        (item) => item?.id === layoutDetails?.sectionDetailsId
      )

      if (data) {
        apiCallForEditData(data)
      }
    }
  }, [layoutDetails, dropDownData?.tableData])

  return (
    <ModelComponent
      show={layoutDetails?.show}
      modalsize={'xl'}
      modalheaderclass={''}
      modeltitle={
        layoutDetails?.layoutTypeName
          ? `Manage layout: ${splitStringOnCapitalLettersAndUnderscores(
              layoutDetails?.layoutTypeName
            )}`
          : `Manage layout details`
      }
      onHide={() => {
        setLayoutDetails({
          ...layoutDetails,
          show: !layoutDetails.show,
          layoutId: null,
          layoutTypeId: null,
          isDataUpdated: false
        })
        layoutDetails?.isDataUpdated && fetchHomePageData()
      }}
      backdrop={'static'}
      initialValue
    >
      <Formik
        enableReinitialize={true}
        initialValues={initialValues}
        validationSchema={validationSchema}
        onSubmit={onSubmit}
      >
        {({ values, setFieldValue, errors }) => (
          <Form id='return-policy-details'>
            {loading && <Loader />}
            {(dropDownData?.tableData?.length <
              layoutDetails?.maxImagesLength ||
              Number(layoutDetails?.maxImagesLength) === 0 ||
              values?.id) && (
              <>
                <Row className='justify-content-between'>
                  <Col md={3}>
                    <Row>
                      <Col md={12}>
                        <div className='input-file-wrapper m--cst-filetype mb-3'>
                          <label
                            className='form-label required'
                            htmlFor='image'
                          >
                            Image
                          </label>
                          <input
                            id='image'
                            className='form-control w-100'
                            name='image'
                            type='file'
                            accept='image/jpg, image/png, image/jpeg'
                            onChange={(event) => {
                              const objectUrl = URL.createObjectURL(
                                event.target.files[0]
                              )
                              if (event.target.files[0].type !== '') {
                                setFieldValue('imageUrl', objectUrl)
                              }
                              setFieldValue('image', event.target.files[0])
                            }}
                            hidden
                          />
                          {values?.image ? (
                            <div className='position-relative m--img-preview d-flex rounded-2 overflow-hidden w-100 h-50 pv-homepage-image-box'>
                              <img
                                src={
                                  values?.imageUrl?.includes('blob')
                                    ? values?.imageUrl
                                    : `${process.env.REACT_APP_IMG_URL}${
                                        fromLendingPage
                                          ? _lendingPageImg_
                                          : _homePageImg_
                                      }${values?.image}`
                                }
                                alt={values?.imageAlt}
                                title={
                                  values?.image?.name ? values?.image?.name : ''
                                }
                                className='rounded-2'
                              ></img>
                              <span
                                onClick={(e) => {
                                  setFieldValue('image', null)
                                  setFieldValue('imageUrl', null)
                                  setFieldValue('imageFile', null)
                                  document.getElementById('image').value = null
                                }}
                              >
                                <i className='m-icon m-icon--close'></i>
                              </span>
                            </div>
                          ) : (
                            <>
                              <label
                                className='m__image_default d-flex align-items-center justify-content-center rounded-2 w-100'
                                htmlFor='image'
                              >
                                <i className='m-icon m-icon--defaultpreview'></i>
                              </label>
                            </>
                          )}
                          <ErrorMessage name='image' component={TextError} />
                        </div>
                      </Col>
                      <Col md={12}>
                        <FormikControl
                          isRequired
                          control='input'
                          label='Image alt'
                          type='text'
                          name='imageAlt'
                          onChange={(e) => {
                            setFieldValue('imageAlt', e?.target?.value)
                          }}
                          placeholder='Image alt'
                        />
                      </Col>
                    </Row>
                  </Col>
                  <Col className='border-1 border-start ps-4'>
                    <Row>
                      <Col md={2}>
                        <FormikControl
                          isRequired
                          control='input'
                          label='Sequence'
                          type='text'
                          name='sequence'
                          onChange={(e) => {
                            const inputValue = e?.target?.value
                            const isValid = _integerRegex_.test(inputValue)
                            const fieldName = e?.target?.name
                            if (isValid || !inputValue)
                              setFieldValue([fieldName], inputValue)
                          }}
                          placeholder='Sequence'
                        />
                      </Col>
                      <Col md={4}>
                        <label
                          htmlFor='Redirect To'
                          className='form-label required'
                        >
                          Redirect to
                        </label>
                        <Select
                          isClearable
                          id='RedirectTo'
                          menuPortalTarget={document.body}
                          menuPosition={'fixed'}
                          styles={customStyles}
                          value={
                            values?.redirectTo && {
                              label: values?.redirectTo,
                              value: values?.red
                            }
                          }
                          options={
                            fromLendingPage
                              ? [
                                  {
                                    label: 'Product list',
                                    value: 'Product list'
                                  },
                                  {
                                    label: 'Specific product',
                                    value: 'Specific product'
                                  },
                                  // {
                                  //   label: 'Collection',
                                  //   value: 'Collection'
                                  // },
                                  // {
                                  //   label: 'Static page',
                                  //   value: 'Static page'
                                  // },
                                  {
                                    label: 'Custom link',
                                    value: 'Custom link'
                                  }
                                ]
                              : [
                                  {
                                    label: 'Product list',
                                    value: 'Product list'
                                  },
                                  {
                                    label: 'Specific product',
                                    value: 'Specific product'
                                  },
                                  // {
                                  //   label: 'Collection',
                                  //   value: 'Collection'
                                  // },
                                  // {
                                  //   label: 'Static page',
                                  //   value: 'Static page'
                                  // },
                                  // {
                                  //   label: 'Landing page',
                                  //   value: 'Landing page'
                                  // },
                                  {
                                    label: 'Custom link',
                                    value: 'Custom link'
                                  }
                                ]
                          }
                          onChange={(e) => {
                            let apiUrls = []
                            switch (e?.value) {
                              case 'Product list':
                                !dropDownData?.category?.length &&
                                  apiUrls.push({
                                    endpoint: 'MainCategory/getEndCategory',
                                    queryString: '?pageSize=0&pageIndex=0',
                                    state: 'category'
                                  })
                                break
                              case 'Specific product':
                                !dropDownData?.product?.length &&
                                  apiUrls.push({
                                    endpoint: 'Product/AllChildProducts',
                                    queryString: '',
                                    state: 'product'
                                  })
                                break
                              case 'Collection':
                                !dropDownData?.collectionPage?.length &&
                                  apiUrls.push({
                                    endpoint: 'ManageCollection',
                                    queryString: '?pageIndex=0&pageSize=0',
                                    state: 'collectionPage'
                                  })
                              case 'Static page':
                                !dropDownData?.staticPage?.length &&
                                  apiUrls.push({
                                    endpoint: 'ManageStaticPages',
                                    queryString: '',
                                    state: 'staticPage'
                                  })
                                break
                              case 'Landing page':
                                !dropDownData?.landingPage?.length &&
                                  apiUrls.push({
                                    endpoint: 'LendingPage',
                                    queryString: '',
                                    state: 'landingPage'
                                  })
                                break
                            }
                            const fetchData = async () => {
                              setLoading(true)
                              const responses = await fetchAllGenericData(
                                apiUrls
                              )
                              responses.forEach((response) => {
                                switch (response.state) {
                                  case 'category':
                                    setDropDownData((draft) => {
                                      draft.category = response.data
                                    })
                                    break

                                  case 'product':
                                    setDropDownData((draft) => {
                                      draft.product = response.data
                                    })
                                    break

                                  case 'staticPage':
                                    setDropDownData((draft) => {
                                      draft.staticPage = response.data
                                    })
                                    break

                                  case 'landingPage':
                                    setDropDownData((draft) => {
                                      draft.landingPage = response.data
                                    })
                                    break

                                  case 'collectionPage':
                                    setDropDownData((draft) => {
                                      draft.collectionPage = response.data
                                    })
                                    break

                                  default:
                                    break
                                }
                              })
                              setLoading(false)
                            }
                            apiUrls?.length && fetchData()
                            setFieldValue('redirectTo', e?.value)
                            setFieldValue('brandIds', [])
                            setFieldValue('colorIds', [])
                            setFieldValue('sizeIds', [])
                            setFieldValue('collectionId', null)
                            setFieldValue('staticPageId', null)
                            setFieldValue('categoryId', null)
                            setFieldValue('lendingPageId', null)
                            setFieldValue('productId', null)
                            setFieldValue('customLinks', '')
                          }}
                        />
                        <ErrorMessage name='redirectTo' component={TextError} />
                      </Col>
                      {values?.redirectTo?.toLowerCase() === 'product list' && (
                        <>
                          <Col md={6}>
                            <label
                              htmlFor='categoryId'
                              className='form-label required'
                            >
                              Select category
                            </label>
                            <Select
                              id='categoryId'
                              menuPortalTarget={document.body}
                              menuPosition={'fixed'}
                              isClearable
                              value={
                                values?.categoryId && {
                                  label: dropDownData?.category?.find(
                                    (obj) => obj.id === values?.categoryId
                                  )?.pathNames,
                                  value: values?.collectionId
                                }
                              }
                              styles={customStyles}
                              options={dropDownData?.category?.map(
                                ({ pathNames, id }) => ({
                                  label: pathNames,
                                  value: id
                                })
                              )}
                              onChange={(e) => {
                                const fetchData = async () => {
                                  try {
                                    setLoading(true)
                                    let apiUrls = []
                                    if (!dropDownData?.brand?.length) {
                                      apiUrls.push({
                                        endpoint: 'Brand',
                                        queryString: '?pageSize=0&pageIndex=0',
                                        state: 'brand'
                                      })
                                    }
                                    const data = await callApi(
                                      'AssignSpecificationToCategory/getByCatId',
                                      `?catId=${e?.value}`
                                    )
                                    if (data?.isAllowColors) {
                                      apiUrls.push({
                                        endpoint: 'color',
                                        queryString: '?pageindex=0&PageSize=0',
                                        state: 'color'
                                      })
                                    }
                                    if (data?.isAllowSize) {
                                      apiUrls.push({
                                        endpoint:
                                          'AssignSizeValuesToCategory/byAssignSpecId',
                                        queryString: `?assignSpecId=${data?.id}&pageindex=0&PageSize=0`,
                                        state: 'sizeType'
                                      })
                                    }
                                    const fetchData = async () => {
                                      let sizeType, brand, color

                                      const responses =
                                        await fetchAllGenericData(apiUrls)
                                      responses.forEach((response) => {
                                        switch (response.state) {
                                          case 'sizeType':
                                            sizeType = response.data
                                            break

                                          case 'brand':
                                            brand = response?.data
                                            break

                                          case 'color':
                                            color = response?.data
                                            break

                                          default:
                                            break
                                        }
                                      })

                                      if (brand) {
                                        setDropDownData((draft) => {
                                          draft.brand = brand
                                        })
                                      }
                                      setDropDownData((draft) => {
                                        draft.color = color
                                        draft.sizeType = sizeType
                                      })
                                      setLoading(false)
                                    }
                                    fetchData()
                                  } catch (error) {
                                    setLoading(false)
                                  }
                                }
                                setFieldValue('categoryId', e?.value ?? null)
                                fetchData()
                              }}
                            />
                            {errors?.categoryId && (
                              <div className={'text-danger'}>
                                {errors?.categoryId}
                              </div>
                            )}
                          </Col>
                          {dropDownData?.brand?.length > 0 && (
                            <Col md={4}>
                              <label
                                htmlFor='SelectBrands'
                                className='form-label'
                              >
                                Select Brand
                              </label>
                              <Select
                                isMulti
                                name='brandIds'
                                id='brandIds'
                                menuPortalTarget={document.body}
                                menuPosition={'fixed'}
                                value={
                                  values?.brandIds?.length > 0 &&
                                  values?.brandIds?.map(({ name, id }) => {
                                    return {
                                      label: dropDownData?.brand?.find(
                                        (obj) => obj.id === id
                                      )?.name,
                                      value: id
                                    }
                                  })
                                }
                                styles={customStyles}
                                options={dropDownData?.brand?.map(
                                  ({ name, id }) => ({
                                    label: name,
                                    value: id
                                  })
                                )}
                                onChange={(e, event) => {
                                  let fieldName = event?.name
                                  let listData = e?.map((data) => {
                                    return { name: data?.label, id: data.value }
                                  })
                                  setFieldValue([fieldName], listData)
                                }}
                              />
                            </Col>
                          )}

                          {dropDownData?.sizeType?.length > 0 && (
                            <Col md={4}>
                              <label
                                htmlFor='selectSize'
                                className='form-label'
                              >
                                Select size
                              </label>
                              <Select
                                isMulti
                                name='sizeIds'
                                id='sizeIds'
                                menuPortalTarget={document.body}
                                menuPosition={'fixed'}
                                styles={customStyles}
                                value={
                                  values?.sizeIds?.length > 0 &&
                                  values.sizeIds?.map(({ id }) => {
                                    return {
                                      label: dropDownData?.sizeType?.find(
                                        (obj) => obj.sizeId === id
                                      )?.sizeName,
                                      value: id
                                    }
                                  })
                                }
                                options={dropDownData?.sizeType?.map(
                                  ({ sizeName, sizeId }) => ({
                                    label: sizeName,
                                    value: sizeId
                                  })
                                )}
                                onChange={(e, event) => {
                                  let fieldName = event?.name
                                  let listData = e?.map((data) => {
                                    return { name: data?.label, id: data.value }
                                  })
                                  setFieldValue([fieldName], listData)
                                }}
                              />
                            </Col>
                          )}

                          {dropDownData?.color?.length > 0 && (
                            <Col md={4}>
                              <label
                                htmlFor='selectColors'
                                className='form-label'
                              >
                                Select color
                              </label>
                              <Select
                                isMulti
                                id='colorIds'
                                name='colorIds'
                                menuPortalTarget={document.body}
                                value={
                                  values?.colorIds?.length > 0 &&
                                  values?.colorIds?.map(({ id }) => {
                                    return {
                                      label: dropDownData?.color?.find(
                                        (obj) => obj.id === id
                                      )?.name,
                                      value: id
                                    }
                                  })
                                }
                                menuPosition={'fixed'}
                                styles={customStyles}
                                options={dropDownData?.color?.map(
                                  ({ name, id }) => ({
                                    label: name,
                                    value: id
                                  })
                                )}
                                onChange={(e, event) => {
                                  let fieldName = event?.name
                                  let listData = e?.map((data) => {
                                    return { name: data?.label, id: data.value }
                                  })
                                  setFieldValue([fieldName], listData)
                                }}
                              />
                            </Col>
                          )}
                        </>
                      )}

                      {values?.redirectTo?.toLowerCase() === 'collection' && (
                        <Col md={4}>
                          <label
                            htmlFor='SelectCollection'
                            className='form-label required'
                          >
                            Select collection
                          </label>
                          <Select
                            id='SelectCollection'
                            menuPortalTarget={document.body}
                            menuPosition={'fixed'}
                            value={
                              values?.collectionId && {
                                label: dropDownData?.collectionPage?.find(
                                  (obj) => obj.id === values?.collectionId
                                )?.name,
                                value: values?.collectionId
                              }
                            }
                            styles={customStyles}
                            options={dropDownData?.collectionPage?.map(
                              ({ name, id }) => ({
                                label: name,
                                value: id
                              })
                            )}
                            onChange={(e) => {
                              setFieldValue('collectionId', e?.value)
                            }}
                          />
                          <ErrorMessage
                            name='collectionId'
                            component={TextError}
                          />
                        </Col>
                      )}

                      {values?.redirectTo?.toLowerCase() === 'landing page' && (
                        <Col md={4}>
                          <label
                            htmlFor='selectProduct'
                            className='form-label required'
                          >
                            Select landing page
                          </label>
                          <Select
                            id='landingPage'
                            menuPortalTarget={document.body}
                            menuPosition={'fixed'}
                            styles={customStyles}
                            value={
                              values?.lendingPageId && {
                                label: dropDownData?.landingPage?.find(
                                  (obj) => obj.id === values?.lendingPageId
                                )?.name,
                                value: values?.lendingPageId
                              }
                            }
                            options={dropDownData?.landingPage?.map(
                              ({ name, id }) => ({
                                label: name,
                                value: id
                              })
                            )}
                            onChange={(e) => {
                              setFieldValue('lendingPageId', e?.value)
                            }}
                          />
                          <ErrorMessage
                            name='lendingPageId'
                            component={TextError}
                          />
                        </Col>
                      )}

                      {values?.redirectTo?.toLowerCase() ===
                        'specific product' && (
                        <Col md={4}>
                          <label
                            htmlFor='selectProduct'
                            className='form-label required'
                          >
                            Select product
                          </label>
                          <Select
                            id='productId'
                            menuPortalTarget={document.body}
                            menuPosition={'fixed'}
                            styles={customStyles}
                            value={
                              values?.productId && {
                                value: values?.productId,
                                label: dropDownData?.product?.find(
                                  (obj) => obj.productGuid === values?.productId
                                )?.productName
                              }
                            }
                            options={dropDownData?.product?.map(
                              ({ productName, productGuid }) => ({
                                label: productName,
                                value: productGuid
                              })
                            )}
                            onChange={(e) => {
                              setFieldValue('productId', e?.value)
                            }}
                          />
                          <ErrorMessage
                            name='productId'
                            component={TextError}
                          />
                        </Col>
                      )}

                      {values?.redirectTo?.toLowerCase() === 'static page' && (
                        <Col md={4}>
                          <label
                            htmlFor='selectStaticPage'
                            className='form-label required'
                          >
                            Select Static Page
                          </label>
                          <Select
                            id='selectStaticPage'
                            menuPortalTarget={document.body}
                            menuPosition={'fixed'}
                            styles={customStyles}
                            value={
                              values?.staticPageId && {
                                label: dropDownData?.staticPage?.find(
                                  (obj) => obj.id === values?.staticPageId
                                )?.name,
                                value: values?.staticPageId
                              }
                            }
                            options={dropDownData?.staticPage?.map(
                              ({ name, id }) => ({ label: name, value: id })
                            )}
                            onChange={(e) => {
                              setFieldValue('staticPageId', e?.value)
                            }}
                          />
                          <ErrorMessage
                            name='staticPageId'
                            component={TextError}
                          />
                        </Col>
                      )}

                      {values?.redirectTo?.toLowerCase() === 'custom link' && (
                        <Col md={4}>
                          <FormikControl
                            isRequired
                            control='input'
                            label='Custom Link'
                            type='text'
                            name='customLinks'
                            onChange={(e) => {
                              setFieldValue('customLinks', e?.target?.value)
                            }}
                            placeholder='Custom link'
                          />
                        </Col>
                      )}
                      <Row className='mt-2'>
                        {/* <Col md={4}>
                          <label htmlFor='AssignCountry' className='form-label'>
                            Assign Country
                          </label>
                          <Select
                            isMulti
                            id='AssignCountry'
                            menuPortalTarget={document.body}
                            menuPosition={'fixed'}
                            styles={customStyles}
                            value={
                              values?.assignCountry?.length > 0 &&
                              values.assignCountry?.map((data) => {
                                return {
                                  label: dropDownData?.country?.find(
                                    (obj) => obj?.id === data?.id
                                  )?.name,
                                  value: data?.id
                                }
                              })
                            }
                            options={dropDownData?.country?.map(
                              ({ name, id }) => ({
                                label: name,
                                value: id
                              })
                            )}
                            onChange={(e) => {
                              const fetchData = async (ids) => {
                                try {
                                  let apiUrls = []
                                  apiUrls.push({
                                    endpoint: 'State/byCountryIds',
                                    queryString: `?countryIds=${ids}&PageIndex=0&PageSize=0`,
                                    state: 'state'
                                  })
                                  const fetchData = async () => {
                                    let state
                                    setLoading(true)

                                    const responses = await fetchAllGenericData(
                                      apiUrls
                                    )
                                    responses.forEach((response) => {
                                      switch (response.state) {
                                        case 'state':
                                          state = response.data
                                          break

                                        default:
                                          break
                                      }
                                    })
                                    setDropDownData((draft) => {
                                      draft.state = state
                                      draft.city = []
                                    })
                                    setLoading(false)
                                  }
                                  fetchData()
                                } catch (error) {
                                  setLoading(false)
                                }
                              }
                              let assignCountry = e?.map((data) => {
                                return { name: data?.label, id: data.value }
                              })
                              setFieldValue('assignCountry', assignCountry)
                              setFieldValue('assignState', [])
                              setFieldValue('assignCity', [])

                              let ids = prepareIdsData(assignCountry)

                              ids
                                ? fetchData(ids)
                                : setDropDownData((draft) => {
                                    draft.state = []
                                    draft.city = []
                                  })
                            }}
                          />
                        </Col>
                        {dropDownData?.state?.length > 0 && (
                          <Col md={4}>
                            <label htmlFor='AssignState' className='form-label'>
                              Assign State
                            </label>
                            <Select
                              isMulti
                              id='AssignState'
                              menuPortalTarget={document.body}
                              menuPosition={'fixed'}
                              styles={customStyles}
                              value={
                                values?.assignState &&
                                values.assignState?.map((data) => {
                                  return {
                                    label: dropDownData?.state?.find(
                                      (obj) => obj?.id === data?.id
                                    )?.name,
                                    value: data?.id
                                  }
                                })
                              }
                              options={dropDownData?.state?.map(
                                ({ name, id }) => ({
                                  label: name,
                                  value: id
                                })
                              )}
                              onChange={(e) => {
                                const fetchData = async (ids) => {
                                  try {
                                    let apiUrls = []
                                    apiUrls.push({
                                      endpoint: 'City/byStateandCountryIds',
                                      queryString: `?pageSize=0&pageIndex=0&stateIds=${ids}`,
                                      state: 'city'
                                    })
                                    const fetchData = async () => {
                                      let city
                                      setLoading(true)

                                      const responses =
                                        await fetchAllGenericData(apiUrls)
                                      responses.forEach((response) => {
                                        switch (response.state) {
                                          case 'city':
                                            city = response.data
                                            break

                                          default:
                                            break
                                        }
                                      })
                                      setDropDownData((draft) => {
                                        draft.city = city
                                      })
                                      setLoading(false)
                                    }
                                    fetchData()
                                  } catch (error) {
                                    setLoading(false)
                                  }
                                }
                                let assignState = e?.map((data) => {
                                  return { name: data?.label, id: data.value }
                                })
                                setFieldValue('assignState', assignState)
                                setFieldValue('assignCity', [])

                                let ids = prepareIdsData(assignState)

                                ids
                                  ? fetchData(ids)
                                  : setDropDownData((draft) => {
                                      draft.city = []
                                    })
                              }}
                            />
                          </Col>
                        )}

                        {dropDownData?.city?.length > 0 && (
                          <Col md={4}>
                            <label htmlFor='AssignCity' className='form-label'>
                              Assign City
                            </label>
                            <Select
                              isMulti
                              id='AssignCity'
                              menuPortalTarget={document.body}
                              menuPosition={'fixed'}
                              styles={customStyles}
                              value={
                                values?.assignCity &&
                                values.assignCity?.map((data) => {
                                  return {
                                    label: dropDownData?.city?.find(
                                      (obj) => obj?.id === data?.id
                                    )?.name,
                                    value: data?.id
                                  }
                                })
                              }
                              options={dropDownData?.city?.map(
                                ({ name, id }) => ({
                                  label: name,
                                  value: id
                                })
                              )}
                              onChange={(e) => {
                                let assignCity = e?.map((data) => {
                                  return { name: data?.label, id: data.value }
                                })
                                setFieldValue('assignCity', assignCity)
                              }}
                            />
                          </Col>
                        )} */}

                        <div>
                          <Button
                            type='submit'
                            variant='primary'
                            className='d-flex mt-3 align-items-center gap-2 justify-content-center fw-semibold'
                          >
                            {values?.id ? 'Update' : 'Add'} Section
                          </Button>
                        </div>
                      </Row>
                    </Row>
                  </Col>
                </Row>
                {layoutDetails?.layoutName?.toLowerCase() === 'thumbline' && (
                  <>
                    <IpCheckbox
                      checked={values?.isTitleVisible ? true : false}
                      checkboxLabel={'Title Visibility'}
                      checkboxid={'isTitleVisible'}
                      value={'isTitleVisible'}
                      changeListener={(e) => {
                        setFieldValue('isTitleVisible', e?.checked)
                      }}
                    />

                    {values?.isTitleVisible && (
                      <Row>
                        <Col md={5}>
                          <FormikControl
                            control='input'
                            label='Title'
                            type='text'
                            name='title'
                            onChange={(e) => {
                              setFieldValue('title', e?.target?.value)
                            }}
                            placeholder='Title'
                            isRequired
                          />
                        </Col>

                        <Col md={5}>
                          <FormikControl
                            control='input'
                            label='Sub title'
                            type='text'
                            name='subTitle'
                            onChange={(e) => {
                              setFieldValue('subTitle', e?.target?.value)
                            }}
                            placeholder='Sub title'
                          />
                        </Col>
                        <Col md={2}>
                          <label
                            htmlFor='titlePosition'
                            className='form-label required'
                          >
                            Title position
                          </label>
                          <Select
                            id='titlePosition'
                            menuPortalTarget={document.body}
                            menuPosition={'fixed'}
                            styles={customStyles}
                            value={
                              values?.titlePosition && {
                                label: values?.titlePosition,
                                value: values?.titlePosition
                              }
                            }
                            options={[
                              {
                                label: 'Left',
                                value: 'Left'
                              },
                              {
                                label: 'Center',
                                value: 'Center'
                              },
                              {
                                label: 'Right',
                                value: 'Right'
                              }
                            ]}
                            onChange={(e) => {
                              setFieldValue('titlePosition', e?.label)
                            }}
                          />
                          <ErrorMessage
                            name='titlePosition'
                            component={TextError}
                            customclass={'cfz-12 lh-sm'}
                          />
                        </Col>
                      </Row>
                    )}
                  </>
                )}
              </>
            )}

            <h5 className='my-3 head_h3'>First Category List</h5>
            <Table className='align-middle table-list hr_table_seller'>
              <thead>
                <tr>
                  <th>Image</th>
                  <th>Sequence</th>
                  <th>Redirect To</th>
                  <th>Status</th>
                  {checkPageAccess(pageAccess, allPages?.homePage, [
                    allCrudNames?.update,
                    allCrudNames?.delete
                  ]) && <th className='text-center'>Action</th>}
                </tr>
              </thead>
              <tbody>
                {dropDownData?.tableData?.length > 0 ? (
                  dropDownData?.tableData?.map((data) => (
                    <tr key={Math.floor(Math.random() * 100000)}>
                      <td>
                        <div className='d-flex gap-2 align-items-center'>
                          <Image
                            src={
                              data?.image
                                ? `${process.env.REACT_APP_IMG_URL}${
                                    fromLendingPage
                                      ? _lendingPageImg_
                                      : _homePageImg_
                                  }${data?.image}`
                                : 'https://placehold.jp/50x50.png'
                            }
                            className='rounded-1 img-object-fit-con '
                            height='50px'
                          />
                          <span>{data?.sectionName}</span>
                        </div>
                      </td>
                      <td>{data?.sequence}</td>
                      <td>{data?.redirectTo}</td>
                      <td>
                        <HKBadge
                          badgesbgname={
                            data?.status?.toLowerCase() === 'active'
                              ? 'success'
                              : 'danger'
                          }
                          badgesTxtname={data?.status}
                          badgeClassname={''}
                        />
                      </td>
                      {checkPageAccess(pageAccess, allPages?.homePage, [
                        allCrudNames?.update,
                        allCrudNames?.delete
                      ]) && (
                        <td>
                          <div className='d-flex gap-2 justify-content-center'>
                            {checkPageAccess(
                              pageAccess,
                              allPages?.homePage,
                              allCrudNames?.update
                            ) && (
                              <span
                                onClick={async () => {
                                  setFieldValue('redirectTo', data?.redirectTo)
                                  setFieldValue('imageAlt', data?.imageAlt)
                                  setFieldValue('sequence', data?.sequence)
                                  setFieldValue('id', data?.id)
                                  apiCallForEditData(data)
                                  setFieldValue('productId', data?.productId)
                                }}
                              >
                                <EditIcon bg={'bg'} />
                              </span>
                            )}
                            {checkPageAccess(
                              pageAccess,
                              allPages?.homePage,
                              allCrudNames?.delete
                            ) &&
                              values?.id !== data?.id && (
                                <span
                                  onClick={() => {
                                    Swal.fire({
                                      title: _SwalDelete.title,
                                      text: _SwalDelete.text,
                                      icon: _SwalDelete.icon,
                                      showCancelButton:
                                        _SwalDelete.showCancelButton,
                                      confirmButtonColor:
                                        _SwalDelete.confirmButtonColor,
                                      cancelButtonColor:
                                        _SwalDelete.cancelButtonColor,
                                      confirmButtonText:
                                        _SwalDelete.confirmButtonText,
                                      cancelButtonText:
                                        _SwalDelete.cancelButtonText
                                    }).then((result) => {
                                      if (result.isConfirmed) {
                                        handleDelete(data?.id)
                                      } else if (result.isDenied) {
                                      }
                                    })
                                  }}
                                >
                                  <DeleteIcon bg={'bg'} />
                                </span>
                              )}
                          </div>
                        </td>
                      )}
                    </tr>
                  ))
                ) : (
                  <tr>
                    <td colSpan={5} className='text-center'>
                      Record does not Exist.
                    </td>
                  </tr>
                )}
              </tbody>
            </Table>
          </Form>
        )}
      </Formik>
    </ModelComponent>
  )
}

export default CategoryWidget
