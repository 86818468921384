import React from 'react'
import Button from 'react-bootstrap/Button'
import Modal from 'react-bootstrap/Modal'

const ModelComponent = (props) => {
  return (
    <>
      <Modal
        {...props}
        size={props.modalsize || 'lg'}
        aria-labelledby='contained-modal-title-vcenter'
        centered
      >
        <Modal.Header closeButton>
          <Modal.Title
            id='contained-modal-title-vcenter'
            className={props.modalheaderclass || 'fw-semibold'}
          >
            {props.modeltitle || 'Default Modal Title'}
          </Modal.Title>
        </Modal.Header>

        <Modal.Body>{props.children}</Modal.Body>

        <Modal.Footer className={props.footerclass || ''}>
          {/* {props.footer} */}

          {props?.formbuttonid ? (
            <>
              <Button
                variant={props.closebtnvariant || 'primary'}
                type={'submit'}
                form={props.formbuttonid || ''}
              >
                {props.submitname || 'Save'}
              </Button>
              <Button
                variant={props.closebtnvariant || 'light'}
                onClick={props.onHide}
              >
                {props.btnclosetext || 'Close'}
              </Button>
            </>
          ) : (
            !props?.closebtnshow && (
              <Button
                variant={props.closebtnvariant || 'light'}
                onClick={props.onHide}
              >
                {props.btnclosetext || 'Close'}
              </Button>
            )
          )}
        </Modal.Footer>
      </Modal>
    </>
  )
}

export default ModelComponent
