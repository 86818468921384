import React from 'react'
import ReactDOM from 'react-dom/client'
import App from './App'
import reportWebVitals from './reportWebVitals'
import './css/style.scss'
import { BrowserRouter } from 'react-router-dom'
import LoaderComponent from './components/LoderComponent'
import { Provider } from 'react-redux'
import { PersistGate } from 'redux-persist/integration/react'
import { persistor, store } from './pages/redux/store'
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
// import { RouterProvider } from 'react-router-dom';
// import router from './router/index';
// import Header from './components/Header';
const root = ReactDOM.createRoot(document.getElementById('root'))

root.render(
  <React.StrictMode>
    {/* <RouterProvider router={router}>
      <Header> */}
    <Provider store={store}>
      <PersistGate loading={null} persistor={persistor}>
        <BrowserRouter>
          <App />
        </BrowserRouter>
      </PersistGate>
    </Provider>
    {/* </Header>
    </RouterProvider> */}
  </React.StrictMode>
)

reportWebVitals()
