import React from 'react'
import { Route, Routes } from 'react-router-dom'
import ProtectedRoute from './lib/ProtectedRoute'
import AssignCategory from './pages/category/category-attributes/AssignCategory'
import ManageFilter from './pages/category/category-attributes/ManageFilter'
import ManageAttributes from './pages/category/manage-attributes/ManageAttributes'
import ManageCategory from './pages/category/manage-category/ManageCategory'
import Changepassword from './pages/ChangePassword/Changepassword'
// import Dashboard from './pages/Dashboard'
import EditProfile from './pages/editProfile/EditProfile'
import ForgotPassword from './pages/forgot-password/ForgotPassword'
import ResetPassword from './pages/forgot-password/ResetPassword'
import Login from './pages/login/Login.jsx'
import Logs from './pages/Logs/Logs'
import NotFound from './pages/NotFound/NotFound'
import Notification from './pages/Notification/Notification'
import AddProduct from './pages/product/AddProduct'
import ArchiveProducts from './pages/product/ArchiveProducts'
import ManageProduct from './pages/product/ManageProduct'
import ManageAssignRoles from './pages/settings/AssignRoles/ManageAssignRoles'
import ManageDownload from './pages/settings/DownloadInformation/ManageDownload'
import AdminAcountInformation from './pages/settings/ManageAdmin/AdminAcountInformation'
import ManageAdmin from './pages/settings/ManageAdmin/ManageAdmin'
import ManageBimCad from './pages/settings/ManageBimCad/ManageBimCad'
import ManageBrand from './pages/settings/ManageBrand/ManageBrand'
import ManageHomePage from './pages/settings/ManageHomePage/ManageHomePage'
import ManageRole from './pages/settings/ManageRole/ManageRole'
import ManageUser from './pages/settings/ManageUser/ManageUser'
import ManageWishlistProject from './pages/settings/ManageWishlistProject/ManageWishlistProject.jsx'
import ManageReport from './pages/settings/Report/ManageReport'
import ManageRequestInformation from './pages/settings/RequestInformation/ManageRequestInformation'
import ManageSeller from './pages/settings/seller/ManageSeller'
import SellerDetails from './pages/settings/seller/SellerDetails'
import Setting from './pages/settings/Setting'
import ManageSubscribe from './pages/Subscribe/ManageSubscribe'
import Dashboard from './pages/dashboard/Dashboard.jsx'

const AllRoutes = () => {
  return (
    <Routes>
      <Route exact path='/login' element={<Login />}></Route>
      <Route exact path='/forgot-password' element={<ForgotPassword />} />
      <Route exact path='/reset/:token/:uid' element={<ResetPassword />} />
      <Route element={<ProtectedRoute />}>
        <Route exact path='/dashboard' element={<Dashboard />}></Route>
        <Route
          exact
          path='/settings/setting/change-password'
          element={<Changepassword />}
        ></Route>
        <Route exact path='/edit-profile' element={<EditProfile />}></Route>
        <Route
          exact
          path='/category/manage-category'
          element={<ManageCategory />}
        ></Route>
        <Route
          exact
          path='/category/manage-attributes'
          element={<ManageAttributes />}
        ></Route>
        <Route
          exact
          path='/category/assign-category'
          element={<AssignCategory />}
        ></Route>
        <Route
          exact
          path='/category/assign-category/manage-filter'
          element={<ManageFilter />}
        ></Route>
        <Route
          exact
          path='/category/assign-category/manage-filter/:id'
          element={<ManageFilter />}
        ></Route>
        <Route exact path='/settings/setting' element={<Setting />}></Route>

        <Route exact path='/manage-seller' element={<ManageSeller />} />

        <Route exact path='/manage-brand' element={<ManageBrand />}></Route>
        <Route
          exact
          path='/manage-brand/:assignBrand'
          element={<ManageBrand />}
        ></Route>
        <Route
          exact
          path='/manage-seller/seller-details/:id'
          element={<SellerDetails />}
        ></Route>

        <Route exact path='/manage-product' element={<ManageProduct />}></Route>
        <Route
          exact
          path='/manage-product/add-product'
          element={<AddProduct />}
        />
        <Route
          exact
          path='/settings/setting/manage-admin'
          element={<ManageAdmin />}
        ></Route>
        <Route
          exact
          path='/settings/setting/adminacountinformation'
          element={<AdminAcountInformation />}
        ></Route>
        {/* <Route
          exact
          path='/settings/setting/manage-static-page'
          element={<StaticPage />}
        ></Route> */}
        <Route
          exact
          path='/settings/setting/manage-roles'
          element={<ManageRole />}
        ></Route>
        {/* <Route
          exact
          path='/settings/setting/manage-collection'
          element={<ManageCollection />}
        ></Route> */}
        <Route
          exact
          path='/settings/setting/assign-page-role'
          element={<ManageAssignRoles />}
        ></Route>
        {/* <Route
          exact
          path='/settings/setting/manage-config'
          element={<ManageConfig />}
        ></Route> */}
        <Route
          exact
          path='/settings/setting/manage-bim-cad'
          element={<ManageBimCad />}
        ></Route>
        <Route
          exact
          path='/settings/setting/manage-home-page'
          element={<ManageHomePage />}
        ></Route>

        <Route
          exact
          path='/request-information'
          element={<ManageRequestInformation />}
        ></Route>

        <Route
          exact
          path='/download-information'
          element={<ManageDownload />}
        ></Route>
      </Route>
      <Route
        exact
        path='/manage-product/archive-products'
        element={<ArchiveProducts />}
      ></Route>
      <Route exact path='/logs' element={<Logs />}></Route>
      <Route exact path='/notification' element={<Notification />}></Route>
      <Route exact path='/manage-user' element={<ManageUser />}></Route>
      <Route
        exact
        path='/manage-wishlist'
        element={<ManageWishlistProject />}
      ></Route>
      <Route exact path='/manage-report' element={<ManageReport />}></Route>
      <Route exact path='/subscribe' element={<ManageSubscribe />}></Route>
      <Route
        path='*'
        element={
          <NotFound
            title='Page Not Found'
            subTitle='Sorry, the page you are looking for does not exist.'
          />
        }
      />
    </Routes>
  )
}

export default AllRoutes
