import { logout } from '../pages/redux/slice/userSlice'
import axiosProvider from './AxiosProvider'
import { getDeviceId, getRefreshToken } from './GetBaseUrl'
export const handleLogout = async () => {
  try {
    const { store } = await import('../pages/redux/store')
    const { userId } = store.getState().user?.userInfo

    if (userId) {
      await axiosProvider({
        method: 'POST',
        endpoint: 'Account/Admin/logout',
        data: {
          userId,
          deviceid: getDeviceId(),
          refreshToken: getRefreshToken()
        }
      })

      store.dispatch(logout())
    }
  } catch (error) {
    console.error('Failed to load store:', error)
  }
}
