export const _offerType_ = [
  { label: 'Percentage', value: 'percentage discount' },
  { label: 'Flat Discount', value: 'flat discount' },
  { label: 'Free Shipping', value: 'free shipping' }
]

export const isMarketPlace = true
export const setMarginOnProductLevel = true
export const shippingOnCategoryLevel = false
export const maxWarehouseQty = 1000000
