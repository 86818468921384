import React, { useState, useEffect } from 'react'
import { Link } from 'react-router-dom'
import { setPageTitle } from '../../redux/slice/pageTitleSlice'
import { useDispatch, useSelector } from 'react-redux'
import {
  allCrudNames,
  allPages,
  checkPageAccess
} from '../../../lib/AllPageNames'
import NotFound from '../../NotFound/NotFound'
import MainUser from './MainUser'

const ManageUser = () => {
  const dispatch = useDispatch()
  useEffect(() => {
    dispatch(setPageTitle('Manage User'))
  }, [])
  const { pageAccess } = useSelector((state) => state.user)

  const [activeToggle, setActiveToggle] = useState('user')

  return checkPageAccess(pageAccess, allPages?.user, allCrudNames?.read) ? (
    <div className='card overflow-hidden'>
      <div className='card-body p-0'>
        <div className='nav-tabs-horizontal nav nav-tabs'>
          {checkPageAccess(pageAccess, allPages?.user, allCrudNames?.read) && (
            <Link
              onClick={() => setActiveToggle('user')}
              data-toggle='tab'
              className={`nav-link fw-semibold ${
                activeToggle === 'user' ? 'active show' : ''
              }`}
            >
              <span className='nav-span'>
                User
                <hr />
              </span>
            </Link>
          )}
        </div>

        <div className='tab-content p-3'>
          {checkPageAccess(pageAccess, allPages?.user, allCrudNames?.read) && (
            <div
              id='user'
              className={`tab-pane fade ${
                activeToggle === 'user' ? 'active show' : ''
              }`}
            >
              {activeToggle === 'user' && <MainUser />}
            </div>
          )}
        </div>
      </div>
    </div>
  ) : (
    <NotFound />
  )
}

export default ManageUser
