import React , {useState  , useEffect} from 'react';
import { ListGroup } from 'react-bootstrap';

const CustomScrollSpy = ({ targetIds }) => {
    const [activeId, setActiveId] = useState(targetIds)
    useEffect(() => {
        const handleScroll = () => {
            const scrollPosition = window.scrollY

            for (let i = targetIds.length - 1; i >= 0; i--) {
                const targetId = targetIds[i]
                const target = document.getElementById(targetId)

                if (target.offsetTop <= scrollPosition + 100) {
                    setActiveId(targetId)
                    break
                }
            }
        }
        window.addEventListener('scroll', handleScroll)
        return () => {
            window.removeEventListener('scroll', handleScroll)
        }
    }, [activeId])

    return (
        <ListGroup as='ul' className='navigation position-sticky'>
            <ListGroup.Item as='li' disabled>
                <span
                    className='pv-seller-detail-head'
                    style={{ marginBottom: '0px' }}
                >
                    Quick navigation
                </span>
            </ListGroup.Item>
            {targetIds.map((id) => (
                <ListGroup.Item
                    as='li'
                    key={id}
                    className={id === activeId ? 'active' : ' '}
                >
                    <a href={`#${id}`}>{id}</a>
                </ListGroup.Item>
            ))}
        </ListGroup>
    )
}

export default CustomScrollSpy;