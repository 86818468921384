import React, { useEffect, useState } from 'react'
import { useNavigate } from 'react-router-dom'
import { Button, Table } from 'react-bootstrap'
import ReactPaginate from 'react-paginate'
import axiosProvider from '../../lib/AxiosProvider'
import Loader from '../../components/Loader'
import CustomToast from '../../components/Toast/CustomToast'
import HKBadge from '../../components/Badges'
import { useDispatch, useSelector } from 'react-redux'
import { setPageTitle } from '../redux/slice/pageTitleSlice'
import QuickUpdate from './QuickUpdate'
import { _productImg_ } from '../../lib/ImagePath'
import { _SwalDelete } from '../../lib/exceptionMessage'
import EditIcon from '../../components/AllSvgIcon/EditIcon'
import SearchBox from '../../components/Searchbox'
import useDebounce from '../../lib/useDebounce'
import { callApi, pageRangeDisplayed } from '../../lib/GetBaseUrl'
import { currencyIcon } from '../../lib/GetBaseUrl'
import { useImmer } from 'use-immer'
import Select from 'react-select'
import { customStyles } from '../../components/customStyles'
import { allCrudNames, allPages, checkPageAccess } from '../../lib/AllPageNames'

const ArchiveProducts = () => {
  const [searchText, setSearchText] = useState('')
  const [pageCount, setPageCount] = useState(1)
  const [loading, setLoading] = useState(false)
  const [data, setData] = useState()
  const [toast, setToast] = useState({
    show: false,
    text: null,
    variation: null
  })
  const navigate = useNavigate()
  const [quickUpdate, setQuickUpdate] = useState({
    id: null,
    show: false,
    isDataUpdated: false
  })
  const [filterDetails, setFilterDetails] = useImmer({
    pageSize: 10,
    pageIndex: 1,
    status: 'Active',
    searchText: '',
    sellerId: '',
    categoryId: ''
  })
  const [allState, setAllState] = useImmer({
    sellerDetails: [],
    category: []
  })
  const dispatch = useDispatch()
  useEffect(() => {
    dispatch(setPageTitle('Archive Products'))
  }, [])

  const handlePageClick = (event) => {
    setFilterDetails((draft) => {
      draft.pageIndex = event.selected + 1
    })
  }
  const { pageAccess } = useSelector((state) => state.user)

  const debounceSearchText = useDebounce(searchText, 500)

  useEffect(() => {
    fetchData()
  }, [filterDetails])

  useEffect(() => {
    setFilterDetails((draft) => {
      draft.searchText = searchText
      draft.pageIndex = 1
    })
  }, [debounceSearchText])

  const fetchData = async () => {
    setLoading(true)
    const response = await axiosProvider({
      method: 'GET',
      endpoint: `Product/ArchiveProducts`,
      queryString: `?searchText=${filterDetails?.searchText}&pageIndex=${filterDetails?.pageIndex}&pageSize=${filterDetails?.pageSize}&sellerId=${filterDetails?.sellerId}&categoryId=${filterDetails?.categoryId}`
    })
      .then((res) => {
        setLoading(false)
        if (res.status === 200) {
          setData(res)
          setPageCount(res?.data?.pagination?.pageCount)
        }
      })
      .catch((err) => {
        setLoading(false)
      })
  }

  const fetchAllGenericData = async (apiUrls) => {
    try {
      const responseArray = await Promise.all(
        apiUrls.map((url) => callApi(url.endpoint, url.queryString, url.state))
      )

      return responseArray
    } catch (error) {}
  }

  useEffect(() => {
    const apiUrls = [
      {
        endpoint: 'SellerData/bindActiveSeller',
        queryString: '?pageindex=0&PageSize=0'
      },
      { endpoint: 'MainCategory/getEndCategory', queryString: '' }
    ]
    const fetchData = async () => {
      setLoading(true)
      const [sellerDetails, category] = await fetchAllGenericData(apiUrls)
      setAllState((draft) => {
        draft.sellerDetails = sellerDetails
        draft.category = category
      })
      setLoading(false)
    }
    fetchData()
  }, [])

  return (
    <div className='card'>
      <div className='card-body'>
        <div className='d-flex gap-4 flex-column'>
          {loading && <Loader />}
          {toast?.show && (
            <CustomToast text={toast?.text} variation={toast?.variation} />
          )}

          <div
            style={{ backgroundColor: 'var(--bs-white)' }}
            className='pv-product-table-main rounded'
          >
            <div className='row'>
              <div className='col-md-3'>
                <div className='input-file-wrapper mb-3'>
                  <label className='form-label required'>Select Seller</label>
                  <Select
                    id='sellerId'
                    placeholder='Select Seller'
                    styles={customStyles}
                    menuPortalTarget={document.body}
                    isClearable
                    value={
                      filterDetails?.sellerId && {
                        value: filterDetails?.sellerId,
                        label: allState?.sellerDetails?.find(
                          (data) => data?.userID === filterDetails?.sellerId
                        )?.displayName
                      }
                    }
                    options={
                      allState?.sellerDetails?.length
                        ? allState?.sellerDetails?.map(
                            ({
                              userID,
                              displayName,
                              shipmentBy,
                              shipmentChargesPaidByName
                            }) => ({
                              value: userID,
                              label: displayName,
                              shipmentBy,
                              shipmentPaidBy: shipmentChargesPaidByName
                            })
                          )
                        : []
                    }
                    onChange={(e) => {
                      setFilterDetails((draft) => {
                        draft.sellerId = e?.value ?? ''
                      })
                    }}
                  />
                </div>
              </div>
              <div className='col-md-3'>
                <div className='input-file-wrapper'>
                  <label className='form-label required'>Select Category</label>
                  <Select
                    styles={customStyles}
                    menuPortalTarget={document.body}
                    id='categoryId'
                    isClearable
                    placeholder='Select Category'
                    value={
                      filterDetails?.categoryId && {
                        value: filterDetails?.categoryId,
                        label: allState?.category?.find(
                          (data) => data?.id === filterDetails?.categoryId
                        )?.pathNames
                      }
                    }
                    disabled
                    options={
                      allState?.category?.length
                        ? allState?.category?.map(
                            ({ id, pathNames, name }) => ({
                              value: id,
                              label: pathNames,
                              categoryName: name
                            })
                          )
                        : []
                    }
                    onChange={async (e) => {
                      setFilterDetails((draft) => {
                        draft.categoryId = e?.value ?? ''
                      })
                    }}
                  />
                </div>
              </div>
              <div className='col-md-3'>
                <div className='input-file-wrapper'>
                  <label className='form-label required'>Search</label>
                  <SearchBox
                    placeholderText='Search'
                    searchclassnamewrapper={'searchbox-wrapper w-100'}
                    btnVariant={'secondary'}
                    value={searchText}
                    onChange={(e) => {
                      setSearchText(e?.target?.value)
                    }}
                  />
                </div>
              </div>
              <div className='col-md-3'>
                <Button
                  className='mt-4'
                  variant='light'
                  size='md'
                  onClick={() => {
                    setFilterDetails((draft) => {
                      draft.sellerId = ''
                      draft.brandId = ''
                      draft.categoryId = ''
                      draft.status = ''
                      draft.searchText = ''
                      draft.pageIndex = 1
                      draft.pageSize = 10
                    })
                  }}
                >
                  Reset
                </Button>
              </div>
            </div>
            <div className='d-flex align-items-center gap-3 justify-content-end'>
              <div className='d-flex align-items-center'>
                <label className='me-1'>Show</label>
                <select
                  name='dataget'
                  id='parpageentries'
                  className='form-select me-1'
                  onChange={(e) => {
                    setFilterDetails((draft) => {
                      draft.pageSize = e?.target?.value
                      draft.pageIndex = 1
                    })
                  }}
                >
                  <option value='10'>10</option>
                  <option value='25'>25</option>
                  <option value='50'>50</option>
                  <option value='100'>100</option>
                  <option value='200'>200</option>
                  <option value='500'>500</option>
                </select>
                entries
              </div>
            </div>
            <Table className='align-middle mt-3 table-list manage-product-table'>
              <thead className='align-middle'>
                <tr>
                  <th>Product Details</th>
                  <th>Brand</th>
                  {/* <th>Pricing Details</th>
                  <th>Quantity</th> */}
                  <th>Status</th>
                  <th>Live</th>
                  {checkPageAccess(
                    pageAccess,
                    allPages?.archiveProduct,
                    allCrudNames?.update
                  ) && <th className='text-center'>Action</th>}
                </tr>
              </thead>
              <tbody className='border border-1' style={{ boxShadow: 'unset' }}>
                {data?.data?.data?.length > 0 ? (
                  data?.data?.data?.map((data) => (
                    <React.Fragment
                      key={`${data.id}${Math.floor(Math.random() * 10000000)}`}
                    >
                      <tr
                        className='timeline'
                        key={`${data.id}${Math.floor(
                          Math.random() * 10000000
                        )}`}
                      >
                        <td>
                          <div className='d-flex gap-3 align-items-center'>
                            <img
                              style={{ width: '100px', zIndex: '1' }}
                              className='img-thumbnail position-relative'
                              src={
                                data?.productImage?.length > 0 &&
                                `${process.env.REACT_APP_IMG_URL}${_productImg_}${data?.productImage}`
                              }
                              alt='...'
                            />
                            <div className='d-flex flex-column'>
                              <p className='pv-font-hard mb-1'>
                                {' '}
                                {data?.productName}
                              </p>
                              <small>
                                Product Master Code:{' '}
                                <span className='fw-600'>
                                  {data?.productMasterId}
                                </span>
                              </small>

                              <small>
                                Product Code:{' '}
                                <span className='fw-600'>
                                  {data?.productId}
                                </span>
                              </small>
                            </div>
                          </div>
                        </td>
                        <td>{data?.brandName ?? '-'}</td>
                        {/* <td className="text-center">
                          <div className="d-flex flex-column gap-1 align-items-start">
                            <div className="badge text-bg-light">
                              <span className="pv-font-hard">
                                Selling Price :{" "}
                              </span>
                              <span>
                                {currencyIcon}
                                {data?.sellingPrice ?? '-'}
                              </span>
                            </div>
                            <div className='badge text-bg-light'>
                              <span className='pv-font-hard'>MRP : </span>
                              <span>
                                {currencyIcon}
                                {data?.mrp ?? '-'}
                              </span>
                            </div>
                            <div className='badge text-bg-light'>
                              <span className='pv-font-hard'>Discount : </span>
                              <span>
                                {data?.discount ? `${data?.discount} %` : '-'}
                              </span>
                            </div>
                          </div>{' '}
                        </td>
                        <td className="text-center">{data?.quantity ?? "-"}</td> */}
                        <td>
                          <HKBadge
                            badgesbgname={
                              data?.status?.toLowerCase() === 'active'
                                ? 'success'
                                : 'danger'
                            }
                            badgesTxtname={data?.status}
                            badgeClassname={''}
                          />
                        </td>
                        <td>{data?.live ? 'Yes' : 'No'}</td>
                        {checkPageAccess(
                          pageAccess,
                          allPages?.archiveProduct,
                          allCrudNames?.update
                        ) && (
                          <td className='text-center'>
                            <span
                              onClick={() => {
                                navigate(
                                  `/manage-product/add-product?id=${data?.productId}&sellerId=${data?.sellerID}`
                                )
                                // setQuickUpdate({
                                //   id: data.productId,
                                //   show: !quickUpdate.show
                                // })
                              }}
                            >
                              <EditIcon bg='bg' />
                            </span>{' '}
                          </td>
                        )}
                      </tr>
                    </React.Fragment>
                  ))
                ) : (
                  <tr>
                    <td colSpan={7} className='text-center'>
                      {data?.data?.message}
                    </td>
                  </tr>
                )}
              </tbody>
            </Table>
            {/* Pagination start */}
            <ReactPaginate
              className='list-inline m-cst--pagination d-flex justify-content-end gap-1'
              breakLabel='...'
              nextLabel=''
              onPageChange={handlePageClick}
              pageRangeDisplayed={pageRangeDisplayed}
              pageCount={pageCount}
              previousLabel=''
              renderOnZeroPageCount={null}
              forcePage={filterDetails?.pageIndex - 1}
            />
          </div>
        </div>
      </div>
      {quickUpdate?.show && (
        <QuickUpdate
          quickUpdate={quickUpdate}
          setQuickUpdate={setQuickUpdate}
          loading={loading}
          setLoading={setLoading}
          setToast={setToast}
          toast={toast}
          fetchArchiveProduct={fetchData}
        />
      )}
    </div>
  )
}

export default ArchiveProducts
