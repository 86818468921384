import { OverlayTrigger, Tooltip } from 'react-bootstrap'
import axiosProvider from './AxiosProvider'

export const encodedSearchText = (searchText) => {
  return encodeURIComponent(searchText.replace(/"/g, ''))
}

export const textTooltip = (value) => {
  return (
    <OverlayTrigger
      key={'top'}
      variant='light'
      placement={'top'}
      overlay={<Tooltip id={`tooltip-top`}>{formatMRP(value)}</Tooltip>}
    >
      <span className='cursor-pointer-tooltip'>{formatNumbers(value)}</span>
    </OverlayTrigger>
  )
}

export const fetchDataFromApi = async (
  endpoint,
  setLoading,
  method = 'GET',
  queryParams = {},
  signal
) => {
  if (setLoading) {
    setLoading(true)
  }
  try {
    const queryString =
      '?' +
      Object.keys(queryParams)
        .map((key) => {
          let value = queryParams[key]

          if (key === 'searchText') {
            value = encodedSearchText(value)
          }

          return `${key}=${value}`
        })
        .join('&')

    const response = await axiosProvider({
      method,
      endpoint,
      queryString,
      signal
    })

    if (response?.status === 200) {
      if (response) {
        if (setLoading) {
          setLoading(false)
        }

        return response
      } else {
        throw new Error('Failed to fetch data')
      }
    }
  } catch (err) {
    if (setLoading) {
      setLoading(false)
    }
    console.error(err)
    throw err
  }
}

export const formatNumbers = (number) => {
  if (number === undefined || number === null || number === 0) return 0
  const abbreviations = ['', 'K', 'M', 'B', 'T']
  const tier = Math.floor(Math.log10(Math.abs(number)) / 3)
  const formattedNumber = (number / Math.pow(10, tier * 3)).toFixed(1)
  return formattedNumber.replace(/\.0$/, '') + abbreviations[tier]
}

export const formatMRP = (mrp) => {
  if (mrp >= 10000000) {
    const crore = Math.floor(mrp / 10000000)
    const remainingLakhs = Math.floor((mrp % 10000000) / 100000)
    const remainingThousands = Math.floor((mrp % 100000) / 1000)
    const remainingUnits = mrp % 1000

    return `${crore.toLocaleString('en-IN')},${remainingLakhs
      .toLocaleString('en-IN')
      .padStart(2, '0')},${remainingThousands
      .toLocaleString('en-IN')
      .padStart(2, '0')},${remainingUnits
      .toLocaleString('en-IN')
      .padStart(3, '0')}`
  } else if (mrp >= 100000) {
    const lakh = Math.floor(mrp / 100000)
    const remainingThousands = Math.floor((mrp % 100000) / 1000)
    const remainingUnits = mrp % 1000

    return `${lakh.toLocaleString('en-IN')},${remainingThousands
      .toLocaleString('en-IN')
      .padStart(2, '0')},${remainingUnits
      .toLocaleString('en-IN')
      .padStart(3, '0')}`
  } else if (mrp >= 1000) {
    const thousands = Math.floor(mrp / 1000)
    const remainingUnits = mrp % 1000

    return `${thousands.toLocaleString('en-IN')},${remainingUnits
      .toLocaleString('en-IN')
      .padStart(3, '0')}`
  } else {
    return mrp.toLocaleString('en-IN')
  }
}

const getTooltipLabel = (selectedValue) => {
  switch (selectedValue) {
    case 'All':
      return 'Month'
    case 'Today':
      return 'Day'
    case 'Yesterday':
      return 'Day'
    case 'This Week':
      return 'Days'
    case 'Last Week':
      return 'Days'
    case 'Last 15 Days':
      return 'Day'
    case 'This Month':
      return 'Weeks'
    case 'Last Month':
      return 'Weeks'
    case 'Last 3 Months':
      return 'Months'
    case 'Last 6 Months':
      return 'Months'
    case 'This Year':
      return 'Months'
    case 'Last Year':
      return 'Months'
    default:
      return ''
  }
}

export const CustomTooltip = ({ active, payload, displayText }) => {
  if (active && payload && payload.length) {
    return (
      <div className='customized-tooltip-content' style={{ border: 'none' }}>
        <div className='list' style={{ border: 'none' }}>
          <div className='ms-0 p-2'>
            <p>{`${getTooltipLabel(displayText)}: ${
              payload[0]?.payload?.monthDetails
            }`}</p>
            <span style={{ color: payload[0].color }} className='fw-800'>
              Total : {formatMRP(payload[0].value)}
            </span>
          </div>
        </div>
      </div>
    )
  }

  return null
}

export const renderTooltipContent = (o) => {
  const { payload, label } = o

  return (
    <div className='customized-tooltip-content p-2'>
      <p className='total mb-2'>{`${label}`}</p>
      {payload?.map((entry, index) => (
        <span
          className='text-capitalize'
          key={`item-${index}`}
          style={{ color: entry.color }}
        >
          {`${entry.name}: ${entry.value}`}
        </span>
      ))}
    </div>
  )
}
