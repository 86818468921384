/* eslint-disable react-hooks/exhaustive-deps */
import { Form, Formik } from 'formik'
import moment from 'moment'
import React, { useEffect, useState } from 'react'
import { Button, Table } from 'react-bootstrap'
import ReactPaginate from 'react-paginate'
import { useSelector } from 'react-redux'
import { useLocation } from 'react-router-dom'
import Swal from 'sweetalert2'
import { useImmer } from 'use-immer'
import * as Yup from 'yup'
import DeleteIcon from '../../components/AllSvgIcon/DeleteIcon'
import EditIcon from '../../components/AllSvgIcon/EditIcon'
import { customStyles } from '../../components/customStyles'
import FormikControl from '../../components/FormikControl'
import Loader from '../../components/Loader'
import ModelComponent from '../../components/Modal'
import SearchBox from '../../components/Searchbox'
import CustomToast from '../../components/Toast/CustomToast'
import { allCrudNames, allPages, checkPageAccess } from '../../lib/AllPageNames'
import axiosProvider from '../../lib/AxiosProvider'
import { _SwalDelete } from '../../lib/exceptionMessage'
import { pageRangeDisplayed, showToast } from '../../lib/GetBaseUrl'
import { _emailRegex_ } from '../../lib/Regex'
import useDebounce from '../../lib/useDebounce'

const MainSubscribe = () => {
  const initVal = {
    name: '',
    description: '',
    logo: '',
    status: 'Active',
    brandCertificate: '',
    backgroundBanner: '',
    browsebyBrandImage: ''
  }
  const [initialValues, setInitialValues] = useState(initVal)
  const [searchText, setSearchText] = useState()
  const [data, setData] = useState()
  const [modalShow, setModalShow] = useState(false)
  const [loading, setLoading] = useState(true)
  const [toast, setToast] = useState({
    show: false,
    text: null,
    variation: null
  })
  const [filterDetails, setFilterDetails] = useImmer({
    pageSize: 10,
    pageIndex: 1,
    searchText: ''
  })
  const { userInfo } = useSelector((state) => state?.user)
  const location = useLocation()
  const { pageAccess } = useSelector((state) => state.user)

  const debounceSearchText = useDebounce(searchText, 500)

  useEffect(() => {
    if (debounceSearchText) {
      setFilterDetails((draft) => {
        draft.searchText = debounceSearchText
        draft.pageIndex = 1
      })
    } else {
      setFilterDetails((draft) => {
        draft.searchText = ''
        draft.pageIndex = 1
      })
    }
  }, [debounceSearchText])

  const fetchData = async () => {
    setLoading(true)
    await axiosProvider({
      method: 'GET',
      endpoint: 'UserSubscribe',
      queryString: `?email=${filterDetails?.searchText}&pageIndex=${filterDetails?.pageIndex}&pageSize=${filterDetails?.pageSize}`
    })
      .then((res) => {
        if (res?.status === 200) {
          setLoading(false)
          setData(res)
        }
      })
      .catch((err) => {
        setLoading(false)
      })
  }

  useEffect(() => {
    fetchData()
  }, [filterDetails])

  const handlePageClick = (event) => {
    setFilterDetails((draft) => {
      draft.pageIndex = event.selected + 1
    })
  }

  const validationSchema = Yup.object().shape({
    email: Yup.string()
      .required('Email Id is Required')
      .matches(_emailRegex_, 'Please enter a valid email id')
  })

  const onSubmit = async (values) => {
    setLoading(true)
    const response = await axiosProvider({
      method: values?.id ? 'PUT' : 'POST',
      endpoint: 'UserSubscribe',
      data: values,
      logData: values,
      oldData: initialValues,
      location: location?.pathname,
      userId: userInfo?.userId
    })
      .then((res) => {
        if (res?.status === 200) {
          fetchData()
          setModalShow(false)
        }
        setLoading(false)
        showToast(toast, setToast, res)
      })
      .catch((err) => {
        setLoading(false)
      })
  }

  const handleDelete = async (id) => {
    setLoading(true)
    await axiosProvider({
      method: 'DELETE',
      endpoint: `UserSubscribe?id=${id}`,
      userId: userInfo?.userId,
      location: location.pathname
    })
      .then((res) => {
        if (res?.status === 200) {
          if (res?.data?.code === 200) {
            if (
              filterDetails?.pageIndex > 1 &&
              data?.data?.data?.length === 1
            ) {
              setFilterDetails((draft) => {
                draft.pageIndex = filterDetails?.pageIndex - 1
              })
            } else {
              fetchData()
            }
          }
        }
        setLoading(false)
        setToast({
          show: true,
          text: res?.data?.message,
          variation: res?.data?.code !== 200 ? 'error' : 'success'
        })

        setTimeout(() => {
          setToast({ ...toast, show: false })
        }, 2000)
      })
      .catch((err) => {
        setLoading(false)
      })
  }

  return (
    <>
      <div className='d-flex align-items-center mb-3 gap-3 flex-row-reverse'>
        {checkPageAccess(
          pageAccess,
          allPages?.subscribe,
          allCrudNames?.write
        ) && (
          <Button
            variant='warning'
            className='d-flex align-items-center gap-2 fw-semiboldd-flex align-items-center gap-2 fw-semibold btn btn-warning ms-auto'
            onClick={() => {
              setInitialValues(initVal)
              setModalShow(true)
            }}
          >
            <i className='m-icon m-icon--plusblack'></i>
            Create
          </Button>
        )}
        <div className='d-flex align-items-center'>
          <label className='me-1'>Show</label>
          <select
            styles={customStyles}
            menuPortalTarget={document.body}
            name='dataget'
            id='parpageentries'
            className='form-select me-1'
            onChange={(e) => {
              setFilterDetails((draft) => {
                draft.pageSize = e?.target?.value
                draft.pageIndex = 1
              })
            }}
          >
            <option value='10'>10</option>
            <option value='25'>25</option>
            <option value='50'>50</option>
            <option value='100'>100</option>
            <option value='200'>200</option>
            <option value='500'>500</option>
          </select>
        </div>
        <SearchBox
          placeholderText={'Search'}
          value={searchText}
          searchclassnamewrapper={'searchbox-wrapper'}
          onChange={(e) => {
            setSearchText(e?.target?.value)
          }}
          // btnVariant={"secondary"}
        />
      </div>

      {toast?.show && (
        <CustomToast text={toast?.text} variation={toast?.variation} />
      )}

      {loading && <Loader />}

      <ModelComponent
        show={modalShow}
        modalsize={'md'}
        modalheaderclass={''}
        modeltitle={
          !initialValues?.id ? 'Create Subscribe' : 'Update Subscribe'
        }
        onHide={() => setModalShow(false)}
        btnclosetext={''}
        closebtnvariant={''}
        backdrop={'static'}
        formbuttonid={'mainBrand'}
        submitname={!initialValues?.id ? 'Create' : 'Update'}
      >
        <Formik
          enableReinitialize={true}
          initialValues={initialValues}
          validationSchema={validationSchema}
          onSubmit={onSubmit}
        >
          {({ values, setFieldValue }) => (
            <Form id='mainBrand'>
              <div className='row'>
                <div className='col-md-12'>
                  <FormikControl
                    isRequired
                    control='input'
                    label='Email Id'
                    type='text'
                    name='email'
                    placeholder='Enter Email'
                    onChange={(e) => {
                      setFieldValue('email', e?.target?.value)
                    }}
                  />
                </div>
              </div>
            </Form>
          )}
        </Formik>
      </ModelComponent>

      {loading && <Loader />}

      <Table responsive className='align-middle table-list'>
        <thead>
          <tr>
            <th>Email</th>
            <th>Subscribed At</th>
            {checkPageAccess(pageAccess, allPages?.subscribe, [
              allCrudNames?.update,
              allCrudNames?.delete
            ]) && <th className='text-center'>Action</th>}
          </tr>
        </thead>
        <tbody>
          {data?.data?.data?.length > 0 ? (
            data?.data?.data?.map((data, index) => (
              <tr key={data.id}>
                <td>{data?.email}</td>
                <td>{moment(data.createdAt).format('DD/MM/YYYY')}</td>
                {checkPageAccess(pageAccess, allPages?.subscribe, [
                  allCrudNames?.update,
                  allCrudNames?.delete
                ]) && (
                  <td className='text-center'>
                    <div className='d-flex gap-2 justify-content-center'>
                      {checkPageAccess(
                        pageAccess,
                        allPages?.subscribe,
                        allCrudNames?.update
                      ) && (
                        <span
                          onClick={() => {
                            setModalShow(!modalShow)
                            setInitialValues(data)
                          }}
                        >
                          <EditIcon bg={'bg'} />
                        </span>
                      )}
                      {checkPageAccess(
                        pageAccess,
                        allPages?.subscribe,
                        allCrudNames?.delete
                      ) && (
                        <span
                          onClick={() => {
                            Swal.fire({
                              title: _SwalDelete.title,
                              text: _SwalDelete.text,
                              icon: _SwalDelete.icon,
                              showCancelButton: _SwalDelete.showCancelButton,
                              confirmButtonColor:
                                _SwalDelete.confirmButtonColor,
                              cancelButtonColor: _SwalDelete.cancelButtonColor,
                              confirmButtonText: _SwalDelete.confirmButtonText,
                              cancelButtonText: _SwalDelete.cancelButtonText
                            }).then((result) => {
                              if (result.isConfirmed) {
                                handleDelete(data?.id)
                              } else if (result.isDenied) {
                              }
                            })
                          }}
                        >
                          <DeleteIcon bg={'bg'} />
                        </span>
                      )}
                    </div>
                  </td>
                )}
              </tr>
            ))
          ) : (
            <tr>
              <td colSpan={4} className='text-center'>
                {data?.data?.message}
              </td>
            </tr>
          )}
        </tbody>
      </Table>

      <ReactPaginate
        className='list-inline m-cst--pagination d-flex justify-content-end gap-1'
        breakLabel='...'
        nextLabel=''
        onPageChange={handlePageClick}
        pageRangeDisplayed={pageRangeDisplayed}
        pageCount={data?.data?.pagination?.pageCount ?? 0}
        previousLabel=''
        renderOnZeroPageCount={null}
        forcePage={filterDetails?.pageIndex - 1}
      />
    </>
  )
}

export default MainSubscribe
