import React from 'react'
import { useSelector } from 'react-redux'
import { Link, useLocation } from 'react-router-dom'
import logo from '../images/logo.png'
import { allCrudNames, allPages, checkPageAccess } from '../lib/AllPageNames'
import Header from './Header'

const renderThumb = ({ style, ...props }) => {
  const thumbStyle = {
    borderRadius: 6,
    backgroundColor: 'transparent'
  }
  return <div style={{ ...style, ...thumbStyle }} {...props} />
}

//Hover effect
function Onhover() {
  if (document.querySelector('.app').classList.contains('sidenav-toggled'))
    document.querySelector('.app').classList.add('sidenav-toggled-open')
}
function Outhover() {
  document.querySelector('.app').classList.remove('sidenav-toggled-open')
}

const Sidebar = () => {
  const location = useLocation()
  const { pathname } = location
  const splitLocation = pathname.split('/')
  const { pageTitle } = useSelector((state) => state.pageTitle)

  const { pageAccess } = useSelector((state) => state.user)

  const toggleOpen = (id) => {
    const mainEls = document.getElementsByClassName('side-item__list')
    let isSameEl = false

    for (let i = 0; i < mainEls.length; i++) {
      if (mainEls[i].id === id && mainEls[i].classList.contains('is-open')) {
        isSameEl = true
      }

      mainEls[i].classList.remove('is-open')
    }
    const topEl = document.getElementById(id)

    return isSameEl
      ? topEl.classList.remove('is-open')
      : topEl.classList.add('is-open')
  }
  return (
    <>
      <Header pageTitle={pageTitle} />
      <div className='sticky'>
        <div className='app-sidebar__overlay'></div>
        <aside
          className='app-sidebar'
          onMouseOver={() => Onhover()}
          onMouseOut={() => Outhover()}
        >
          {/* <CustomScrollbars> */}
          <div className='header side-header'>
            <Link className='header-brand1' to='/dashboard'>
              <img
                src={logo}
                className='header-brand-img light-logo'
                alt={'logo-1'}
              />
              <img
                src='/images/logo.png'
                className='header-brand-img light-logo1'
                alt={'logo-2'}
              />
            </Link>
          </div>
          <div className='main-sidemenu'>
            <ul className='side-menu list-inline' id='sidebar-main'>
              <li
                className={
                  splitLocation[1] === 'dashboard' ? 'slide active' : 'slide'
                }
              >
                <Link className='side-menu__item' to='/dashboard'>
                  <i className='m-icon m-icon--dashboard'></i>
                  <span className='side-menu__label'> Dashboard </span>
                </Link>
              </li>
              {checkPageAccess(
                pageAccess,
                allPages?.product,
                allCrudNames?.read
              ) && (
                <li
                  className={
                    splitLocation[1] === 'manage-product'
                      ? 'slide active'
                      : 'slide'
                  }
                >
                  <Link className='side-menu__item' to='/manage-product'>
                    <i className='m-icon m-icon--products'></i>
                    <span className='side-menu__label'>Products</span>
                    <i className=' fa angle fa-angle-right '></i>
                  </Link>
                </li>
              )}
              {checkPageAccess(
                pageAccess,
                [
                  allPages?.category,
                  allPages?.size,
                  allPages?.color,
                  allPages?.assignAttributes
                ],
                allCrudNames?.read
              ) && (
                <li
                  id='maincategory'
                  className={
                    splitLocation[1] === 'category'
                      ? 'side-item__list is-open'
                      : 'side-item__list'
                  }
                >
                  <div
                    className={
                      splitLocation[1] === 'category' ? 'slide active' : 'slide'
                    }
                    onClick={() => {
                      toggleOpen('maincategory')
                    }}
                  >
                    <div
                      className='side-menu__item'
                      style={{ cursor: 'pointer' }}
                    >
                      <i className='m-icon m-icon--categories'></i>
                      <span className='side-menu__label'>Categories</span>
                      <i className='m-icon m-icon--down-arrow'></i>
                    </div>
                  </div>
                  <ul className='side-menu side-item__sub-list list-inline'>
                    {checkPageAccess(
                      pageAccess,
                      allPages?.category,
                      allCrudNames?.read
                    ) && (
                      <li
                        className={
                          splitLocation[2] === 'manage-category'
                            ? 'slide subactive'
                            : 'slide'
                        }
                      >
                        <Link
                          className='side-menu__item side-menu__sub-items'
                          to='/category/manage-category'
                        >
                          <span className='side-menu__label'>Category</span>
                        </Link>
                      </li>
                    )}

                    {checkPageAccess(pageAccess, [
                      allPages?.size,
                      allPages?.color
                    ]) && (
                      <li
                        className={
                          splitLocation[2] === 'manage-attributes'
                            ? 'slide subactive'
                            : 'slide'
                        }
                      >
                        <Link
                          className='side-menu__item side-menu__sub-items'
                          to='/category/manage-attributes'
                        >
                          <span className='side-menu__label'>Attributes</span>
                        </Link>
                      </li>
                    )}

                    {checkPageAccess(
                      pageAccess,
                      allPages?.assignAttributes,
                      allCrudNames?.read
                    ) && (
                      <li
                        className={
                          splitLocation[2] === 'assign-category'
                            ? 'slide subactive'
                            : 'slide'
                        }
                      >
                        <Link
                          className='side-menu__item side-menu__sub-items'
                          to='/category/assign-category'
                        >
                          <span className='side-menu__label'>
                            Assign Attributes
                          </span>
                        </Link>
                      </li>
                    )}
                  </ul>
                </li>
              )}
              {checkPageAccess(
                pageAccess,
                allPages?.manageSeller,
                allCrudNames?.read
              ) && (
                <li
                  className={
                    splitLocation[1] === 'manage-seller'
                      ? 'slide active'
                      : 'slide'
                  }
                >
                  <Link className='side-menu__item' to='/manage-seller'>
                    <i className='m-icon m-icon--sellers'></i>
                    <span className='side-menu__label'> Seller</span>
                    <i className=' fa angle fa-angle-right '></i>
                  </Link>
                </li>
              )}
              {checkPageAccess(
                pageAccess,
                [allPages?.Brand, allPages?.assignBrandToSeller],
                allCrudNames?.read
              ) && (
                <li
                  className={
                    splitLocation[1] === 'manage-brand'
                      ? 'slide active'
                      : 'slide'
                  }
                >
                  <Link className='side-menu__item' to='/manage-brand'>
                    <i className='m-icon m-icon--brands'></i>
                    <span className='side-menu__label'> Brand</span>
                    <i className=' fa angle fa-angle-right '></i>
                  </Link>
                </li>
              )}
              {checkPageAccess(
                pageAccess,
                allPages?.user,
                allCrudNames?.read
              ) && (
                <li
                  className={
                    splitLocation[1] === 'manage-user'
                      ? 'slide active'
                      : 'slide'
                  }
                >
                  <Link className='side-menu__item' to='/manage-user'>
                    <i className='m-icon m-icon--User'></i>
                    <span className='side-menu__label'>User</span>
                    <i className=' fa angle fa-angle-right '></i>
                  </Link>
                </li>
              )}
              {/* {checkPageAccess(
                pageAccess,
                allPages?.report,
                allCrudNames?.read
              ) && (
                <li
                  className={
                    splitLocation[1] === 'manage-report'
                      ? 'slide active'
                      : 'slide'
                  }
                >
                  <Link className='side-menu__item' to='/manage-report'>
                    <i className='m-icon m-icon--report'></i>
                    <span className='side-menu__label'> Report</span>
                    <i className=' fa angle fa-angle-right '></i>
                  </Link>
                </li>
              )} */}
              {checkPageAccess(
                pageAccess,
                allPages?.wishlist,
                allCrudNames?.read
              ) && (
                <li
                  className={
                    splitLocation[1] === 'manage-wishlist'
                      ? 'slide active'
                      : 'slide'
                  }
                >
                  <Link className='side-menu__item' to='/manage-wishlist'>
                    <i className='m-icon m-icon--wishlist'></i>
                    <span className='side-menu__label'>
                      {' '}
                      Wishlist / Project
                    </span>
                    <i className=' fa angle fa-angle-right '></i>
                  </Link>
                </li>
              )}
              {checkPageAccess(
                pageAccess,
                allPages?.downloadInformation,
                allCrudNames?.read
              ) && (
                <li
                  className={
                    splitLocation[1] === 'download-information'
                      ? 'slide active'
                      : 'slide'
                  }
                >
                  <Link className='side-menu__item' to='/download-information'>
                    <i className='m-icon m-icon--download-information'></i>
                    <span className='side-menu__label'>
                      {' '}
                      Download <br /> Information
                    </span>
                    <i className=' fa angle fa-angle-right '></i>
                  </Link>
                </li>
              )}
              {checkPageAccess(
                pageAccess,
                allPages?.requestInformation,
                allCrudNames?.read
              ) && (
                <li
                  className={
                    splitLocation[1] === 'request-information'
                      ? 'slide active'
                      : 'slide'
                  }
                >
                  <Link className='side-menu__item' to='/request-information'>
                    <i className='m-icon m-icon--request-information'></i>
                    <span className='side-menu__label'>
                      Request <br /> Information
                    </span>
                    <i className=' fa angle fa-angle-right '></i>
                  </Link>
                </li>
              )}
              {checkPageAccess(
                pageAccess,
                allPages?.subscribe,
                allCrudNames?.read
              ) && (
                <li
                  className={
                    splitLocation[1] === 'subscribe' ? 'slide active' : 'slide'
                  }
                >
                  <Link className='side-menu__item' to='/subscribe'>
                    <i className='m-icon m-icon--subscribe'></i>
                    <span className='side-menu__label'>Subscribe</span>
                    <i className=' fa angle fa-angle-right '></i>
                  </Link>
                </li>
              )}
              {checkPageAccess(
                pageAccess,
                allPages?.settings,
                allCrudNames?.read
              ) && (
                <li
                  className={
                    splitLocation[2] === 'setting' ? 'slide active' : 'slide'
                  }
                >
                  <Link className='side-menu__item' to='/settings/setting'>
                    <i className='m-icon m-icon--setting'></i>
                    <span className='side-menu__label'>Settings</span>
                    <i className=' fa angle fa-angle-right '></i>
                  </Link>
                </li>
              )}
              {checkPageAccess(
                pageAccess,
                allPages?.logs,
                allCrudNames?.read
              ) && (
                <li
                  className={
                    splitLocation[1] === 'logs' ? 'slide active' : 'slide'
                  }
                >
                  <Link className='side-menu__item' to='/logs'>
                    <i className='m-icon m-icon--Logs'></i>
                    <span className='side-menu__label'>Logs</span>
                    <i className=' fa angle fa-angle-right '></i>
                  </Link>
                </li>
              )}
            </ul>
          </div>
          {/* </CustomScrollbars> */}
        </aside>
      </div>
    </>
  )
}

export default Sidebar
