import { ErrorMessage, Form, Formik } from 'formik'
import React, { useEffect, useState } from 'react'
import { Button, Table } from 'react-bootstrap'
import ReactPaginate from 'react-paginate'
import { useDispatch, useSelector } from 'react-redux'
import { useLocation } from 'react-router-dom'
import Swal from 'sweetalert2'
import { useImmer } from 'use-immer'
import * as Yup from 'yup'
import DeleteIcon from '../../../components/AllSvgIcon/DeleteIcon'
import EditIcon from '../../../components/AllSvgIcon/EditIcon'
import { customStyles } from '../../../components/customStyles'
import FormikControl from '../../../components/FormikControl'
import Loader from '../../../components/Loader'
import ModelComponent from '../../../components/Modal'
import TextError from '../../../components/TextError'
import CustomToast from '../../../components/Toast/CustomToast'
import {
  allCrudNames,
  allPages,
  checkPageAccess
} from '../../../lib/AllPageNames'
import axiosProvider from '../../../lib/AxiosProvider'
import { _SwalDelete } from '../../../lib/exceptionMessage'
import { pageRangeDisplayed } from '../../../lib/GetBaseUrl'
import { _categoryImg_ } from '../../../lib/ImagePath'
import useDebounce from '../../../lib/useDebounce'
import { setPageTitle } from '../../redux/slice/pageTitleSlice'

const MainBimCad = () => {
  const [initialdata, setInnitialData] = useState(true)
  const [modalShow, setModalShow] = useState(false)
  const [searchText, setSearchText] = useState()
  const [data, setData] = useState()
  const [initialValues, setInitialValues] = useState({
    bimcadImage: '',
    bimcadName: ''
  })
  const [loading, setLoading] = useState(false)
  const [toast, setToast] = useState({
    show: false,
    text: null,
    variation: null
  })
  const [filterDetails, setFilterDetails] = useImmer({
    pageSize: 10,
    pageIndex: 1,
    searchText: ''
  })

  const dispatch = useDispatch()
  useEffect(() => {
    dispatch(setPageTitle('Manage Bim Cad'))
  }, [])

  const { userId } = useSelector((state) => state?.user?.userInfo)
  const location = useLocation()
  const { pageAccess } = useSelector((state) => state.user)

  const fetchData = async () => {
    setLoading(true)
    await axiosProvider({
      method: 'GET',
      endpoint: 'BimcadLibrary',
      queryString: `?pageIndex=${filterDetails?.pageIndex}&pageSize=${filterDetails?.pageSize}&isDeleted=false`
    })
      .then((res) => {
        if (res.status === 200) {
          setLoading(false)
          setData(res)
        }
      })
      .catch((err) => {
        setLoading(false)
      })
  }

  const onSubmit = async (values, { resetForm }) => {
    let dataofForm = {
      BimcadName: values?.bimcadName,
      BimcadImage: values?.bimcadImage,
      IsImageAvailable: values?.bimcadImage ? true : false
    }

    let method = 'POST'

    if (values?.id) {
      method = 'PUT'
      dataofForm = { ...dataofForm, Id: values?.id }
    }

    const submitFormData = new FormData()

    const keys = Object.keys(dataofForm)

    keys.forEach((key) => {
      submitFormData.append(key, dataofForm[key])
    })

    setLoading(true)
    await axiosProvider({
      method,
      endpoint: `BimcadLibrary`,
      data: submitFormData,
      logData: values,
      location: location?.pathname,
      userId
    })
      .then((res) => {
        if (res?.data?.code === 200) {
          resetForm({ values: '' })
          fetchData()
          setModalShow(false)
        }
        setLoading(false)
        setToast({
          show: true,
          text: res?.data?.message,
          variation: res?.data?.code !== 200 ? 'error' : 'success'
        })

        setTimeout(() => {
          setToast({ ...toast, show: false })
        }, 2000)
      })
      .catch((err) => {
        setLoading(false)
      })
  }

  const debounceSearchText = useDebounce(searchText, 500)

  useEffect(() => {
    if (debounceSearchText) {
      setFilterDetails((draft) => {
        draft.searchText = debounceSearchText
        draft.pageIndex = 1
      })
    } else {
      setFilterDetails((draft) => {
        draft.searchText = ''
        draft.pageIndex = 1
      })
    }
  }, [debounceSearchText])

  useEffect(() => {
    fetchData()
  }, [filterDetails])

  const handlePageClick = (event) => {
    setFilterDetails((draft) => {
      draft.pageIndex = event.selected + 1
    })
  }

  const SUPPORTED_FORMATS = ['image/jpg', 'image/jpeg', 'image/png']

  const validationSchema = Yup.object().shape({
    bimcadName: Yup.string().required('Bim Cad is Required'),
    bimcadImage: Yup.string()
      .test(
        'fileFormat',
        'File formate is not supported, Please use .jpg/.png/.jpeg format support',
        (value) => {
          if (typeof value === 'string') return true
          else {
            return value && SUPPORTED_FORMATS?.includes(value.type)
          }
        }
      )
      .test('fileSize', 'File must be less than 2MB', (value) => {
        if (typeof value === 'string') {
          return true
        } else return value !== undefined && value && value.size <= 2000000
      })
      .required('Bim Cad Image is required')
  })

  const handleDelete = async (id) => {
    setLoading(true)
    await axiosProvider({
      method: 'DELETE',
      endpoint: 'BimcadLibrary',
      queryString: `?Id=${id}`,
      userId,
      location: location.pathname
    })
      .then((res) => {
        if (res?.status === 200) {
          if (filterDetails?.pageIndex > 1 && data?.data?.data?.length === 1) {
            setFilterDetails((draft) => {
              draft.pageIndex = filterDetails?.pageIndex - 1
            })
          } else {
            fetchData()
          }
        }
        setLoading(false)
        setToast({
          show: true,
          text: res?.data?.message,
          variation: res?.data?.code !== 200 ? 'error' : 'success'
        })

        setTimeout(() => {
          setToast({ ...toast, show: false })
        }, 2000)
      })
      .catch((err) => {
        setLoading(false)
      })
  }

  return (
    <>
      {toast?.show && (
        <CustomToast text={toast?.text} variation={toast?.variation} />
      )}
      {loading && !modalShow && <Loader />}

      <div className='d-flex align-items-center mb-3 gap-3 justify-content-between'>
        {checkPageAccess(
          pageAccess,
          allPages?.manageBimCad,
          allCrudNames?.write
        ) && (
          <Button
            variant='warning'
            className='d-flex align-items-center gap-2 fw-semibold'
            onClick={() => {
              setInnitialData(true)
              setModalShow(!modalShow)
            }}
          >
            <i className='m-icon m-icon--plusblack'></i>
            Create
          </Button>
        )}

        <div className='d-flex align-items-center'>
          <label className='me-1'>Show</label>
          <select
            styles={customStyles}
            name='dataget'
            id='parpageentries'
            className='form-select me-1'
            onChange={(e) => {
              setFilterDetails((draft) => {
                draft.pageSize = e?.target?.value
              })
            }}
          >
            <option value='10'>10</option>
            <option value='25'>25</option>
            <option value='50'>50</option>
            <option value='100'>100</option>
            <option value='200'>200</option>
            <option value='500'>500</option>
          </select>
          entries
        </div>
      </div>
      <div className='admin_table'>
        <Table hover className='align-middle table-list mt-3'>
          <thead>
            <tr>
              <th>Image</th>
              <th>Name</th>
              {checkPageAccess(pageAccess, allPages?.manageBimCad, [
                allCrudNames?.update,
                allCrudNames?.delete
              ]) && <th className='text-center'>Action</th>}
            </tr>
          </thead>
          <tbody>
            {data?.data?.data?.length > 0 ? (
              data?.data?.data?.map((data) => (
                <tr key={data?.id}>
                  <td>
                    <img
                      style={{ width: '50px' }}
                      className='rounded-2'
                      src={
                        data?.bimcadImage
                          ? `${process.env.REACT_APP_IMG_URL}${_categoryImg_}${data?.bimcadImage}`
                          : 'https://placehold.jp/50x50.png'
                      }
                      alt='...'
                    />
                  </td>
                  <td>{data?.bimcadName}</td>
                  {checkPageAccess(pageAccess, allPages?.manageBimCad, [
                    allCrudNames?.update,
                    allCrudNames?.delete
                  ]) && (
                    <td className='text-center'>
                      <div className='d-flex gap-2 justify-content-center'>
                        {checkPageAccess(
                          pageAccess,
                          allPages?.manageBimCad,
                          allCrudNames?.update
                        ) && (
                          <span
                            onClick={() => {
                              setModalShow(!modalShow)
                              setInitialValues(data)
                            }}
                          >
                            <EditIcon bg={'bg'} />
                          </span>
                        )}
                        {checkPageAccess(
                          pageAccess,
                          allPages?.manageBimCad,
                          allCrudNames?.delete
                        ) && (
                          <span
                            onClick={() => {
                              Swal.fire({
                                title: _SwalDelete.title,
                                text: _SwalDelete.text,
                                icon: _SwalDelete.icon,
                                showCancelButton: _SwalDelete.showCancelButton,
                                confirmButtonColor:
                                  _SwalDelete.confirmButtonColor,
                                cancelButtonColor:
                                  _SwalDelete.cancelButtonColor,
                                confirmButtonText:
                                  _SwalDelete.confirmButtonText,
                                cancelButtonText: _SwalDelete.cancelButtonText
                              }).then((result) => {
                                if (result.isConfirmed) {
                                  handleDelete(data?.id)
                                } else if (result.isDenied) {
                                }
                              })
                            }}
                          >
                            <DeleteIcon bg={'bg'} />
                          </span>
                        )}
                      </div>
                    </td>
                  )}
                </tr>
              ))
            ) : (
              <tr>
                <td colSpan={6} className='text-center'>
                  {data?.data?.message}
                </td>
              </tr>
            )}
          </tbody>
        </Table>
        <ReactPaginate
          className='list-inline m-cst--pagination d-flex justify-content-end gap-1'
          breakLabel='...'
          nextLabel=''
          onPageChange={handlePageClick}
          pageRangeDisplayed={pageRangeDisplayed}
          pageCount={data?.data?.pagination?.pageCount ?? 0}
          previousLabel=''
          renderOnZeroPageCount={null}
          forcePage={filterDetails?.pageIndex - 1}
        />
      </div>

      <ModelComponent
        show={modalShow}
        modalsize={'md'}
        modalheaderclass={''}
        modeltitle={'Manage Bim Cad'}
        onHide={() => {
          setInitialValues({
            bimcadImage: '',
            bimcadName: ''
          })
          setModalShow(!modalShow)
        }}
        btnclosetext={''}
        closebtnvariant={''}
        backdrop={'static'}
        formbuttonid={'main-tax-type'}
        submitname={!initialValues?.id ? 'Create' : 'Update'}
      >
        <Formik
          enableReinitialize={true}
          initialValues={initialValues}
          validationSchema={validationSchema}
          onSubmit={onSubmit}
        >
          {({ values, setFieldValue }) => (
            <Form id='main-tax-type'>
              {loading && modalShow && <Loader />}
              <div className='row'>
                <div className='col-md-3'>
                  <div className='input-file-wrapper m--cst-filetype mb-3'>
                    <label
                      className='form-label required'
                      htmlFor='bimcadImage'
                    >
                      Image
                    </label>
                    <input
                      id='bimcadImage'
                      className='form-control'
                      name='bimcadImage'
                      type='file'
                      accept='image/jpg, image/png, image/jpeg'
                      onChange={(event) => {
                        const objectUrl = URL.createObjectURL(
                          event.target.files[0]
                        )
                        if (event.target.files[0].type !== '') {
                          setFieldValue('bimcadImageUrl', objectUrl)
                        }
                        setFieldValue('bimcadImage', event.target.files[0])
                      }}
                      hidden
                    />
                    {values?.bimcadImage ? (
                      <div className='position-relative m--img-preview d-flex rounded-2 overflow-hidden'>
                        <img
                          src={
                            values?.bimcadImageUrl?.includes('blob')
                              ? values?.bimcadImageUrl
                              : `${process.env.REACT_APP_IMG_URL}${_categoryImg_}${values?.bimcadImage}`
                          }
                          alt='Preview'
                          title={
                            values?.bimcadImage ? values?.bimcadImage?.name : ''
                          }
                          className='rounded-2'
                        ></img>
                        <span
                          onClick={(e) => {
                            setFieldValue('bimcadImageUrl', null)
                            setFieldValue('bimcadImage', null)
                            if (
                              document?.getElementById('bimcadImage')?.value
                            ) {
                              document.getElementById('bimcadImage').value =
                                null
                            }
                          }}
                        >
                          <i className='m-icon m-icon--close'></i>
                        </span>
                      </div>
                    ) : (
                      <>
                        <label
                          className='m__image_default d-flex align-items-center justify-content-center rounded-2'
                          htmlFor='bimcadImage'
                        >
                          <i className='m-icon m-icon--defaultpreview'></i>
                        </label>
                      </>
                    )}
                    <ErrorMessage
                      name='bimcadImage'
                      component={TextError}
                      customclass={'cfz-12 lh-sm'}
                    />
                  </div>
                </div>
                <div className='col-md-9'>
                  <div className='col-md-12'>
                    <FormikControl
                      isRequired
                      control='input'
                      label='Bim Cad name'
                      type='text'
                      name='bimcadName'
                      value={values?.bimcadName}
                      placeholder='Enter Bim Cad'
                    />
                  </div>
                </div>
              </div>
            </Form>
          )}
        </Formik>
      </ModelComponent>
    </>
  )
}

export default MainBimCad
