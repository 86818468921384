import React from 'react';
import OverlayTrigger from 'react-bootstrap/OverlayTrigger';
import Tooltip from 'react-bootstrap/Tooltip';
import TooltipComponent from '../Tooltip';

const InfoRoundedIcon = ({tooltipText}) => {
    const [isHovered, setIsHovered] = React.useState(false);

    let fillColor = "#6c6c6c"
    let hoverColor = "101010"

    const handleMouseEnter = () => {
        setIsHovered(true);
    };

    const handleMouseLeave = () => {
        setIsHovered(false);
    };



    return (
        // <svg
        //   width={size}
        //   height={size}
        //   viewBox="0 0 24 24"
        //   fill={isHovered ? hoverColor : fillColor}
        //   onMouseEnter={handleMouseEnter}
        //   onMouseLeave={handleMouseLeave}
        //   {...props}
        // >
        // </svg>
        <>
            <TooltipComponent toolplace="top" tooltipText={tooltipText}>
                <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 48 48" width="48px" height="48px" fill={isHovered ? hoverColor : fillColor} onMouseEnter={handleMouseEnter} onMouseLeave={handleMouseLeave}>
                    <path d="M 24 4 C 12.972066 4 4 12.972074 4 24 C 4 35.027926 12.972066 44 24 44 C 35.027934 44 44 35.027926 44 24 C 44 12.972074 35.027934 4 24 4 z M 24 7 C 33.406615 7 41 14.593391 41 24 C 41 33.406609 33.406615 41 24 41 C 14.593385 41 7 33.406609 7 24 C 7 14.593391 14.593385 7 24 7 z M 24 14 A 2 2 0 0 0 24 18 A 2 2 0 0 0 24 14 z M 23.976562 20.978516 A 1.50015 1.50015 0 0 0 22.5 22.5 L 22.5 33.5 A 1.50015 1.50015 0 1 0 25.5 33.5 L 25.5 22.5 A 1.50015 1.50015 0 0 0 23.976562 20.978516 z" />
                </svg>
            </TooltipComponent>
        </>
    )
};

export default InfoRoundedIcon;