import React, { useState } from 'react'
import { customStyles } from '../../components/customStyles'
import Select from 'react-select'
import DeleteIcon from '../../components/AllSvgIcon/DeleteIcon'
import Button from 'react-bootstrap/Button'
import Swal from 'sweetalert2'
import { _SwalDelete } from '../../lib/exceptionMessage'
import { _categoryImg_, _productBimcad_, _tempImg_ } from '../../lib/ImagePath'
import ModelComponent from '../../components/Modal'
import FormikControl from '../../components/FormikControl'
import PlusIcon from '../../components/AllSvgIcon/PlusIcon'
import { Table } from 'react-bootstrap'

function ProductBimCad({
  values,
  setFieldValue,
  uploadBimcad,
  allState,
  setLoading
}) {
  const [modalShow, setModalShow] = useState(false)

  const downloadURI = async (uri, name, folderName) => {
    let staticLink = `${process.env.REACT_APP_IMG_URL}${folderName}${uri}`

    try {
      const response = await fetch(staticLink)
      const data = await response.blob()

      const url = window.URL.createObjectURL(data)
      const link = document.createElement('a')
      link.href = url
      link.setAttribute('download', name)
      document.body.appendChild(link)
      link.click()
    } catch (error) {}
  }

  return (
    <div className='card'>
      <div className='card-body'>
        <h5 className='mb-3 head_h3'>Product BimCad</h5>
        <div className='input-file-wrapper mb-3 d-flex gap-3 align-items-end'>
          <div className='d-flex flex-column col-3'>
            <label className='form-label pv-w-fitcontent'>
              Select Bimcad
            </label>
            <Select
              id='bimcad'
              placeholder='Select Bimcad'
              styles={customStyles}
              value={
                values?.bimcadId && {
                  value: values?.bimcadId,
                  label: allState?.bimcad?.find(
                    (data) => data?.id === values?.bimcadId
                  )?.bimcadName
                }
              }
              options={
                allState?.bimcad?.length
                  ? allState.bimcad
                      .map((product) => {
                        const matchingBimcad = values?.productBimcad?.find(
                          (bimcad) => bimcad.bimcadId === product.id
                        )
                        if (!matchingBimcad) {
                          return {
                            value: product.id,
                            label: product.bimcadName,
                            bimcadImage: product.bimcadImage
                          }
                        } else {
                          return null
                        }
                      })
                      .filter(Boolean)
                  : []
              }
              onChange={(e) => {
                setFieldValue('bimcadId', e?.value)
                setFieldValue('bimcadName', e?.label)
                setFieldValue('bimcadImage', e?.bimcadImage)
                setFieldValue('validation', {
                  ...values?.validation,
                  bimcad: ''
                })
              }}
            />
          </div>
          <Button
            variant='primary'
            className='col-1 fw-semibold'
            onClick={() => {
              if (values?.bimcadId) {
                setFieldValue('validation', {
                  ...values?.validation,
                  bimcad: ''
                })
                setModalShow(!modalShow)
              } else {
                setFieldValue('validation', {
                  ...values?.validation,
                  bimcad: 'Please select bimcad'
                })
              }
              // setFieldValue('productBimcad', productBimcad)
            }}
          >
            Add
          </Button>
          {values?.validation?.bimcad && (
            <span className='text-danger'>{values?.validation?.bimcad}</span>
          )}
        </div>
        {values?.productBimcad?.length > 0 &&
          Array.from(
            new Set(values?.productBimcad?.map((item) => item?.bimcadId))
          )?.map((bimcadId) => (
            <div>
              <div className='d-flex align-items-center mb-3 gap-3'>
                <h6 className='mb-0 fw-bold'>
                  {
                    values?.productBimcad?.find(
                      (item) => item?.bimcadId === bimcadId
                    )?.bimcadName
                  }
                </h6>
                <span className='d-flex gap-3'>
                  <Button
                    variant='outline-primary'
                    size='sm'
                    onClick={() => {
                      setFieldValue('bimcadId', bimcadId)
                      setFieldValue(
                        'bimcadName',
                        values?.productBimcad?.find(
                          (item) => item?.bimcadId === bimcadId
                        )?.bimcadName
                      )
                      setModalShow(!modalShow)
                    }}
                  >
                    <span>Edit</span>
                  </Button>
                  <Button
                    variant='outline-danger'
                    size='sm'
                    onClick={() => {
                      Swal.fire({
                        title: _SwalDelete.title,
                        text: _SwalDelete.text,
                        icon: _SwalDelete.icon,
                        showCancelButton: _SwalDelete.showCancelButton,
                        confirmButtonColor: _SwalDelete.confirmButtonColor,
                        cancelButtonColor: _SwalDelete.cancelButtonColor,
                        confirmButtonText: _SwalDelete.confirmButtonText,
                        cancelButtonText: _SwalDelete.cancelButtonText
                      }).then((result) => {
                        if (result.isConfirmed) {
                          let productBimcad = values?.productBimcad ?? []
                          productBimcad = productBimcad?.filter(
                            (bimcad) => bimcad?.bimcadId !== bimcadId
                          )
                          setFieldValue('productBimcad', productBimcad)
                        } else if (result.isDenied) {
                        }
                      })
                    }}
                  >
                    <span>Delete</span>
                  </Button>
                </span>
              </div>
              <div className='row justify-content-start mb-3'>
                <Table responsive hover className='align-middle table-list'>
                  <thead className='align-middle'>
                    <tr>
                      <th className='text-nowrap'>Bim cad type</th>
                      <th className='text-nowrap'>Description</th>
                      {/* <th className='text-nowrap'>File name</th> */}
                      <th className='text-center'>Action</th>
                    </tr>
                  </thead>
                  <tbody>
                    {values?.productBimcad
                      ?.filter((item) => item?.bimcadId === bimcadId)
                      ?.map((data) => (
                        <tr key={Math.floor(Math.random() * 1000000)}>
                          <td>{data?.bimcadName}</td>
                          <td>{data?.fileDescription}</td>
                          {/* <td>{data?.fileName}</td> */}
                          <td className='text-center'>
                            <div className='d-flex gap-3 align-items-center justify-content-center'>
                              <svg
                                onClick={() => {
                                  downloadURI(
                                    data?.fileName,
                                    data?.bimcadName,
                                    data?.id ? _productBimcad_ : _tempImg_
                                  )
                                }}
                                role='button'
                                xmlns='http://www.w3.org/2000/svg'
                                width='18'
                                height='22'
                                viewBox='0 0 24.123 28.948'
                              >
                                <path
                                  id='Icon_ionic-md-download'
                                  data-name='Icon ionic-md-download'
                                  d='M30.873,14.715h-6.89V4.5H13.64V14.715H6.75L18.812,26.633ZM6.75,30.041v3.407H30.873V30.041Z'
                                  transform='translate(-6.75 -4.5)'
                                  fill='#999'
                                />
                              </svg>
                              <span
                                onClick={() => {
                                  Swal.fire({
                                    title: _SwalDelete.title,
                                    text: _SwalDelete.text,
                                    icon: _SwalDelete.icon,
                                    showCancelButton:
                                      _SwalDelete.showCancelButton,
                                    confirmButtonColor:
                                      _SwalDelete.confirmButtonColor,
                                    cancelButtonColor:
                                      _SwalDelete.cancelButtonColor,
                                    confirmButtonText:
                                      _SwalDelete.confirmButtonText,
                                    cancelButtonText:
                                      _SwalDelete.cancelButtonText
                                  }).then((result) => {
                                    if (result.isConfirmed) {
                                      let productBimcad =
                                        values?.productBimcad ?? []

                                      setFieldValue(
                                        'productBimcad',
                                        productBimcad?.filter(
                                          (item) =>
                                            item?.fileName !== data?.fileName
                                        )
                                      )
                                    } else if (result.isDenied) {
                                    }
                                  })
                                }}
                              >
                                <DeleteIcon bg={'bg'} />
                              </span>
                            </div>
                          </td>
                        </tr>
                      ))}
                  </tbody>
                </Table>
              </div>
            </div>
          ))}
      </div>

      <ModelComponent
        show={modalShow}
        modalsize={'md'}
        modalheaderclass={''}
        modeltitle={`Bim Cad: ${values?.bimcadName}`}
        onHide={() => {
          setFieldValue('bimcadId', null)
          setFieldValue('bimcadName', '')
          setFieldValue('bimcadImage', '')
          setFieldValue('bimcadFilename', '')
          setModalShow(false)
        }}
        btnclosetext={''}
        closebtnvariant={''}
        backdrop={'static'}
      >
        <div className='input-file-wrapper w-100 align-items-start mb-3 row'>
          <div
            className='col-12 d-flex align-items-center gap-3 mb-3'
            style={{
              position: 'relative',
              display: 'inline-block'
            }}
          >
            <label
              className='col-3 text-center'
              htmlFor='bimcadFileInput'
              style={{
                cursor: 'pointer',
                padding: '4px 5px',
                border: '1px solid #ccc',
                borderRadius: '4px',
                backgroundColor: '#f9f9f9'
              }}
            >
              Choose File
            </label>
            <input
              type='file'
              id='bimcadFileInput'
              className='d-none'
              onChange={(e) => {
                const handleFileChange = async (e, values, setFieldValue) => {
                  try {
                    const file = e?.target?.files[0]
                    setFieldValue('validation', {
                      ...values?.validation,
                      bimcadUpload: ''
                    })
                    if (file) {
                      setFieldValue('bimcadFilename', file?.name)
                      setFieldValue('bimcadFile', file)
                    }
                  } catch (error) {
                    setFieldValue('validation', {
                      ...values?.validation,
                      bimcadUpload: error?.message
                    })
                  }
                }

                handleFileChange(e, values, setFieldValue)
              }}
            />
            {values?.bimcadFilename && (
              <p className='mb-0'>File: {values?.bimcadFilename}</p>
            )}
          </div>
          <div className='col-9'>
            <FormikControl
              control='input'
              type='text'
              name='fileDescription'
              placeholder='Bimcad description'
              value={values?.fileDescription}
              label=''
            />
          </div>
          <Button
            variant='primary'
            className='col-3 fw-semibold'
            onClick={async () => {
              if (values?.fileDescription && values?.bimcadFile) {
                setLoading(true)
                const imgUrl = await uploadBimcad(
                  values,
                  values?.bimcadFile,
                  setFieldValue
                )
                setLoading(false)

                let productBimcad = values?.productBimcad?.length
                  ? values.productBimcad
                  : []

                productBimcad = [
                  ...productBimcad,
                  {
                    bimcadId: values?.bimcadId,
                    fileName: imgUrl,
                    bimcadName: values?.bimcadName,
                    bimcadImage: values?.bimcadImage,
                    fileDescription: values?.fileDescription
                  }
                ]

                document.getElementById('bimcadFileInput').value = null
                setFieldValue('validation', {
                  ...values?.validation,
                  bimcadUpload: ''
                })
                setFieldValue('fileDescription', '')
                setFieldValue('bimcadFilename', '')
                setFieldValue('bimcadFile', '')
                setFieldValue('productBimcad', productBimcad)
              } else {
                setFieldValue('validation', {
                  ...values?.validation,
                  bimcadUpload: !values?.bimcadFile
                    ? 'Please select file'
                    : !values?.fileDescription && 'Please enter description'
                })
              }
            }}
          >
            Add
          </Button>
          {values?.validation?.bimcadUpload && (
            <div className='text-danger'>
              {values?.validation?.bimcadUpload}
            </div>
          )}
        </div>
        {values?.productBimcad?.length > 0 &&
          values?.productBimcad?.filter(
            (item) => item?.bimcadId === values?.bimcadId
          )?.length > 0 && (
            <Table responsive hover className='align-middle table-list'>
              <thead className='align-middle'>
                <tr>
                  <th className='text-nowrap'>Bim cad type</th>
                  <th className='text-nowrap'>Description</th>
                  {/* <th className='text-nowrap'>File name</th> */}
                  <th className='text-center'>Action</th>
                </tr>
              </thead>
              <tbody>
                {values?.productBimcad
                  ?.filter((item) => item?.bimcadId === values?.bimcadId)
                  ?.map((data) => (
                    <tr key={Math.floor(Math.random() * 1000000)}>
                      <td>{data?.bimcadName}</td>
                      <td>{data?.fileDescription}</td>
                      {/* <td>{data?.fileName}</td> */}
                      <td className='text-center'>
                        <div className='d-flex gap-3 align-items-center justify-content-center'>
                          <svg
                            onClick={() => {
                              downloadURI(
                                data?.fileName,
                                data?.bimcadName,
                                data?.id ? _categoryImg_ : _tempImg_
                              )
                            }}
                            role='button'
                            xmlns='http://www.w3.org/2000/svg'
                            width='18'
                            height='22'
                            viewBox='0 0 24.123 28.948'
                          >
                            <path
                              id='Icon_ionic-md-download'
                              data-name='Icon ionic-md-download'
                              d='M30.873,14.715h-6.89V4.5H13.64V14.715H6.75L18.812,26.633ZM6.75,30.041v3.407H30.873V30.041Z'
                              transform='translate(-6.75 -4.5)'
                              fill='#999'
                            />
                          </svg>
                          <span
                            onClick={() => {
                              Swal.fire({
                                title: _SwalDelete.title,
                                text: _SwalDelete.text,
                                icon: _SwalDelete.icon,
                                showCancelButton: _SwalDelete.showCancelButton,
                                confirmButtonColor:
                                  _SwalDelete.confirmButtonColor,
                                cancelButtonColor:
                                  _SwalDelete.cancelButtonColor,
                                confirmButtonText:
                                  _SwalDelete.confirmButtonText,
                                cancelButtonText: _SwalDelete.cancelButtonText
                              }).then((result) => {
                                if (result.isConfirmed) {
                                  let productBimcad =
                                    values?.productBimcad ?? []

                                  setFieldValue(
                                    'productBimcad',
                                    productBimcad?.filter(
                                      (item) =>
                                        item?.fileName !== data?.fileName
                                    )
                                  )
                                } else if (result.isDenied) {
                                }
                              })
                            }}
                          >
                            <DeleteIcon bg={'bg'} />
                          </span>
                        </div>
                      </td>
                    </tr>
                  ))}
              </tbody>
            </Table>
          )}
      </ModelComponent>
    </div>
  )
}

export default ProductBimCad
