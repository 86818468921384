import React from 'react'
import { InputGroup } from 'react-bootstrap'
import {
  isAllowWarehouseManagement,
  prepareProductName
} from '../../lib/GetBaseUrl'
import { _integerRegex_ } from '../../lib/Regex'
import { maxWarehouseQty } from '../../lib/AllStaticVariables'

const ManageSizeValues = ({ values, setFieldValue, allState }) => {
  return (
    <div className='col-md-12 mb-3'>
      <label className='form-label required'>Size Value</label>
      <div className='d-flex align-items-center custom_mt_0 gap-4 flex-wrap'>
        {values?.productPrices?.map((pricingDetails) => (
          <InputGroup className='custom_checkbox' key={pricingDetails?.sizeID}>
            <InputGroup.Checkbox
              id={`${pricingDetails?.sizeID}`}
              checked={pricingDetails?.isCheckedForQty}
              onChange={(e) => {
                let productPriceList = values?.productPrices ?? []

                if (productPriceList.length > 0) {
                  productPriceList = productPriceList?.map((data) => {
                    if (data?.sizeID === pricingDetails?.sizeID) {
                      return {
                        ...data,
                        mrp: 1000,
                        sellingPrice: 1000,
                        discount: '0',
                        quantity: e?.target?.checked ? 1 : '',
                        manageWarehouseStock: false,
                        sizeID: data?.sizeID,
                        sizeName: data?.sizeName,
                        isCheckedForQty: e?.target?.checked,
                        productWarehouses: []
                      }
                    } else {
                      return {
                        ...data
                      }
                    }
                  })
                }
                let checkMultipleSizeSelected = productPriceList?.filter(
                  (obj) => obj?.isCheckedForQty
                )?.length

                if (checkMultipleSizeSelected > 1) {
                  setFieldValue(
                    'productName',
                    prepareProductName(
                      pricingDetails?.sizeName,
                      3,
                      'size',
                      values,
                      false
                    )
                  )
                } else {
                  if (checkMultipleSizeSelected === 1) {
                    if (values?.isAllowSizeInTitle) {
                      setFieldValue(
                        'productName',
                        prepareProductName(
                          productPriceList?.find((obj) => obj.isCheckedForQty)
                            ?.sizeName,
                          values?.titleSequenceOfSize,
                          'size',
                          values,
                          true
                        )
                      )
                    } else {
                      setFieldValue(
                        'productName',
                        prepareProductName(
                          pricingDetails?.sizeName,
                          4,
                          'size',
                          values,
                          false
                        )
                      )
                    }
                  } else {
                    setFieldValue(
                      'productName',
                      prepareProductName(
                        pricingDetails?.sizeName,
                        2,
                        'size',
                        values,
                        false
                      )
                    )
                  }
                }

                setFieldValue('productPrices', productPriceList)
              }}
            />
            <label className='custom_label' htmlFor={pricingDetails?.sizeID}>
              {pricingDetails?.sizeName}
            </label>
            {/* {!isAllowWarehouseManagement && (
              <input
                disabled={pricingDetails?.isCheckedForQty ? false : true}
                type='text'
                placeholder='Qty'
                value={pricingDetails?.quantity}
                className='form-control custom_textbox'
                onChange={(e) => {
                  const inputValue = e?.target?.value
                  const isValid = _integerRegex_.test(inputValue)
                  if (
                    (isValid ||
                      (inputValue >= 0 &&
                        inputValue <= maxWarehouseQty &&
                        isValid)) &&
                    !isNaN(inputValue) || !inputValue
                  ) {
                    let productPriceList = values?.productPrices ?? []

                    if (productPriceList.length > 0) {
                      productPriceList = productPriceList?.map((data) => {
                        if (data?.sizeID === pricingDetails?.sizeID) {
                          return {
                            ...data,
                            quantity: e?.target?.value
                          }
                        } else {
                          return data
                        }
                      })
                    }
                    setFieldValue('productPrices', productPriceList)
                  }
                }}
              />
            )} */}
          </InputGroup>
        ))}
      </div>
    </div>
  )
}

export default ManageSizeValues
