import React, { useState } from 'react'
import { Link } from 'react-router-dom'
import { setPageTitle } from '../redux/slice/pageTitleSlice'
import { useDispatch, useSelector } from 'react-redux'
import { useEffect } from 'react'
import { allCrudNames, allPages, checkPageAccess } from '../../lib/AllPageNames'
import NotFound from '../NotFound/NotFound'
import MainLogs from './MainLogs'

const Logs = () => {
  const [activeToggle, setActiveToggle] = useState('logs')
  const dispatch = useDispatch()
  useEffect(() => {
    dispatch(setPageTitle('Logs'))
  }, [])
  const { pageAccess } = useSelector((state) => state.user)

  return checkPageAccess(pageAccess, allPages?.logs, allCrudNames?.read) ? (
    <>
      <div className='card overflow-hidden'>
        {checkPageAccess(pageAccess, allPages?.logs, allCrudNames?.read) && (
          <div className='card-body p-0'>
            <div className='nav-tabs-horizontal nav nav-tabs'>
              <Link
                onClick={() => setActiveToggle('logs')}
                data-toggle='tab'
                className={`nav-link fw-semibold ${
                  activeToggle === 'logs' ? 'active show' : ''
                }`}
              >
                <span className='nav-span'>
                  Logs
                  <hr />
                </span>
              </Link>
            </div>
            <div className='tab-content p-3'>
              <div
                id='logs'
                className={`tab-pane fade ${
                  activeToggle === 'logs' ? 'active show' : ''
                }`}
              >
                {activeToggle === 'logs' && <MainLogs />}
              </div>
            </div>
          </div>
        )}
      </div>
    </>
  ) : (
    <NotFound />
  )
}

export default Logs
