import React, { useEffect } from 'react'
import cart from '../../images/cart.png'
import { Link } from 'react-router-dom'
import logo from '../../images/archioo-logo.png'
import IpCheckbox from '../../components/IpCheckbox'
import bagl from '../../images/bagl.png'
import bagr from '../../images/bagr.png'
import location from '../../images/location.png'
import { Formik, Form } from 'formik'
import * as Yup from 'yup'
import { useDispatch } from 'react-redux'
import { Button } from 'react-bootstrap'
import FormikControl from '../../components/FormikControl'
import Loader from '../../components/Loader'
import CustomToast from '../../components/Toast/CustomToast'
import { useState } from 'react'
import axios from 'axios'
import { getBaseUrl, showToast } from '../../lib/GetBaseUrl'
import { setUserInfo } from '../redux/slice/userSlice'
import Nookies from 'nookies'
import { _exception } from '../../lib/exceptionMessage'

const Login = () => {
  const dispatch = useDispatch()
  const [loading, setLoading] = useState(false)
  const [toast, setToast] = useState({
    show: false,
    text: null,
    variation: null
  })
  let deviceId = `Archioo${Math.floor(Math.random() * 1000000000)}`

  const { userName, password, isRemember } = Nookies.get()

  const initialVal = {
    userName: userName || '',
    password: password || '',
    newPasswordVisible: false,
    isRemember: isRemember ? true : false,
    deviceId
  }

  const validationSchema = Yup.object().shape({
    userName: Yup.string()
      .email('Invalid email address')
      .required('Username Required'),
    password: Yup.string().required('Password Required')
  })
  const config = {
    headers: {
      'Content-Type': 'application/json'
    }
  }

  const handleSubmit = (values, setFieldValue) => {
    setLoading(true)
    const response = axios
      .post(`${getBaseUrl()}/Account/admin/Login`, values, config)
      .then(async (res) => {
        setFieldValue('validation', '')
        if (res?.status === 200) {
          if (res?.data?.code == 200) {
            dispatch(setUserInfo(res?.data))
            localStorage.setItem('userToken', res?.data?.tokens?.accessToken)
            localStorage.setItem(
              'refreshToken',
              res?.data?.tokens?.refreshToken
            )
            localStorage.setItem('deviceId', values?.deviceId)

            if (values.isRemember) {
              Nookies.set(null, 'isRemember', values?.isRemember, {
                maxAge: 30 * 24 * 60 * 60,
                path: '/'
              })

              Nookies.set(null, 'userName', values.userName, {
                maxAge: 30 * 24 * 60 * 60,
                path: '/'
              })

              Nookies.set(null, 'password', values?.password, {
                maxAge: 30 * 24 * 60 * 60,
                path: '/'
              })
            } else {
              Nookies.destroy(null, 'isRemember')
              Nookies.destroy(null, 'userName')
              Nookies.destroy(null, 'password')
            }
          } else {
            setToast({
              show: true,
              text: res?.data?.message,
              variation: 'error'
            })

            setTimeout(() => {
              setToast({ ...toast, show: false })
            }, 2000)
          }
          setLoading(false)
        }
      })
      .catch((err) => {
        setLoading(false)

        showToast(toast, setToast, {
          data: {
            message: _exception?.message,
            code: 204
          }
        })
      })
  }

  return (
    <>
      {loading && <Loader />}
      <div className='main_background'>
        {toast?.show && (
          <CustomToast text={toast?.text} variation={toast?.variation} />
        )}
        <div className='container_custom  pos-relative'>
          <div>
            <img src={bagl} className='left_bag' alt='left bag' />
            <img src={bagr} className='right_bag' alt='right bag' />
            <img src={location} className='location' alt='location' />
          </div>
          <Formik
            initialValues={initialVal}
            validationSchema={validationSchema}
            onSubmit={(values, { setFieldValue }) => {
              handleSubmit(values, setFieldValue)
            }}
          >
            {({ values, setFieldValue }) => (
              <Form>
                <div className='card_custom'>
                  <div className='row g-0 d-flex align-items-center py-5 px-4'>
                    <div className='col-6'>
                      <div className='card-body px-5'>
                        <img src={logo} alt='' className='c-logo' />
                        <FormikControl
                          isRequired
                          label='Email Address'
                          control='input'
                          className='he-10'
                          type='email'
                          name='userName'
                          placeholder='Enter Email Address'
                          onChange={(e) => {
                            setFieldValue('userName', e?.target?.value)
                          }}
                          maxLength={50}
                          onBlur={(e) => {
                            let fieldName = e?.target?.name
                            setFieldValue(fieldName, values[fieldName]?.trim())
                          }}
                        />
                        <div className='input-wrapper pv-login-password mb-4 pos-relative'>
                          <FormikControl
                            label='Password'
                            type={
                              values.newPasswordVisible ? 'text' : 'password'
                            }
                            isRequired
                            control='input'
                            name='password'
                            className='he-10'
                            placeholder='Password'
                            onChange={(e) => {
                              setFieldValue('password', e?.target?.value)
                            }}
                            maxLength={50}
                            onBlur={(e) => {
                              let fieldName = e?.target?.name
                              setFieldValue(
                                fieldName,
                                values[fieldName]?.trim()
                              )
                            }}
                          />
                          <div
                            className='eye-absolute'
                            style={{
                              position: 'absolute',
                              top: '2.1rem',
                              right: '10px'
                            }}
                          >
                            {!values.newPasswordVisible ? (
                              <svg
                                xmlns='http://www.w3.org/2000/svg'
                                width='18'
                                height='18'
                                onClick={() => {
                                  setFieldValue(
                                    'newPasswordVisible',
                                    !values.newPasswordVisible
                                  )
                                }}
                                viewBox='0 0 24 24'
                                fill='none'
                                stroke='currentColor'
                                strokeWidth='2'
                                strokeLinecap='round'
                                strokeLinejoin='round'
                                className={`feather feather-eye link-icon toggle-password eye-icon w-75`}
                                data-password=''
                              >
                                <path d='M1 12s4-8 11-8 11 8 11 8-4 8-11 8-11-8-11-8z'></path>
                                <circle cx='12' cy='12' r='3'></circle>
                              </svg>
                            ) : (
                              <svg
                                xmlns='http://www.w3.org/2000/svg'
                                width='18'
                                height='18'
                                viewBox='0 0 24 24'
                                fill='none'
                                stroke='currentColor'
                                onClick={() => {
                                  setFieldValue(
                                    'newPasswordVisible',
                                    !values.newPasswordVisible
                                  )
                                }}
                                strokeWidth='2'
                                strokeLinecap='round'
                                strokeLinejoin='round'
                                className='feather feather-eye-off link-icon toggle-password eye-off-icon w-75'
                                data-password=''
                              >
                                <path d='M17.94 17.94A10.07 10.07 0 0 1 12 20c-7 0-11-8-11-8a18.45 18.45 0 0 1 5.06-5.94M9.9 4.24A9.12 9.12 0 0 1 12 4c7 0 11 8 11 8a18.5 18.5 0 0 1-2.16 3.19m-6.72-1.07a3 3 0 1 1-4.24-4.24'></path>
                                <line x1='1' y1='1' x2='23' y2='23'></line>
                              </svg>
                            )}
                          </div>
                        </div>

                        <div className='row mt-5'>
                          <div className='col d-flex align-items-center'>
                            <div className='form-check pl_0'>
                              <IpCheckbox
                                checkboxLabel={'Remember me'}
                                checkboxid={'isRemember'}
                                value={'isRemember'}
                                changeListener={(e) => {
                                  setFieldValue('isRemember', e?.checked)
                                }}
                                checked={values?.isRemember}
                              />
                            </div>
                          </div>
                          <div className='col d-flex justify-content-end'>
                            <Link className='c_link' to='/forgot-password'>
                              Lost your password?
                            </Link>
                          </div>
                        </div>
                        <Button
                          type='submit'
                          buttontext='Login'
                          className='login_btn'
                        >
                          Login
                        </Button>
                        <p className='text-danger py-2 text-center'>
                          {values?.validation}
                        </p>
                        {/* <button type="button" className="btn btn-primary mt-4 w-100">Login</button> */}
                      </div>
                    </div>
                    <div className='col-6'>
                      <img src={cart} alt='' className='w-100 custom_cart' />
                    </div>
                  </div>
                </div>
              </Form>
            )}
          </Formik>
        </div>
      </div>
    </>
  )
}

export default Login
