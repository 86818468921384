import { DatePicker } from 'antd'
import moment from 'moment/moment'
import React, { useEffect, useState } from 'react'
import { Table } from 'react-bootstrap'
import ReactPaginate from 'react-paginate'
import { useDispatch } from 'react-redux'
import Select from 'react-select'
import { useImmer } from 'use-immer'
import { customStyles } from '../../../components/customStyles'
import Loader from '../../../components/Loader'
import axiosProvider from '../../../lib/AxiosProvider'
import { _exception } from '../../../lib/exceptionMessage'
import { pageRangeDisplayed, showToast } from '../../../lib/GetBaseUrl'
import { _productImg_ } from '../../../lib/ImagePath'
import { setPageTitle } from '../../redux/slice/pageTitleSlice'

const ManageWishlistProject = () => {
  const dispatch = useDispatch()
  const [loading, setLoading] = useState(false)
  const [toast, setToast] = useState({
    show: false,
    text: null,
    variation: null
  })
  const [filterDetails, setFilterDetails] = useImmer({
    pageIndex: 1,
    pageSize: 10,
    brandId: '',
    brandName: '',
    fromDate: '',
    toDate: ''
  })
  const [brands, setBrands] = useState()
  const [data, setData] = useState([])

  const fetchPageData = async (filterDetails) => {
    try {
      setLoading(true)

      const wishlistResponse = await axiosProvider({
        method: 'GET',
        endpoint: `admin/Wishlist?brandId=${filterDetails?.brandId}&fromDate=${
          filterDetails?.fromDate
            ? filterDetails?.fromDate?.format('DD-MM-YYYY')
            : ''
        }&toDate=${
          filterDetails?.toDate
            ? filterDetails?.toDate?.format('DD-MM-YYYY')
            : ''
        }&pageIndex=${filterDetails?.pageIndex}&pageSize=${
          filterDetails?.pageSize
        }`
      })

      setLoading(false)
      if (wishlistResponse?.status === 200) {
        setData(wishlistResponse)
      }
    } catch (_exception) {
      setLoading(false)
      showToast(toast, setToast, {
        data: {
          message: _exception?.message || 'Error occurred while fetching data',
          code: 204
        }
      })
    }
  }

  const fetchBrandData = async () => {
    setLoading(true)
    try {
      const response = await axiosProvider({
        method: 'GET',
        endpoint: `Brand`,
        queryString: `?pageIndex=0&pageSize=0`
      })
      if (response?.status === 200) {
        setBrands(response?.data?.data)
      }
    } catch (error) {
      showToast(toast, setToast, {
        data: {
          message: _exception?.message || 'Error occurred while fetching data',
          code: 204
        }
      })
    }
    setLoading(false)
  }

  const handlePageClick = (event) => {
    setFilterDetails((draft) => {
      draft.pageIndex = event.selected + 1
    })
    fetchPageData({ ...filterDetails, pageIndex: event.selected + 1 })
  }

  const disabledDate = (current) => {
    return current && current < moment('2023-01-01').startOf('day')
  }

  const disabledEndDate = (current, startValue) => {
    const today = moment().startOf('day')

    if (current.isBefore(today)) {
      return true
    }

    if (startValue && current.isBefore(startValue.startOf('day'))) {
      return true
    }

    return false
  }
  useEffect(() => {
    fetchPageData(filterDetails)
  }, [filterDetails])

  useEffect(() => {
    dispatch(setPageTitle('Wishlist'))
    fetchBrandData()
  }, [])

  return (
    <div>
      {loading && <Loader />}
      <div className='d-flex align-items-center gap-3 justify-content-between'>
        <div className='d-flex gap-3'>
          <div className='col-md-6'>
            <Select
              styles={customStyles}
              id='brandId'
              isClearable
              menuPortalTarget={document.body}
              value={
                filterDetails?.brandId && {
                  label: filterDetails?.brandName,
                  value: filterDetails?.brandId
                }
              }
              placeholder='Select Brand'
              options={brands?.map(({ id, name }) => ({
                value: id,
                label: name
              }))}
              onChange={(e) => {
                setFilterDetails((draft) => {
                  draft.brandId = e?.value ? e?.value : ''
                  draft.brandName = e?.label ? e?.label : ''
                })
              }}
            />
          </div>
          <div className='col-md-6'>
            <DatePicker
              className='w-100'
              inputReadOnly
              format='DD-MM-YYYY'
              placeholder='From date'
              onChange={(date) => {
                setFilterDetails((draft) => {
                  draft.fromDate = date
                  draft.toDate = ''
                  draft.pageIndex = 1
                })
              }}
              disabledDate={disabledDate}
              value={filterDetails?.fromDate}
            />
          </div>

          {filterDetails?.fromDate && (
            <div className='col-md-6'>
              <DatePicker
                className='w-100'
                inputReadOnly
                format='DD-MM-YYYY'
                placeholder='To date'
                onChange={(date) => {
                  setFilterDetails((draft) => {
                    draft.toDate = date
                    draft.pageIndex = 1
                  })
                }}
                disabledDate={(current) =>
                  disabledEndDate(current, filterDetails?.fromDate)
                }
                value={filterDetails?.toDate}
              />
            </div>
          )}
        </div>
        <div className='d-flex align-items-center'>
          <label className='me-1'>Show</label>
          <select
            styles={customStyles}
            menuPortalTarget={document.body}
            name='dataget'
            id='parpageentries'
            className='form-select me-1'
            onChange={(e) => {
              setFilterDetails((draft) => {
                draft.pageSize = e?.target?.value
                draft.pageIndex = 1
              })
              fetchPageData({
                ...filterDetails,
                pageSize: e?.target?.value,
                pageIndex: 1
              })
            }}
          >
            <option value='10'>10</option>
            <option value='25'>25</option>
            <option value='50'>50</option>
            <option value='100'>100</option>
            <option value='200'>200</option>
            <option value='500'>500</option>
          </select>
        </div>
      </div>
      <Table className='align-middle mt-3 table-list manage-product-table'>
        <thead className='align-middle'>
          <tr>
            <th>Product Details</th>
            <th>Wishlist Details</th>
            <th>User Details</th>
            <th>Created date</th>
          </tr>
        </thead>
        <tbody className='border border-1' style={{ boxShadow: 'unset' }}>
          {data?.data?.data?.length > 0 ? (
            data?.data?.data?.map((data) => (
              <React.Fragment key={data.id}>
                <tr className='timeline'>
                  <td>
                    <div className='d-flex gap-3 align-items-center'>
                      <img
                        style={{
                          width: '100px'
                        }}
                        className='img-thumbnail position-relative cp'
                        src={
                          data?.products?.image1 &&
                          `${process.env.REACT_APP_IMG_URL}${_productImg_}${data?.products?.image1}`
                        }
                        alt='...'
                      />
                      <div>
                        <p className='pv-font-hard mb-1 cp'>
                          Name: {data?.products?.productName}
                        </p>
                        {data?.products?.categoryName && (
                          <small className='d-block'>
                            Category: <b>{data?.products?.categoryName}</b>
                          </small>
                        )}
                        {data?.products?.brandName && (
                          <small className='d-block'>
                            Brand: <b>{data?.products?.brandName}</b>
                          </small>
                        )}
                      </div>
                    </div>
                  </td>
                  <td>
                    {data?.wishlistGroup && (
                      <small className='d-block'>
                        Group: <b>{data?.wishlistGroup}</b>
                      </small>
                    )}
                    {data?.wishlistProject && (
                      <small className='d-block'>
                        Project: <b>{data?.wishlistProject}</b>
                      </small>
                    )}
                  </td>
                  <td>
                    {data?.customer?.firstName && (
                      <small className='d-block'>
                        Name:{' '}
                        <b>
                          {data?.customer?.firstName} {data?.customer?.lastName}
                        </b>
                      </small>
                    )}
                    {data?.customer?.userName && (
                      <small className='d-block'>
                        Email id: <b>{data?.customer?.userName}</b>
                      </small>
                    )}
                    {data?.customer?.mobileNo && (
                      <small className='d-block'>
                        Mobile no: <b>{data?.customer?.mobileNo}</b>
                      </small>
                    )}
                  </td>
                  <td>{moment(data.createdAt).format('DD/MM/YYYY')}</td>
                </tr>
              </React.Fragment>
            ))
          ) : (
            <tr>
              <td colSpan={7} className='text-center'>
                {data?.data?.message}
              </td>
            </tr>
          )}
        </tbody>
      </Table>
      <ReactPaginate
        className='list-inline m-cst--pagination d-flex justify-content-end gap-1'
        breakLabel='...'
        nextLabel=''
        onPageChange={handlePageClick}
        pageRangeDisplayed={pageRangeDisplayed}
        pageCount={data?.data?.pagination?.pageCount}
        previousLabel=''
        renderOnZeroPageCount={null}
        forcePage={filterDetails?.pageIndex - 1}
      />
    </div>
  )
}

export default ManageWishlistProject
