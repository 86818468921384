import axiosProvider from './AxiosProvider'

export const getBaseUrl = () => {
  if (process.env.NODE_ENV !== 'production') {
    return process.env.REACT_APP_API_URL
  }

  return process.env.REACT_APP_API_URL
}

export const getUserToken = () => {
  return localStorage.getItem('userToken')
    ? localStorage.getItem('userToken')
    : null
}

export const getRefreshToken = () => {
  return localStorage.getItem('refreshToken')
    ? localStorage.getItem('refreshToken')
    : null
}

export const getDeviceId = () => {
  return localStorage.getItem('deviceId')
    ? localStorage.getItem('deviceId')
    : null
}

export const isAdharCardAllowed = false
export const isShippingOnCat = false
export const isSellerWithGST = true
export const isAllowOneProductMultipleSeller = false
export const isAllowPriceVariant = false
export const isAllowWarehouseManagement = false
export const isAllowExpiryDate = false
export const currencyIcon = '₹'
export const isAllowMultipleGST = true

// a little function to help us with reordering the result
export const Reorder = (list, startIndex, endIndex) => {
  const result = Array.from(list)
  const [removed] = result.splice(startIndex, 1)
  result.splice(endIndex, 0, removed)

  return result
}

const grid = 6
export const getItemStyle = (isDragging, draggableStyle) => {
  return {
    // some basic styles to make the items look a bit nicer
    userSelect: 'none',
    padding: grid * 2,
    margin: `0 0 ${grid}px 0`,
    textAlign: 'right',

    // change background colour if dragging
    background: isDragging ? 'lightgreen' : 'grey',

    // styles we need to apply on draggables
    ...draggableStyle
  }
}

export const getQuestionListStyle = (isDraggingOver) => ({
  background: isDraggingOver ? 'lightblue' : 'lightgrey',
  padding: 8,
  width: 350
})

export const getAnswerListStyle = (isDraggingOver) => ({
  background: isDraggingOver ? 'lightblue' : 'lightgrey',
  padding: 4,
  width: 250
})

export const getUniqueListBy = (arr, key) => {
  return [...new Map(arr.map((item) => [item[key], item])).values()]
}

export const changeHandler = (fieldName, value, setFieldValue) => {
  setFieldValue([fieldName], value)
}

export const productStatus = [
  { value: 'Active', label: 'Active' },
  {
    value: 'Request for Approval',
    label: 'Request for Approval'
  },
  {
    value: 'Bulk Upload',
    label: 'Bulk Upload'
  },
  {
    value: 'Poor Image Quality',
    label: 'Poor Image Quality'
  },
  {
    value: 'Incorrect Category',
    label: 'Incorrect Category'
  },
  {
    value: 'Restricted Image',
    label: 'Restricted Image'
  },
  {
    value: 'Restricted Keyword',
    label: 'Restricted Keyword'
  },
  {
    value: 'Feature Missing',
    label: 'Feature Missing'
  },
  {
    value: 'Multiple Issues',
    label: 'Multiple Issues'
  },
  {
    value: 'Archived',
    label: 'Archived'
  }
]

export const redirectTo = [
  { value: 'Product List', label: 'Product List' },
  {
    value: 'Static Page',
    label: 'Static Page'
  },
  {
    value: 'Landing Page',
    label: 'Landing Page'
  },
  {
    value: 'Collection Page',
    label: 'Collection Page'
  },
  {
    value: 'Other Links',
    label: 'Other Links'
  }
]

export const ckEditorConfig = {
  toolbar: [
    '|',
    'bold',
    'italic',
    '|',
    'bulletedList',
    'numberedList',
    '|',
    'undo',
    'redo'
  ]
}

export function searchArray(array, searchText) {
  const results = []

  for (let i = 0; i < array.length; i++) {
    const item = array[i]

    for (const key in item) {
      if (typeof item[key] === 'string' || typeof item[key] === 'number') {
        const value = item[key].toString().toLowerCase()
        if (value.includes(searchText.toLowerCase())) {
          results.push(item)
          break
        }
      }
    }
  }

  return results
}

export const prepareDisplayCalculationData = (values) => {
  return {
    mrp: values?.mrp ?? 0,
    sellingPrice: values?.sellingPrice ?? 0
  }
}

export const preparePerWarehouseStock = (allState, productPrices, values) => {
  let productWarehouseArray = productPrices?.productWarehouses ?? []
  let productPrice = productPrices
  if (productWarehouseArray?.length) {
    if (allState?.warehouseDetails.length === productWarehouseArray.length) {
      productWarehouseArray = productWarehouseArray?.map((warehouse) => {
        return {
          ...warehouse,
          quantity: productPrice.perWarehouseStock
        }
      })
    } else {
      productWarehouseArray = allState?.warehouseDetails?.map((obj1) => {
        const obj2 = productWarehouseArray?.find(
          (obj2) => obj2?.sizeID === obj1?.id
        )
        return obj2
          ? { ...obj2, quantity: productPrice?.perWarehouseStock }
          : {
              warehouseId: obj1?.id,
              warehouseName: obj1?.name,
              quantity: productPrices?.perWarehouseStock
            }
      })
    }
  } else {
    productWarehouseArray = allState?.warehouseDetails?.map((warehouseData) => {
      return {
        quantity: productPrice?.perWarehouseStock,
        warehouseId: warehouseData?.id,
        warehouseName: warehouseData?.name
      }
    })
  }

  let total = 0
  if (productPrice?.perWarehouseStock && allState?.warehouseDetails) {
    total =
      Number(productPrice?.perWarehouseStock) *
      allState?.warehouseDetails?.length
  }

  productPrice = {
    ...productPrice,
    mrp: values?.isSizeWisePriceVariant ? '' : values?.mrp,
    sellingPrice: values?.isSizeWisePriceVariant ? '' : values?.sellingPrice,
    discount: values?.isSizeWisePriceVariant ? '' : values?.discount,
    quantity: total,
    perWarehouseStock:
      productPrice?.perWarehouseStock &&
      Number(productPrice?.perWarehouseStock),
    productWarehouses: productWarehouseArray
  }

  let productPriceArray = values?.productPrices ?? []

  productPriceArray = productPriceArray?.map((product) => {
    if (product?.sizeID) {
      if (product?.sizeID === productPrice?.sizeID) {
        return {
          ...product,
          quantity: total,
          mrp: values?.mrp,
          sellingPrice: values?.sellingPrice,
          discount: values?.discount,
          perWarehouseStock:
            productPrice?.perWarehouseStock &&
            Number(productPrice?.perWarehouseStock),
          productWarehouses: productWarehouseArray,
          manageWarehouseStock: productPrice?.manageWarehouseStock ?? false
        }
      } else {
        return product
      }
    } else if (!allState?.sizeType) {
      return {
        ...product,
        quantity: total,
        perWarehouseStock:
          productPrice?.perWarehouseStock &&
          Number(productPrice?.perWarehouseStock),
        productWarehouses: productWarehouseArray,
        manageWarehouseStock: productPrice?.manageWarehouseStock ?? false
      }
    } else {
      return product
    }
  })

  return productPriceArray
}

export function generateRandomString(length) {
  const characters =
    'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789'
  let randomString = ''

  for (let i = 0; i < length; i++) {
    const randomIndex = Math.floor(Math.random() * characters.length)
    randomString += characters[randomIndex]
  }

  return randomString
}

export const callApi = (endpoint, queryString, state) => {
  return axiosProvider({
    method: 'GET',
    endpoint,
    queryString
  })
    .then((res) => {
      if (res?.status === 200) {
        if (state) {
          return {
            state,
            data: res?.data?.data ? res?.data?.data : res?.data
          }
        } else {
          return res?.data?.data
            ? typeof res?.data?.data === 'boolean'
              ? res?.data
              : res?.data?.data
            : res?.data
        }
      }
    })
    .catch((err) => {})
}

export const splitStringOnCapitalLettersAndUnderscores = (string) => {
  const substrings = string.split(/(?=[A-Z_])/)

  for (let i = 0; i < substrings.length; i++) {
    substrings[i] = substrings[i].replace('_', '')
  }

  const joinedString = substrings.join(' ')

  return joinedString
}

export const convertStringFormat = (inputString) => {
  return inputString
    ?.split('>')
    ?.map((item) => item.trim())
    ?.join(' > ')
}

export const prepareProductName = (
  name,
  sequence,
  field,
  values,
  isAllowValueToAdd = true
) => {
  if (!name || !sequence || !field) {
    return []
  }
  let productName = values?.productName ?? []

  if (isAllowValueToAdd) {
    productName = productName?.filter((product) => product?.field !== field)
    const updatedProduct = { name, sequence, field }
    productName.push(updatedProduct)
  } else {
    productName = productName?.filter((product) => product?.field !== field)
  }

  return productName?.sort((a, b) => a?.sequence - b?.sequence)
}

export const arrangeNamesBySequence = (productArray) => {
  if (Array.isArray(productArray)) {
    if (!productArray || productArray?.length === 0) {
      return ''
    }

    productArray?.sort((a, b) => a?.sequence - b?.sequence)
    return productArray?.map((product) => product.name)?.join(' ')
  } else {
    return ''
  }
}

export const _status_ = [
  {
    label: 'Active',
    value: 'Active'
  },
  {
    label: 'Inactive',
    value: 'Inactive'
  }
]

export const videoFormats = [
  'mp4',
  'avi',
  'mov',
  'mkv',
  'wmv',
  'flv',
  'webm',
  'mpeg',
  '3gp'
]

export const prepareNotificationData = (values) => {
  return {
    senderId: values?.userId,
    receiverId: values?.reciverId ?? null,
    userType: values?.userType,
    notificationTitle: values?.notificationTitle,
    notificationDescription: values?.notificationDescription ?? '',
    url: values?.url,
    imageUrl: values?.imageUrl ?? null,
    isRead: false,
    notificationsOf: values?.notifcationsof
  }
}

export const pageRangeDisplayed = 2
export const removeBrTags = (html) => {
  const tempDivElement = document.createElement('div')
  tempDivElement.innerHTML = html
  tempDivElement.querySelectorAll('br').forEach((brElement) => {
    brElement.parentNode.removeChild(brElement)
  })
  return tempDivElement.innerHTML
}

export const hasSizeID = (sellerProducts) => {
  if (
    !sellerProducts ||
    !Array.isArray(sellerProducts) ||
    sellerProducts.length === 0
  ) {
    return false
  }

  for (const product of sellerProducts) {
    if (
      !product.productPrices ||
      !Array.isArray(product.productPrices) ||
      product.productPrices.length === 0
    ) {
      continue
    }
    for (const price of product.productPrices) {
      if (price.sizeID !== null) {
        return true
      }
    }
  }
  return false
}

const setMultipleFieldValues = (values, setFieldValue) => {
  Object.keys(values).forEach((fieldName) => {
    setFieldValue(fieldName, values[fieldName])
  })
}

export const generatePassword = (length) => {
  const charSets = [
    'abcdefghijklmnopqrstuvwxyz',
    'ABCDEFGHIJKLMNOPQRSTUVWXYZ',
    '0123456789',
    '!@#$%^&*()_+'
  ]

  if (length < charSets.length) {
    throw new Error(
      'Password length must be at least 4 to include all required types.'
    )
  }

  const getRandomChar = (charSet) =>
    charSet[Math.floor(Math.random() * charSet.length)]

  let password = charSets.map(getRandomChar).join('')

  for (let i = password.length; i < length; i++) {
    const randomCharSet = charSets[Math.floor(Math.random() * charSets.length)]
    password += getRandomChar(randomCharSet)
  }

  return password
}

export const showToast = (toast, setToast, res) => {
  setToast({
    show: true,
    text: res?.data?.message,
    variation: res?.data?.code !== 200 ? 'error' : 'success'
  })

  setTimeout(() => {
    setToast({ ...toast, show: false })
  }, 2000)
}

export const _orderStatus_ = {
  cancelled: 'Cancelled',
  placed: 'Placed',
  confirmed: 'Confirmed',
  packed: 'Packed',
  ship: 'Shipped',
  delivered: 'Delivered',
  returned: 'Returned'
}

export const generateSawbNumber = () => {
  let randomNum = Math.floor(1000000000 + Math.random() * 9000000000) // Generates a random 10-digit number
  return `sawb${randomNum}`
}

export const arrangeOrderItems = (orderItems) => {
  let statusSequence = [
    _orderStatus_.placed,
    _orderStatus_.confirmed,
    _orderStatus_.packed,
    _orderStatus_.ship,
    _orderStatus_.delivered
  ]
  let statusIndexMap = {}
  statusSequence.forEach((status, index) => {
    statusIndexMap[status] = index
  })

  orderItems.sort((a, b) => statusIndexMap[a.status] - statusIndexMap[b.status])

  return orderItems
}

export const arrangeItemsByStatusAndPackage = (items) => {
  let arrangedItems = {
    Placed: [],
    Confirmed: [],
    Shipped: [],
    Delivered: [],
    Returned: [],
    Cancelled: []
  }

  let packageMap = {}

  items?.forEach((item) => {
    if (arrangedItems[item?.status]) {
      arrangedItems[item?.status]?.push(item)
    }

    if (item.status === _orderStatus_.packed && item.packageNo) {
      if (!packageMap[item?.packageNo]) {
        packageMap[item?.packageNo] = []
      }
      packageMap[item?.packageNo]?.push(item)
    }
  })

  for (let packageNo in packageMap) {
    arrangedItems[packageNo] = packageMap[packageNo]
  }

  for (let status in arrangedItems) {
    if (!arrangedItems[status]?.length) {
      delete arrangedItems[status]
    }
  }

  return arrangedItems
}

export const fontSizeConfig = {
  options: ['tiny', 'small', 'big', 'default']
}

export const headingStyles = {
  options: [
    { model: 'paragraph', title: 'Normal' },
    { model: 'heading1', view: 'h1', title: 'Heading 1' },
    { model: 'heading2', view: 'h2', title: 'Heading 2' },
    { model: 'heading3', view: 'h3', title: 'Heading 3' },
    { model: 'heading4', view: 'h4', title: 'Heading 4' },
    { model: 'heading5', view: 'h5', title: 'Heading 5' },
    { model: 'heading6', view: 'h6', title: 'Heading 6' }
  ]
}
