/* eslint-disable react-hooks/exhaustive-deps */
import { ErrorMessage, Form, Formik } from 'formik'
import moment from 'moment'
import React, { useEffect, useRef, useState } from 'react'
import {
  Button,
  Dropdown,
  DropdownButton,
  Image,
  Offcanvas,
  Table
} from 'react-bootstrap'
import ReactPaginate from 'react-paginate'
import { useDispatch, useSelector } from 'react-redux'
import { useLocation, useNavigate } from 'react-router-dom'
import Select from 'react-select'
import Swal from 'sweetalert2'
import { useImmer } from 'use-immer'
import * as Yup from 'yup'
import DeleteIcon from '../../../components/AllSvgIcon/DeleteIcon'
import EditIcon from '../../../components/AllSvgIcon/EditIcon'
import HKBadge from '../../../components/Badges'
import { customStyles } from '../../../components/customStyles'
import FormikControl from '../../../components/FormikControl'
import Loader from '../../../components/Loader'
import ModelComponent from '../../../components/Modal'
import SearchBox from '../../../components/Searchbox'
import TextError from '../../../components/TextError'
import CustomToast from '../../../components/Toast/CustomToast'
import {
  allCrudNames,
  allPages,
  checkPageAccess
} from '../../../lib/AllPageNames'
import axiosProvider from '../../../lib/AxiosProvider'
import { _SwalDelete, _exception } from '../../../lib/exceptionMessage'
import { pageRangeDisplayed, showToast } from '../../../lib/GetBaseUrl'
import { _categoryImg_ } from '../../../lib/ImagePath'
import useDebounce from '../../../lib/useDebounce'
import { setPageTitle } from '../../redux/slice/pageTitleSlice'
import BasicInfoModal from './BasicInfoModal'
import CreateSellerModal from './CreateSellerModal'

const AddSeller = () => {
  const ref = useRef()
  const dispatch = useDispatch()
  const [loading, setLoading] = useState(false)
  const [data, setData] = useState()
  const [searchText, setSearchText] = useState('')
  const navigate = useNavigate()
  const isModalRequired = false

  const [modalShow, setModalShow] = useImmer({
    createSeller: false,
    basicInfo: false,
    gstInfo: false,
    warehouse: false
  })

  const [filterDetails, setFilterDetails] = useImmer({
    pageSize: 10,
    pageIndex: 1,
    status: '',
    searchText: ''
  })

  const [toast, setToast] = useState({
    show: false,
    text: null,
    variation: null
  })
  const [modalShowVideoLink, setModalShowVideoLink] = useState({
    sellerId: null,
    show: false
  })
  const [modalShowCatalogue, setModalShowCatalogue] = useState({
    show: false,
    sellerId: null
  })
  const [modalShowGallery, setModalShowGallery] = useState({
    show: false,
    sellerId: null
  })
  const [initVal, setInitVal] = useState({ link: '', title: '' })
  const [filterModal, setFilterModal] = useImmer({
    pageSize: 10,
    pageIndex: 1,
    status: 'Active',
    searchText: ''
  })
  const [modalData, setModalData] = useState()

  useEffect(() => {
    dispatch(setPageTitle('Manage Seller'))
  }, [])

  const location = useLocation()
  const { userInfo } = useSelector((state) => state?.user)
  const { pageAccess } = useSelector((state) => state.user)
  const debounceSearchText = useDebounce(searchText, 500)
  const initValues = {
    createSeller: {
      firstName: '',
      lastName: '',
      emailID: '',
      mobileNo: '',
      password: '',
      // gstNo: '',
      // kycFor: '',
      cpass: '',
      displayName: '',
      ownerName: '',
      contactPersonName: '',
      contactPersonMobileNo: '',
      typeOfCompany: '',
      companyRegistrationNo: '',
      bussinessType: '',
      status: '',
      userID: ''
    },
    basicInfo: {
      name: '',
      description: '',
      logo: '',
      brandCertificate: '',
      backgroundBanner: '',
      browsebyBrandImage: '',
      sellerId: '',
      status: 'Active',
      website: ''
    },
    gstInfo: {
      userID: '',
      gstNo: '',
      legalName: '',
      tradeName: '',
      gstType: '',
      gstDoc: '',
      registeredAddressLine1: '',
      registeredAddressLine2: '',
      registeredLandmark: '',
      registeredPincode: '',
      registeredStateId: '',
      registeredCityId: '',
      registeredCountryId: '',
      tcsNo: '',
      status: ''
    },
    warehouse: {
      userID: '',
      gstInfoId: null,
      name: '',
      contactPersonName: '',
      contactPersonMobileNo: '',
      addressLine1: '',
      addressLine2: '',
      landmark: '',
      pincode: '',
      countryId: '',
      stateId: '',
      cityId: '',
      status: ''
    }
  }

  const [initialValues, setInitialValues] = useState(initValues)

  const fetchData = async () => {
    setLoading(true)
    await axiosProvider({
      method: 'GET',
      endpoint: 'SellerData/search',
      queryString: `?searchText=${filterDetails?.searchText?.trim()}&pageIndex=${
        filterDetails?.pageIndex
      }&pageSize=${filterDetails?.pageSize}&status=${filterDetails?.status}`
    })
      .then((res) => {
        setLoading(false)
        if (res?.status === 200) {
          setData(res)
        }
      })
      .catch((err) => {
        setLoading(false)
      })
  }

  useEffect(() => {
    dispatch(setPageTitle('Manage Seller'))
  }, [])

  useEffect(() => {
    setFilterDetails((draft) => {
      draft.searchText = searchText
      draft.pageIndex = 1
    })
  }, [debounceSearchText])

  useEffect(() => {
    fetchData()
  }, [filterDetails])

  // const handleEditData = async (endpoint, moduleName) => {
  //   setLoading(true)
  //   const response = await axiosProvider({
  //     method: 'GET',
  //     endpoint
  //     // queryString: `?userId=${id}`
  //   })
  //     .then((res) => {
  //       if (res?.status === 200) {
  //         Array.isArray(res?.data?.data)
  //           ? setEditData({
  //               data: res?.data?.data,
  //               userID: endpoint.split('=')[1]
  //             })
  //           : setEditData(res?.data?.data)
  //         // dispatch(setSellerDetails())
  //         // res?.data?.data?.length > 0
  //         //   ? setEditData(res?.data?.data[0])
  //         //   : setEditData(res?.data?.data)
  //         setInnitialData(false)
  //         moduleName === 'create-seller'
  //           ? setCreateModal(true)
  //           : setCreateModal(false)
  //         moduleName === 'basic-info'
  //           ? setModalBasicInfo(true)
  //           : setModalBasicInfo(false)
  //         moduleName === 'gst' ? setModalGSTInfo(true) : setModalGSTInfo(false)
  //         moduleName === 'warehouse' ? setModalWare(true) : setModalWare(false)
  //         // setModalBasicInfo(true)
  //       }
  //       setLoading(false)
  //     })
  //     .catch((err) => {
  //       setLoading(false)
  //     })
  // }

  const handlePageClick = async (
    event,
    forModal = false,
    forVideo = false,
    forGallery = false
  ) => {
    if (forModal) {
      let endpoint = forVideo
        ? 'UserVideoLinks'
        : forGallery
        ? 'UserGallery'
        : 'UserCatalogue'
      let userId = forVideo
        ? modalShowVideoLink?.sellerId
        : forGallery
        ? modalShowGallery?.sellerId
        : modalShowCatalogue?.sellerId
      setFilterModal((draft) => {
        draft.pageIndex = event.selected + 1
      })
      setLoading(true)
      const data = await axiosProvider({
        method: 'GET',
        endpoint,
        queryString: `?userId=${userId}&pageIndex=${
          event.selected + 1
        }&pageSize=${filterModal?.pageSize}`
      })
      setLoading(false)
      setModalData(data?.data)
    } else {
      setFilterDetails((draft) => {
        draft.pageIndex = event.selected + 1
      })
    }
  }

  const handleSellerActive = async (values, endpoint = 'SellerData/Update') => {
    setLoading(true)

    await axiosProvider({
      method: 'PUT',
      endpoint,
      data: values,
      userId: userInfo?.userId,
      location: location.pathname
    })
      .then((res) => {
        if (res?.status === 200) {
          fetchData()
        }
        setToast({
          show: true,
          text: res?.data?.message,
          variation: res?.data?.code !== 200 ? 'error' : 'success'
        })

        setTimeout(() => {
          setToast({ ...toast, show: false })
        }, 2000)
        setLoading(false)
      })
      .catch((err) => {
        setLoading(false)
      })
  }

  const handleArchiveAndDelete = async (
    userId,
    endpoint = 'SellerData/archived'
  ) => {
    setLoading(true)

    await axiosProvider({
      method: 'PUT',
      endpoint,
      queryString: `?userId=${userId}`,
      userId: userInfo?.userId,
      location: location.pathname
    })
      .then((res) => {
        if (res?.status === 200) {
          fetchData()
        }
        setToast({
          show: true,
          text: res?.data?.message,
          variation: res?.data?.code !== 200 ? 'error' : 'success'
        })

        setTimeout(() => {
          setToast({ ...toast, show: false })
        }, 2000)
        setLoading(false)
      })
      .catch((err) => {
        setLoading(false)
      })
  }

  const SUPPORTED_FORMATS = ['application/pdf']

  const validationSchema = modalShowVideoLink?.show
    ? Yup.object().shape({
        link: Yup.string().required('Please enter link'),
        title: Yup.string().required('Please enter title')
      })
    : modalShowGallery?.show
    ? Yup.object().shape({
        fileName: Yup.string().required('Image is required')
      })
    : Yup.object().shape({
        catalogueName: Yup.string().required('Please enter catalogue name'),
        fileName: Yup.mixed()
          .test(
            'fileFormat',
            'File formate is not supported, Please use .pdf format support',
            (value) => {
              if (typeof value === 'string') return true
              else {
                return value && SUPPORTED_FORMATS?.includes(value.type)
              }
            }
          )
          .test('fileSize', 'File must be less than 30MB', (value) => {
            if (typeof value === 'string') {
              return true
            } else return value !== undefined && value && value.size <= 30000000
          })
          .required('Image is required')
      })

  const onSubmitVideo = async (values, { resetForm }) => {
    setLoading(true)
    values = { ...values, userId: modalShowVideoLink?.sellerId }
    let method = values?.id ? 'PUT' : 'POST'
    await axiosProvider({
      method,
      endpoint: 'UserVideoLinks',
      data: values,
      location: location?.pathname,
      userId: userInfo?.userId
    })
      .then(async (res) => {
        if (res.status === 200) {
          setInitVal({ link: '', title: '' })
          resetForm({ name: '' })
          const data = await axiosProvider({
            method: 'GET',
            endpoint: 'UserVideoLinks',
            queryString: `?userId=${values?.userId}&pageIndex=${filterModal?.pageIndex}&pageSize=${filterModal?.pageSize}`
          })
          setModalData(data?.data)
        }
        setLoading(false)
        setToast({
          show: true,
          text: res?.data?.message,
          variation: res?.data?.code !== 200 ? 'error' : 'success'
        })

        setTimeout(() => {
          setToast({ ...toast, show: false })
        }, 2000)
      })
      .catch((err) => {
        setLoading(false)
      })
  }

  const onSubmitCatalogue = async (values, { resetForm }) => {
    setLoading(true)
    let method = values?.id ? 'PUT' : 'POST'

    let dataofForm = {
      UserId: modalShowCatalogue?.sellerId,
      CatalogueName: values?.catalogueName,
      FileName: values?.fileName,
      IsImageAvailable: values?.fileName ? true : false
    }

    if (values?.id) {
      dataofForm = { ...dataofForm, Id: values?.id }
    }

    const submitFormData = new FormData()

    const keys = Object.keys(dataofForm)

    keys.forEach((key) => {
      submitFormData.append(key, dataofForm[key])
    })

    await axiosProvider({
      method,
      endpoint: `UserCatalogue`,
      data: submitFormData,
      logData: values,
      location: location?.pathname,
      userId: userInfo?.userId
    })
      .then(async (res) => {
        if (res.status === 200) {
          setInitVal({ catalogueName: '', fileName: '' })
          resetForm({ catalogueName: '', fileName: '' })
          document.getElementById('fileName').value = null
          const data = await axiosProvider({
            method: 'GET',
            endpoint: 'UserCatalogue',
            queryString: `?userId=${modalShowCatalogue?.sellerId}&pageIndex=${filterModal?.pageIndex}&pageSize=${filterModal?.pageSize}`
          })
          setModalData(data?.data)
        }
        setLoading(false)
        setToast({
          show: true,
          text: res?.data?.message,
          variation: res?.data?.code !== 200 ? 'error' : 'success'
        })

        setTimeout(() => {
          setToast({ ...toast, show: false })
        }, 2000)
      })
      .catch((err) => {
        setLoading(false)
      })
  }

  const onSubmitGallery = async (values, { resetForm }) => {
    setLoading(true)
    let method = values?.id ? 'PUT' : 'POST'

    let valObj = {
      userId: modalShowGallery?.sellerId,
      IsImageAvailable: true,
      fileName: values?.fileName
    }
    if (values?.id) {
      valObj = { ...valObj, Id: values?.id }
    }
    const submitFormData = new FormData()

    const keys = Object.keys(valObj)

    keys.forEach((key) => {
      submitFormData.append(key, valObj[key])
    })

    await axiosProvider({
      method,
      endpoint: `UserGallery`,
      data: submitFormData,
      logData: values,
      location: location?.pathname,
      userId: userInfo?.userId
    })
      .then(async (res) => {
        if (res.status === 200) {
          setInitVal({ catalogueName: '', fileName: '' })
          resetForm({ catalogueName: '', fileName: '' })
          const data = await axiosProvider({
            method: 'GET',
            endpoint: 'UserGallery',
            queryString: `?userId=${modalShowGallery?.sellerId}&pageIndex=${filterModal?.pageIndex}&pageSize=${filterModal?.pageSize}`
          })
          setModalData(data?.data)
        }
        setLoading(false)
        setToast({
          show: true,
          text: res?.data?.message,
          variation: res?.data?.code !== 200 ? 'error' : 'success'
        })

        setTimeout(() => {
          setToast({ ...toast, show: false })
        }, 2000)
      })
      .catch((err) => {
        setLoading(false)
      })
  }

  const handleDelete = async (endpoint, fetchEndpoint) => {
    setLoading(true)
    await axiosProvider({
      method: 'DELETE',
      endpoint,
      userId: userInfo?.userId,
      location: location.pathname
    })
      .then(async (res) => {
        if (res?.status === 200) {
          const data = await axiosProvider({
            method: 'GET',
            endpoint: fetchEndpoint
          })
          setModalData(data?.data)
        }
        setToast({
          show: true,
          text: res?.data?.message,
          variation: res?.data?.code !== 200 ? 'error' : 'success'
        })

        setTimeout(() => {
          setToast({ ...toast, show: false })
        }, 2000)
        setLoading(false)
      })
      .catch((err) => {
        setLoading(false)
      })
  }

  const downloadURI = async (uri, name, folderName) => {
    let staticLink = `${process.env.REACT_APP_IMG_URL}${folderName}${uri}`

    try {
      const response = await fetch(staticLink)
      const data = await response.blob()

      const url = window.URL.createObjectURL(data)
      const link = document.createElement('a')
      link.href = url
      link.setAttribute('download', name)
      document.body.appendChild(link)
      link.click()
    } catch (error) {}
  }

  return (
    <>
      <div className='d-flex align-items-center gap-3 mb-3 flex-row-reverse'>
        {checkPageAccess(
          pageAccess,
          allPages?.manageSeller,
          allCrudNames?.write
        ) && (
          <Button
            variant='warning'
            className='d-flex align-items-center gap-2 fw-semibold ms-auto'
            onClick={() => {
              setInitialValues(initValues)
              setModalShow((draft) => {
                draft.createSeller = true
              })
            }}
          >
            <i className='m-icon m-icon--plusblack'></i>
            Create Seller
          </Button>
        )}

        <div className='input-file-wrapper'>
          <Select
            styles={customStyles}
            menuPortalTarget={document.body}
            ref={ref}
            isClearable
            id='status'
            name='status'
            placeholder='Seller Status'
            options={[
              {
                label: 'Active',
                value: 'Active'
              },
              {
                label: 'Archived',
                value: 'Archived'
              },
              {
                label: 'Inactive',
                value: 'Inactive'
              }
            ]}
            onChange={async (e) => {
              if (e?.value) {
                setFilterDetails((draft) => {
                  draft.pageIndex = 1
                  draft.status = e?.value
                })
              } else {
                setFilterDetails((draft) => {
                  draft.pageIndex = 1
                  draft.status = ''
                })
              }
            }}
          />
        </div>
        <div className='d-flex align-items-center me-2'>
          <label className='me-1'>Show</label>
          <select
            styles={customStyles}
            name='dataget'
            className='form-select me-1'
            value={filterDetails?.pageSize}
            onChange={async (e) => {
              setFilterDetails((draft) => {
                draft.pageSize = e?.target?.value
                draft.pageIndex = 1
              })
            }}
          >
            <option value='10'>10</option>
            <option value='25'>25</option>
            <option value='50'>50</option>
            <option value='100'>100</option>
            <option value='200'>200</option>
            <option value='500'>500</option>
          </select>
        </div>

        <SearchBox
          placeholderText={'Search'}
          value={searchText}
          searchclassnamewrapper={'searchbox-wrapper'}
          onChange={(e) => {
            setSearchText(e?.target?.value)
          }}
        />
      </div>

      {/* <div className='d-flex justify-content-between align-items-center mb-3 gap-3'>
        <div className='d-flex gap-2 col-md-6'>
          <div className='input-file-wrapper w-100'>
            <Select
              id='state'
              placeholder='Select seller'
              styles={customStyles}
            />
          </div>
        </div>
      </div> */}
      <ModelComponent
        show={modalShowVideoLink?.show}
        modalsize={'md'}
        modalheaderclass={''}
        modeltitle={'Video Id'}
        onHide={() => {
          setFilterModal((draft) => {
            draft.pageSize = 10
            draft.pageIndex = 1
            draft.searchText = ''
          })
          setInitVal({ link: '' })
          setModalShowVideoLink({ show: false, sellerId: null })
        }}
        btnclosetext={''}
        closebtnvariant={''}
        backdrop={'static'}
      >
        <div className='row'>
          <Formik
            enableReinitialize={true}
            initialValues={initVal}
            validationSchema={validationSchema}
            onSubmit={onSubmitVideo}
          >
            {({ values, setFieldValue }) => (
              <Form id='video'>
                <div className='row'>
                  <div className='col-md-12'>
                    <div className='input-file-wrapper mb-3'>
                      <FormikControl
                        isRequired
                        control='input'
                        label='Title'
                        id='title'
                        type='text'
                        name='title'
                        value={values?.title}
                        placeholder='Title'
                        onChange={(e) => {
                          setFieldValue('title', e?.target?.value)
                        }}
                      />
                    </div>
                  </div>
                  <div className='col-md-12'>
                    <div className='input-file-wrapper mb-3'>
                      <FormikControl
                        isRequired
                        control='input'
                        label='Video Id'
                        id='link'
                        type='text'
                        name='link'
                        value={values?.link}
                        placeholder='Video Id'
                        onChange={(e) => {
                          setFieldValue('link', e?.target?.value)
                        }}
                      />
                      <p className='default_col text-muted'>
                        <small>
                          Example: https://www.youtube.com/watch?v={' '}
                          <b>[Your Video Id]</b>
                        </small>
                      </p>
                    </div>
                  </div>

                  <div className='col-12 text-center'>
                    <Button
                      variant='primary'
                      className='fw-semibold px-3'
                      type='submit'
                    >
                      {values?.id ? 'Update' : 'Add'}
                    </Button>
                  </div>
                </div>
              </Form>
            )}
          </Formik>

          <div
            className={`${
              modalData?.data?.length > 6 ? 'table-responsive mt-3' : 'mt-3'
            }`}
          >
            <Table
              hover
              className='align-middle table-list hr_table_seller'
              // style={{ height: `${filteredData?.data?.data?.length < 5 ? '500px' : 'auto'}` }}
            >
              <thead>
                <tr>
                  <th>Video Id</th>
                  <th>Title</th>
                  {checkPageAccess(pageAccess, allPages?.manageSeller, [
                    allCrudNames?.update,
                    allCrudNames?.delete
                  ]) && <th className='text-center'>Action</th>}
                </tr>
              </thead>
              <tbody>
                {modalData?.data?.length > 0 ? (
                  modalData?.data?.map((data) => (
                    <tr key={Math.floor(Math.random() * 1000000)}>
                      <td>{data?.link}</td>
                      <td>{data?.title}</td>
                      <td className='text-center'>
                        <div className='d-flex gap-2 justify-content-center'>
                          {checkPageAccess(
                            pageAccess,
                            allPages?.manageSeller,
                            allCrudNames?.update
                          ) && (
                            <span
                              onClick={() => {
                                setInitVal(data)
                              }}
                            >
                              <EditIcon bg={'bg'} />
                            </span>
                          )}

                          {checkPageAccess(
                            pageAccess,
                            allPages?.manageSeller,
                            allCrudNames?.delete
                          ) && (
                            <span
                              onClick={() => {
                                Swal.fire({
                                  title: _SwalDelete.title,
                                  text: _SwalDelete.text,
                                  icon: _SwalDelete.icon,
                                  showCancelButton:
                                    _SwalDelete.showCancelButton,
                                  confirmButtonColor:
                                    _SwalDelete.confirmButtonColor,
                                  cancelButtonColor:
                                    _SwalDelete.cancelButtonColor,
                                  confirmButtonText:
                                    _SwalDelete.confirmButtonText,
                                  cancelButtonText: _SwalDelete.cancelButtonText
                                }).then((result) => {
                                  if (result.isConfirmed) {
                                    handleDelete(
                                      `UserVideoLinks?Id=${data?.id}`,
                                      `UserVideoLinks?userId=${data?.userId}&pageIndex=${filterModal?.pageIndex}&pageSize=${filterModal?.pageSize}`
                                    )
                                  } else if (result.isDenied) {
                                  }
                                })
                              }}
                            >
                              <DeleteIcon bg={'bg'} />
                            </span>
                          )}
                        </div>
                      </td>
                    </tr>
                  ))
                ) : (
                  <tr>
                    <td colSpan={2} className='text-center'>
                      {modalData?.message
                        ? modalData?.message
                        : 'No record found'}
                    </td>
                  </tr>
                )}
              </tbody>
            </Table>
          </div>

          <ReactPaginate
            className='list-inline m-cst--pagination d-flex justify-content-end gap-1'
            breakLabel='...'
            nextLabel=''
            onPageChange={(event) => handlePageClick(event, true, true)}
            pageRangeDisplayed={pageRangeDisplayed}
            pageCount={modalData?.pagination?.pageCount ?? 0}
            previousLabel=''
            renderOnZeroPageCount={null}
            forcePage={filterModal?.pageIndex - 1}
          />
        </div>
      </ModelComponent>
      <ModelComponent
        show={modalShowCatalogue?.show}
        modalsize={'md'}
        modalheaderclass={''}
        modeltitle={'Add Catalogue'}
        onHide={() => {
          setModalShowCatalogue({ show: false, sellerId: null })
          setInitVal()
          setFilterModal((draft) => {
            draft.pageSize = 10
            draft.pageIndex = 1
            draft.searchText = ''
          })
        }}
        btnclosetext={''}
        closebtnvariant={''}
        backdrop={'static'}
      >
        <Formik
          enableReinitialize={true}
          initialValues={initVal}
          validationSchema={validationSchema}
          onSubmit={onSubmitCatalogue}
        >
          {({ values, setFieldValue }) => (
            <Form id='catalogue'>
              <div className='input-file-wrapper w-100 align-items-start mb-3 row'>
                <div
                  className='col-12 d-flex align-items-center gap-3 mb-3'
                  style={{
                    position: 'relative',
                    display: 'inline-block'
                  }}
                >
                  <label
                    className='col-3 text-center'
                    htmlFor='fileName'
                    style={{
                      cursor: 'pointer',
                      padding: '4px 5px',
                      border: '1px solid #ccc',
                      borderRadius: '4px',
                      backgroundColor: '#f9f9f9'
                    }}
                  >
                    Choose File
                  </label>
                  <input
                    type='file'
                    id='fileName'
                    className='d-none'
                    onChange={(e) => {
                      const file = e?.target?.files[0]
                      if (file) {
                        setFieldValue('fileName', file)
                      }
                    }}
                  />
                  {values?.fileName && (
                    <p className='mb-0'>
                      File: {values?.fileName?.name ?? values?.fileName}
                    </p>
                  )}
                  <ErrorMessage name='fileName' component={TextError} />
                </div>
                <div className='col-9'>
                  <FormikControl
                    control='input'
                    type='text'
                    name='catalogueName'
                    placeholder='Catalogue Name'
                    value={values?.catalogueName}
                    label=''
                  />
                </div>
                <Button
                  variant='primary'
                  className='col-3 fw-semibold'
                  type='submit'
                >
                  {values?.id ? 'Update' : 'Add'}
                </Button>
              </div>
              <div
                className={`${
                  modalData?.data?.length > 6 ? 'table-responsive' : ''
                }`}
              >
                <Table
                  hover
                  className='align-middle table-list hr_table_seller'
                  // style={{ height: `${filteredData?.data?.data?.length < 5 ? '500px' : 'auto'}` }}
                >
                  <thead>
                    <tr>
                      <th>Catalogue Name</th>
                      {checkPageAccess(pageAccess, allPages?.manageSeller, [
                        allCrudNames?.update,
                        allCrudNames?.delete
                      ]) && <th className='text-center'>Action</th>}
                    </tr>
                  </thead>
                  <tbody>
                    {modalData?.data?.length > 0 ? (
                      modalData?.data?.map((data) => (
                        <tr key={Math.floor(Math.random() * 1000000)}>
                          <td>{data?.catalogueName}</td>
                          <td className='text-center'>
                            <div className='d-flex gap-2 justify-content-center align-items-center'>
                              <svg
                                onClick={() => {
                                  downloadURI(
                                    data?.fileName,
                                    data?.catalogueName,
                                    _categoryImg_
                                  )
                                }}
                                role='button'
                                xmlns='http://www.w3.org/2000/svg'
                                width='18'
                                height='22'
                                viewBox='0 0 24.123 28.948'
                              >
                                <path
                                  id='Icon_ionic-md-download'
                                  data-name='Icon ionic-md-download'
                                  d='M30.873,14.715h-6.89V4.5H13.64V14.715H6.75L18.812,26.633ZM6.75,30.041v3.407H30.873V30.041Z'
                                  transform='translate(-6.75 -4.5)'
                                  fill='#999'
                                />
                              </svg>
                              {checkPageAccess(
                                pageAccess,
                                allPages?.manageSeller,
                                allCrudNames?.update
                              ) && (
                                <span
                                  onClick={() => {
                                    setInitVal(data)
                                  }}
                                >
                                  <EditIcon bg={'bg'} />
                                </span>
                              )}

                              {checkPageAccess(
                                pageAccess,
                                allPages?.manageSeller,
                                allCrudNames?.delete
                              ) && (
                                <span
                                  onClick={() => {
                                    Swal.fire({
                                      title: _SwalDelete.title,
                                      text: _SwalDelete.text,
                                      icon: _SwalDelete.icon,
                                      showCancelButton:
                                        _SwalDelete.showCancelButton,
                                      confirmButtonColor:
                                        _SwalDelete.confirmButtonColor,
                                      cancelButtonColor:
                                        _SwalDelete.cancelButtonColor,
                                      confirmButtonText:
                                        _SwalDelete.confirmButtonText,
                                      cancelButtonText:
                                        _SwalDelete.cancelButtonText
                                    }).then((result) => {
                                      if (result.isConfirmed) {
                                        handleDelete(
                                          `UserCatalogue?Id=${data?.id}`,
                                          `UserCatalogue?userId=${data?.userId}&pageIndex=${filterModal?.pageIndex}&pageSize=${filterModal?.pageSize}`
                                        )
                                      } else if (result.isDenied) {
                                      }
                                    })
                                  }}
                                >
                                  <DeleteIcon bg={'bg'} />
                                </span>
                              )}
                            </div>
                          </td>
                        </tr>
                      ))
                    ) : (
                      <tr>
                        <td colSpan={2} className='text-center'>
                          {modalData?.message
                            ? modalData?.message
                            : 'No record found'}
                        </td>
                      </tr>
                    )}
                  </tbody>
                </Table>
              </div>

              <ReactPaginate
                className='list-inline m-cst--pagination d-flex justify-content-end gap-1'
                breakLabel='...'
                nextLabel=''
                onPageChange={(event) => handlePageClick(event, true)}
                pageRangeDisplayed={pageRangeDisplayed}
                pageCount={modalData?.pagination?.pageCount ?? 0}
                previousLabel=''
                renderOnZeroPageCount={null}
                forcePage={filterModal?.pageIndex - 1}
              />
            </Form>
          )}
        </Formik>
      </ModelComponent>

      {/* Seller Gallery Modal */}
      <ModelComponent
        show={modalShowGallery?.show}
        modalsize={'md'}
        modalheaderclass={''}
        modeltitle={'Add Gallery'}
        onHide={() => {
          setModalShowGallery({ show: false, sellerId: null })
          setInitVal()
          setFilterModal((draft) => {
            draft.pageSize = 10
            draft.pageIndex = 1
            draft.searchText = ''
          })
        }}
        btnclosetext={''}
        closebtnvariant={''}
        backdrop={'static'}
      >
        <Formik
          enableReinitialize={true}
          initialValues={initVal}
          validationSchema={validationSchema}
          onSubmit={onSubmitGallery}
        >
          {({ values, setFieldValue }) => (
            <Form id='gallery'>
              <div className='input-file-wrapper w-100 align-items-center mb-3 row'>
                <div className='input-file-wrapper m--cst-filetype mb-3 col-4'>
                  <label className='form-label required' htmlFor='image'>
                    Image
                  </label>
                  <input
                    id='image'
                    className='form-control'
                    name=''
                    type='file'
                    accept='image/jpg, image/png, image/jpeg'
                    onChange={(event) => {
                      const objectUrl = URL.createObjectURL(
                        event.target.files[0]
                      )
                      if (event.target.files[0].type !== '') {
                        setFieldValue('image', objectUrl)
                      }
                      setFieldValue('fileName', event.target.files[0])
                    }}
                    hidden
                  />
                  {values?.fileName ? (
                    <div className='position-relative m--img-preview d-flex rounded-2 overflow-hidden'>
                      <img
                        src={
                          values?.image?.includes('blob')
                            ? values?.image
                            : `${process.env.REACT_APP_IMG_URL}${_categoryImg_}${values?.fileName}`
                        }
                        alt='Preview Category'
                        title={values?.image ? values?.filename?.name : ''}
                        className='rounded-2'
                      ></img>
                      <span
                        onClick={(e) => {
                          setFieldValue('image', null)
                          setFieldValue('fileName', null)
                          document.getElementById('image').value = null
                        }}
                      >
                        <i className='m-icon m-icon--close'></i>
                      </span>
                    </div>
                  ) : (
                    <>
                      <label
                        className='m__image_default d-flex align-items-center justify-content-center rounded-2'
                        htmlFor='image'
                      >
                        <i className='m-icon m-icon--defaultpreview'></i>
                      </label>
                    </>
                  )}
                  <ErrorMessage
                    name='fileName'
                    component={TextError}
                    customclass={'cfz-12 lh-sm'}
                  />
                </div>
                <Button
                  variant='primary'
                  className='col-3 fw-semibold'
                  type='submit'
                >
                  {values?.id ? 'Update' : 'Add'}
                </Button>
              </div>
              <div
                className={`${
                  modalData?.data?.length > 6 ? 'table-responsive' : ''
                }`}
              >
                <Table
                  hover
                  className='align-middle table-list hr_table_seller'
                >
                  <thead>
                    <tr>
                      <th>Image</th>
                      {checkPageAccess(pageAccess, allPages?.manageSeller, [
                        allCrudNames?.update,
                        allCrudNames?.delete
                      ]) && <th className='text-center'>Action</th>}
                    </tr>
                  </thead>
                  <tbody>
                    {modalData?.data?.length > 0 ? (
                      modalData?.data?.map((data) => (
                        <tr key={Math.floor(Math.random() * 1000000)}>
                          <td>
                            <div className='d-flex gap-2 align-items-center'>
                              <Image
                                src={
                                  data?.fileName
                                    ? `${process.env.REACT_APP_IMG_URL}${_categoryImg_}${data?.fileName}`
                                    : 'https://placehold.jp/50x50.png'
                                }
                                className='rounded-1 img-object-fit-con'
                                height='50px'
                                width='50px'
                              />
                              <span>{data?.name}</span>
                            </div>
                          </td>
                          <td className='text-center'>
                            <div className='d-flex gap-2 justify-content-center align-items-center'>
                              <svg
                                onClick={() => {
                                  downloadURI(
                                    data?.fileName,
                                    data?.fileName,
                                    _categoryImg_
                                  )
                                }}
                                role='button'
                                xmlns='http://www.w3.org/2000/svg'
                                width='18'
                                height='22'
                                viewBox='0 0 24.123 28.948'
                              >
                                <path
                                  id='Icon_ionic-md-download'
                                  data-name='Icon ionic-md-download'
                                  d='M30.873,14.715h-6.89V4.5H13.64V14.715H6.75L18.812,26.633ZM6.75,30.041v3.407H30.873V30.041Z'
                                  transform='translate(-6.75 -4.5)'
                                  fill='#999'
                                />
                              </svg>
                              {checkPageAccess(
                                pageAccess,
                                allPages?.manageSeller,
                                allCrudNames?.update
                              ) && (
                                <span
                                  onClick={() => {
                                    setInitVal(data)
                                  }}
                                >
                                  <EditIcon bg={'bg'} />
                                </span>
                              )}

                              {checkPageAccess(
                                pageAccess,
                                allPages?.manageSeller,
                                allCrudNames?.delete
                              ) && (
                                <span
                                  onClick={() => {
                                    Swal.fire({
                                      title: _SwalDelete.title,
                                      text: _SwalDelete.text,
                                      icon: _SwalDelete.icon,
                                      showCancelButton:
                                        _SwalDelete.showCancelButton,
                                      confirmButtonColor:
                                        _SwalDelete.confirmButtonColor,
                                      cancelButtonColor:
                                        _SwalDelete.cancelButtonColor,
                                      confirmButtonText:
                                        _SwalDelete.confirmButtonText,
                                      cancelButtonText:
                                        _SwalDelete.cancelButtonText
                                    }).then((result) => {
                                      if (result.isConfirmed) {
                                        handleDelete(
                                          `UserGallery?Id=${data?.id}`,
                                          `UserGallery?userId=${data?.userId}&pageIndex=${filterModal?.pageIndex}&pageSize=${filterModal?.pageSize}`
                                        )
                                      } else if (result.isDenied) {
                                      }
                                    })
                                  }}
                                >
                                  <DeleteIcon bg={'bg'} />
                                </span>
                              )}
                            </div>
                          </td>
                        </tr>
                      ))
                    ) : (
                      <tr>
                        <td colSpan={2} className='text-center'>
                          {modalData?.message
                            ? modalData?.message
                            : 'No record found'}
                        </td>
                      </tr>
                    )}
                  </tbody>
                </Table>
              </div>

              <ReactPaginate
                className='list-inline m-cst--pagination d-flex justify-content-end gap-1'
                breakLabel='...'
                nextLabel=''
                onPageChange={(event) =>
                  handlePageClick(event, true, false, true)
                }
                pageRangeDisplayed={pageRangeDisplayed}
                pageCount={modalData?.pagination?.pageCount ?? 0}
                previousLabel=''
                renderOnZeroPageCount={null}
                forcePage={filterModal?.pageIndex - 1}
              />
            </Form>
          )}
        </Formik>
      </ModelComponent>
      <div
        className={`${data?.data?.data?.length > 6 ? 'table-responsive' : ''}`}
      >
        <Table className='align-middle table-list hr_table_seller'>
          <thead>
            <tr>
              <th>Seller Name</th>
              <th>Contact Details</th>
              <th>Date</th>
              <th>Seller Status</th>
              {/* <th>KYC Status</th> */}
              <th className='text-center'>More</th>
            </tr>
          </thead>
          <tbody>
            {data?.data?.data?.length > 0 ? (
              data?.data?.data?.map((user) => (
                <tr key={user?.userID}>
                  <td>
                    {user?.fullName}
                    {/* <div>
                      <span
                        style={{ cursor: 'pointer' }}
                        onClick={() => {
                          navigate(
                            `/manage-seller/seller-details/${user?.userID}`
                          )
                        }}
                        className='fw-600'
                      >
                        {user?.displayName ? user?.displayName : user?.fullName}
                      </span>
                      <div className='d-flex align-items-center gap-2 pt-1 pb-1'>
                        <i className='m-icon m-icon--user'></i>
                        {user.tradeName}
                      </div>
                    </div> */}
                  </td>
                  <td>
                    {/* {user.userName} */}
                    <div className='d-flex align-items-center gap-2 pt-1 pb-1'>
                      <i className='m-icon m-icon--msg'></i>
                      {user?.emailID}
                    </div>
                    <div className='d-flex align-items-center gap-2 pt-1 pb-1'>
                      <i className='m-icon m-icon--cell'></i>
                      Phone: {user?.phoneNumber}
                    </div>
                  </td>
                  <td>
                    {user?.createdAt && (
                      <p className='mb-0'>
                        <span className='text-muted'>Created: </span>{' '}
                        {moment(user?.createdAt).format('DD-MM-YYYY')}
                      </p>
                    )}

                    {user?.modifiedDate && (
                      <p className='mb-0'>
                        <span className='text-muted'>Modified: </span>{' '}
                        {moment(user?.modifiedDate).format('DD-MM-YYYY')}
                      </p>
                    )}
                  </td>
                  <td>
                    <HKBadge
                      badgesbgname={
                        user?.sellerStatus?.toLowerCase() === 'active' ||
                        user?.sellerStatus?.toLowerCase() === 'approved'
                          ? 'success'
                          : 'danger'
                      }
                      badgesTxtname={
                        user?.sellerStatus ? user?.sellerStatus : 'Pending'
                      }
                      badgeClassname={'mb-2'}
                    />
                  </td>
                  {/* <td>
                    <HKBadge
                      badgesbgname={
                        user?.status?.toLowerCase() === 'active' ||
                        user?.status?.toLowerCase() === 'approved'
                          ? 'success'
                          : 'danger'
                      }
                      badgesTxtname={user?.status ? user?.status : 'Pending'}
                      badgeClassname={'mb-2'}
                    />
                  </td> */}
                  <td className='text-center'>
                    <DropdownButton
                      align='end'
                      // className="d-none"
                      title={<i className='m-icon m-icon--dots'></i>}
                      id='dropdown-menu-align-end'
                      className='custom_dropdown'
                    >
                      {checkPageAccess(
                        pageAccess,
                        allPages?.manageSeller,
                        allCrudNames?.update
                      ) && (
                        <Dropdown.Item
                          eventKey='1'
                          onClick={async () => {
                            try {
                              setLoading(true)
                              const response = await axiosProvider({
                                method: 'GET',
                                endpoint: `Brand/SellerBrands?SellerID=${user?.userID}`
                              })
                              setLoading(false)
                              if (response?.status === 200) {
                                let basicInfo = response?.data?.data
                                if (!basicInfo) {
                                  basicInfo = {
                                    ...initialValues?.basicInfo,
                                    sellerId: user?.userID
                                  }
                                } else {
                                  basicInfo = basicInfo[0]
                                }
                                setInitialValues({
                                  ...initialValues,
                                  basicInfo
                                })
                                setModalShow((draft) => {
                                  draft.basicInfo = true
                                })
                              }
                            } catch {
                              showToast(toast, setToast, {
                                data: {
                                  message: _exception?.message,
                                  code: 204
                                }
                              })
                            }
                          }}
                        >
                          Manage Brand
                        </Dropdown.Item>
                      )}
                      {/* {checkPageAccess(
                        pageAccess,
                        allPages?.manageSeller,
                        allCrudNames?.update
                      ) && (
                        <Dropdown.Item
                          eventKey='2'
                          onClick={async () => {
                            try {
                              setLoading(true)
                              const response = await axiosProvider({
                                method: 'GET',
                                endpoint: `seller/GSTInfo/byUserId?userId=${user?.userID}`
                              })
                              setLoading(false)
                              if (response?.status === 200) {
                                let apiResponse = response?.data?.data
                                let gstInfo
                                if (Array.isArray(apiResponse)) {
                                  if (apiResponse?.length === 0) {
                                    gstInfo = {
                                      ...initialValues?.gstInfo,
                                      userID: user?.userID
                                    }
                                  } else {
                                    gstInfo = apiResponse
                                  }
                                } else if (
                                  typeof apiResponse === 'object' &&
                                  apiResponse !== null
                                ) {
                                  gstInfo = apiResponse
                                } else {
                                  gstInfo = {
                                    ...initialValues?.gstInfo,
                                    userID: user?.userID
                                  }
                                }
                                setInitialValues({
                                  ...initialValues,
                                  gstInfo
                                })
                                setModalShow((draft) => {
                                  draft.gstInfo = true
                                })
                              }
                            } catch {
                              showToast(toast, setToast, {
                                data: {
                                  message: _exception?.message,
                                  code: 204
                                }
                              })
                            }
                          }}
                        >
                          Manage GST
                        </Dropdown.Item>
                      )}
                      {checkPageAccess(
                        pageAccess,
                        allPages?.manageSeller,
                        allCrudNames?.update
                      ) && (
                        <Dropdown.Item
                          eventKey='3'
                          onClick={async () => {
                            try {
                              setLoading(true)
                              const response = await axiosProvider({
                                method: 'GET',
                                endpoint: `seller/Warehouse/WarehouseSearch?UserID=${user?.userID}`
                              })
                              setLoading(false)
                              if (response?.status === 200) {
                                let apiResponse = response?.data?.data
                                let warehouse
                                if (Array.isArray(apiResponse)) {
                                  if (apiResponse?.length === 0) {
                                    warehouse = {
                                      ...initialValues?.warehouse,
                                      userID: user?.userID
                                    }
                                  } else {
                                    warehouse = apiResponse
                                  }
                                } else if (
                                  typeof apiResponse === 'object' &&
                                  apiResponse !== null
                                ) {
                                  warehouse = apiResponse
                                } else {
                                  warehouse = {
                                    ...initialValues?.warehouse,
                                    userID: user?.userID
                                  }
                                }
                                setInitialValues({
                                  ...initialValues,
                                  warehouse
                                })
                                setModalShow((draft) => {
                                  draft.warehouse = true
                                })
                              }
                            } catch {
                              showToast(toast, setToast, {
                                data: {
                                  message: _exception?.message,
                                  code: 204
                                }
                              })
                            }
                          }}
                        >
                          Manage Warehouse
                        </Dropdown.Item>
                      )} */}
                      <Dropdown.Item
                        eventKey='4'
                        onClick={() => {
                          navigate(
                            `/manage-seller/seller-details/${user?.userID}`
                          )
                        }}
                      >
                        View Seller Details
                      </Dropdown.Item>
                      {checkPageAccess(
                        pageAccess,
                        allPages?.manageSeller,
                        allCrudNames?.update
                      ) && (
                        <Dropdown.Item
                          onClick={async () => {
                            try {
                              setLoading(true)
                              const response = await axiosProvider({
                                method: 'GET',
                                endpoint: `Account/Seller/GetbyId?userId=${user?.userID}`
                              })
                              setLoading(false)
                              if (response?.status === 200) {
                                setInitialValues({
                                  ...initialValues,
                                  createSeller: response?.data?.data
                                })
                                setModalShow((draft) => {
                                  draft.createSeller = true
                                })
                              }
                            } catch {
                              showToast(toast, setToast, {
                                data: {
                                  message: _exception?.message,
                                  code: 204
                                }
                              })
                            }
                          }}
                        >
                          Edit Profile
                        </Dropdown.Item>
                      )}

                      {/* <Dropdown.Item eventKey='6'>
                        Get Feed back sms
                      </Dropdown.Item>
                      <Dropdown.Item eventKey='7'>
                        Send SMS and Email
                      </Dropdown.Item> */}
                      {checkPageAccess(
                        pageAccess,
                        allPages?.manageSeller,
                        allCrudNames?.update
                      ) && (
                        <Dropdown.Item
                          eventKey='8'
                          onClick={async () => {
                            setLoading(true)
                            const data = await axiosProvider({
                              method: 'GET',
                              endpoint: 'UserVideoLinks',
                              queryString: `?userId=${user?.userID}&pageIndex=${filterModal?.pageIndex}&pageSize=${filterModal?.pageSize}`
                            })
                            setLoading(false)
                            setModalData(data?.data)
                            setInitVal({ link: '', title: '' })
                            setModalShowVideoLink({
                              show: true,
                              sellerId: user?.userID
                            })
                          }}
                        >
                          Video Link
                        </Dropdown.Item>
                      )}
                      {checkPageAccess(
                        pageAccess,
                        allPages?.manageSeller,
                        allCrudNames?.update
                      ) && (
                        <Dropdown.Item
                          eventKey='9'
                          onClick={async () => {
                            setLoading(true)
                            const data = await axiosProvider({
                              method: 'GET',
                              endpoint: 'UserCatalogue',
                              queryString: `?userId=${user?.userID}&pageIndex=${filterModal?.pageIndex}&pageSize=${filterModal?.pageSize}`
                            })
                            setLoading(false)
                            setModalData(data?.data)

                            setInitVal({ fileName: '', catalogueName: '' })
                            setModalShowCatalogue({
                              show: true,
                              sellerId: user?.userID
                            })
                          }}
                        >
                          Add Catalogue
                        </Dropdown.Item>
                      )}

                      {/* Seller Gallery */}
                      {checkPageAccess(
                        pageAccess,
                        allPages?.manageSeller,
                        allCrudNames?.update
                      ) && (
                        <Dropdown.Item
                          eventKey='9'
                          onClick={async () => {
                            setLoading(true)
                            const data = await axiosProvider({
                              method: 'GET',
                              endpoint: 'UserGallery',
                              queryString: `?userId=${user?.userID}&pageIndex=${filterModal?.pageIndex}&pageSize=${filterModal?.pageSize}`
                            })
                            setLoading(false)
                            setModalData(data?.data)

                            setInitVal({ fileName: '' })
                            setModalShowGallery({
                              show: true,
                              sellerId: user?.userID
                            })
                          }}
                        >
                          Add Gallery
                        </Dropdown.Item>
                      )}
                      {/* <Dropdown.Item eventKey='10'>Send KYC Link</Dropdown.Item> */}
                      {checkPageAccess(
                        pageAccess,
                        allPages?.manageSeller,
                        allCrudNames?.delete
                      ) && user?.sellerStatus?.toLowerCase() === 'active' ? (
                        <Dropdown.Item
                          eventKey='11'
                          onClick={() => {
                            Swal.fire({
                              title: 'Are you sure you want to Archive?',
                              icon: _SwalDelete.icon,
                              showCancelButton: _SwalDelete.showCancelButton,
                              confirmButtonColor:
                                _SwalDelete.confirmButtonColor,
                              cancelButtonColor: _SwalDelete.cancelButtonColor,
                              confirmButtonText: 'Yes',
                              cancelButtonText: _SwalDelete.cancelButtonText
                            }).then((result) => {
                              if (result.isConfirmed) {
                                handleArchiveAndDelete(user?.userID)
                              } else if (result.isDenied) {
                              }
                            })
                          }}
                        >
                          Archive
                        </Dropdown.Item>
                      ) : (
                        checkPageAccess(
                          pageAccess,
                          allPages?.manageSeller,
                          allCrudNames?.update
                        ) && (
                          <Dropdown.Item
                            eventKey='11'
                            onClick={() => {
                              Swal.fire({
                                title: `Are you sure you want to Active ${
                                  user?.displayName
                                    ? user?.displayName
                                    : user?.fullName
                                }?`,
                                icon: _SwalDelete.icon,
                                showCancelButton: _SwalDelete.showCancelButton,
                                confirmButtonColor:
                                  _SwalDelete.confirmButtonColor,
                                cancelButtonColor:
                                  _SwalDelete.cancelButtonColor,
                                confirmButtonText: 'Yes',
                                cancelButtonText: _SwalDelete.cancelButtonText
                              }).then((result) => {
                                if (result.isConfirmed) {
                                  handleSellerActive({
                                    id: user?.userID,
                                    firstName: user?.firstName,
                                    lastName: user?.lastName,
                                    userName: user?.emailID,
                                    mobileNo: user?.phoneNumber,
                                    profileImage: null,
                                    status: 'Active',
                                    isEmailConfirmed: false,
                                    isPhoneConfirmed: false,
                                    roleTypeId: null,
                                    roleType: null
                                  })
                                } else if (result.isDenied) {
                                }
                              })
                            }}
                          >
                            Active
                          </Dropdown.Item>
                        )
                      )}
                      {checkPageAccess(
                        pageAccess,
                        allPages?.manageSeller,
                        allCrudNames?.delete
                      ) &&
                        !user?.status && (
                          <Dropdown.Item
                            eventKey='12'
                            onClick={() => {
                              Swal.fire({
                                title: _SwalDelete.title,
                                text: _SwalDelete.text,
                                icon: _SwalDelete.icon,
                                showCancelButton: _SwalDelete.showCancelButton,
                                confirmButtonColor:
                                  _SwalDelete.confirmButtonColor,
                                cancelButtonColor:
                                  _SwalDelete.cancelButtonColor,
                                confirmButtonText:
                                  _SwalDelete.confirmButtonText,
                                cancelButtonText: _SwalDelete.cancelButtonText
                              }).then((result) => {
                                if (result.isConfirmed) {
                                  handleArchiveAndDelete(
                                    user?.userID,
                                    'SellerData/delete'
                                  )
                                } else if (result.isDenied) {
                                }
                              })
                            }}
                          >
                            Delete
                          </Dropdown.Item>
                        )}
                    </DropdownButton>
                  </td>
                </tr>
              ))
            ) : (
              <tr>
                <td colSpan={5} className='text-center'>
                  {data?.data?.message}
                </td>
              </tr>
            )}
          </tbody>
        </Table>
      </div>

      <ReactPaginate
        className='list-inline m-cst--pagination d-flex justify-content-end gap-1'
        breakLabel='...'
        nextLabel=''
        onPageChange={handlePageClick}
        pageRangeDisplayed={pageRangeDisplayed}
        pageCount={data?.data?.pagination?.pageCount ?? 0}
        previousLabel=''
        renderOnZeroPageCount={null}
        forcePage={filterDetails?.pageIndex - 1}
      />
      {loading && <Loader />}

      {isModalRequired ? (
        <>
          {modalShow?.createSeller && (
            <CreateSellerModal
              loading={loading}
              setLoading={setLoading}
              initialValues={initialValues}
              setInitialValues={setInitialValues}
              modalShow={modalShow}
              setModalShow={setModalShow}
              isModalRequired={isModalRequired}
              fetchData={fetchData}
              initValues={initValues}
              toast={toast}
              setToast={setToast}
            />
          )}

          {modalShow?.basicInfo && (
            <BasicInfoModal
              loading={loading}
              setLoading={setLoading}
              initialValues={initialValues}
              setInitialValues={setInitialValues}
              modalShow={modalShow}
              setModalShow={setModalShow}
              isModalRequired={isModalRequired}
              fetchData={fetchData}
              initValues={initValues}
              toast={toast}
              setToast={setToast}
            />
          )}

          {/* {modalGSTInfo && (
            <GSTInfo
              loading={loading}
              setLoading={setLoading}
              initialValues={initialValues}
              setInitialValues={setInitialValues}
              modalShow={modalShow}
              setModalShow={setModalShow}
              isModalRequired={isModalRequired}
              fetchData={fetchData}
              initValues={initValues}
              toast={toast}
              setToast={setToast}
            />
          )}

          {modalShow?.warehouse && (
            <WarehouseModal
              loading={loading}
              setLoading={setLoading}
              initialValues={initialValues}
              setInitialValues={setInitialValues}
              modalShow={modalShow}
              setModalShow={setModalShow}
              isModalRequired={isModalRequired}
              fetchData={fetchData}
              initValues={initValues}
              toast={toast}
              setToast={setToast}
            />
          )} */}
        </>
      ) : (
        <>
          {modalShow?.createSeller ||
          modalShow?.basicInfo ||
          modalShow?.gstInfo ||
          modalShow?.warehouse ? (
            <Offcanvas
              className={modalShow?.basicInfo ? '' : 'pv-offcanvas'}
              style={{ width: '40%' }}
              placement='end'
              show={
                modalShow?.createSeller ||
                modalShow?.basicInfo ||
                modalShow?.gstInfo ||
                modalShow?.warehouse
              }
              backdrop='static'
              onHide={() => {
                setInitialValues(initValues)
                setModalShow((draft) => {
                  draft.createSeller = false
                  draft.basicInfo = false
                  draft.gstInfo = false
                  draft.warehouse = false
                })
              }}
            >
              <Offcanvas.Header closeButton>
                <Offcanvas.Title className='bold'>
                  {modalShow?.createSeller
                    ? initialValues?.createSeller?.userID
                      ? 'Update Seller'
                      : 'Create Seller'
                    : modalShow?.basicInfo
                    ? 'Manage Brand'
                    : modalShow?.gstInfo
                    ? 'GST Info'
                    : modalShow?.warehouse
                    ? 'Warehouse'
                    : ''}
                </Offcanvas.Title>
              </Offcanvas.Header>
              <Offcanvas.Body>
                {modalShow?.createSeller && (
                  <CreateSellerModal
                    loading={loading}
                    setLoading={setLoading}
                    initialValues={initialValues}
                    setInitialValues={setInitialValues}
                    modalShow={modalShow}
                    setModalShow={setModalShow}
                    isModalRequired={isModalRequired}
                    fetchData={fetchData}
                    initValues={initValues}
                    toast={toast}
                    setToast={setToast}
                  />
                )}

                {modalShow?.basicInfo && (
                  <BasicInfoModal
                    loading={loading}
                    setLoading={setLoading}
                    initialValues={initialValues}
                    setInitialValues={setInitialValues}
                    modalShow={modalShow}
                    setModalShow={setModalShow}
                    isModalRequired={isModalRequired}
                    fetchData={fetchData}
                    initValues={initValues}
                    toast={toast}
                    setToast={setToast}
                  />
                )}

                {/* {modalGSTInfo && (
                  <GSTInfo
                    loading={loading}
                    setLoading={setLoading}
                    initialValues={initialValues}
                    setInitialValues={setInitialValues}
                    modalShow={modalShow}
                    setModalShow={setModalShow}
                    isModalRequired={isModalRequired}
                    fetchData={fetchData}
                    initValues={initValues}
                    toast={toast}
                    setToast={setToast}
                  />
                )}

                {modalShow?.warehouse && (
                  <WarehouseModal
                    loading={loading}
                    setLoading={setLoading}
                    initialValues={initialValues}
                    setInitialValues={setInitialValues}
                    modalShow={modalShow}
                    setModalShow={setModalShow}
                    isModalRequired={isModalRequired}
                    fetchData={fetchData}
                    initValues={initValues}
                    toast={toast}
                    setToast={setToast}
                  />
                )} */}
              </Offcanvas.Body>
            </Offcanvas>
          ) : (
            <></>
          )}
        </>
      )}

      {toast?.show && (
        <CustomToast text={toast?.text} variation={toast?.variation} />
      )}
    </>
  )
}

export default AddSeller
