import React from 'react'
import { Form } from 'react-bootstrap'

const IpCheckbox = ({
  checkboxid,
  checkboxLabel,
  isDisabled,
  value,
  changeListener,
  checked,
  ...rest
}) => {
  const emitVal = (e) => {
    const val = {
      checked: e.target.checked,
      value: e.target.value
    }
    changeListener(val)
  }

  return (
    <div className='checkbox-wrapper'>
      <Form.Check
        type='checkbox'
        id={checkboxid}
        label={checkboxLabel}
        value={value}
        checked={checked}
        disabled={isDisabled ? true : false}
        onChange={(e) => {
          emitVal(e)
        }}
        {...rest}
      />
    </div>
  )
}

export default IpCheckbox
