import React from 'react'
import { _homePageImg_, _lendingPageImg_ } from '../../lib/ImagePath'
import Slider from 'react-slick'
import Swal from 'sweetalert2'
import { _SwalDelete } from '../../lib/exceptionMessage'
import ImgEditComponet from '../ManageHomePage/ImgEditComponet'

const SingleImage = ({
  renderSmallImage,
  column,
  data,
  type,
  layoutsInfo,
  section,
  handleDelete,
  layoutDetails,
  setLayoutDetails,
  fromLendingPage,
  handleImgDelete
}) => {
  const settings = {
    arrows: false,
    dots: true,
    infinite: true,
    autoplay: true,
    autoplaySpeed: 2000,
    slidesToShow: 1,
    // centerMode: true,
    sidetoscroll: 3
  }

  const minImagesLength = layoutsInfo?.layout_min_images ?? 0
  const maxImagesLength = layoutsInfo?.layout_max_images ?? 0

  const sectionDelete = (id, specificImgDelete = false) => {
    Swal.fire({
      title: _SwalDelete.title,
      text: _SwalDelete.text,
      icon: _SwalDelete.icon,
      showCancelButton: _SwalDelete.showCancelButton,
      confirmButtonColor: _SwalDelete.confirmButtonColor,
      cancelButtonColor: _SwalDelete.cancelButtonColor,
      confirmButtonText: _SwalDelete.confirmButtonText,
      cancelButtonText: _SwalDelete.cancelButtonText
    }).then((result) => {
      if (result.isConfirmed) {
        if (specificImgDelete) {
          let endpoint = fromLendingPage
            ? `LendingPageSectionDetails?id=${id}`
            : `ManageHomePageDetails?id=${id}`
          handleImgDelete(endpoint)
        } else {
          handleDelete(section?.section_id)
        }
      } else if (result.isDenied) {
      }
    })
  }

  const renderComponent = (card, imgPath) => {
    return (
      <div
        key={card?.id ? card?.id : Math.floor(Math.random() * 100000)}
        className='position-relative pv-globle-imgedit-btn'
      >
        {/* <a href='#'> */}
        <img
          style={{ objectFit: 'cover' }}
          className='w-100 h-100'
          src={
            card?.image
              ? `${process.env.REACT_APP_IMG_URL}${
                  fromLendingPage ? _lendingPageImg_ : _homePageImg_
                }${card?.image}`
              : imgPath
          }
        />
        <ImgEditComponet
          sectionDelete={() => {
            sectionDelete(card?.section_details_id, true)
          }}
          sectionEdit={() => {
            let layoutTypeDetailsId = layoutsInfo?.layout_details?.find(
              (obj) =>
                obj?.section_type?.toLowerCase() === type &&
                obj?.layout_type_detail_name?.toLowerCase() === column
            )?.layout_type_detail_id
            setLayoutDetails({
              show: !layoutDetails?.show,
              sectionId: section?.section_id,
              minImagesLength,
              maxImagesLength,
              layoutTypeDetailsId,
              sectionDetailsId: card?.section_details_id
            })
          }}
          showDeleteIcon={imgPath ? false : true}
        />
        {/* </a> */}
      </div>
    )
  }

  return (
    <div>
      {data?.length > 1 ? (
        <div className='position-relative pv-grid-gallary-slider'>
          <Slider {...settings}>
            {data?.map((card) => renderComponent(card))}
          </Slider>
        </div>
      ) : data?.length === 1 ? (
        <div className='d-flex justify-content-center'>
          {renderComponent(data[0])}
        </div>
      ) : (
        <div className='d-flex justify-content-center'>
          {renderComponent(
            null,
            type === 'single' && !renderSmallImage
              ? 'https://placehold.jp/600x600.png'
              : 'https://placehold.jp/600x300.png'
          )}
        </div>
      )}
    </div>
  )
}

export default SingleImage
