import React, { useEffect, useRef } from "react"
import { Button, Image } from 'react-bootstrap'
const ProductDetailsVideo = ({ srcId, title, isOpen, toggleVideo, index }) => {
  const videoRef = useRef()
  useEffect(() => {
    if (isOpen) {
      document.body.style.overflow = "hidden"
    } else {
      document.body.style.overflow = "auto"
    }
    return () => {
      document.body.style.overflow = "auto"
    }
  }, [isOpen])
  const handleIframeClick = (event) => {
    event.stopPropagation()
  }
  return (
    <div className="prdct_videocell" onClick={() => toggleVideo(index, !isOpen)}>
      <div className="prdct_videoposter_img">
        <Image
          src={`https://img.youtube.com/vi/${srcId}/0.jpg`}
          alt="Dimensions"
          height={'100%'}
          width={'100%'}
          quality={100}
          sizes="100vw"
          className="video_posterimg"
        />
        <div className="prdctvideoplayer_icon">
          <i className="m-icon m-icon--videoplayer-icon"></i>
        </div>
      </div>
      <p className="prdct_videotitle_wr">
        <span className="prdct_videotitle">{title}</span>
      </p>
      {isOpen && (
        <div className="prdct_videoiframe" ref={videoRef} onClick={handleIframeClick}>
          {/* <Button
            onClick={() => toggleVideo(index, false)}
          >
            Close </Button> */}
            <button type="button" className="btn-close video_closebtn" aria-label="Close" onClick={() => toggleVideo(index, false)}></button>
          <iframe
            className="pdctvideo_iframeytb"
            src={`https://www.youtube.com/embed/${srcId}`}
            title="YouTube video player"
            frameBorder="0"
            allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
            allowFullScreen
          ></iframe>
        </div>
      )}
    </div>
  )
}
export default ProductDetailsVideo