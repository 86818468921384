import React, { useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { Link } from 'react-router-dom'
import { allCrudNames, allPages, checkPageAccess } from '../../lib/AllPageNames'
import NotFound from '../NotFound/NotFound'
import { setPageTitle } from '../redux/slice/pageTitleSlice'

const Setting = () => {
  const dispatch = useDispatch()

  useEffect(() => {
    dispatch(setPageTitle('Settings'))
  }, [dispatch])

  const { pageAccess, userInfo } = useSelector((state) => state.user)

  return checkPageAccess(pageAccess, allPages?.settings, allCrudNames?.read) ? (
    <div className='d-grid settings_cards_grid'>
      <div className='row'>
        {checkPageAccess(
          pageAccess,
          [
            // allPages?.createProductCollection,
            // allPages?.collectionMappingList,
            // allPages?.manageStaticPage,
            allPages?.homePage
          ],
          allCrudNames?.read
        ) && (
          <div className='col-md-12 mb-3'>
            <h4 className='fw-semibold'>Appearance</h4>
            <div className='row'>
              {/* {checkPageAccess(
                  pageAccess,
                  [
                    allPages?.createProductCollection,
                    allPages?.collectionMappingList
                  ],
                  allCrudNames?.read
                ) && (
                  <div className='col-md-4'>
                    <div className='card mb-3'>
                      <div className='card-body'>
                        <Link
                          to='/settings/setting/manage-collection'
                          className='d-flex align-items-center gap-2'
                        >
                          <i className='m-icon m-icon--manageCollection'></i>
                          <p className='fs-5 mb-0'>Manage Collection</p>
                        </Link>
                      </div>
                    </div>
                  </div>
                )} */}
              {/* {checkPageAccess(
                  pageAccess,
                  allPages?.manageStaticPage,
                  allCrudNames?.read
                ) && (
                  <div className='col-md-4'>
                    <div className='card mb-3'>
                      <div className='card-body'>
                        <Link
                          to='/settings/setting/manage-static-page'
                          className='d-flex align-items-center gap-2'
                        >
                          <i className='m-icon m-icon--manageStaticPage'></i>
                          <p className='fs-5 mb-0'>Manage Static Pages</p>
                        </Link>
                      </div>
                    </div>
                  </div>
                )} */}
              {checkPageAccess(
                pageAccess,
                allPages?.homePage,
                allCrudNames?.read
              ) && (
                <div className='col-md-4'>
                  <div className='card mb-3'>
                    <div className='card-body'>
                      <Link
                        to='/settings/setting/manage-home-page'
                        className='d-flex align-items-center gap-2'
                      >
                        <i className='m-icon m-icon--manageStaticPage'></i>
                        <p className='fs-5 mb-0'>Manage Home Page</p>
                      </Link>
                    </div>
                  </div>
                </div>
              )}
            </div>
          </div>
        )}

        {(checkPageAccess(
          pageAccess,
          [
            allPages?.manageAdmin,
            // allPages?.manageConfig,
            allPages?.manageBimCad
          ],
          allCrudNames?.read
        ) ||
          userInfo?.userType?.toLowerCase() === 'super admin') && (
          <div className='col-md-12 mb-3'>
            <h4 className='fw-semibold'>Management</h4>
            <div className='row gy-3'>
              {checkPageAccess(
                pageAccess,
                allPages?.manageAdmin,
                allCrudNames?.read
              ) && (
                <div className='col-md-4'>
                  <div className='card mb-3'>
                    <div className='card-body'>
                      <Link
                        to='/settings/setting/manage-admin'
                        className='d-flex align-items-center gap-2'
                      >
                        <i className='m-icon m-icon--manageAdmin'></i>
                        <p className='fs-5 mb-0'>Manage Admin</p>
                      </Link>
                    </div>
                  </div>
                </div>
              )}
              {userInfo?.userType?.toLowerCase() === 'super admin' && (
                <div className='col-md-4'>
                  <div className='card mb-3'>
                    <div className='card-body'>
                      <Link
                        to='/settings/setting/manage-roles'
                        className='d-flex align-items-center gap-2'
                      >
                        <i className='m-icon m-icon--manageRoles'></i>
                        <p className='fs-5 mb-0'>Manage Roles</p>
                      </Link>
                    </div>
                  </div>
                </div>
              )}
              {userInfo?.userType?.toLowerCase() === 'super admin' && (
                <div className='col-md-4'>
                  <div className='card mb-3'>
                    <div className='card-body'>
                      <Link
                        to='/settings/setting/assign-page-role'
                        className='d-flex align-items-center gap-2'
                      >
                        <i className='m-icon m-icon--assignpagerole'></i>
                        <p className='fs-5 mb-0'>Assign Page Role</p>
                      </Link>
                    </div>
                  </div>
                </div>
              )}
              {/* {checkPageAccess(
                  pageAccess,
                  allPages?.manageConfig,
                  allCrudNames?.read
                ) && (
                  <div className='col-md-4'>
                    <div className='card mb-3'>
                      <div className='card-body'>
                        <Link
                          to='/settings/setting/manage-config'
                          className='d-flex align-items-center gap-2'
                        >
                          <i className='m-icon m-icon--manageconfig'></i>
                          <p className='fs-5 mb-0'>Manage Config</p>
                        </Link>
                      </div>
                    </div>
                  </div>
                )} */}
              {checkPageAccess(
                pageAccess,
                allPages?.manageBimCad,
                allCrudNames?.read
              ) && (
                <div className='col-md-4'>
                  <div className='card mb-3'>
                    <div className='card-body'>
                      <Link
                        to='/settings/setting/manage-bim-cad'
                        className='d-flex align-items-center gap-2'
                      >
                        <i className='m-icon m-icon--manageconfig'></i>
                        <p className='fs-5 mb-0'>Manage BIM CAD</p>
                      </Link>
                    </div>
                  </div>
                </div>
              )}
            </div>
          </div>
        )}
      </div>
    </div>
  ) : (
    <NotFound />
  )
}

export default Setting
