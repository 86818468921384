import React, { useState } from 'react'
import varified from '../../images/varified.png'
import { Link, useNavigate } from 'react-router-dom'
import logo from '../../images/archioo-logo.png'
import HKbutton from '../../components/HKButton'
import IpTextbox from '../../components/IpTextbox'
import bagl from '../../images/bagl.png'
import * as Yup from 'yup'
import bagr from '../../images/bagr.png'
import location from '../../images/location.png'
import { ErrorMessage, Form, Formik } from 'formik'
import Loader from '../../components/Loader'
import TextError from '../../components/TextError'
import axios from 'axios'
import { getBaseUrl, showToast } from '../../lib/GetBaseUrl'
import CustomToast from '../../components/Toast/CustomToast'
import { _exception } from '../../lib/exceptionMessage'
const ForgotPassword = () => {
  const [loading, setLoading] = useState(false)
  const [toast, setToast] = useState({
    show: false,
    text: null,
    variation: null
  })
  const validationSchema = Yup.object().shape({
    email: Yup.string()
      .email('Invalid email address')
      .required('Email ID Required')
  })

  const config = {
    headers: {
      'Content-Type': 'application/json'
    }
  }

  const navigate = useNavigate()

  const onSubmit = (values, { setFieldValue }) => {
    setLoading(true)
    const response = axios
      .post(`${getBaseUrl()}/Account/admin/ForgotPassword`, values, config)
      .then((res) => {
        if (res?.status === 200) {
          if (res?.data?.code == 200) {
            setFieldValue('resetLink', res?.data?.data?.resetLink)
          } else {
            setFieldValue('resetLink', '')
          }
          showToast(toast, setToast, res)
          setLoading(false)
        }
      })
      .catch((err) => {
        setLoading(false)

        showToast(toast, setToast, {
          data: {
            message: _exception?.message,
            code: 204
          }
        })
      })
  }

  return (
    <div className='main_background'>
      {loading && <Loader />}
      {toast?.show && (
        <CustomToast text={toast?.text} variation={toast?.variation} />
      )}
      <div className='container_custom  pos-relative'>
        <div>
          <img src={bagl} className='left_bag' alt='' />
          <img src={bagr} className='right_bag' alt='' />
          <img src={location} className='location' alt='' />
        </div>
        <div className='card_custom'>
          <div className='row g-0 d-flex align-items-center py-5rem'>
            <div className='col-6'>
              <div className='card-body px-5'>
                <img src={logo} alt='' className='c-logo' />
                <Formik
                  initialValues={{ email: '' }}
                  validationSchema={validationSchema}
                  onSubmit={onSubmit}
                >
                  {({ values, setFieldValue }) => (
                    <Form>
                      <IpTextbox
                        labelClass='required'
                        customClass='he-10'
                        type='email'
                        inputPlaceholder='Enter your Email'
                        labelText="Enter your Email ID and we'll send your password"
                        htmlFor='email'
                        inputId='email'
                        onChange={(e) => {
                          setFieldValue('email', e?.target?.value)
                        }}
                        maxLength={50}
                        onBlur={(e) => {
                          let fieldName = e?.target?.name
                          setFieldValue(fieldName, values[fieldName]?.trim())
                        }}
                      />
                      <ErrorMessage name='email' component={TextError} />
                      <div className='row mb-3'>
                        <div className='col d-flex justify-content-end'>
                          <Link className='c_link' to='/login'>
                            <i className='m-icon m-icon--arrow_back'></i>
                            Back to log in
                          </Link>
                        </div>
                      </div>
                      <HKbutton
                        buttonText='Send Email'
                        customClass='login_btn'
                      />

                      {values?.resetLink && (
                        <div
                          className='text-primary mt-2'
                          style={{ cursor: 'pointer' }}
                          onClick={() => {
                            let tokenAndUID = values?.resetLink
                              ?.split('/')
                              ?.slice(-2)
                            navigate(
                              `/reset/${tokenAndUID[0]}/${tokenAndUID[1]}`
                            )
                          }}
                        >
                          Reset Password Link
                        </div>
                      )}
                    </Form>
                  )}
                </Formik>
              </div>
            </div>
            <div className='col-6'>
              <img src={varified} alt='' className='w-100 custom_cart c_var' />
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}

export default ForgotPassword
