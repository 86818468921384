import React, { useState, useEffect } from 'react'
import { Link } from 'react-router-dom'
import MainSize from './size-attribute/MainSize'
import SpecificationTab from './SpecificationTab'
import { useDispatch, useSelector } from 'react-redux'
import { setPageTitle } from '../../redux/slice/pageTitleSlice'
import MainColor from './color-attribute/MainColor'
import {
  allCrudNames,
  allPages,
  checkPageAccess
} from '../../../lib/AllPageNames'
import NotFound from '../../NotFound/NotFound'

const ManageAttributes = () => {
  const dispatch = useDispatch()
  useEffect(() => {
    dispatch(setPageTitle('Manage Attributes'))
  }, [])

  const { pageAccess } = useSelector((state) => state.user)

  const [activeToggle, setActiveToggle] = useState(
    checkPageAccess(pageAccess, allPages?.size, allCrudNames?.read)
      ? 'sizes'
      : checkPageAccess(pageAccess, allPages?.color, allCrudNames?.read)
      ? 'colors'
      : checkPageAccess(
          pageAccess,
          allPages?.manageSpecifications,
          allCrudNames?.read
        )
      ? 'specification'
      : ''
  )

  return checkPageAccess(
    pageAccess,
    [allPages?.size, allPages?.color, allPages?.manageSpecifications],
    allCrudNames?.read
  ) ? (
    <>
      <div className='d-flex gap-3 align-items-start'>
        <div className='card overflow-hidden position-sticky top-80'>
          <div className='card-body p-0'>
            <div className='nav-tabs-vertical flex-column nav nav-tabs p-2 gap-2'>
              {checkPageAccess(
                pageAccess,
                allPages?.size,
                allCrudNames?.read
              ) && (
                <Link
                  onClick={() => setActiveToggle('sizes')}
                  data-toggle='tab'
                  className={`d-flex flex-column gap-2 align-items-center nav-link ${
                    activeToggle === 'sizes' ? 'active show' : ''
                  }`}
                >
                  <i className='m-icon m-icon--categpry-size'></i>
                  <span className='text-capitalize fs-6 lh-1'>size</span>
                </Link>
              )}

              {checkPageAccess(
                pageAccess,
                allPages?.color,
                allCrudNames?.read
              ) && (
                <Link
                  onClick={() => setActiveToggle('colors')}
                  to='#colors'
                  data-toggle='tab'
                  className={`d-flex flex-column gap-2 align-items-center nav-link ${
                    activeToggle === 'colors' ? 'active show' : ''
                  }`}
                >
                  <i className='m-icon m-icon--categpry-color'></i>
                  <span className='text-capitalize fs-6 lh-1'>Colors</span>
                </Link>
              )}
              {checkPageAccess(
                pageAccess,
                allPages?.manageSpecifications,
                allCrudNames?.read
              ) && (
                <Link
                  onClick={() => setActiveToggle('specification')}
                  to='#specification'
                  data-toggle='tab'
                  className={`d-flex flex-column gap-2 align-items-center nav-link ${
                    activeToggle === 'specification' ? 'active show' : ''
                  }`}
                >
                  <i className='m-icon m-icon--specification'></i>
                  <span className='text-capitalize fs-6 lh-1'>
                    Specification
                  </span>
                </Link>
              )}
            </div>
          </div>
        </div>
        <div className='tab-content flex-fill'>
          <div
            id='sizes'
            className={`tab-pane fade ${
              activeToggle === 'sizes' ? 'active show' : ''
            }`}
          >
            {activeToggle === 'sizes' && <MainSize />}
          </div>
          <div
            id='colors'
            className={`tab-pane fade ${
              activeToggle === 'colors' ? 'active show' : ''
            }`}
          >
            {activeToggle === 'colors' && <MainColor />}
          </div>

          <div
            id='specification'
            className={`tab-pane fade ${
              activeToggle === 'specification' ? 'active show' : ''
            }`}
          >
            {activeToggle === 'specification' && <SpecificationTab />}
          </div>
        </div>
      </div>
    </>
  ) : (
    <NotFound />
  )
}

export default ManageAttributes
