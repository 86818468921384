import { Form, Formik } from 'formik'
import React, { useEffect, useRef, useState } from 'react'
import {
  Breadcrumb,
  Card,
  Col,
  InputGroup,
  Offcanvas,
  Row,
  Tab,
  Table
} from 'react-bootstrap'
import Accordion from 'react-bootstrap/Accordion'
import ModelComponent from '../../components/Modal'
import { removeBrTags } from '../../lib/GetBaseUrl'
import {
  _productBimcad_,
  _productImg_,
  _specificationDocs_
} from '../../lib/ImagePath'
import ProductDetailsVideo from './ProductDetailsVideo'

const ProductDetail = ({
  previewOffcanvasShow,
  setPreviewOffcanvasShow,
  allState,
  setAllState,
  data,
  fetchCalculation,
  prepareDisplayCalculationData
}) => {
  const ref = useRef(null)
  const thumbnailsRef = useRef(null)
  const [show, setShow] = useState(false)
  const [target, setTarget] = useState(null)
  const [isMobile, setIsMobile] = useState(false)
  const [modalSizeShow, setSizeModalShow] = useState(false)
  const [videos, setVideos] = useState(false)

  const [selectedMedia, setSelectedMedia] = useState(
    allState?.preview?.productImage[0]
  )
  const [showScrollButtons, setShowScrollButtons] = useState({
    showTopButton: false,
    showBottomButton: true
  })

  const parseHTMLString = (htmlString) => {
    htmlString = removeBrTags(htmlString)
    const container = document.createElement('div')
    container.innerHTML = htmlString
    const elements = Array.from(container.childNodes)

    return elements.map((node, index) => {
      if (node.nodeType === Node.TEXT_NODE) {
        return node.textContent
      } else {
        const { tagName, attributes } = node
        const props = {}
        for (let i = 0; i < attributes.length; i++) {
          const { name, value } = attributes[i]
          props[name] = value
        }

        const children = parseHTMLString(node.innerHTML)

        return React.createElement(
          tagName.toLowerCase(),
          { key: index, ...props },
          children
        )
      }
    })
  }

  const handleClick = (event) => {
    setShow(!show)
    setTarget(event.target)
  }

  const handleThumbnailHover = (media) => {
    setSelectedMedia(media)
  }

  const downloadURI = async (uri, name, folderName) => {
    let staticLink = `${process.env.REACT_APP_IMG_URL}${folderName}${uri}`

    try {
      const response = await fetch(staticLink)
      const data = await response.blob()

      const url = window.URL.createObjectURL(data)
      const link = document.createElement('a')
      link.href = url
      link.setAttribute('download', name)
      document.body.appendChild(link)
      link.click()
    } catch (error) {}
  }

  useEffect(() => {
    const container = thumbnailsRef.current

    if (!container) {
      return
    }

    const handleScroll = () => {
      setShowScrollButtons({
        showTopButton: container.scrollTop > 0,
        showBottomButton:
          container.scrollTop < container.scrollHeight - container.clientHeight
      })
    }

    handleScroll()
    container.addEventListener('scroll', handleScroll)
    return () => {
      container.removeEventListener('scroll', handleScroll)
    }
  }, [thumbnailsRef])

  const scrollToBottom = () => {
    const container = thumbnailsRef.current
    const scrollTop = container.scrollTop + 200
    container.scrollTo({ top: scrollTop, behavior: 'smooth' })
    setShowScrollButtons({
      showTopButton: true,
      showBottomButton:
        scrollTop < container.scrollHeight - container.clientHeight
    })
  }

  const scrollToTop = () => {
    const container = thumbnailsRef.current
    const scrollTop = container.scrollTop - 200
    container.scrollTo({ top: scrollTop, behavior: 'smooth' })
    setShowScrollButtons({
      showTopButton: scrollTop > 0,
      showBottomButton: true
    })
  }

  return (
    <Formik
      initialValues={{
        ...allState?.preview,
        sellingPrice:
          allState?.preview?.sellerProducts[0]?.productPrices[0]?.sellingPrice,
        mrp: allState?.preview?.sellerProducts[0]?.productPrices[0]?.mrp,
        discount:
          allState?.preview?.sellerProducts[0]?.productPrices[0]?.discount
      }}
    >
      {({ values, setFieldValue }) => (
        <Form>
          <Offcanvas
            className='pv-offcanvas'
            placement='end'
            show={previewOffcanvasShow}
            backdrop={'static'}
            onHide={() => {
              setAllState({
                ...allState,
                preview: null,
                displayCalculation: null
              })
              setPreviewOffcanvasShow(false)
            }}
          >
            <Offcanvas.Header closeButton>
              <Offcanvas.Title className='bold'>
                Product Detail Page
              </Offcanvas.Title>
            </Offcanvas.Header>
            <Offcanvas.Body className='p-4'>
              <ModelComponent
                show={modalSizeShow}
                modalsize={'lg'}
                modeltitle={'Warehouse Quantities'}
                modalheaderclass={''}
                onHide={() => {
                  setSizeModalShow(false)
                }}
                backdrop={'static'}
              >
                {values?.sellerProducts?.length > 0 &&
                  values?.sellerProducts[0]?.productPrices?.map(
                    (productPrice) => (
                      <Row className='gy-2 flex-wrap'>
                        <h5 className='mb-2 head_h3'>
                          {!productPrice?.productWarehouses?.length
                            ? `${productPrice?.sizeName} Size Quantity : ${productPrice?.quantity}`
                            : `${productPrice?.sizeName} Size Warehouse Quantities`}
                        </h5>

                        {productPrice?.productWarehouses?.length > 0 &&
                          productPrice?.productWarehouses?.map((warehouse) => (
                            <Col md={3}>
                              <Card
                                style={{ width: '12rem' }}
                                className='text-center'
                              >
                                <Card.Header>
                                  {warehouse?.warehouseName}
                                </Card.Header>
                                <Card.Body>
                                  <Card.Title className='mb-0'>
                                    Quantity {warehouse?.quantity}
                                  </Card.Title>
                                </Card.Body>
                              </Card>
                            </Col>
                          ))}
                      </Row>
                    )
                  )}
              </ModelComponent>

              <Tab.Container id='left-tabs-example' defaultActiveKey='Summary'>
                <Row className='gy-4'>
                  <Col md={12}>
                    <div
                      className='p-3 bg-white rounded'
                      style={{
                        boxShadow:
                          '0rem 0.0625rem 0.9375rem 0.0625rem rgba(62, 57, 107, 0.07)'
                      }}
                    >
                      <div>
                        <h5 className='bold'>{values?.productName}</h5>
                      </div>
                      <div className='d-flex gap-2'>
                        <span>Categories :</span>
                        <Breadcrumb>
                          {values?.categoryPathName?.split('>')?.map((item) => (
                            <Breadcrumb.Item>{item}</Breadcrumb.Item>
                          ))}
                        </Breadcrumb>
                      </div>
                      <div className='d-flex gap-3'>
                        {/* <HKBadge
                          badgesbgname={
                            data?.status === "Active" ? "success" : "danger"
                          }
                          badgesTxtname={data?.status}
                          badgeClassname={""}
                        /> */}
                        <span>Product Code :- {values?.productId}</span>
                      </div>
                    </div>
                  </Col>
                </Row>
              </Tab.Container>

              <Row className='mt-4 gy-5'>
                <Col md={6}>
                  <div className='product-details'>
                    {!isMobile && (
                      <>
                        <div className='thumbnails-container'>
                          {showScrollButtons.showTopButton && (
                            <button
                              className='thumbs_btn thumbs_top_btn'
                              onClick={scrollToTop}
                            >
                              <i className='m-icon icon-up-arrow'></i>
                            </button>
                          )}
                          <div
                            className='thumbnails'
                            ref={thumbnailsRef}
                            style={{
                              border:
                                values?.productImage?.length > 4
                                  ? '1px solid #ccc'
                                  : 'none'
                            }}
                          >
                            {values?.productImage?.map((media, index) =>
                              media?.type === 'video' ? (
                                <div
                                  key={index}
                                  className='video-thumbnail thumbnail'
                                  onMouseOver={() =>
                                    handleThumbnailHover(media)
                                  }
                                >
                                  <img
                                    src={media?.poster}
                                    alt={media?.alt}
                                    style={{ width: '100%' }}
                                  />
                                  <span className='play-icon'>▶</span>
                                </div>
                              ) : (
                                <div
                                  key={index}
                                  className={`thumbnail ${
                                    selectedMedia?.image === media?.image
                                      ? 'active'
                                      : ''
                                  }`}
                                  onMouseOver={() =>
                                    handleThumbnailHover(media)
                                  }
                                >
                                  <img
                                    src={`${process.env.REACT_APP_IMG_URL}${_productImg_}${media?.image}`}
                                    alt={`Preview ${values?.productName}`}
                                    style={{ width: '100%' }}
                                  />
                                </div>
                              )
                            )}
                          </div>
                          {showScrollButtons.showBottomButton &&
                            values?.productImage.length > 4 && (
                              <button
                                className='thumbs_btn thumbs_btm_btn'
                                onClick={scrollToBottom}
                              >
                                <i className='m-icon icon-down-arrow'></i>
                              </button>
                            )}
                        </div>

                        <div className='main-media'>
                          {selectedMedia?.type === 'video' ? (
                            <div className='video-thumbnail slider-video-thumbnail'>
                              <img
                                src={selectedMedia?.poster}
                                alt={selectedMedia.alt}
                                style={{ width: '100%' }}
                              />
                              <span className='play-icon'>▶</span>
                            </div>
                          ) : (
                            <img
                              src={`${process.env.REACT_APP_IMG_URL}${_productImg_}${selectedMedia?.image}`}
                              alt={`Preview ${values?.productName}`}
                              style={{ width: '100%' }}
                            />
                          )}
                        </div>
                      </>
                    )}
                  </div>
                </Col>

                <Col md={6}>
                  <Card className='product-card-main mb-4'>
                    <Card.Body>
                      {values?.brandName && (
                        <div className='product_brands_wishlist_icon'>
                          <p className='prdct__brands_nm'>
                            {values?.brandName}
                          </p>
                        </div>
                      )}
                      {values?.productName && (
                        <h1 className='product_name'>{values?.productName}</h1>
                      )}

                      {/* <div className="product_pricong_offer_deliverychrg">
                        <span className="total_pricing_product">
                          {currencyIcon}
                          {values?.sellingPrice}
                        </span>
                        <span className='actual_pricing_product'>
                          {currencyIcon}
                          {values?.mrp}
                        </span>
                        <span className='prd-list-offer'>
                          ({values?.discount}% OFF)
                        </span>
                        <div ref={ref}>
                          <Button
                            onClick={handleClick}
                            className='btn-calculation'
                          >
                            Pricing Calculation
                          </Button>

                          <Overlay
                            show={show}
                            target={target}
                            placement='bottom'
                            container={ref}
                            containerPadding={20}
                          >
                            <Popover id='popover-contained'>
                              <Popover.Header as='h3'>
                                Pricing Calculation
                              </Popover.Header>
                              <Popover.Body>
                                {allState?.displayCalculation && (
                                  <PricingCalculation
                                    fromPreview={true}
                                    displayCalculation={
                                      allState?.displayCalculation
                                    }
                                  />
                                )}
                              </Popover.Body>
                            </Popover>
                          </Overlay>
                        </div>
                      </div> */}
                      {/* <p className="prd_include_taxes">
                        Inclusive of all taxes
                      </p> */}
                      {/* <div className="counter-main">
                        <div className="counter-title">
                          <p className="counter-title-quanti">
                            Total Quantity :
                          </p>
                        </div>
                        <div className='counter-btn-cart'>
                          <p className='counter-text'>
                            {values?.sellerProducts[0]?.productPrices?.reduce(
                              (acc, item) => {
                                return acc + (item?.quantity || 0)
                              },
                              0
                            )}
                          </p>
                        </div>
                      </div> */}

                      {values?.sellerProducts[0]?.productPrices?.some(
                        (item) => item?.sizeID
                      ) && (
                        <div className='color-main'>
                          <p className='product-color-title'>Sizes:</p>
                          <div className='product-color d-flex align-items-center gap-2'>
                            {values?.sellerProducts[0]?.productPrices?.map(
                              (size, index) => (
                                <InputGroup
                                  className='custom_checkbox pv-product-size-Main'
                                  key={size?.sizeID}
                                >
                                  <InputGroup.Radio
                                    name='size'
                                    id={`${size?.sizeID}`}
                                    defaultChecked={index === 0}
                                    onClick={(e) => {
                                      setFieldValue('mrp', size?.mrp)
                                      setFieldValue(
                                        'sellingPrice',
                                        size?.sellingPrice
                                      )
                                      setFieldValue('discount', size?.discount)

                                      // fetchCalculation(
                                      //   "Product/DisplayCalculation",
                                      //   prepareDisplayCalculationData({
                                      //     mrp: size?.mrp,
                                      //     sellingPrice: size?.sellingPrice,
                                      //     categoryId: values?.categoryId,
                                      //     brandID: values?.brandID,
                                      //     sellerID:
                                      //       values?.sellerProducts[0]?.sellerID,
                                      //     weightSlabId:
                                      //       values?.sellerProducts[0]
                                      //         ?.weightSlabId,
                                      //     shipmentBy: values?.shipmentBy,
                                      //     shippingPaidBy:
                                      //       values?.shippingPaidBy,
                                      //     marginPercentage:
                                      //       size?.marginPercentage,
                                      //     marginIn: size?.marginIn,
                                      //     marginCost: size?.marginCost,
                                      //   }),
                                      //   (displayCalculation) => {
                                      //     if (
                                      //       displayCalculation?.customerPricing
                                      //     ) {
                                      //       setAllState({
                                      //         ...allState,
                                      //         displayCalculation,
                                      //       });
                                      //     }
                                      //   }
                                      // );
                                    }}
                                  />
                                  <label
                                    className='custom_label'
                                    htmlFor={size?.sizeID}
                                  >
                                    {size?.sizeName}
                                  </label>
                                  {/* <Badge bg="danger">QT {size?.quantity}</Badge> */}
                                </InputGroup>
                              )
                            )}
                          </div>
                        </div>
                      )}

                      {values?.productColorMapping?.length > 0 && (
                        <div className='color-main'>
                          <p className='product-color-title'>Color:</p>
                          <p className='product-color'>
                            {values?.productColorMapping
                              ?.map((item) => item?.colorName)
                              .join(', ')}
                          </p>
                        </div>
                      )}
                      {/* <div className="color-image-show-main">
                        <div className="product-images-color">
                          <img
                            src='https://rukminim2.flixcart.com/image/144/144/k20r8nk0/headphone/7/w/c/realme-buds-2-original-imafhgrckbygsyrk.jpeg?q=50'
                            alt=''
                          />
                        </div>
                        <div className='product-images-color'>
                          <img
                            src='https://rukminim2.flixcart.com/image/144/144/k3g73bk0/headphone/3/b/z/realme-buds-2-original-imafmkr2z5huapjr.jpeg?q=50'
                            alt=''
                          />
                        </div>
                        <div className='product-images-color'>
                          <img
                            src='https://rukminim2.flixcart.com/image/144/144/k3g73bk0/headphone/z/h/x/realme-buds-2-original-imafmkr2rhhjkjqc.jpeg?q=50'
                            alt=''
                          />
                        </div>
                        <div className='product-images-color'>
                          <img
                            src='https://rukminim2.flixcart.com/image/144/144/l4ei1e80/headphone/b/j/w/bullets-wireless-z2-oneplus-original-imagfaww7ga6nshz.jpeg?q=50'
                            alt=''
                          />
                        </div>
                        <div className='product-images-color'>
                          <img
                            src='https://rukminim2.flixcart.com/image/144/144/l0sgyvk0/headphone/m/b/c/buds-z2-oneplus-original-imagcg5stg9fzes6.jpeg?q=50'
                            alt=''
                          />
                        </div>
                      </div> */}
                      {/* <div className="btn-warehouse">
                        <Button
                          className='btn-warehouse-produt'
                          variant='primary'
                          onClick={() => {
                            setSizeModalShow(true)
                          }}
                        >
                          View Warehouse Quantity
                        </Button>
                      </div> */}
                    </Card.Body>
                  </Card>

                  <Accordion defaultActiveKey='0' alwaysOpen className='mt-4'>
                    {(values?.description || values?.highlights) && (
                      <Accordion.Item eventKey='1'>
                        <Accordion.Header>Product Details</Accordion.Header>
                        <Accordion.Body>
                          {values?.description && (
                            <>
                              <h4>Description</h4>
                              <div className='product-details-var fs-5'>
                                {parseHTMLString(values?.description)?.map(
                                  (element, index) => (
                                    <React.Fragment key={index}>
                                      {element}
                                    </React.Fragment>
                                  )
                                )}
                              </div>
                            </>
                          )}
                          {values?.highlights && (
                            <>
                              <h4>Highlight</h4>
                              <div className='product-details-var fs-5'>
                                {parseHTMLString(values?.highlights)?.map(
                                  (element, index) => (
                                    <React.Fragment key={index}>
                                      {element}
                                    </React.Fragment>
                                  )
                                )}
                              </div>
                            </>
                          )}
                        </Accordion.Body>
                      </Accordion.Item>
                    )}

                    {values?.productSpecificationsMapp?.length > 0 &&
                      Array.from(
                        new Set(
                          values?.productSpecificationsMapp?.map(
                            (item) => item?.specId
                          )
                        )
                      )?.map((specId) => {
                        let item = values?.productSpecificationsMapp?.filter(
                          (item) => item?.specId === specId
                        )
                        return (
                          <Accordion.Item
                            eventKey={Math.floor(Math.random() * 100000)}
                          >
                            <Accordion.Header>
                              {item[0]?.specificationName}
                            </Accordion.Header>
                            <Accordion.Body>
                              {item?.map((data) => (
                                <div className='cabl-name'>
                                  <p className='cabl-name-title'>
                                    {data?.specificationTypeName} :
                                  </p>
                                  <div className='cable-title-detail d-flex gap-3'>
                                    {data?.value}{' '}
                                    {data?.fileName && (
                                      <div className='d-flex gap-3 align-items-center justify-content-center'>
                                        <svg
                                          onClick={() => {
                                            downloadURI(
                                              data?.fileName,
                                              data?.value,
                                              _specificationDocs_
                                            )
                                          }}
                                          role='button'
                                          xmlns='http://www.w3.org/2000/svg'
                                          width='14'
                                          height='18'
                                          viewBox='0 0 24.123 28.948'
                                        >
                                          <path
                                            id='Icon_ionic-md-download'
                                            data-name='Icon ionic-md-download'
                                            d='M30.873,14.715h-6.89V4.5H13.64V14.715H6.75L18.812,26.633ZM6.75,30.041v3.407H30.873V30.041Z'
                                            transform='translate(-6.75 -4.5)'
                                            fill='#999'
                                          />
                                        </svg>
                                      </div>
                                    )}
                                  </div>
                                </div>
                              ))}
                            </Accordion.Body>
                          </Accordion.Item>
                        )
                      })}
                    {values?.productBimcad?.length > 0 && (
                      <Accordion.Item
                        eventKey={Math.floor(Math.random() * 100000)}
                      >
                        <Accordion.Header>Bim Cad</Accordion.Header>
                        <Accordion.Body>
                          <Table
                            responsive
                            hover
                            className='align-middle table-list'
                          >
                            <thead className='align-middle'>
                              <tr>
                                <th className='text-nowrap'>Bim cad type</th>
                                <th className='text-nowrap'>Description</th>
                                <th className='text-center'>Action</th>
                              </tr>
                            </thead>
                            <tbody>
                              {values?.productBimcad?.map((data) => (
                                <tr key={Math.floor(Math.random() * 1000000)}>
                                  <td>{data?.bimcadName}</td>
                                  <td>{data?.fileDescription}</td>
                                  <td className='text-center'>
                                    <div className='d-flex gap-3 align-items-center justify-content-center'>
                                      <svg
                                        onClick={() => {
                                          downloadURI(
                                            data?.fileName,
                                            data?.bimcadName,
                                            _productBimcad_
                                          )
                                        }}
                                        role='button'
                                        xmlns='http://www.w3.org/2000/svg'
                                        width='18'
                                        height='22'
                                        viewBox='0 0 24.123 28.948'
                                      >
                                        <path
                                          id='Icon_ionic-md-download'
                                          data-name='Icon ionic-md-download'
                                          d='M30.873,14.715h-6.89V4.5H13.64V14.715H6.75L18.812,26.633ZM6.75,30.041v3.407H30.873V30.041Z'
                                          transform='translate(-6.75 -4.5)'
                                          fill='#999'
                                        />
                                      </svg>
                                    </div>
                                  </td>
                                </tr>
                              ))}
                            </tbody>
                          </Table>
                        </Accordion.Body>
                      </Accordion.Item>
                    )}
                  </Accordion>
                </Col>
                {values?.productVideoLinks?.length > 0 && (
                  <Col md={6}>
                    <Row>
                      {values?.productVideoLinks?.map((item, index) => (
                        <Col md={4}>
                          <ProductDetailsVideo
                            key={index}
                            index={index}
                            isOpen={videos === index}
                            toggleVideo={(index, isOpen) =>
                              setVideos(isOpen ? index : false)
                            }
                            srcId={item?.link}
                            title={item?.title}
                          />
                        </Col>
                      ))}
                    </Row>
                  </Col>
                )}
              </Row>
            </Offcanvas.Body>
          </Offcanvas>
        </Form>
      )}
    </Formik>
  )
}

export default ProductDetail
