import ClassicEditor from '@ckeditor/ckeditor5-build-classic'
import { CKEditor } from '@ckeditor/ckeditor5-react'
import { Form, Formik } from 'formik'
import React, { useEffect, useRef, useState } from 'react'
import { Button, OverlayTrigger, Tooltip } from 'react-bootstrap'
import { useDispatch, useSelector } from 'react-redux'
import { useLocation, useNavigate, useSearchParams } from 'react-router-dom'
import Select from 'react-select'
import { TagsInput } from 'react-tag-input-component'
import Swal from 'sweetalert2'
import { useImmer } from 'use-immer'
import * as Yup from 'yup'
import DeleteIcon from '../../components/AllSvgIcon/DeleteIcon'
import PlusIcon from '../../components/AllSvgIcon/PlusIcon'
import { customStyles } from '../../components/customStyles'
import FormikControl from '../../components/FormikControl'
import Loader from '../../components/Loader'
import CustomToast from '../../components/Toast/CustomToast'
import axiosProvider from '../../lib/AxiosProvider'
import { _SwalDelete } from '../../lib/exceptionMessage'
import {
  arrangeNamesBySequence,
  callApi,
  changeHandler,
  ckEditorConfig,
  isAllowPriceVariant,
  isAllowWarehouseManagement,
  prepareNotificationData,
  prepareProductName,
  productStatus
} from '../../lib/GetBaseUrl'
import { _specificationDocs_, _tempImg_ } from '../../lib/ImagePath'
import { setPageTitle } from '../redux/slice/pageTitleSlice'
import ImagesAndLink from './ImagesAndLink'
import NotWarehouseAndPrice from './NotWarehouseAndPrice'
import NotWarehousePrice from './NotWarehousePrice'
import ProductBimCad from './ProductBimCad'
import WarehouseAndPrice from './WarehouseAndPrice'
import WarehouseNotPrice from './WarehouseNotPrice'

const AddProduct = () => {
  const updateProductSpecificationMapp = (
    productSpecificationMapp,
    items,
    specId,
    specTypeId,
    fileName = null
  ) => {
    if (!fileName)
      productSpecificationMapp = productSpecificationMapp.filter(
        (spec) => spec.specTypeId !== specTypeId
      )

    if (typeof items === 'object' && !Array.isArray(items)) {
      const item = items
      productSpecificationMapp = [
        ...productSpecificationMapp,
        { specId, specTypeId, specValueId: item.value, value: item.label }
      ]
    } else if (Array.isArray(items)) {
      items.forEach((item) => {
        productSpecificationMapp = [
          ...productSpecificationMapp,
          {
            specId,
            specTypeId,
            specValueId: item.value,
            value: item.label
          }
        ]
      })
    } else if (typeof items === 'string') {
      if (items.trim() === '') {
        if (fileName) {
          productSpecificationMapp = productSpecificationMapp.filter(
            (spec) => spec.fileName !== fileName
          )
        } else {
          productSpecificationMapp = productSpecificationMapp.filter(
            (spec) => spec.specTypeId !== specTypeId
          )
        }
      } else {
        productSpecificationMapp = [
          ...productSpecificationMapp,
          {
            specId,
            specTypeId,
            specValueId: null,
            value: items,
            fileName
          }
        ]
      }
    }

    return productSpecificationMapp
  }

  function sortSecondArrayByFirstOrder(firstArray, secondArray) {
    // Create a map of specTypeId to its index
    const specTypeIndexMap = new Map()
    firstArray[0].types.forEach((type, index) => {
      specTypeIndexMap.set(type.specTypeId, index)
    })

    // Track the indices for each specTypeId in secondArray
    const specTypeIndices = new Map()
    secondArray.forEach((item, index) => {
      const specTypeId = item.specTypeId
      if (!specTypeIndices.has(specTypeId)) {
        specTypeIndices.set(specTypeId, [])
      }
      specTypeIndices.get(specTypeId).push(index)
    })

    // Clone the secondArray to avoid modifying the original
    const clonedArray = secondArray.slice(0)

    // Sort clonedArray based on the order of specTypeIds in firstArray
    clonedArray.sort((a, b) => {
      const indexA = specTypeIndexMap.get(a.specTypeId)
      const indexB = specTypeIndexMap.get(b.specTypeId)

      if (indexA !== indexB) {
        return indexA - indexB
      } else {
        // If same index, sort by the order they appeared in secondArray
        const aIndices = specTypeIndices.get(a.specTypeId)
        const bIndices = specTypeIndices.get(b.specTypeId)
        return aIndices[0] - bIndices[0]
      }
    })

    return clonedArray
  }

  const [allState, setAllState] = useImmer({
    sellerDetails: [],
    brand: [],
    category: [],
    color: [],
    sizeType: [],
    warehouseDetails: [],
    hsnCode: [],
    weight: []
  })
  const [loading, setLoading] = useState(false)
  const [calculation, setCalculation] = useState()
  const [scrollPosition, setScrollPosition] = useState(true)
  const navigate = useNavigate()
  const [searchParams] = useSearchParams()
  const id = searchParams.get('id')
  const sellerId = searchParams.get('sellerId')
  const lastScrollPosition = useRef(0)
  const initialValues = {
    sellerID: null,
    isMasterProduct: true,
    brandName: '',
    categoryName: '',
    live: true,
    productPrices: [],
    productColorMapping: [],
    productVideoLinks: [],
    productImage: [],
    productName: [],
    priceVariant: false,
    mrp: '',
    sellingPrice: '',
    discount: '',
    customeProductName: '',
    sellerSKU: '',
    companySKUCode: '',
    validation: {},
    productVariant: [],
    value: '',
    fileName: '',
    productBimcad: [],
    bimcadId: null,
    bimcadDescription: '',
    categoryPathName: '',
    keywords: [],
    perWarehouseStock: '',
    packingLength: '',
    packingBreadth: '',
    packingHeight: '',
    weightSlabName: '',
    weightSlabId: '',
    packingWeight: '',
    status: ''
  }
  const [initialValuesEdit, setInitialValuesEdit] = useState(initialValues)
  const [toast, setToast] = useState({
    show: false,
    text: null,
    variation: null
  })
  const { userInfo } = useSelector((state) => state?.user)
  const location = useLocation()

  const dispatch = useDispatch()
  useEffect(() => {
    dispatch(setPageTitle(id ? 'Edit Product' : 'Add Product'))
  }, [])

  // For calling Multiple Apis
  const fetchAllGenericData = async (apiUrls) => {
    try {
      const responseArray = await Promise.all(
        apiUrls.map((url) => callApi(url.endpoint, url.queryString, url.state))
      )

      return responseArray
    } catch (error) {}
  }

  useEffect(() => {
    if (id) {
      const fetchData = async () => {
        try {
          setLoading(true)
          let apiUrls = []
          let productEditData = await callApi(
            'Product/GetProductDetailsWithSellerId',
            `?ProductId=${id}&sellerId=${sellerId}`
          )

          const assignSpecificationData = await callApi(
            'AssignSpecificationToCategory/getByCatId',
            `?catId=${productEditData?.categoryId}`
          )

          if (assignSpecificationData?.isAllowColorsInTitle) {
            if (assignSpecificationData?.titleSequenceOfColor) {
              productEditData = {
                ...productEditData,
                titleSequenceOfColor:
                  assignSpecificationData?.titleSequenceOfColor,
                isAllowColorsInTitle:
                  assignSpecificationData?.isAllowColorsInTitle
              }
            }
          }
          if (assignSpecificationData?.isAllowColors) {
            apiUrls.push({
              endpoint: 'color',
              queryString: '?pageindex=0&PageSize=0',
              state: 'color'
            })
          }
          if (assignSpecificationData?.isAllowSpecifications) {
            apiUrls.push({
              endpoint: 'AssignSpecValuesToCategory/GetSpecsList',
              queryString: `?AssignSpecId=${assignSpecificationData?.id}`,
              state: 'specificationData'
            })
          }
          if (assignSpecificationData?.isAllowSize) {
            apiUrls.push({
              endpoint: 'AssignSizeValuesToCategory/byAssignSpecId',
              queryString: `?assignSpecId=${assignSpecificationData?.id}&pageindex=0&PageSize=0`,
              state: 'sizeType'
            })
          }
          if (
            typeof assignSpecificationData?.isAllowPriceVariant === 'boolean'
          ) {
            productEditData = {
              ...productEditData,
              isAllowPriceVariant: assignSpecificationData?.isAllowPriceVariant
            }
          }
          if (isAllowWarehouseManagement) {
            productEditData?.sellerProducts?.sellerID &&
              apiUrls.push({
                endpoint: 'seller/Warehouse/WarehouseSearch',
                queryString: `?UserID=${productEditData?.sellerProducts?.sellerID}&status=Active`,
                state: 'warehouseDetails'
              })
          }

          // apiUrls.push(
          //   {
          //     endpoint: 'AssignTaxRateToHSNCode',
          //     queryString: `?pageIndex=0&pageSize=0`,
          //     state: 'hsnCode'
          //   },
          //   {
          //     endpoint: 'WeightSlab',
          //     queryString: `?pageIndex=0&pageSize=0`,
          //     state: 'weight'
          //   }
          // )

          apiUrls.push({
            endpoint: 'BimcadLibrary',
            queryString: '?pageSize=0&pageIndex=0&isDeleted=false',
            state: 'bimcad'
          })

          const fetchData = async () => {
            let sizeType,
              warehouseDetails,
              color,
              // hsnCode,
              // weight,
              specificationData,
              bimcad

            let productName = []
            const responses = await fetchAllGenericData(apiUrls)
            responses.forEach((response) => {
              switch (response.state) {
                case 'sizeType':
                  sizeType = response.data
                  if (sizeType[0]?.isAllowSizeInTitle) {
                    productEditData = {
                      ...productEditData,
                      isAllowSizeInTitle: sizeType[0]?.isAllowSizeInTitle,
                      titleSequenceOfSize: sizeType[0]?.titleSequenceOfSize
                    }
                  }
                  break

                case 'specificationData':
                  specificationData = response.data
                  const matchingSpecs =
                    productEditData?.productSpecificationsMapp?.filter(
                      (item) => {
                        return specificationData?.some(
                          (spec) => spec?.specId === item?.specId
                        )
                      }
                    )

                  matchingSpecs?.forEach((matchingSpec) => {
                    for (const specification of specificationData) {
                      if (specification?.specId === matchingSpec?.specId) {
                        const types = specification?.types

                        for (const type of types) {
                          if (type?.specTypeId === matchingSpec?.specTypeId) {
                            const values = type?.values

                            const hasTitleSpec = values?.some(
                              (value) => value?.isAllowSpecInTitle
                            )

                            if (hasTitleSpec) {
                              const specificationValues = values?.find(
                                (value) => value?.isAllowSpecInTitle
                              )
                              productName = prepareProductName(
                                specificationValues?.name,
                                specificationValues?.titleSequenceOfSpecification,
                                type?.name,
                                { productName },
                                true
                              )
                            }
                          }
                        }
                      }
                    }
                  })
                  break

                case 'warehouseDetails':
                  warehouseDetails = response?.data
                  break

                case 'color':
                  color = response?.data
                  break

                // case 'hsnCode':
                //   hsnCode = response?.data
                //   break

                // case 'weight':
                //   weight = response?.data
                //   break

                case 'bimcad':
                  bimcad = response?.data
                  break

                default:
                  break
              }
            })
            setLoading(false)
            setAllState((draft) => {
              draft.sizeType = sizeType
              draft.warehouseDetails = warehouseDetails
              draft.color = color
              // draft.weight = weight
              // draft.hsnCode = hsnCode
              draft.specificationData = specificationData
              draft.editData = productEditData
              draft.bimcad = bimcad
            })

            prepareEditData(
              { ...productEditData, productName },
              sizeType,
              warehouseDetails
            )
          }
          fetchData()
        } catch (error) {
          setLoading(false)
        }
      }
      fetchData()
    } else {
      const apiUrls = [
        {
          endpoint: 'SellerData/bindActiveSeller',
          queryString: '?pageindex=0&PageSize=0'
        },
        { endpoint: 'MainCategory/getEndCategory', queryString: '' },
        // {
        //   endpoint: 'AssignTaxRateToHSNCode',
        //   queryString: '?pageindex=0&PageSize=0'
        // },
        // { endpoint: 'HSNCode', queryString: '?pageindex=0&PageSize=0' },
        // { endpoint: 'TaxTypeValue', queryString: '?pageindex=0&PageSize=0' },
        // { endpoint: 'WeightSlab', queryString: '?pageindex=0&PageSize=0' },
        {
          endpoint: 'BimcadLibrary',
          queryString: '?pageindex=0&PageSize=0&isDeleted=false'
        }
      ]
      const fetchData = async () => {
        setLoading(true)
        const [sellerDetails, category, bimcad] = await fetchAllGenericData(
          apiUrls
        )
        setAllState((draft) => {
          draft.sellerDetails = sellerDetails
          draft.category = category
          // draft.hsnCode = hsnCode
          // draft.weight = weight
          draft.bimcad = bimcad
        })
        setLoading(false)
      }

      // fetchCalculation(
      //   'Product/DisplayCalculation',
      //   { mrp: '0', sellingPrice: '0' },
      //   (data) => {
      //     setCalculation({ ...calculation, displayCalculation: data })
      //   }
      // )

      fetchData()
    }
  }, [])

  useEffect(() => {
    function handleScroll() {
      const currentScrollPosition =
        window.pageYOffset || document.documentElement.scrollTop

      if (currentScrollPosition > 100) {
        if (currentScrollPosition > lastScrollPosition.current) {
          setScrollPosition(false)
        } else {
          setScrollPosition(true)
        }
      } else {
        setScrollPosition(false)
      }

      lastScrollPosition.current = currentScrollPosition
    }

    window.addEventListener('scroll', handleScroll)

    return () => {
      window.removeEventListener('scroll', handleScroll)
    }
  }, [])

  const uploadFile = async (
    values,
    sequence,
    fileObj,
    setFieldValue,
    fromImagesAndLink = true
  ) => {
    try {
      const dataofForm = {
        Image: fileObj
      }

      const submitFormData = new FormData()

      const keys = Object.keys(dataofForm)

      keys.forEach((key) => {
        submitFormData.append(key, dataofForm[key])
      })

      const res = await axiosProvider({
        method: 'POST',
        endpoint: `Product/ProductTempImage?productName=${arrangeNamesBySequence(
          values?.productName ?? []
        )}&sequence=${sequence}`,
        data: submitFormData
      })

      if (res?.status === 200) {
        if (fromImagesAndLink) {
          let productImage = values?.productImage?.length
            ? values.productImage
            : []

          const objectUrl = URL.createObjectURL(fileObj)

          if (sequence === 1 && !productImage.length) {
            productImage.push({
              sequence: 1,
              image: res?.data,
              imageUrl: objectUrl
            })
          }

          if (sequence > 1 && res?.data && Array.isArray(productImage)) {
            let newItems = {
              sequence,
              image: res?.data,
              imageUrl: objectUrl
            }
            productImage = [...productImage, newItems]
          }

          setFieldValue('productImage', productImage)
        } else {
          return res?.data
        }
      }
    } catch (err) {}
  }

  const uploadBimcad = async (values, fileObj, setFieldValue) => {
    const dataofForm = {
      Image: fileObj
    }

    const submitFormData = new FormData()

    const keys = Object.keys(dataofForm)

    keys.forEach((key) => {
      submitFormData.append(key, dataofForm[key])
    })

    let name = `${values?.fileDescription}_${values?.brandName ?? ''}_${
      values?.sellerName ?? ''
    }`

    try {
      const response = await axiosProvider({
        method: 'POST',
        endpoint: `Product/ProductTempImage?productName=${name}&sequence=1`,
        data: submitFormData
      })

      if (response?.status === 200) {
        return response?.data
      }
    } catch (error) {
      console.error('Error uploading bimcad:', error)
    }

    return null
  }

  const fetchCalculation = async (endpoint, data, setterFunc) => {
    // setLoading(true)
    const response = await axiosProvider({
      method: 'POST',
      endpoint,
      data
    })
      .then((res) => {
        // setLoading(false)
        if (res.status === 200) {
          return setterFunc(res?.data)
        }
      })
      .catch((err) => {
        // setLoading(false)
      })
  }

  const checkAllRequiredField = (values, setFieldValue) => {
    // if (!values?.companySKUCode) {
    //   setFieldValue('finalValidation', 'Product SKU Required')
    //   return false
    // }
    if (!values?.status) {
      setFieldValue('finalValidation', 'Product Status Required')
      return false
    }
    // if (!values?.sellerSKU) {
    //   setFieldValue('finalValidation', 'Seller SKU Required')
    //   return false
    // }
    if (!values?.customeProductName) {
      setFieldValue('finalValidation', 'Product Status custom product name')
      return false
    }
    if (!values?.sellerID) {
      setFieldValue('finalValidation', 'Please Select Seller')
      return false
    }
    if (!values?.brandID) {
      setFieldValue('finalValidation', 'Please Select Brand')
      return false
    }
    if (!values?.categoryId) {
      setFieldValue('finalValidation', 'Please Select Category')
      return false
    }
    if (!values?.description) {
      setFieldValue('finalValidation', 'Please Enter Description')
      return false
    }
    // if (!values?.hsnCodeId) {
    //   setFieldValue('finalValidation', 'Please Select HSN Code')
    //   return false
    // }
    // if (!values?.taxValueId) {
    //   setFieldValue('finalValidation', 'Please Select Tax Rate')
    //   return false
    // }
    if (allState?.color?.length) {
      if (!values?.productColorMapping?.length) {
        setFieldValue('finalValidation', 'Please Select Color')
        return false
      }
    }
    if (allState?.sizeType?.length) {
      if (!values?.tempSizeId) {
        setFieldValue('finalValidation', 'Please Select Size Type')
        return false
      }
    }
    // if (!values?.productPrices?.length) {
    //   setFieldValue('finalValidation', 'Please Select atleast one size value')
    //   return false
    // }

    if (!values?.productPrices?.filter((item) => item?.quantity > 0)?.length) {
      setFieldValue('finalValidation', 'Please add atleast one value')
      return false
    }
    // if (!values?.mrp && !values?.isSizeWisePriceVariant) {
    //   setFieldValue('finalValidation', 'MRP Required')
    //   return false
    // }
    // if (!values?.sellingPrice && !values?.isSizeWisePriceVariant) {
    //   setFieldValue('finalValidation', 'Selling Price Required')
    //   return false
    // }
    // if (!values?.discount && !values?.isSizeWisePriceVariant) {
    //   setFieldValue('finalValidation', 'Discount Required')
    //   return false
    // }
    // if (!values?.weightSlabId) {
    //   setFieldValue('finalValidation', 'Please Select Weight Slab')
    //   return false
    // }

    if (!values?.productImage?.length) {
      setFieldValue('finalValidation', 'Please add atleast one image')
      return false
    }
    return true
  }

  const handleSubmit = async (values, setFieldValue) => {
    setFieldValue('validation', { ...values?.validation, file: '' })
    if (checkAllRequiredField(values, setFieldValue)) {
      setFieldValue('finalValidation', '')
      let val = {
        ...values,
        // productLength: 0,
        // productBreadth: 0,
        // productHeight: 0,
        // productWeight: 0,
        productColorMapping: [values?.productColorMapping[0]],
        productSpecificationsMapp: values?.productSpecificationsMapp
          ? values?.productSpecificationsMapp
          : [],
        productName: arrangeNamesBySequence(values?.productName ?? [])
      }
      if (val?.productPrices?.length) {
        let sellerProductList = {
          sellerID: values?.sellerID,
          sellerSKU: values?.sellerSKU,
          isExistingProduct: false,
          live: values?.live,
          brandID: values?.brandID,
          status: values?.status,
          // packingHeight: values?.packingHeight ?? 0,
          // packingLength: values?.packingLength ?? 0,
          // packingBreadth: values?.packingBreadth ?? 0,
          // packingWeight: values?.packingWeight ?? 0,
          // weightSlabId: values?.weightSlabId,
          isSizeWisePriceVariant: values?.isSizeWisePriceVariant
        }
        if (values?.productId) {
          sellerProductList = {
            ...sellerProductList,
            id: values?.sellerProducts?.id
          }
        }
        sellerProductList = {
          ...sellerProductList,
          productPrices: val?.productPrices.filter(
            (data) => data.quantity > 0 && data?.mrp
          )
        }
        val = {
          ...val,
          sellerProducts: sellerProductList
        }
      }
      val = { ...val, keywords: values?.keywords?.toString() }

      if (val?.productSpecificationsMapp?.length > 0) {
        val = {
          ...val,
          productSpecificationsMapp: sortSecondArrayByFirstOrder(
            allState?.specificationData,
            val?.productSpecificationsMapp
          )
        }
      }

      delete val?.productPrices
      let method = ''
      if (val?.productId) {
        method = 'PUT'
      } else {
        method = 'POST'
      }

      setLoading(true)
      const response = await axiosProvider({
        method,
        endpoint: `Product/Product`,
        data: val,
        oldData: allState?.editData,
        location: location?.pathname,
        userId: userInfo?.userId
      })
        .then((res) => {
          if (res?.status === 200) {
            const sendNotification = axiosProvider({
              endpoint: 'Notification',
              method: 'POST',
              data: prepareNotificationData({
                imageUrl: val?.productImage[0]?.image,
                reciverId: val?.sellerID,
                userId: userInfo?.userId,
                userType: userInfo?.userType,
                notificationTitle: `Product name: ${val?.productName} ${
                  method === 'PUT'
                    ? 'updated successfully'
                    : 'added successfully'
                }`,
                notificationDescription: `${
                  method === 'PUT' ? 'Product added by:' : 'Product updated by:'
                } ${userInfo?.fullName}`,
                url: `/manage-product?id=${
                  values?.productId ? values?.productId : res?.data?.data
                }`,
                notifcationsof: 'Product'
              })
            })
            setToast({
              show: true,
              text: res?.data?.message,
              variation: res?.data?.code !== 200 ? 'error' : 'success'
            })

            setTimeout(() => {
              navigate('/manage-product')
              setToast({ ...toast, show: false })
            }, 2000)
          }
          setLoading(false)
        })
        .catch((err) => {
          setLoading(false)
        })
    }
  }

  const downloadURI = async (uri, name, folderName) => {
    let staticLink = `${process.env.REACT_APP_IMG_URL}${folderName}${uri}`

    try {
      const response = await fetch(staticLink)
      const data = await response.blob()

      const url = window.URL.createObjectURL(data)
      const link = document.createElement('a')
      link.href = url
      link.setAttribute('download', name)
      document.body.appendChild(link)
      link.click()
    } catch (error) {}
  }

  const prepareEditData = (productEditData, sizeType, warehouseDetails) => {
    let tempData = {
      ...productEditData,
      isExistingProduct: false,
      keywords: productEditData?.keywords
        ? productEditData?.keywords?.split(',')
        : [],
      live: productEditData?.sellerProducts?.live,
      packingWeight: productEditData?.sellerProducts?.packingWeight,
      packingLength: productEditData?.sellerProducts?.packingLength,
      packingHeight: productEditData?.sellerProducts?.packingHeight,
      packingBreadth: productEditData?.sellerProducts?.packingBreadth,
      discount: productEditData?.sellerProducts?.productPrices[0]?.discount,
      sellingPrice:
        productEditData?.sellerProducts?.productPrices[0]?.sellingPrice,
      mrp: productEditData?.sellerProducts?.productPrices[0]?.mrp,
      sellerSKU: productEditData?.sellerProducts?.sellerSKU,
      sellerID: productEditData?.sellerProducts?.sellerID,
      status: productEditData?.sellerProducts?.status,
      weightSlabId: productEditData?.sellerProducts?.weightSlabId,
      sellerName: productEditData?.sellerProducts?.sellerName,
      brandName: productEditData?.sellerProducts?.brandName,
      tempColorId: productEditData?.productColorMapping[0]?.colorId,
      productVariant: [],
      isSizeWisePriceVariant:
        productEditData?.sellerProducts?.isSizeWisePriceVariant
    }

    if (tempData?.productSpecificationsMapp) {
      let productSpecification = tempData?.productSpecificationsMapp
      productSpecification = productSpecification?.map((data) => {
        tempData = { ...tempData, [data?.specificationTypeName]: data?.value }
      })
    }

    let sizeTypeID
    let productPricesTemp = []

    const tempSizeType = sizeType
    let productName = Array.isArray(tempData?.productName)
      ? tempData?.productName
      : []

    if (productEditData?.sellerProducts?.brandName) {
      productName = prepareProductName(
        productEditData?.sellerProducts?.brandName,
        -1,
        'brand',
        { productName },
        true
      )
    }

    if (productEditData?.categoryName) {
      productName = prepareProductName(
        productEditData?.categoryName,
        -1,
        'category',
        { productName },
        true
      )
    }

    if (productEditData?.isAllowColorsInTitle) {
      productName = prepareProductName(
        productEditData?.productColorMapping[0]?.colorName,
        productEditData?.titleSequenceOfColor,
        'color',
        { productName },
        true
      )
    }

    if (tempSizeType?.length > 0) {
      if (tempData?.sellerProducts?.productPrices?.length > 0) {
        sizeTypeID = tempSizeType?.find(
          (obj) =>
            obj.sizeId === tempData?.sellerProducts?.productPrices[0]?.sizeID
        )?.sizeTypeID

        let getAllSizeValues = tempSizeType?.filter(
          (obj) => obj.sizeTypeID === sizeTypeID
        )
        productPricesTemp = getAllSizeValues?.map((obj1) => {
          let productWarehouse = []
          const obj2 = tempData?.sellerProducts?.productPrices?.find(
            (obj2) => obj2?.sizeID === obj1?.sizeId
          )

          if (obj2) {
            if (warehouseDetails) {
              productWarehouse = warehouseDetails?.map((warehouseData) => {
                let productPriceWarehouseData = obj2?.productWarehouses?.find(
                  (data) => data?.warehouseId === warehouseData?.id
                )

                return productPriceWarehouseData
                  ? productPriceWarehouseData
                  : {
                      warehouseId: warehouseData?.id,
                      warehouseName: warehouseData?.name,
                      quantity: 0,
                      sizeID: obj2?.sizeID
                    }
              })
              return {
                ...obj2,
                productWarehouses: productWarehouse,
                isCheckedForQty: tempData?.sellerProducts
                  ?.isSizeWisePriceVariant
                  ? false
                  : true,
                manageWarehouseStock: true,
                isDataInTable: tempData?.sellerProducts?.isSizeWisePriceVariant
                  ? true
                  : false
              }
            } else {
              return {
                ...obj2,
                isCheckedForQty: tempData?.sellerProducts
                  ?.isSizeWisePriceVariant
                  ? false
                  : true,
                isDataInTable: tempData?.sellerProducts?.isSizeWisePriceVariant
                  ? true
                  : false
              }
            }
          } else {
            return {
              mrp: '',
              discount: '',
              sellingPrice: '',
              productWarehouses: productWarehouse,
              sizeID: obj1?.sizeId,
              sizeName: obj1?.sizeName,
              sellerProductId: tempData?.sellerProducts?.id
            }
          }
        })
      } else {
        productPricesTemp = tempSizeType?.map((data) => {
          return {
            mrp: '',
            sellingPrice: '',
            quantity: '',
            productWarehouse: '',
            sizeID: data?.sizeId,
            sizeName: data?.sizeName,
            isCheckedForQty: false,
            isDataInTable: false
          }
        })
      }
      if (tempData?.sellerProducts?.productPrices?.length === 1) {
        if (productEditData?.isAllowSizeInTitle) {
          productName = prepareProductName(
            productEditData?.sellerProducts?.productPrices[0]?.sizeName,
            productEditData?.titleSequenceOfSize,
            'size',
            { productName },
            true
          )
        }
      }
    } else {
      let productPrices = tempData?.sellerProducts?.productPrices[0]
      if (warehouseDetails) {
        let productWarehouse = warehouseDetails?.map((warehouseData) => {
          let productPriceWarehouseData =
            productPrices?.productWarehouses?.find(
              (data) => data?.warehouseId === warehouseData?.id
            )
          return productPriceWarehouseData
            ? productPriceWarehouseData
            : {
                warehouseId: warehouseData?.id,
                warehouseName: warehouseData?.name,
                quantity: 0
              }
        })
        productPrices = {
          ...productPrices,
          productWarehouses: productWarehouse,
          isCheckedForQty: true,
          manageWarehouseStock: true
        }
        productPricesTemp.push(productPrices)
      } else {
        productPricesTemp.push(productPrices)
      }
    }
    tempData = {
      ...tempData,
      productPrices: productPricesTemp,
      tempSizeId: sizeTypeID,
      productName
    }

    // if (tempData?.mrp && tempData?.sellingPrice) {
    //   fetchCalculation(
    //     'Product/DisplayCalculation',
    //     {
    //       mrp: tempData?.isSizeWisePriceVariant ? '0' : tempData?.mrp,
    //       sellingPrice: tempData?.isSizeWisePriceVariant
    //         ? '0'
    //         : tempData?.sellingPrice
    //     },
    //     (data) => {
    //       setCalculation({ ...calculation, displayCalculation: data })
    //     }
    //   )
    // }

    if (tempData?.isSizeWisePriceVariant) {
      tempData = { ...tempData, mrp: '', sellingPrice: '', discount: '' }
    }

    setInitialValuesEdit(tempData)
  }

  const SUPPORTED_FORMATS = [
    'image/jpg',
    'image/jpeg',
    'image/png',
    'application/pdf'
  ]

  const validateImage = Yup.object().shape(
    {
      filename: Yup.mixed().when('filename', {
        is: (value) => value?.name,
        then: (schema) =>
          schema.test(
            'fileFormat',
            'File formate is not supported, Please use .jpg/.png/.jpeg format support',
            (value) => value && SUPPORTED_FORMATS.includes(value.type)
          ),
        otherwise: (schema) => schema.nullable()
      })
    },
    ['filename', 'filename']
  )

  return (
    <Formik
      enableReinitialize={id ? true : false}
      initialValues={!id ? initialValues : initialValuesEdit}
      // validationSchema={validationSchema}
      onSubmit={(values, { setFieldValue }) => {
        handleSubmit(values, setFieldValue)
      }}
    >
      {({ values, setFieldValue, onSubmit, resetForm }) => (
        <Form className='product_form'>
          <div
            className={
              !scrollPosition
                ? 'card position-sticky top_80 active'
                : 'card position-sticky top_80'
            }
          >
            <div className='card-body'>
              <div className='box d-flex justify-content-between align-items-center'>
                <div>
                  {!scrollPosition && (
                    <label className='form-label fw-normal text-nowrap mb-0'>
                      Product Name
                    </label>
                  )}

                  <p
                    className='mb-0 bold text-nowrap'
                    style={{ fontSize: '22px', color: '#001B5A' }}
                  >
                    {arrangeNamesBySequence(values?.productName ?? [])}
                  </p>
                  {values?.productName?.length <= 0 && (
                    <p
                      className='mb-0 bold text-nowrap'
                      style={{ fontSize: '14px' }}
                    >
                      Please fill in all details to generate the product name.
                    </p>
                  )}
                </div>
                <div className='d-flex align-items-center gap-4 w-100 justify-content-end'>
                  <div className='col-md-4 col-xxl-3'>
                    <div className='input-file-wrapper'>
                      {!scrollPosition && (
                        <label className='form-label fw-normal required'>
                          Product Status
                        </label>
                      )}

                      <Select
                        id='papproval'
                        placeholder='Product Status'
                        styles={customStyles}
                        menuPortalTarget={document.body}
                        value={
                          values?.status && {
                            value: values?.status,
                            label: values?.status
                          }
                        }
                        options={productStatus}
                        onBlur={() => {
                          if (values?.status) {
                            setFieldValue('validation', {
                              ...values?.validation,
                              status: ''
                            })
                          } else {
                            setFieldValue('validation', {
                              ...values?.validation,
                              status: 'Product Status Required'
                            })
                          }
                        }}
                        onChange={(e) => {
                          setFieldValue('status', e?.value)
                          if (e?.value) {
                            setFieldValue('validation', {
                              ...values?.validation,
                              status: ''
                            })
                          } else {
                            setFieldValue('validation', {
                              ...values?.validation,
                              status: 'Product Status Required'
                            })
                          }
                        }}
                      />
                    </div>
                  </div>
                  <div>
                    {!scrollPosition && (
                      <label className='form-label fw-normal'>
                        Is Product Live
                      </label>
                    )}

                    <div className='switch'>
                      <input
                        type='radio'
                        value={true}
                        id='yes'
                        checked={values?.live}
                        name='choice'
                        onChange={(e) => {
                          if (e?.target?.checked) setFieldValue('live', true)
                        }}
                      />
                      <label htmlFor='yes'>Yes</label>
                      <input
                        type='radio'
                        value={false}
                        id='no'
                        name='choice'
                        checked={!values?.live}
                        onChange={(e) => {
                          if (e?.target?.checked) setFieldValue('live', false)
                        }}
                      />
                      <label htmlFor='no'>No</label>
                      <span className='switchFilter'></span>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          {toast?.show && (
            <CustomToast text={toast?.text} variation={toast?.variation} />
          )}
          <div className='card'>
            <div className='card-body'>
              {loading && <Loader />}
              <div className='row d-flex justify-content-between mb-3'>
                <div className='col-md-3'>
                  <h5 className='mb-3 head_h3'>Add New Product</h5>
                </div>
              </div>

              <div className='row'>
                <div className='col-md-6'>
                  <div className='input-file-wrapper mb-3'>
                    <FormikControl
                      control='input'
                      label='Custom Product Name'
                      isRequired
                      id='customeProductName'
                      type='text'
                      // onBlur={() => {
                      //   if (values?.customeProductName) {
                      //     setFieldValue('validation', {
                      //       ...values?.validation,
                      //       customeProductName: ''
                      //     })
                      //   } else {
                      //     setFieldValue('validation', {
                      //       ...values?.validation,
                      //       customeProductName: 'Custom Product Required'
                      //     })
                      //   }
                      // }}
                      onChange={(e) => {
                        changeHandler(
                          'customeProductName',
                          e?.target?.value,
                          setFieldValue
                        )
                        // if (e?.target?.value) {
                        //   setFieldValue('validation', {
                        //     ...values?.validation,
                        //     customeProductName: ''
                        //   })
                        // } else {
                        //   setFieldValue('validation', {
                        //     ...values?.validation,
                        //     customeProductName: 'Custom Product Required'
                        //   })
                        // }
                      }}
                      name='customeProductName'
                      placeholder='Custom Product Name'
                    />
                    {/* {values?.validation?.customeProductName && (
                      <span className='text-danger'>
                        {values?.validation?.customeProductName}
                      </span>
                    )} */}
                  </div>
                </div>
                {/* <div className='col-md-3'>
                  <div className='input-file-wrapper mb-3'>
                    <FormikControl
                      control='input'
                      label='Product SKU'
                      id='companySKUCode'
                      isRequired
                      type='text'
                      name='companySKUCode'
                      placeholder='Product SKU'
                      onChange={(e) => {
                        changeHandler(
                          'companySKUCode',
                          e?.target?.value,
                          setFieldValue
                        )
                        if (e?.target?.value) {
                          setFieldValue('validation', {
                            ...values?.validation,
                            companySKUCode: ''
                          })
                        } else {
                          setFieldValue('validation', {
                            ...values?.validation,
                            companySKUCode: 'Company SKU Required'
                          })
                        }
                      }}
                      onBlur={async () => {
                        if (values?.companySKUCode) {
                          if (
                            values?.brandID &&
                            values?.categoryId &&
                            values?.companySKUCode
                          ) {
                            const response = await axiosProvider({
                              method: 'POST',
                              endpoint: 'Product/CheckCompanySkuCode',
                              data: {
                                categoryId: values?.categoryId,
                                brandID: values?.brandID,
                                companySKUCode: values?.companySKUCode
                                  ? values?.companySKUCode
                                  : '',
                                productId: values?.productId ?? 0
                              }
                            })
                            setFieldValue('validation', {
                              ...values?.validation,
                              companySKUCode:
                                response?.data?.code === 200
                                  ? ''
                                  : 'Sku code already assigned'
                            })

                            setFieldValue(
                              'isCompanySKUAvailable',
                              response?.data?.code === 200 ? true : false
                            )
                          }
                        } else {
                          setFieldValue('validation', {
                            ...values?.validation,
                            companySKUCode: 'Product SKU Required'
                          })
                        }
                      }}
                    />
                    {values?.validation?.companySKUCode && (
                      <span className='text-danger'>
                        {values?.validation?.companySKUCode}
                      </span>
                    )}
                  </div>
                </div>
                <div className='col-md-3'>
                  <div className='input-file-wrapper mb-3'>
                    <FormikControl
                      control='input'
                      label='Seller SKU code'
                      id='sellerSKU'
                      isRequired
                      type='text'
                      name='sellerSKU'
                      onBlur={async () => {
                        if (values?.sellerSKU) {
                          if (
                            values?.brandID &&
                            values?.categoryId &&
                            values?.sellerID &&
                            values?.sellerSKU
                          ) {
                            const response = await axiosProvider({
                              method: 'POST',
                              endpoint: 'Product/CheckSellerSkuCode',
                              data: {
                                sellerID: values?.sellerID,
                                categoryId: values?.categoryId,
                                brandID: values?.brandID,
                                sellerSKUCode: values?.sellerSKU
                                  ? values?.sellerSKU
                                  : '',
                                sellerProductId: values?.sellerProducts?.id ?? 0
                              },
                              location: location?.pathname
                            })
                            setFieldValue('validation', {
                              ...values?.validation,
                              sellerSKU:
                                response?.data?.code === 200
                                  ? ''
                                  : 'Sku code already assigned'
                            })
                            setFieldValue(
                              'isSellerSKUAvailable',
                              response?.data?.code === 200 ? true : false
                            )
                          }
                        } else {
                          setFieldValue('validation', {
                            ...values?.validation,
                            sellerSKU: 'Seller SKU Required'
                          })
                        }
                      }}
                      onChange={(e) => {
                        changeHandler(
                          'sellerSKU',
                          e?.target?.value,
                          setFieldValue
                        )
                        if (e?.target?.value) {
                          setFieldValue('validation', {
                            ...values?.validation,
                            sellerSKU: ''
                          })
                        } else {
                          setFieldValue('validation', {
                            ...values?.validation,
                            sellerSKU: 'Seller SKU Required'
                          })
                        }
                      }}
                      placeholder='Seller SKU code'
                    />
                    {values?.validation?.sellerSKU && (
                      <span className='text-danger'>
                        {values?.validation?.sellerSKU}
                      </span>
                    )}
                  </div>
                </div> */}

                <div className='col-md-3'>
                  {id ? (
                    <div className='input-file-wrapper mb-3'>
                      <FormikControl
                        control='input'
                        label='Seller Name'
                        disabled
                        id='sellerName'
                        isRequired
                        type='text'
                        name='sellerName'
                        placeholder='Seller Name'
                      />
                    </div>
                  ) : (
                    <div className='input-file-wrapper mb-3'>
                      <label className='form-label required'>
                        Select Seller
                      </label>
                      <Select
                        id='sellerID'
                        placeholder='Select Seller'
                        styles={customStyles}
                        menuPortalTarget={document.body}
                        value={
                          values?.sellerID && {
                            value: values?.sellerID,
                            label: allState?.editData
                              ? allState?.sellerDetails?.find(
                                  (data) => data?.userID === values?.sellerID
                                )?.displayName
                              : values?.sellerName
                          }
                        }
                        options={
                          allState?.sellerDetails?.length
                            ? allState?.sellerDetails?.map(
                                ({
                                  userID,
                                  displayName,
                                  shipmentBy,
                                  shipmentChargesPaidByName
                                }) => ({
                                  value: userID,
                                  label: displayName,
                                  shipmentBy,
                                  shipmentPaidBy: shipmentChargesPaidByName
                                })
                              )
                            : []
                        }
                        onBlur={() => {
                          if (values?.sellerID) {
                            setFieldValue('validation', {
                              ...values?.validation,
                              sellerID: ''
                            })
                          } else {
                            setFieldValue('validation', {
                              ...values?.validation,
                              sellerID: 'Seller Required'
                            })
                          }
                        }}
                        onChange={(e) => {
                          if (e?.value) {
                            setFieldValue('validation', {
                              ...values?.validation,
                              sellerID: ''
                            })
                          } else {
                            setFieldValue('validation', {
                              ...values?.validation,
                              sellerID: 'Seller Required'
                            })
                          }

                          const fetchAllData = () => {
                            setLoading(true)
                            Promise.all([
                              callApi(
                                'AssignBrandToSeller/bySeller&BrandId',
                                `?sellerId=${e?.value}`
                              ),
                              e?.value &&
                                isAllowWarehouseManagement &&
                                callApi(
                                  'seller/Warehouse/WarehouseSearch',
                                  `?UserID=${e?.value}&status=Active`
                                )
                            ])
                              .then(([brand, warehouseDetails]) => {
                                setLoading(false)
                                if (!brand?.length) {
                                  Swal.fire({
                                    title: `No brands assinged to ${e?.label}`,
                                    text: `Do you want to assign brand to ${e?.label} or change seller?`,
                                    icon: _SwalDelete.icon,
                                    // showCancelButton:
                                    //   _SwalDelete.showCancelButton,
                                    otherButtonColor:
                                      _SwalDelete.confirmButtonColor,
                                    // cancelButtonColor:
                                    //   _SwalDelete.cancelButtonColor,
                                    confirmButtonText: 'Change',
                                    // cancelButtonText: 'Assign',
                                    allowOutsideClick: false
                                  }).then((result) => {
                                    if (result.isConfirmed) {
                                      setFieldValue('tempSizeValueName', '')
                                      setFieldValue('tempSizeValueId', null)
                                      setFieldValue('tempSizeId', null)
                                      setFieldValue('sellerID', null)
                                      setFieldValue('shipmentBy', null)
                                      setFieldValue('shipmentPaidBy', null)
                                      setFieldValue('sellerName', '')
                                      setFieldValue('brandID', null)
                                      setFieldValue('brandName', '')
                                      setAllState((draft) => {
                                        draft.brand = []
                                      })
                                      setFieldValue('productPrices', [])
                                      setFieldValue(
                                        'productName',
                                        prepareProductName(
                                          'brand',
                                          3,
                                          'brand',
                                          values,
                                          false
                                        )
                                      )
                                    }
                                    //  else if (result.isDismissed) {
                                    //   navigate('/manage-brand')
                                    // }
                                  })
                                } else {
                                  if (
                                    isAllowWarehouseManagement &&
                                    !warehouseDetails?.length
                                  ) {
                                    Swal.fire({
                                      title: `No warehouse assinged to ${e?.label}`,
                                      text: `Do you want to add warehouse to ${e?.label} or change seller?`,
                                      icon: _SwalDelete.icon,
                                      showCancelButton:
                                        _SwalDelete.showCancelButton,
                                      confirmButtonColor:
                                        _SwalDelete.confirmButtonColor,
                                      cancelButtonColor:
                                        _SwalDelete.cancelButtonColor,
                                      confirmButtonText: 'Change',
                                      cancelButtonText: 'Add',
                                      allowOutsideClick: false
                                    }).then((result) => {
                                      if (result.isConfirmed) {
                                        setFieldValue('tempSizeValueName', '')
                                        setFieldValue('tempSizeValueId', null)
                                        setFieldValue('tempSizeId', null)
                                        setFieldValue('sellerID', null)
                                        setFieldValue('shipmentBy', null)
                                        setFieldValue('shipmentPaidBy', null)
                                        setFieldValue('sellerName', '')
                                        setFieldValue('brandID', null)
                                        setFieldValue('brandName', '')
                                        setAllState((draft) => {
                                          draft.brand = []
                                        })
                                        setFieldValue('productPrices', [])
                                        setFieldValue(
                                          'productName',
                                          prepareProductName(
                                            'brand',
                                            3,
                                            'brand',
                                            values,
                                            false
                                          )
                                        )
                                      } else if (result.isDismissed) {
                                        navigate('/manage-seller')
                                      }
                                    })
                                  } else {
                                    setAllState((draft) => {
                                      draft.brand = brand
                                      draft.warehouseDetails = warehouseDetails
                                    })
                                    if (brand?.length === 1) {
                                      setFieldValue(
                                        'brandID',
                                        brand[0]?.brandId
                                      )
                                      setFieldValue(
                                        'brandName',
                                        brand[0]?.brandName
                                      )
                                    }
                                  }
                                }
                              })
                              .catch(() => {
                                setLoading(false)
                              })
                          }

                          if (e) {
                            setFieldValue('tempSizeValueName', '')
                            setFieldValue('tempSizeValueId', null)
                            setFieldValue('tempSizeId', null)
                            setFieldValue('sellerID', e?.value)
                            setFieldValue('shipmentBy', e?.shipmentBy)
                            setFieldValue('shipmentPaidBy', e?.shipmentPaidBy)
                            setFieldValue('sellerName', e?.label)
                            setFieldValue('brandID', null)
                            setFieldValue('brandName', '')
                            setAllState((draft) => {
                              draft.brand = []
                            })
                            setFieldValue('productPrices', [])
                            setFieldValue(
                              'productName',
                              prepareProductName(
                                'brand',
                                3,
                                'brand',
                                values,
                                false
                              )
                            )
                            fetchAllData()
                          }
                        }}
                      />
                    </div>
                  )}
                </div>

                <div className='col-md-3'>
                  {id ? (
                    <div className='input-file-wrapper mb-3'>
                      <FormikControl
                        control='input'
                        label='Brand Name'
                        disabled
                        id='brandName'
                        isRequired
                        type='text'
                        name='brandName'
                        placeholder='Brand Name'
                      />
                    </div>
                  ) : (
                    <div className='input-file-wrapper mb-3'>
                      <label className='form-label required'>
                        Select Brand
                      </label>
                      <Select
                        id='brandID'
                        isClearable
                        placeholder='Select Brand'
                        styles={customStyles}
                        menuPortalTarget={document.body}
                        value={
                          values?.brandID && {
                            value: values?.brandID,
                            label: allState?.editData
                              ? allState?.brand?.filter(
                                  (data) => data?.brandId === values?.brandID
                                )[0]?.brandName
                              : values?.brandName
                          }
                        }
                        options={
                          allState?.brand?.length
                            ? allState?.brand?.map(
                                ({ brandId, brandName }) => ({
                                  value: brandId,
                                  label: brandName
                                })
                              )
                            : []
                        }
                        onBlur={() => {
                          if (values?.sellerID)
                            if (values?.brandID) {
                              setFieldValue('validation', {
                                ...values?.validation,
                                brandId: ''
                              })
                            } else {
                              setFieldValue('validation', {
                                ...values?.validation,
                                brandId: 'Brand Required'
                              })
                            }
                        }}
                        onChange={async (e) => {
                          let productName = values?.productName ?? []
                          if (values?.sellerID)
                            if (e?.value) {
                              setFieldValue('validation', {
                                ...values?.validation,
                                brandId: ''
                              })
                            } else {
                              setFieldValue('validation', {
                                ...values?.validation,
                                brandId: 'Brand Required'
                              })
                            }

                          if (values?.categoryName) {
                            productName = prepareProductName(
                              values?.categoryName,
                              -1,
                              'category',
                              values,
                              true
                            )
                          }
                          productName = prepareProductName(
                            e?.label,
                            -2,
                            'brand',
                            values,
                            true
                          )

                          setFieldValue('productName', productName)

                          setFieldValue('brandID', e?.value)
                          setFieldValue('brandName', e?.label)

                          // let validation = values?.validation ?? {}
                          // if (
                          //   values?.sellerSKU &&
                          //   e?.value &&
                          //   values?.categoryId
                          // ) {
                          //   const response = await axiosProvider({
                          //     method: 'POST',
                          //     endpoint: 'Product/CheckSellerSkuCode',
                          //     data: {
                          //       sellerID: values?.sellerID,
                          //       categoryId: values?.categoryId,
                          //       brandID: e?.value,
                          //       sellerSKUCode: values?.sellerSKU
                          //         ? values?.sellerSKU
                          //         : ''
                          //     }
                          //   })
                          //   validation = {
                          //     ...validation,
                          //     sellerSKU:
                          //       response?.data?.code === 200
                          //         ? ''
                          //         : 'Sku code already assigned'
                          //   }
                          //   setFieldValue(
                          //     'isSellerSKUAvailable',
                          //     response?.data?.code === 200 ? true : false
                          //   )
                          // }

                          // if (
                          //   values?.companySKUCode &&
                          //   e?.value &&
                          //   values?.categoryId
                          // ) {
                          //   const response = await axiosProvider({
                          //     method: 'POST',
                          //     endpoint: 'Product/CheckCompanySkuCode',
                          //     data: {
                          //       categoryId: values?.categoryId,
                          //       brandID: e?.value,
                          //       companySKUCode: values?.companySKUCode
                          //         ? values?.companySKUCode
                          //         : ''
                          //     }
                          //   })
                          //   validation = {
                          //     ...validation,
                          //     companySKUCode:
                          //       response?.data?.code === 200
                          //         ? ''
                          //         : 'Sku code already assigned'
                          //   }
                          //   setFieldValue(
                          //     'isCompanySKUAvailable',
                          //     response?.data?.code === 200 ? true : false
                          //   )
                          // }
                          // setFieldValue('validation', validation)
                        }}
                      />
                      {values?.validation?.brandId && (
                        <span className='text-danger'>
                          {values?.validation?.brandId}
                        </span>
                      )}
                    </div>
                  )}
                </div>
                <div className='col-md-12 mb-3'>
                  {id ? (
                    <div className='input-file-wrapper mb-3'>
                      <FormikControl
                        control='input'
                        label='Category Name'
                        disabled
                        id='categoryName'
                        isRequired
                        type='text'
                        name='categoryPathName'
                        placeholder='Category Name'
                      />
                    </div>
                  ) : (
                    <div className='input-file-wrapper'>
                      {' '}
                      <label className='form-label required'>
                        Select Category
                      </label>
                      <Select
                        styles={customStyles}
                        menuPortalTarget={document.body}
                        id='categoryId'
                        placeholder='Select Category'
                        value={
                          values?.categoryId && {
                            value: values?.categoryId,
                            label: allState?.editData
                              ? allState?.category?.find(
                                  (data) => data?.id === values?.categoryId
                                )?.pathNames
                              : values?.categoryPathName
                          }
                        }
                        disabled
                        options={
                          allState?.category?.length
                            ? allState?.category?.map(
                                ({ id, pathNames, name }) => ({
                                  value: id,
                                  label: pathNames,
                                  categoryName: name
                                })
                              )
                            : []
                        }
                        onChange={async (e) => {
                          const fetchData = async () => {
                            try {
                              setLoading(true)
                              let productName = []
                              let apiUrls = []
                              const data = await callApi(
                                'AssignSpecificationToCategory/getByCatId',
                                `?catId=${e?.value}`
                              )

                              if (Array.isArray(data)) {
                                setLoading(false)
                                Swal.fire({
                                  title: `No attributes assinged to ${e?.label}`,
                                  text: 'Do you want to change this category or assign attributes to this category?',
                                  icon: _SwalDelete.icon,
                                  showCancelButton:
                                    _SwalDelete.showCancelButton,
                                  otherButtonColor:
                                    _SwalDelete.otherButtonColor,
                                  cancelButtonColor:
                                    _SwalDelete.cancelButtonColor,
                                  confirmButtonText: 'Change',
                                  cancelButtonText: 'Assign',
                                  allowOutsideClick: false
                                }).then((result) => {
                                  if (result.isConfirmed) {
                                    setFieldValue('categoryId', null)
                                    setFieldValue('categoryName', '')
                                  } else if (result.isDismissed) {
                                    navigate(
                                      '/category/assign-category/manage-filter'
                                    )
                                  }
                                })
                              } else {
                                if (values?.brandName) {
                                  productName = prepareProductName(
                                    values?.brandName,
                                    -2,
                                    'brand',
                                    { productName },
                                    true
                                  )
                                }
                                productName = prepareProductName(
                                  e?.categoryName,
                                  -1,
                                  'category',
                                  { productName },
                                  true
                                )
                                if (e?.value) {
                                  setFieldValue('validation', {
                                    ...values?.validation,
                                    categoryId: ''
                                  })
                                } else {
                                  setFieldValue('validation', {
                                    ...values?.validation,
                                    categoryId: 'Category Required'
                                  })
                                }
                                if (data?.isAllowColors) {
                                  apiUrls.push({
                                    endpoint: 'color',
                                    queryString: '?pageindex=0&PageSize=0',
                                    state: 'color'
                                  })
                                }
                                if (data?.isAllowSpecifications) {
                                  apiUrls.push({
                                    endpoint:
                                      'AssignSpecValuesToCategory/GetSpecsList',
                                    queryString: `?AssignSpecId=${data?.id}`,
                                    state: 'specificationData'
                                  })
                                }
                                if (data?.isAllowSize) {
                                  apiUrls.push({
                                    endpoint:
                                      'AssignSizeValuesToCategory/byAssignSpecId',
                                    queryString: `?assignSpecId=${data?.id}&pageindex=0&PageSize=0`,
                                    state: 'sizeType'
                                  })
                                }
                                if (isAllowWarehouseManagement) {
                                  values?.sellerID &&
                                    apiUrls.push({
                                      endpoint:
                                        'seller/Warehouse/WarehouseSearch',
                                      queryString: `?UserID=${values?.sellerID}&status=Active`,
                                      state: 'warehouseDetails'
                                    })
                                }
                                resetForm({
                                  values: {
                                    ...initialValues,
                                    sellerID: values?.sellerID,
                                    sellerName: values?.sellerName,
                                    brandID: values?.brandID,
                                    brandName: values?.brandName,
                                    categoryId: e?.value,
                                    categoryName: e?.categoryName,
                                    categoryPathName: e?.label,
                                    live: values?.live,
                                    productPrices: [],
                                    productColorMapping: [],
                                    productVideoLinks: [],
                                    productImage: [],
                                    priceVariant: false,
                                    mrp: '',
                                    sellingPrice: '',
                                    discount: '',
                                    customeProductName:
                                      values?.customeProductName,
                                    sellerSKU: values?.sellerSKU,
                                    companySKUCode: values?.companySKUCode,
                                    validation: values?.validation,
                                    assiCategoryId: data?.id,
                                    tempSizeId: null,
                                    description: values?.description,
                                    highlights: values?.highlights,
                                    isAllowColorsInTitle:
                                      data?.isAllowColorsInTitle,
                                    titleSequenceOfColor:
                                      data?.titleSequenceOfColor,
                                    isAllowPriceVariant:
                                      data?.isAllowPriceVariant,
                                    productName,
                                    productColorMapping: [],
                                    tempColorId: null,
                                    tempColorName: '',
                                    productSpecificationsMapp: [],
                                    status: values?.status
                                  }
                                })

                                const fetchData = async () => {
                                  let sizeType,
                                    warehouseDetails,
                                    color,
                                    specificationData

                                  const responses = await fetchAllGenericData(
                                    apiUrls
                                  )
                                  setLoading(false)
                                  responses.forEach((response) => {
                                    switch (response.state) {
                                      case 'sizeType':
                                        sizeType =
                                          response?.data?.length > 0
                                            ? response?.data
                                            : null
                                        break

                                      case 'warehouseDetails':
                                        warehouseDetails = response?.data
                                        break

                                      case 'color':
                                        color = response?.data
                                        break

                                      case 'specificationData':
                                        specificationData = response?.data
                                        break

                                      default:
                                        break
                                    }
                                  })

                                  setAllState({
                                    ...allState,
                                    sizeType,
                                    warehouseDetails,
                                    specificationData,
                                    color
                                  })

                                  if (!sizeType) {
                                    setFieldValue('productPrices', [
                                      {
                                        mrp: 1000,
                                        sellingPrice: 1000,
                                        discount: 0,
                                        quantity: 1,
                                        sizeID: null,
                                        productWarehouses: []
                                      }
                                    ])
                                  }
                                }
                                fetchData()
                                if (!apiUrls?.length) {
                                  setFieldValue('productPrices', [
                                    {
                                      mrp: 1000,
                                      sellingPrice: 1000,
                                      discount: 0,
                                      quantity: 1,
                                      sizeID: null,
                                      productWarehouses: []
                                    }
                                  ])
                                }
                              }
                            } catch (error) {
                              setLoading(false)
                            }
                          }
                          if (e?.value) {
                            setFieldValue('validation', {
                              ...values?.validation,
                              categoryId: ''
                            })
                          } else {
                            setFieldValue('validation', {
                              ...values?.validation,
                              categoryId: 'Category Required'
                            })
                          }
                          fetchData()

                          // let validation = values?.validation ?? {}
                          // if (
                          //   values?.sellerID &&
                          //   values?.sellerSKU &&
                          //   values?.brandID &&
                          //   e?.value
                          // ) {
                          //   const response = await axiosProvider({
                          //     method: 'POST',
                          //     endpoint: 'Product/CheckSellerSkuCode',
                          //     data: {
                          //       sellerID: values?.sellerID,
                          //       categoryId: e?.value,
                          //       brandID: values?.brandID,
                          //       sellerSKUCode: values?.sellerSKU
                          //         ? values?.sellerSKU
                          //         : ''
                          //     }
                          //   })
                          //   validation = {
                          //     ...validation,
                          //     sellerSKU:
                          //       response?.data?.code === 200
                          //         ? ''
                          //         : 'Sku code already assigned'
                          //   }
                          //   setFieldValue(
                          //     'isSellerSKUAvailable',
                          //     response?.data?.code === 200 ? true : false
                          //   )
                          // }

                          // if (
                          //   values?.companySKUCode &&
                          //   values?.brandID &&
                          //   e?.value
                          // ) {
                          //   const response = await axiosProvider({
                          //     method: 'POST',
                          //     endpoint: 'Product/CheckCompanySkuCode',
                          //     data: {
                          //       categoryId: e?.value,
                          //       brandID: values?.brandID,
                          //       companySKUCode: values?.companySKUCode
                          //         ? values?.companySKUCode
                          //         : ''
                          //     }
                          //   })
                          //   validation = {
                          //     ...validation,
                          //     companySKUCode:
                          //       response?.data?.code === 200
                          //         ? ''
                          //         : 'Sku code already assigned'
                          //   }
                          //   setFieldValue(
                          //     'isCompanySKUAvailable',
                          //     response?.data?.code === 200 ? true : false
                          //   )
                          // }
                          // setFieldValue('validation', validation)
                        }}
                        onBlur={() => {
                          let validation = values?.validation
                          if (values?.categoryId) {
                            validation = {
                              ...validation,
                              categoryId: ''
                            }
                          } else {
                            validation = {
                              ...validation,
                              categoryId: 'Category Required'
                            }
                          }

                          setFieldValue('validation', validation)
                        }}
                      />
                    </div>
                  )}
                  {values?.validation?.categoryId && (
                    <span className='text-danger'>
                      {values?.validation?.categoryId}
                    </span>
                  )}
                </div>

                <div className='mb-3 col-md-12'>
                  <label className='form-label required'>Description</label>
                  <CKEditor
                    editor={ClassicEditor}
                    data={values?.description ? values?.description : '<p></p>'}
                    onChange={(event, editor) => {
                      const data = editor.getData()
                      setFieldValue('description', data)
                      if (data) {
                        setFieldValue('validation', {
                          ...values?.validation,
                          description: ''
                        })
                      } else {
                        setFieldValue('validation', {
                          ...values?.validation,
                          description: 'Description Required'
                        })
                      }
                    }}
                    onBlur={() => {
                      if (values?.description) {
                        setFieldValue('validation', {
                          ...values?.validation,
                          description: ''
                        })
                      } else {
                        setFieldValue('validation', {
                          ...values?.validation,
                          description: 'Description Required'
                        })
                      }
                    }}
                    config={ckEditorConfig}
                  />
                  {values?.validation?.description && (
                    <span className='text-danger'>
                      {values?.validation?.description}
                    </span>
                  )}
                </div>

                <div className='mb-3 col-md-12'>
                  <label className='form-label'>Details</label>
                  <CKEditor
                    editor={ClassicEditor}
                    data={values?.highlights ? values?.highlights : '<p></p>'}
                    onChange={(event, editor) => {
                      const data = editor.getData()
                      setFieldValue('highlights', data)
                    }}
                    config={ckEditorConfig}
                  />
                </div>

                <div className='col-md-6'>
                  <label className='form-label'>Keywords</label>
                  <div className='input-file-wrapper mb-3'>
                    <TagsInput
                      value={values?.keywords ?? []}
                      separators={['Enter', ',']}
                      placeHolder='Keywords (Max. 5 keywords)'
                      // inputProps={{ placeholder: 'Add_tags' }}
                      onChange={(tags) => {
                        if (tags?.length < 6) {
                          setFieldValue('keywords', tags)
                        } else {
                          setFieldValue('keywords', values?.keywords)
                        }
                      }}
                      name='keywords'
                    />
                  </div>
                </div>

                {/* <div className='col-md-4 col-xxl-3'>
                  <div className='input-file-wrapper mb-3'>
                    <label className='form-label required'>
                      Select HSN Code
                    </label>
                    <Select
                      id='hsncode'
                      placeholder='Select HSN Code'
                      styles={customStyles}
                      menuPortalTarget={document.body}
                      value={
                        values?.hsnCodeId &&
                        (() => {
                          const matchingHsnCode = allState?.hsnCode?.find(
                            (data) =>
                              data?.hsnCodeId === values?.hsnCodeId &&
                              data?.taxValueId === values?.taxValueId
                          )

                          if (!matchingHsnCode) {
                            return null
                          }

                          return {
                            value: values.hsnCodeId,
                            label: !values?.hsnCodeName
                              ? `${matchingHsnCode.hsnCode} (${matchingHsnCode.taxName})`
                              : values.hsnCodeName
                          }
                        })()
                      }
                      options={
                        allState?.hsnCode?.length
                          ? allState?.hsnCode?.map(
                              ({
                                id,
                                hsnCodeId,
                                hsnCode,
                                taxName,
                                taxValueId
                              }) => ({
                                value: id,
                                label: `${hsnCode} (${taxName})`,
                                taxValueId,
                                hsnCodeId
                              })
                            )
                          : []
                      }
                      onChange={(e) => {
                        if (e?.value) {
                          setFieldValue('validation', {
                            ...values?.validation,
                            hsnCodeId: ''
                          })
                        } else {
                          setFieldValue('validation', {
                            ...values?.validation,
                            hsnCodeId: 'HSN Code Required'
                          })
                        }
                        setFieldValue('hsnCodeId', e?.hsnCodeId)
                        setFieldValue('taxValueId', e?.taxValueId)
                        setFieldValue('hsnCodeName', e?.label)
                      }}
                      onBlur={() => {
                        if (values?.hsnCodeId) {
                          setFieldValue('validation', {
                            ...values?.validation,
                            hsnCodeId: ''
                          })
                        } else {
                          setFieldValue('validation', {
                            ...values?.validation,
                            hsnCodeId: 'HSN Code Required'
                          })
                        }
                      }}
                    />
                    {values?.validation?.hsnCodeId && (
                      <span className='text-danger'>
                        {values?.validation?.hsnCodeId}
                      </span>
                    )}
                  </div>
                </div> */}

                {/* <div className='col-md-4 col-xxl-3'>
                  <div className='input-file-wrapper mb-3'>
                    <label className='form-label required'>
                      Select HSN Code
                    </label>
                    <Select
                      id='hsncode'
                      placeholder='Select HSN Code'
                      value={
                        values?.hsnCodeId && {
                          value: values?.hsnCodeId,
                          label: allState?.editData
                            ? allState?.hsnCode?.filter(
                                (data) => data?.id === values?.hsnCodeId
                              )[0]?.hsnCode
                            : values?.hsnCodeName
                        }
                      }
                      options={
                        allState?.hsnCode &&
                        allState?.hsnCode?.map(({ id, hsnCode }) => ({
                          value: id,
                          label: hsnCode
                        }))
                      }
                      onChange={(e) => {
                        if (e?.value) {
                          setFieldValue('validation', {
                            ...values?.validation,
                            hsnCodeId: ''
                          })
                        } else {
                          setFieldValue('validation', {
                            ...values?.validation,
                            hsnCodeId: 'HSN Code Required'
                          })
                        }
                        setFieldValue('hsnCodeId', e?.value)
                        setFieldValue('hsnCodeName', e?.label)
                      }}
                      onBlur={() => {
                        if (values?.hsnCodeId) {
                          setFieldValue('validation', {
                            ...values?.validation,
                            hsnCodeId: ''
                          })
                        } else {
                          setFieldValue('validation', {
                            ...values?.validation,
                            hsnCodeId: 'HSN Code Required'
                          })
                        }
                      }}
                    />
                    {values?.validation?.hsnCodeId && (
                      <span className='text-danger'>
                        {values?.validation?.hsnCodeId}
                      </span>
                    )}
                  </div>
                </div>

                <div className='col-md-4 col-xxl-3'>
                  <div className='input-file-wrapper mb-3'>
                    <label className='form-label required'>
                      Select Tax Rate
                    </label>
                    <Select
                      id='taxValueId'
                      placeholder='Select Tax Rate'
                      value={
                        values?.taxValueId && {
                          value: values?.taxValueId,
                          label: allState?.editData
                            ? allState?.taxRate?.filter(
                                (data) => data?.id === values?.taxValueId
                              )[0]?.name
                            : values?.taxValueName
                        }
                      }
                      onBlur={() => {
                        if (values?.taxValueId) {
                          setFieldValue('validation', {
                            ...values?.validation,
                            taxValueId: ''
                          })
                        } else {
                          setFieldValue('validation', {
                            ...values?.validation,
                            taxValueId: 'Tax Required'
                          })
                        }
                      }}
                      options={
                        allState?.taxRate &&
                        allState?.taxRate?.map(({ id, name }) => ({
                          value: id,
                          label: name
                        }))
                      }
                      onChange={(e) => {
                        if (e?.value) {
                          setFieldValue('validation', {
                            ...values?.validation,
                            taxValueId: ''
                          })
                        } else {
                          setFieldValue('validation', {
                            ...values?.validation,
                            taxValueId: 'Tax Required'
                          })
                        }
                        setFieldValue('taxValueId', e?.value)
                        setFieldValue('taxValueName', e?.label)
                      }}
                    />
                    {values?.validation?.taxValueId && (
                      <span className='text-danger'>
                        {values?.validation?.taxValueId}
                      </span>
                    )}
                  </div>
                </div> */}
              </div>
            </div>
          </div>
          {allState?.specificationData?.length > 0 && (
            <div className='card'>
              <div className='card-body'>
                {allState?.specificationData?.map((data) => (
                  <div key={data?.specId}>
                    <h5 className='mb-3 head_h3'>{data?.name}</h5>
                    <div className='row mb-3'>
                      {data?.types?.map((specTypeData) => (
                        <div
                          className={
                            specTypeData?.fieldType?.toLowerCase() === 'file'
                              ? 'col-md-12'
                              : 'col-md-4'
                          }
                          key={specTypeData.specTypeId}
                        >
                          {specTypeData?.fieldType?.toLowerCase() ===
                          'dropdownlist' ? (
                            <div className='input-file-wrapper mb-3'>
                              <label className='form-label'>
                                {specTypeData?.name}
                              </label>
                              <Select
                                isMulti={
                                  specTypeData?.values[0]
                                    ?.isAllowMultipleSelection
                                    ? true
                                    : false
                                }
                                isClearable
                                id={specTypeData?.specTypeId}
                                placeholder={specTypeData?.name}
                                value={
                                  values.productSpecificationsMapp?.length &&
                                  values?.productSpecificationsMapp
                                    ?.filter(
                                      (spec) =>
                                        spec?.specTypeId ===
                                        specTypeData?.specTypeId
                                    )
                                    ?.map((data) => {
                                      return {
                                        label: data?.value,
                                        value: data?.specValueId
                                      }
                                    })
                                }
                                options={specTypeData?.values?.map(
                                  ({ name, specValueId }) => ({
                                    value: specValueId,
                                    label: name
                                  })
                                )}
                                onChange={(e) => {
                                  let getSpecification =
                                    allState?.specificationData?.find(
                                      (spec) => spec?.specId === data?.specId
                                    )?.types

                                  let getSpecTypes = getSpecification?.find(
                                    (data) =>
                                      data?.specTypeId ===
                                      specTypeData?.specTypeId
                                  )?.values

                                  let {
                                    isAllowSpecInTitle,
                                    titleSequenceOfSpecification
                                  } = getSpecTypes[0]

                                  let productSpecificationsMapp =
                                    values?.productSpecificationsMapp ?? []

                                  if (e) {
                                    let items = e

                                    if (Array.isArray(e)) {
                                      let firstElement = e[0]
                                      if (e?.length > 1) {
                                        setFieldValue(
                                          'productName',
                                          prepareProductName(
                                            firstElement?.label,
                                            1,
                                            specTypeData?.name,
                                            values,
                                            false
                                          )
                                        )
                                      } else if (e?.length === 1) {
                                        if (isAllowSpecInTitle) {
                                          setFieldValue(
                                            'productName',
                                            prepareProductName(
                                              firstElement?.label,
                                              titleSequenceOfSpecification,
                                              specTypeData?.name,
                                              values,
                                              true
                                            )
                                          )
                                        }
                                      } else {
                                        setFieldValue(
                                          'productName',
                                          prepareProductName(
                                            'Spec',
                                            1,
                                            specTypeData?.name,
                                            values,
                                            false
                                          )
                                        )
                                      }
                                    } else {
                                      if (isAllowSpecInTitle)
                                        setFieldValue(
                                          'productName',
                                          prepareProductName(
                                            e?.label,
                                            titleSequenceOfSpecification,
                                            specTypeData?.name,
                                            values,
                                            true
                                          )
                                        )
                                      else {
                                        setFieldValue(
                                          'productName',
                                          prepareProductName(
                                            e?.label,
                                            1,
                                            specTypeData?.name,
                                            values,
                                            false
                                          )
                                        )
                                      }
                                    }

                                    setFieldValue(
                                      'productSpecificationsMapp',
                                      updateProductSpecificationMapp(
                                        productSpecificationsMapp,
                                        items,
                                        data?.specId,
                                        specTypeData?.specTypeId
                                      )
                                    )

                                    let productPrices = []
                                    if (!allState?.sizeType?.length) {
                                      productPrices.push({
                                        mrp: 1000,
                                        sellingPrice: 1000,
                                        discount: '0',
                                        quantity: 1,
                                        sizeID: null,
                                        productWarehouses: []
                                      })
                                      setFieldValue(
                                        'productPrices',
                                        productPrices
                                      )
                                    }
                                  } else {
                                    let getSpecificationToBeRemoved =
                                      productSpecificationsMapp?.find(
                                        (spec) =>
                                          spec.specTypeId ===
                                          specTypeData?.specTypeId
                                      )
                                    setFieldValue(
                                      'productName',
                                      prepareProductName(
                                        getSpecificationToBeRemoved?.value,
                                        1,
                                        specTypeData?.name,
                                        values,
                                        false
                                      )
                                    )
                                    productSpecificationsMapp =
                                      productSpecificationsMapp.filter(
                                        (spec) =>
                                          spec.specTypeId !==
                                          specTypeData?.specTypeId
                                      )

                                    setFieldValue(
                                      'productSpecificationsMapp',
                                      productSpecificationsMapp
                                    )
                                  }
                                }}
                              />
                            </div>
                          ) : specTypeData?.fieldType?.toLowerCase() ===
                            'file' ? (
                            <>
                              <label className='form-label'>
                                {specTypeData?.name}
                              </label>

                              <div className='row mb-3 gy-3'>
                                {values?.productSpecificationsMapp
                                  ?.filter(
                                    (specFile) =>
                                      specFile?.fileName &&
                                      specFile?.specTypeId ===
                                        specTypeData?.specTypeId
                                  )
                                  ?.map((specImgData) => (
                                    <div
                                      style={{ width: 'fit-content' }}
                                      className='pv-download-btn'
                                    >
                                      <span
                                        className='w-100 rounded border-1 d-inline-block'
                                        style={{
                                          border: `2px dashed var(--medium-gray)`
                                        }}
                                      >
                                        <div
                                          className='p-2 d-flex gap-3 align-items-center justify-content-center'
                                          style={{
                                            background: 'var(--badge-light-bg)'
                                          }}
                                        >
                                          <OverlayTrigger
                                            placement={'top'}
                                            overlay={
                                              <Tooltip id={`tooltip-${'top'}`}>
                                                <strong>Download</strong>.
                                              </Tooltip>
                                            }
                                          >
                                            <span
                                              onClick={() => {
                                                downloadURI(
                                                  specImgData?.fileName,
                                                  specImgData?.value,
                                                  specImgData?.id
                                                    ? _specificationDocs_
                                                    : _tempImg_
                                                )
                                              }}
                                            >
                                              <svg
                                                role='button'
                                                xmlns='http://www.w3.org/2000/svg'
                                                width='18px'
                                                height='22px'
                                                viewBox='0 0 24.123 28.948'
                                              >
                                                <path
                                                  id='Icon_ionic-md-download'
                                                  data-name='Icon ionic-md-download'
                                                  d='M30.873,14.715h-6.89V4.5H13.64V14.715H6.75L18.812,26.633ZM6.75,30.041v3.407H30.873V30.041Z'
                                                  transform='translate(-6.75 -4.5)'
                                                  fill='#aaa'
                                                />
                                              </svg>
                                            </span>
                                          </OverlayTrigger>
                                          <p className='m-0 text-nowrap'>
                                            {specImgData?.value}
                                          </p>
                                          <span
                                            onClick={() => {
                                              let productSpecificationsMapp =
                                                values?.productSpecificationsMapp ??
                                                []

                                              Swal.fire({
                                                title: _SwalDelete.title,
                                                text: _SwalDelete.text,
                                                icon: _SwalDelete.icon,
                                                showCancelButton:
                                                  _SwalDelete.showCancelButton,
                                                confirmButtonColor:
                                                  _SwalDelete.confirmButtonColor,
                                                cancelButtonColor:
                                                  _SwalDelete.cancelButtonColor,
                                                confirmButtonText:
                                                  _SwalDelete.confirmButtonText,
                                                cancelButtonText:
                                                  _SwalDelete.cancelButtonText
                                              }).then((result) => {
                                                if (result.isConfirmed) {
                                                  setFieldValue(
                                                    'productSpecificationsMapp',
                                                    updateProductSpecificationMapp(
                                                      productSpecificationsMapp,
                                                      '',
                                                      data?.specId,
                                                      specTypeData?.specTypeId,
                                                      specImgData?.fileName
                                                    )
                                                  )
                                                } else if (result.isDenied) {
                                                }
                                              })
                                            }}
                                          >
                                            <DeleteIcon />
                                          </span>
                                        </div>
                                      </span>
                                    </div>
                                  ))}
                              </div>
                              <div className='input-file-wrapper mb-3 row'>
                                <div
                                  className='col-auto'
                                  style={{
                                    position: 'relative',
                                    display: 'inline-block'
                                  }}
                                >
                                  <label
                                    htmlFor='fileInput'
                                    style={{
                                      cursor: 'pointer',
                                      padding: '4px 20px',
                                      border: '1px solid #ccc',
                                      borderRadius: '4px',
                                      backgroundColor: '#f9f9f9'
                                    }}
                                  >
                                    Choose File
                                  </label>
                                  <input
                                    type='file'
                                    id='fileInput'
                                    className='w-100'
                                    style={{
                                      position: 'absolute',
                                      left: 0,
                                      top: 0,
                                      opacity: 0
                                    }}
                                    onChange={async (e) => {
                                      const handleFileChange = async (
                                        e,
                                        values,
                                        setFieldValue
                                      ) => {
                                        try {
                                          const file = e?.target?.files[0]
                                          setFieldValue('validation', {
                                            ...values?.validation,
                                            [`fileName${specTypeData?.name}${specTypeData?.specTypeId}`]:
                                              ''
                                          })
                                          if (file) {
                                            await validateImage.validate({
                                              filename: file
                                            })

                                            setFieldValue(
                                              [
                                                `fileName${specTypeData?.name}${specTypeData?.specTypeId}`
                                              ],
                                              file?.name
                                            )

                                            const imgUrl = await uploadFile(
                                              values,
                                              0,
                                              file,
                                              setFieldValue,
                                              false
                                            )
                                            setFieldValue(
                                              [
                                                `imgUrl${specTypeData?.name}${specTypeData?.specTypeId}`
                                              ],
                                              imgUrl
                                            )
                                            let productPrices = []
                                            if (!allState?.sizeType?.length) {
                                              productPrices.push({
                                                mrp: 1000,
                                                sellingPrice: 1000,
                                                discount: '0',
                                                quantity: 1,
                                                sizeID: null,
                                                productWarehouses: []
                                              })
                                              setFieldValue(
                                                'productPrices',
                                                productPrices
                                              )
                                            }
                                          }
                                        } catch (error) {
                                          setFieldValue('validation', {
                                            ...values?.validation,
                                            [`fileName${specTypeData?.name}${specTypeData?.specTypeId}`]:
                                              error?.message
                                          })
                                        }
                                      }

                                      handleFileChange(e, values, setFieldValue)
                                    }}
                                  />
                                </div>
                                {values?.[
                                  `fileName${specTypeData?.name}${specTypeData?.specTypeId}`
                                ] && (
                                  <div className='col-md-3'>
                                    <p>
                                      File:{' '}
                                      {
                                        values?.[
                                          `fileName${specTypeData?.name}${specTypeData?.specTypeId}`
                                        ]
                                      }
                                    </p>
                                  </div>
                                )}
                                <div className='col-md-3'>
                                  <FormikControl
                                    control='input'
                                    type='text'
                                    name='value'
                                    placeholder='File description'
                                    value={
                                      values?.[
                                        `${specTypeData?.name}${specTypeData?.specTypeId}`
                                      ]
                                    }
                                    onChange={(e) => {
                                      let inputValue = e?.target?.value
                                      setFieldValue(
                                        [
                                          `${specTypeData?.name}${specTypeData?.specTypeId}`
                                        ],
                                        inputValue
                                      )
                                    }}
                                  />
                                </div>
                                <span
                                  className='col-md-1'
                                  onMouseDown={() => {
                                    if (
                                      values?.[
                                        `${specTypeData?.name}${specTypeData?.specTypeId}`
                                      ] &&
                                      values?.[
                                        `fileName${specTypeData?.name}${specTypeData?.specTypeId}`
                                      ]
                                    ) {
                                      setFieldValue('validation', {
                                        ...values?.validation,
                                        [`fileName${specTypeData?.name}${specTypeData?.specTypeId}`]:
                                          ''
                                      })
                                      setFieldValue(
                                        `${specTypeData?.name}${specTypeData?.specTypeId}`,
                                        ''
                                      )
                                      setFieldValue(
                                        `fileName${specTypeData?.name}${specTypeData?.specTypeId}`,
                                        ''
                                      )
                                      let productSpecificationsMapp =
                                        values?.productSpecificationsMapp ?? []
                                      setFieldValue(
                                        'productSpecificationsMapp',
                                        updateProductSpecificationMapp(
                                          productSpecificationsMapp,
                                          values?.[
                                            `${specTypeData?.name}${specTypeData?.specTypeId}`
                                          ],
                                          data?.specId,
                                          specTypeData?.specTypeId,
                                          values?.[
                                            `imgUrl${specTypeData?.name}${specTypeData?.specTypeId}`
                                          ]
                                        )
                                      )
                                    } else {
                                      setFieldValue('validation', {
                                        ...values?.validation,
                                        [`fileName${specTypeData?.name}${specTypeData?.specTypeId}`]:
                                          !values?.[
                                            `fileName${specTypeData?.name}${specTypeData?.specTypeId}`
                                          ]
                                            ? 'Please select file'
                                            : !values?.[
                                                `${specTypeData?.name}${specTypeData?.specTypeId}`
                                              ] && 'Please enter description'
                                      })
                                    }
                                  }}
                                >
                                  <PlusIcon bg={'bg'} />
                                </span>
                                {values?.validation?.[
                                  `fileName${specTypeData?.name}${specTypeData?.specTypeId}`
                                ] && (
                                  <div className='text-danger'>
                                    {
                                      values?.validation?.[
                                        `fileName${specTypeData?.name}${specTypeData?.specTypeId}`
                                      ]
                                    }
                                  </div>
                                )}
                              </div>
                            </>
                          ) : (
                            <div className='input-file-wrapper mb-3'>
                              <FormikControl
                                control='input'
                                label={specTypeData?.name}
                                key={specTypeData.specTypeId}
                                type='text'
                                id={specTypeData?.specTypeId}
                                name={specTypeData?.name}
                                value={values?.[specTypeData?.name]}
                                placeholder={specTypeData?.name}
                                onChange={(e) => {
                                  let inputValue = e?.target?.value
                                  setFieldValue(
                                    [specTypeData?.name],
                                    inputValue
                                  )
                                  let productSpecificationsMapp =
                                    values?.productSpecificationsMapp ?? []
                                  setFieldValue(
                                    'productSpecificationsMapp',
                                    updateProductSpecificationMapp(
                                      productSpecificationsMapp,
                                      inputValue,
                                      data?.specId,
                                      specTypeData?.specTypeId
                                    )
                                  )
                                }}
                                onBlur={() => {
                                  let productPrices = []
                                  if (!allState?.sizeType?.length) {
                                    productPrices.push({
                                      mrp: 1000,
                                      sellingPrice: 1000,
                                      discount: '0',
                                      quantity: 1,
                                      sizeID: null,
                                      productWarehouses: []
                                    })
                                    setFieldValue(
                                      'productPrices',
                                      productPrices
                                    )
                                  }
                                }}
                              />
                            </div>
                          )}
                        </div>
                      ))}
                    </div>
                  </div>
                ))}
              </div>
            </div>
          )}

          {!isAllowWarehouseManagement && !isAllowPriceVariant && (
            <NotWarehouseAndPrice
              allState={allState}
              values={values}
              setFieldValue={setFieldValue}
              fetchCalculation={fetchCalculation}
              calculation={calculation}
              setCalculation={setCalculation}
            />
          )}
          {isAllowWarehouseManagement && !isAllowPriceVariant && (
            <WarehouseNotPrice
              allState={allState}
              values={values}
              setFieldValue={setFieldValue}
              fetchCalculation={fetchCalculation}
              calculation={calculation}
              setCalculation={setCalculation}
            />
          )}
          {!isAllowWarehouseManagement && isAllowPriceVariant && (
            <NotWarehousePrice
              allState={allState}
              values={values}
              setFieldValue={setFieldValue}
              fetchCalculation={fetchCalculation}
              calculation={calculation}
              setCalculation={setCalculation}
            />
          )}
          {isAllowWarehouseManagement && isAllowPriceVariant && (
            <WarehouseAndPrice
              allState={allState}
              values={values}
              setFieldValue={setFieldValue}
              fetchCalculation={fetchCalculation}
              calculation={calculation}
              setCalculation={setCalculation}
            />
          )}
          {values?.productName?.length > 0 && (
            <ImagesAndLink
              values={values}
              setFieldValue={setFieldValue}
              uploadFile={uploadFile}
            />
          )}

          <ProductBimCad
            values={values}
            setFieldValue={setFieldValue}
            uploadBimcad={uploadBimcad}
            allState={allState}
            setLoading={setLoading}
          />

          {values?.finalValidation && (
            <span className='text-danger'>{values?.finalValidation}</span>
          )}

          <Button
            variant='primary'
            className='d-flex align-items-center gap-2 justify-content-center fw-semibold'
            type='submit'
            onSubmit={onSubmit}
          >
            {values?.productId ? 'Update product' : 'Save product'}
          </Button>
        </Form>
      )}
    </Formik>
  )
}

export default AddProduct
