import React, {useState} from 'react';

function ArchieveIcon() {
    const [isHovered, setIsHovered] = useState(false);

    let fillColor = "#6c6c6c"
    let hoverColor = "#000"

    const handleMouseEnter = () => {
        setIsHovered(true);
    };

    const handleMouseLeave = () => {
        setIsHovered(false);
    };

    return (
        <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 50 50" style={{ "width": "1.8rem", height: "1.9rem" }} fill={isHovered ? hoverColor : fillColor} onMouseLeave={handleMouseLeave} onMouseEnter={handleMouseEnter}>
            <defs>
                <clipPath id="clip-Archieve">
                    <rect width="50" height="50" />
                </clipPath>
            </defs>
            <g id="Archieve" clipPath="url(#clip-Archieve)">
                <g id="noun-folder-4781963" transform="translate(-69.001 -47.531)">
                    <path id="Path_220" data-name="Path 220" d="M75.849,53.2A5.862,5.862,0,0,0,70,59.046V86.824a5.862,5.862,0,0,0,5.848,5.848h37.037a5.862,5.862,0,0,0,5.848-5.848V65.99a5.866,5.866,0,0,0-5.848-5.848h-16.2a2.52,2.52,0,0,1-2.26-1.4L92.5,54.907A3.1,3.1,0,0,0,89.737,53.2Zm0,2.437H89.737a.649.649,0,0,1,.586.362l1.919,3.839a4.97,4.97,0,0,0,4.439,2.744h16.2A3.393,3.393,0,0,1,116.3,65.99V86.823a3.377,3.377,0,0,1-3.411,3.411H75.849a3.377,3.377,0,0,1-3.411-3.411V59.046a3.377,3.377,0,0,1,3.411-3.411Z" transform="translate(0 2.29)" fillRule="evenodd" />
                    <path id="Path_221" data-name="Path 221" d="M124.408,132.99a1.218,1.218,0,1,0,0,2.437H138.3a1.218,1.218,0,1,0,0-2.437Z" transform="translate(-48.56 -70.558)" fillRule="evenodd" />
                    <path id="Path_196" data-name="Path 196" d="M9.546.224a.764.764,0,0,0-1.081,0L.224,8.465a.764.764,0,0,0,0,1.08l.81.811a.764.764,0,0,0,1.081,0L7.69,4.782V20.111a.764.764,0,0,0,.764.764H9.6a.764.764,0,0,0,.764-.764V4.826l5.53,5.53a.765.765,0,0,0,1.081,0l.811-.811a.765.765,0,0,0,0-1.08Z" transform="translate(103.373 89.004) rotate(180)" />
                    <path id="Path_222" data-name="Path 222" d="M74.9,53.2c-2.7,0-4.9,2.629-4.9,5.848,0,.713.022,1.409.093,2.516,3.833-2.213,8.138-3.348,12.59-1.1.6.3.506,3.329,1.72,3.91,2.572,1.228.219,1.586,3.2,3.283,5.862.547,11.66-.137,16.961,0,3.488.11,6.265,4.338,6.265-1.664,0-3.215-2.206-5.848-4.9-5.848H92.357a2.108,2.108,0,0,1-1.893-1.4l-1.608-3.84A2.591,2.591,0,0,0,86.538,53.2Zm0,2.437H86.538a.543.543,0,0,1,.491.362l1.608,3.839a4.157,4.157,0,0,0,3.72,2.744h13.578a3.154,3.154,0,0,1,2.858,3.411c.22.821.013,1.711-.81,3.028-2.469-1.426-14.894-.487-21.445-2.615-3.577-1.163-1.052-5.568-4.635-6.261-5-.97-6.361.158-9.79,1.077-.034-.366-.07-1.241-.07-2.174A3.14,3.14,0,0,1,74.9,55.634Z" transform="translate(7.899 -3.667)" fillRule="evenodd" />
                </g>
            </g>
        </svg>
    );
}

export default ArchieveIcon;