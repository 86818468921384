import React, { useState, useEffect } from 'react'
import { Image, Table } from 'react-bootstrap'
import TooltipComponent from '../../../components/Tooltip'
import ReactPaginate from 'react-paginate'
import { Formik, Form, ErrorMessage } from 'formik'
import * as Yup from 'yup'
import FormikControl from '../../../components/FormikControl'
import { Button } from 'react-bootstrap'
import SearchBox from '../../../components/Searchbox'
import useDebounce from '../../../lib/useDebounce'
import Select from 'react-select'
import axiosProvider from '../../../lib/AxiosProvider'
import ModelComponent from '../../../components/Modal'
import HKBadge from '../../../components/Badges'
import TextError from '../../../components/TextError'
import Loader from '../../../components/Loader'
import { customStyles } from '../../../components/customStyles'
import CustomToast from '../../../components/Toast/CustomToast'
import Swal from 'sweetalert2'
import { _SwalDelete } from '../../../lib/exceptionMessage'
import EditIcon from '../../../components/AllSvgIcon/EditIcon'
import DeleteIcon from '../../../components/AllSvgIcon/DeleteIcon'
import {
  _status_,
  pageRangeDisplayed,
  showToast
} from '../../../lib/GetBaseUrl'
import { useSelector } from 'react-redux'
import {
  allCrudNames,
  allPages,
  checkPageAccess
} from '../../../lib/AllPageNames'
import { _brandImg_ } from '../../../lib/ImagePath'
import { _alphabetRegex_ } from '../../../lib/Regex'
import { useLocation } from 'react-router-dom'
import { useImmer } from 'use-immer'

const MainBrand = () => {
  const [searchText, setSearchText] = useState()
  const [data, setData] = useState()
  const [modalShow, setModalShow] = useState(false)
  const [loading, setLoading] = useState(true)
  const [toast, setToast] = useState({
    show: false,
    text: null,
    variation: null
  })
  const [filterDetails, setFilterDetails] = useImmer({
    pageSize: 10,
    pageIndex: 1,
    searchText: ''
  })
  const { userId } = useSelector((state) => state?.user?.userInfo)
  const location = useLocation()
  const { pageAccess } = useSelector((state) => state.user)
  const debounceSearchText = useDebounce(searchText, 500)
  const initVal = {
    name: '',
    description: '',
    logo: '',
    status: 'Active',
    brandCertificate: '',
    backgroundBanner: '',
    browsebyBrandImage: ''
  }
  const [initialValues, setInitialValues] = useState(initVal)
  const SUPPORTED_FORMATS = [
    'image/jpg',
    'image/jpeg',
    'image/png',
    'application/pdf'
  ]

  useEffect(() => {
    if (debounceSearchText) {
      setFilterDetails((draft) => {
        draft.searchText = debounceSearchText
        draft.pageIndex = 1
      })
    } else {
      setFilterDetails((draft) => {
        draft.searchText = ''
        draft.pageIndex = 1
      })
    }
  }, [debounceSearchText])

  const fetchData = async () => {
    setLoading(true)
    const response = await axiosProvider({
      method: 'GET',
      endpoint: 'Brand/search',
      queryString: `?searchText=${filterDetails?.searchText}&pageIndex=${filterDetails?.pageIndex}&pageSize=${filterDetails?.pageSize}`
    })
      .then((res) => {
        if (res?.status === 200) {
          setLoading(false)
          setData(res)
        }
      })
      .catch((err) => {
        setLoading(false)
      })
  }

  useEffect(() => {
    fetchData()
  }, [filterDetails])

  const handlePageClick = (event) => {
    setFilterDetails((draft) => {
      draft.pageIndex = event.selected + 1
    })
  }

  const validationSchema = Yup.object().shape({
    name: Yup.string()
      .matches(_alphabetRegex_, 'Invalid brand name')
      .required('Please enter brand name'),
    description: Yup.string().required('Please enter description'),
    status: Yup.string()
      .test(
        'nonull',
        'Please select the status',
        (value) => value !== 'undefined'
      )
      .required('Please select the status'),
    logo: Yup.mixed()
      .test(
        'fileFormat',
        'File formate is not supported, Please use .jpg/.png/.jpeg format support',
        (value) => {
          if (typeof value === 'string') return true
          else {
            return value && SUPPORTED_FORMATS?.includes(value.type)
          }
        }
      )
      .test('fileSize', 'File must be less than 2MB', (value) => {
        if (typeof value === 'string') {
          return true
        } else return value !== undefined && value && value.size <= 2000000
      })
      .required('Image is required'),
    backgroundBanner: Yup.mixed()
      .test(
        'fileFormat',
        'File formate is not supported, Please use .jpg/.png/.jpeg format support',
        (value) => {
          if (typeof value === 'string') return true
          else {
            return value && SUPPORTED_FORMATS?.includes(value.type)
          }
        }
      )
      .test('fileSize', 'File must be less than 2MB', (value) => {
        if (typeof value === 'string') {
          return true
        } else return value !== undefined && value && value.size <= 2000000
      })
      .required('Background banner is required'),
    browsebyBrandImage: Yup.mixed()
      .test(
        'fileFormat',
        'File formate is not supported, Please use .jpg/.png/.jpeg format support',
        (value) => {
          if (typeof value === 'string') return true
          else {
            return value && SUPPORTED_FORMATS?.includes(value.type)
          }
        }
      )
      .test('fileSize', 'File must be less than 2MB', (value) => {
        if (typeof value === 'string') {
          return true
        } else return value !== undefined && value && value.size <= 2000000
      })
      .required('Browse by brand is required')
  })

  const onSubmit = async (values) => {
    let dataofForm = {
      Name: values.name,
      Description: values.description,
      Status: values.status,
      FileName: values?.logo ? values?.logo : '',
      Logo: values?.logo?.name ? values?.logo?.name : '',
      BackgroundBanner: values?.backgroundBanner?.name
        ? values?.backgroundBanner?.name
        : '',
      BackgroundBannerFileName: values?.backgroundBanner
        ? values?.backgroundBanner
        : '',
      BrowsebyBrandImage: values?.browsebyBrandImage?.name
        ? values?.browsebyBrandImage?.name
        : '',
      BrowsebyBrandImageFileName: values?.browsebyBrandImage
        ? values?.browsebyBrandImage
        : '',
      BrandCertificate: '',
      Website: values?.website
    }

    if (values?.id) {
      dataofForm = { ...dataofForm, Id: values?.id }
    }

    const submitFormData = new FormData()

    const keys = Object.keys(dataofForm)

    keys.forEach((key) => {
      submitFormData.append(key, dataofForm[key])
    })
    setLoading(true)
    const response = await axiosProvider({
      method: values?.id ? 'PUT' : 'POST',
      endpoint: `Brand`,
      data: submitFormData,
      oldData: initialValues,
      logData: values,
      location: location?.pathname,
      userId
    })
      .then((res) => {
        if (res?.status === 200) {
          setInitialValues(initVal)
          fetchData()
          setModalShow(false)
        }
        setLoading(false)
        showToast(toast, setToast, res)
      })
      .catch((err) => {
        setLoading(false)
      })
  }

  const handleDelete = async (id) => {
    setLoading(true)
    const response = await axiosProvider({
      method: 'DELETE',
      endpoint: `Brand?id=${id}`,
      userId,
      location: location.pathname
    })
      .then((res) => {
        if (res?.status === 200) {
          fetchData()
        }
        setLoading(false)
        showToast(toast, setToast, res)
      })
      .catch((err) => {
        setLoading(false)
      })
  }

  function truncateParagraph(text, maxLength = 325) {
    if (text.length <= maxLength) {
      return text
    } else {
      let truncatedText = text.substring(0, maxLength - 4) // Subtracting 4 for ' more'
      if (truncatedText.charAt(truncatedText.length - 1) !== ' ') {
        truncatedText = truncatedText.slice(0, -1) // Remove last character if not a space
      }
      return truncatedText + '...'
    }
  }

  return (
    <>
      <div className='d-flex align-items-center mb-3 gap-3'>
        {/* {checkPageAccess(pageAccess, allPages?.Brand, allCrudNames?.write) && (
          <Button
            variant='warning'
            className='d-flex align-items-center gap-2 fw-semiboldd-flex align-items-center gap-2 fw-semibold btn btn-warning ms-auto'
            onClick={() => {
              setInitialValues(initVal)
              setModalShow(true)
            }}
          >
            <i className='m-icon m-icon--plusblack'></i>
            Create
          </Button>
        )} */}
        <div className='d-flex align-items-center'>
          <label className='me-1'>Show</label>
          <select
            styles={customStyles}
            menuPortalTarget={document.body}
            name='dataget'
            id='parpageentries'
            className='form-select me-1'
            onChange={(e) => {
              setFilterDetails((draft) => {
                draft.pageSize = e?.target?.value
                draft.pageIndex = 1
              })
            }}
          >
            <option value='10'>10</option>
            <option value='25'>25</option>
            <option value='50'>50</option>
            <option value='100'>100</option>
            <option value='200'>200</option>
            <option value='500'>500</option>
          </select>
        </div>
        <SearchBox
          placeholderText={'Search'}
          value={searchText}
          searchclassnamewrapper={'searchbox-wrapper'}
          onChange={(e) => {
            setSearchText(e?.target?.value)
          }}
          // btnVariant={"secondary"}
        />
      </div>

      {toast?.show && (
        <CustomToast text={toast?.text} variation={toast?.variation} />
      )}

      <ModelComponent
        show={modalShow}
        modalsize={'md'}
        modalheaderclass={''}
        modeltitle={!initialValues?.id ? 'Create Brand' : 'Update Brand'}
        onHide={() => setModalShow(false)}
        btnclosetext={''}
        closebtnvariant={''}
        backdrop={'static'}
        formbuttonid={'mainBrand'}
        submitname={!initialValues?.id ? 'Create' : 'Update'}
      >
        <Formik
          enableReinitialize
          initialValues={initialValues}
          validationSchema={validationSchema}
          onSubmit={onSubmit}
        >
          {({ values, setFieldValue }) => (
            <Form id='mainBrand'>
              <div className='row'>
                <div className='col-md-4'>
                  {loading && <Loader />}
                  <div className='input-file-wrapper m--cst-filetype mb-3'>
                    <label className='form-label required' htmlFor='logo'>
                      Logo
                    </label>
                    <input
                      id='filename'
                      className='form-control'
                      name='logo'
                      type='file'
                      accept='image/jpg, image/png, image/jpeg'
                      onChange={(event) => {
                        const objectUrl = URL.createObjectURL(
                          event.target.files[0]
                        )
                        if (event.target.files[0].type !== '') {
                          setFieldValue('logoUrl', objectUrl)
                        }
                        setFieldValue(
                          'logo',
                          event?.target?.files[0] ? event?.target?.files[0] : ''
                        )
                      }}
                      hidden
                    />
                    {values?.logo ? (
                      <div className='position-relative m--img-preview d-flex rounded-2 overflow-hidden'>
                        <img
                          src={
                            values?.logoUrl?.includes('blob')
                              ? values?.logoUrl
                              : `${process.env.REACT_APP_IMG_URL}${_brandImg_}${values?.logo}`
                          }
                          alt='Preview Logo'
                          title={values?.logo ? values?.filename?.name : ''}
                          className='rounded-2'
                        ></img>
                        <span
                          onClick={(e) => {
                            setFieldValue('logo', null)
                            setFieldValue('filename', '')
                            document.getElementById('filename').value = null
                          }}
                        >
                          <i className='m-icon m-icon--close'></i>
                        </span>
                      </div>
                    ) : (
                      <>
                        <label
                          className='m__image_default d-flex align-items-center justify-content-center rounded-2'
                          htmlFor='filename'
                        >
                          <i className='m-icon m-icon--defaultpreview'></i>
                        </label>
                      </>
                    )}
                    <ErrorMessage
                      name='logo'
                      component={TextError}
                      customclass={'cfz-12 lh-sm'}
                    />
                  </div>
                </div>

                <div className='col-md-4'>
                  <div className='input-file-wrapper m--cst-filetype mb-3'>
                    <label
                      className='form-label required'
                      htmlFor='backgroundBanner'
                    >
                      Background Banner
                    </label>
                    <input
                      id='backgroundBannerFileName'
                      className='form-control'
                      name='backgroundBanner'
                      type='file'
                      accept='image/jpg, image/png, image/jpeg'
                      onChange={(event) => {
                        const objectUrl = URL.createObjectURL(
                          event.target.files[0]
                        )
                        if (event.target.files[0].type !== '') {
                          setFieldValue('backgroundBannerUrl', objectUrl)
                        }
                        setFieldValue(
                          'backgroundBanner',
                          event?.target?.files[0] ? event?.target?.files[0] : ''
                        )
                      }}
                      hidden
                    />
                    {values?.backgroundBanner ? (
                      <div className='position-relative m--img-preview d-flex rounded-2 overflow-hidden'>
                        <img
                          src={
                            values?.backgroundBannerUrl?.includes('blob')
                              ? values?.backgroundBannerUrl
                              : `${process.env.REACT_APP_IMG_URL}${_brandImg_}${values?.backgroundBanner}`
                          }
                          alt='Preview Banner'
                          title={
                            values?.backgroundBanner
                              ? values?.backgroundBanner?.name
                              : ''
                          }
                          className='rounded-2'
                        ></img>
                        <span
                          onClick={(e) => {
                            setFieldValue('backgroundBanner', null)
                            setFieldValue('backgroundBannerFileName', '')
                            document.getElementById(
                              'backgroundBannerFileName'
                            ).value = null
                          }}
                        >
                          <i className='m-icon m-icon--close'></i>
                        </span>
                      </div>
                    ) : (
                      <>
                        <label
                          className='m__image_default d-flex align-items-center justify-content-center rounded-2'
                          htmlFor='backgroundBannerFileName'
                        >
                          <i className='m-icon m-icon--defaultpreview'></i>
                        </label>
                      </>
                    )}
                    <ErrorMessage
                      name='backgroundBanner'
                      component={TextError}
                      customclass={'cfz-12 lh-sm'}
                    />
                  </div>
                </div>

                <div className='col-md-4'>
                  <div className='input-file-wrapper m--cst-filetype mb-3'>
                    <label
                      className='form-label required'
                      htmlFor='browsebyBrandImage'
                    >
                      Browse By Brand
                    </label>
                    <input
                      id='browsebyBrandImageFileName'
                      className='form-control'
                      name='browsebyBrandImage'
                      type='file'
                      accept='image/jpg, image/png, image/jpeg'
                      onChange={(event) => {
                        const objectUrl = URL.createObjectURL(
                          event.target.files[0]
                        )
                        if (event.target.files[0].type !== '') {
                          setFieldValue('browsebyBrandImageUrl', objectUrl)
                        }
                        setFieldValue(
                          'browsebyBrandImage',
                          event?.target?.files[0] ? event?.target?.files[0] : ''
                        )
                      }}
                      hidden
                    />
                    {values?.browsebyBrandImage ? (
                      <div className='position-relative m--img-preview d-flex rounded-2 overflow-hidden'>
                        <img
                          src={
                            values?.browsebyBrandImageUrl?.includes('blob')
                              ? values?.browsebyBrandImageUrl
                              : `${process.env.REACT_APP_IMG_URL}${_brandImg_}${values?.browsebyBrandImage}`
                          }
                          alt='Preview Brand'
                          title={
                            values?.browsebyBrandImage
                              ? values?.browsebyBrandImage?.name
                              : ''
                          }
                          className='rounded-2'
                        ></img>
                        <span
                          onClick={(e) => {
                            setFieldValue('browsebyBrandImage', null)
                            setFieldValue('browsebyBrandImageFileName', '')
                            document.getElementById(
                              'browsebyBrandImageFileName'
                            ).value = null
                          }}
                        >
                          <i className='m-icon m-icon--close'></i>
                        </span>
                      </div>
                    ) : (
                      <>
                        <label
                          className='m__image_default d-flex align-items-center justify-content-center rounded-2'
                          htmlFor='browsebyBrandImageFileName'
                        >
                          <i className='m-icon m-icon--defaultpreview'></i>
                        </label>
                      </>
                    )}
                    <ErrorMessage
                      name='browsebyBrandImage'
                      component={TextError}
                      customclass={'cfz-12 lh-sm'}
                    />
                  </div>
                </div>

                <div className='col-md-12'>
                  <FormikControl
                    isRequired
                    control='input'
                    label='Brand Name'
                    type='text'
                    name='name'
                    placeholder='Brand name'
                    onChange={(e) => {
                      setFieldValue('name', e?.target?.value)
                    }}
                    onBlur={(e) => {
                      let fieldName = e?.target?.name
                      setFieldValue(fieldName, values[fieldName]?.trim())
                    }}
                  />
                </div>
                <div className='col-md-12'>
                  <FormikControl
                    isRequired
                    as='textarea'
                    control='input'
                    label='Description'
                    type='text'
                    name='description'
                    placeholder='Description'
                    onBlur={(e) => {
                      let fieldName = e?.target?.name
                      setFieldValue(fieldName, values[fieldName]?.trim())
                    }}
                  />
                </div>
                <div className='col-md-12'>
                  <FormikControl
                    isRequired
                    control='input'
                    label='Website'
                    type='text'
                    name='website'
                    placeholder='Enter website'
                    onChange={(e) => {
                      setFieldValue('website', e?.target?.value)
                    }}
                    onBlur={(e) => {
                      let fieldName = e?.target?.name
                      setFieldValue(fieldName, values[fieldName]?.trim())
                    }}
                  />
                </div>
              </div>

              {/* <div className='row'>
                <div className='col-md-12'>
                  <div className='input-select-wrapper mb-3'>
                    <label className='form-label required'>Select Status</label>
                    <Select
                      id='status'
                      menuPortalTarget={document.body}
                      menuPosition={'fixed'}
                      value={
                        values?.status && {
                          value: values.status,
                          label: values.status
                        }
                      }
                      styles={customStyles}
                      options={_status_}
                      onChange={(e) => {
                        if (e) {
                          setFieldValue('status', e?.value)
                        }
                      }}
                    />
                    <ErrorMessage name='status' component={TextError} />
                  </div>
                </div>
              </div> */}
            </Form>
          )}
        </Formik>
      </ModelComponent>

      {loading && !modalShow && <Loader />}

      <Table responsive className='align-middle table-list'>
        <thead>
          <tr>
            <th>Brand Name</th>
            <th>Description</th>
            {/* <th>Status</th> */}
            {checkPageAccess(pageAccess, allPages?.Brand, [
              allCrudNames?.update,
              allCrudNames?.delete
            ]) && <th className='text-center'>Action</th>}
          </tr>
        </thead>
        <tbody>
          {data?.data?.data?.length > 0 ? (
            data?.data?.data?.map((data, index) => (
              <tr key={data.id}>
                <td>
                  <div className='d-flex gap-2 align-items-center'>
                    <Image
                      src={
                        data?.logo
                          ? `${process.env.REACT_APP_IMG_URL}${_brandImg_}${data?.logo}`
                          : 'https://placehold.jp/50x50.png'
                      }
                      className='rounded-1 img-object-fit-con'
                      height='50px'
                      width='50px'
                    />
                    <span>{data.name}</span>
                  </div>
                </td>
                <td>{truncateParagraph(data.description, 355)}</td>
                {/* <td>
                  <HKBadge
                    badgesbgname={
                      data.status === 'Active' ? 'success' : 'danger'
                    }
                    badgesTxtname={data.status}
                    badgeClassname={''}
                  />
                </td> */}
                {checkPageAccess(pageAccess, allPages?.Brand, [
                  allCrudNames?.update,
                  allCrudNames?.delete
                ]) && (
                  <td className='text-center'>
                    <div className='d-flex gap-2 justify-content-center'>
                      {checkPageAccess(
                        pageAccess,
                        allPages?.Brand,
                        allCrudNames?.update
                      ) && (
                        <span
                          onClick={() => {
                            setModalShow(!modalShow)
                            setInitialValues(data)
                          }}
                        >
                          <EditIcon bg={'bg'} />
                        </span>
                      )}
                      {/* {checkPageAccess(
                        pageAccess,
                        allPages?.Brand,
                        allCrudNames?.delete
                      ) && (
                        <span
                          onClick={() => {
                            Swal.fire({
                              title: _SwalDelete.title,
                              text: _SwalDelete.text,
                              icon: _SwalDelete.icon,
                              showCancelButton: _SwalDelete.showCancelButton,
                              confirmButtonColor:
                                _SwalDelete.confirmButtonColor,
                              cancelButtonColor: _SwalDelete.cancelButtonColor,
                              confirmButtonText: _SwalDelete.confirmButtonText,
                              cancelButtonText: _SwalDelete.cancelButtonText
                            }).then((result) => {
                              if (result.isConfirmed) {
                                handleDelete(data?.id)
                              } else if (result.isDenied) {
                              }
                            })
                          }}
                        >
                          <DeleteIcon bg={'bg'} />
                        </span>
                      )} */}
                    </div>
                  </td>
                )}
              </tr>
            ))
          ) : (
            <tr>
              <td colSpan={4} className='text-center'>
                {data?.data?.message}
              </td>
            </tr>
          )}
        </tbody>
      </Table>

      <ReactPaginate
        className='list-inline m-cst--pagination d-flex justify-content-end gap-1'
        breakLabel='...'
        nextLabel=''
        onPageChange={handlePageClick}
        pageRangeDisplayed={pageRangeDisplayed}
        pageCount={data?.data?.pagination?.pageCount ?? 0}
        previousLabel=''
        renderOnZeroPageCount={null}
        forcePage={filterDetails?.pageIndex - 1}
      />
    </>
  )
}

export default MainBrand
