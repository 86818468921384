import React from 'react'
import Select from 'react-select'
import { Formik, Form, ErrorMessage } from 'formik'
import { Form as frm } from 'react-bootstrap'
import { Row, Col } from 'react-bootstrap'
import Button from 'react-bootstrap/Button'

function AdminAcountInformation() {
  return (
    <div style={{ backgroundColor: 'var(--bs-white)' }} className='p-4 rounded'>
      <Formik>
        <Form>
          <Row className='mb-3'>
            <p style={{ fontWeight: '600' }}>Admin Account Information</p>
            <frm.Group as={Col} controlId='formGridEmail'>
              <frm.Label>Email ID*</frm.Label>
              <frm.Control type='email' placeholder='Enter email' />
            </frm.Group>

            <frm.Group as={Col} controlId='formGridPassword'>
              <frm.Label>Password*</frm.Label>
              <frm.Control type='password' placeholder='Password' />
            </frm.Group>
            <frm.Group as={Col} controlId='formGridPasswords'>
              <frm.Label>Confirm PassWord*</frm.Label>
              <frm.Control type='passwords' placeholder='Confirm PassWord' />
            </frm.Group>
          </Row>
          <p style={{ fontWeight: '600' }} className='mt-4 mb-2'>
            Admin Personal Information
          </p>
          <Row className='mb-3 g-3'>
            <Col md={6}>
              <frm.Group controlId='formGridFirstName'>
                <frm.Label>First Name*</frm.Label>
                <frm.Control type='text' />
              </frm.Group>
            </Col>
            <Col md={6}>
              <frm.Group controlId='formGridLastName'>
                <frm.Label>Last Name*</frm.Label>
                <frm.Control type='text' />
              </frm.Group>
            </Col>
            <Col md={6}>
              <frm.Group controlId='formGridMobileNo'>
                <frm.Label>Mobile No*</frm.Label>
                <frm.Control type='text' />
              </frm.Group>
            </Col>

            <Col md={6}>
              <frm.Group controlId='formGridState'>
                <frm.Label>State*</frm.Label>
                <frm.Select defaultValue='Choose...'>
                  <option>Choose...</option>
                  <option>...</option>
                </frm.Select>
              </frm.Group>
            </Col>
            <Col md={6}>
              <frm.Group as={Col} controlId='formGridZip'>
                <frm.Label>Admin Image*</frm.Label>
                <frm.Control type='file' />
              </frm.Group>
            </Col>
          </Row>

          <Button
            variant='primary'
            className='btn-ct-lightblue d-flex align-items-center justify-content-center m-auto'
            type='submit'
          >
            Update Admin
          </Button>
        </Form>
      </Formik>
    </div>
  )
}

export default AdminAcountInformation
