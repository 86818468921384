import { Form, Formik } from 'formik'
import React, { useEffect, useState } from 'react'
import { Button, Table } from 'react-bootstrap'
import ReactPaginate from 'react-paginate'
import { useSelector } from 'react-redux'
import { useLocation } from 'react-router-dom'
import Swal from 'sweetalert2'
import { useImmer } from 'use-immer'
import * as Yup from 'yup'
import DeleteIcon from '../../../../components/AllSvgIcon/DeleteIcon'
import EditIcon from '../../../../components/AllSvgIcon/EditIcon'
import FormikControl from '../../../../components/FormikControl'
import Loader from '../../../../components/Loader'
import ModelComponent from '../../../../components/Modal'
import SearchBox from '../../../../components/Searchbox'
import CustomToast from '../../../../components/Toast/CustomToast'
import {
  allCrudNames,
  allPages,
  checkPageAccess
} from '../../../../lib/AllPageNames'
import axiosProvider from '../../../../lib/AxiosProvider'
import { _SwalDelete } from '../../../../lib/exceptionMessage'
import { pageRangeDisplayed } from '../../../../lib/GetBaseUrl'
import useDebounce from '../../../../lib/useDebounce'

const SizeType = () => {
  const [searchText, setSearchText] = useState()
  const [data, setData] = useState()
  const [modalShow, setModalShow] = useState(false)
  const [loading, setLoading] = useState(false)
  const [toast, setToast] = useState({
    show: false,
    text: null,
    variation: null
  })
  const initVal = {
    typeName: ''
  }
  const [initialValues, setInitialValues] = useState(initVal)
  const { pageAccess } = useSelector((state) => state.user)
  const { userId } = useSelector((state) => state?.user?.userInfo)
  const location = useLocation()

  const debounceSearchText = useDebounce(searchText, 500)
  const [filterDetails, setFilterDetails] = useImmer({
    pageSize: 10,
    pageIndex: 1,
    searchText: ''
  })

  useEffect(() => {
    if (debounceSearchText) {
      setFilterDetails((draft) => {
        draft.searchText = debounceSearchText
        draft.pageIndex = 1
      })
    } else {
      setFilterDetails((draft) => {
        draft.searchText = ''
        draft.pageIndex = 1
      })
    }
  }, [debounceSearchText])

  const fetchData = async () => {
    setLoading(true)
     await axiosProvider({
      method: 'GET',
      endpoint: 'SizeType/search',
      queryString: `?searchText=${filterDetails?.searchText}&pageIndex=${filterDetails?.pageIndex}&pageSize=${filterDetails?.pageSize}`
    })
      .then((res) => {
        setLoading(false)
        if (res.status === 200) {
          setData(res)
        }
      })
      .catch((err) => setLoading(false))
  }

  useEffect(() => {
    fetchData()
  }, [filterDetails])

  const handlePageClick = (event) => {
    setFilterDetails((draft) => {
      draft.pageIndex = event.selected + 1
    })
  }

  const validationSchema = Yup.object().shape({
    typeName: Yup.string()
      .min(1, 'Your Name must consist of at least 3 characters')
      .max(50, 'Your Name is to long')
      .required('Please enter size type')
  })

  const onSubmit = async (values, { resetForm }) => {
    let method = 'POST'
    let endpoint = 'SizeType/CreateParentSize'
    if (values?.id) {
      method = 'PUT'
      endpoint = 'SizeType'
    }
    setLoading(true)
    const createCategory = await axiosProvider({
      method,
      endpoint,
      data: values,
      location: location?.pathname,
      userId,
      oldData: initialValues
    })
      .then((res) => {
        if (res.status === 200) {
          setModalShow(false)
          resetForm({ values: '' })
          fetchData()
        }
        setLoading(false)
        setToast({
          show: true,
          text: res?.data?.message,
          variation: res?.data?.code !== 200 ? 'error' : 'success'
        })

        setTimeout(() => {
          setToast({ ...toast, show: false })
        }, 2000)
      })
      .catch((err) => {
        setLoading(false)
      })
  }

  // const handleEditData = async (id) => {
  //   setLoading(true)
  //   const response = await axiosProvider({
  //     method: 'GET',
  //     endpoint: 'SizeType/ById',
  //     queryString: `?Id=${id}`
  //   })
  //     .then((res) => {
  //       if (res.status === 200) {
  //         setEditData(res?.data?.data)
  //         setInnitialData(false)
  //         setModalShow(true)
  //       }
  //       setLoading(false)
  //     })
  //     .catch((err) => {
  //       setLoading(false)
  //     })
  // }

  // const handleUpdate = async (values, { resetForm }) => {
  //   setLoading(true)
  //   const response = await axiosProvider({
  //     method: 'PUT',
  //     endpoint: 'SizeType',
  //     data: {
  //       id: editData.id,
  //       guid: editData.guid,
  //       typeName: values.TypeName
  //     },
  //     logData: values,
  //     oldData: editData,
  //     location: location?.pathname,
  //     userId
  //   })
  //     .then((res) => {
  //       if (res.status === 200) {
  //         resetForm({ values: '' })
  //         setModalShow(false)
  //         fetchData()
  //         setEditData('')
  //       }
  //       setToast({
  //         show: true,
  //         text: res?.data?.message,
  //         variation: res?.data?.code !== 200 ? 'error' : 'success'
  //       })

  //       setTimeout(() => {
  //         setToast({ ...toast, show: false })
  //       }, 2000)
  //       setLoading(false)
  //     })
  //     .catch((err) => {
  //       setLoading(false)
  //     })
  // }

  const handleDelete = async (id) => {
    setLoading(true)
    await axiosProvider({
      method: 'DELETE',
      endpoint: 'SizeType',
      queryString: `?Id=${id}`,
      userId,
      location: location.pathname
    })
      .then((res) => {
        if (res?.status === 200) {
          if (res?.data?.code === 200) {
            if (
              filterDetails?.pageIndex > 1 &&
              data?.data?.data?.length === 1
            ) {
              setFilterDetails((draft) => {
                draft.pageIndex = filterDetails?.pageIndex - 1
              })
            } else {
              fetchData()
            }
          }
        }
        setToast({
          show: true,
          text: res?.data?.message,
          variation: res?.data?.code !== 200 ? 'error' : 'success'
        })

        setTimeout(() => {
          setToast({ ...toast, show: false })
        }, 2000)
        setLoading(false)
      })
      .catch((err) => {
        setLoading(false)
      })
  }

  return (
    <>
      <div className='d-flex align-items-center mb-3 gap-3 flex-row-reverse'>
        {checkPageAccess(pageAccess, allPages?.size, allCrudNames?.write) && (
          <Button
            variant='warning'
            className='d-flex align-items-center gap-2 fw-semibold btn btn-warning ms-auto'
            onClick={() => {
              setInitialValues(initVal)
              setModalShow(true)
            }}
          >
            <i className='m-icon m-icon--plusblack'></i>
            Create
          </Button>
        )}
        <div className='d-flex align-items-center me-2'>
          <label className='me-1'>Show</label>
          <select
            name='dataget'
            className='form-select me-1'
            onChange={(e) => {
              setFilterDetails((draft) => {
                draft.pageSize = e?.target?.value
                draft.pageIndex = 1
              })
            }}
          >
            <option value='10'>10</option>
            <option value='25'>25</option>
            <option value='50'>50</option>
            <option value='100'>100</option>
            <option value='200'>200</option>
            <option value='500'>500</option>
          </select>
        </div>
        <SearchBox
          placeholderText='Search'
          searchclassnamewrapper={'searchbox-wrapper'}
          value={searchText}
          onChange={(e) => {
            setSearchText(e?.target?.value)
          }}
        />
      </div>

      {toast?.show && (
        <CustomToast text={toast?.text} variation={toast?.variation} />
      )}

      <ModelComponent
        show={modalShow}
        modalsize={'md'}
        modalheaderclass={''}
        modeltitle={'Size Type'}
        onHide={() => {
          setInitialValues(initVal)
          setModalShow(false)
        }}
        btnclosetext={''}
        closebtnvariant={''}
        backdrop={'static'}
        formbuttonid={'type-size'}
      >
        <Formik
          enableReinitialize={true}
          initialValues={initialValues}
          validationSchema={validationSchema}
          onSubmit={onSubmit}
        >
          {({ values, setFieldValue }) => (
            <Form id='type-size'>
              <div className='row align-items-center'>
                <div className='col-md-12'>
                  {loading && <Loader />}
                  <div className='input-wrapper mb-3'>
                    <FormikControl
                      isRequired
                      control='input'
                      label={values?.id ? 'Update type' : 'Add type'}
                      type='text'
                      name='typeName'
                      placeholder='Enter Size Type'
                      onChange={(e) => {
                        setFieldValue('typeName', e?.target?.value)
                      }}
                    />
                  </div>
                </div>
              </div>
            </Form>
          )}
        </Formik>
      </ModelComponent>

      {loading && !modalShow && <Loader />}

      <Table responsive className='align-middle table-list'>
        <thead>
          <tr>
            <th>Size Type</th>
            {checkPageAccess(pageAccess, allPages?.size, [
              allCrudNames?.update,
              allCrudNames?.delete
            ]) && <th className='text-center'>Action</th>}
          </tr>
        </thead>
        <tbody>
          {data?.data?.data?.length > 0 ? (
            data?.data?.data?.map((type) => (
              <tr key={type.id}>
                <td>{type.typeName}</td>
                {checkPageAccess(pageAccess, allPages?.size, [
                  allCrudNames?.update,
                  allCrudNames?.delete
                ]) && (
                  <td className='text-center'>
                    <div className='d-flex gap-2 justify-content-center'>
                      {checkPageAccess(
                        pageAccess,
                        allPages?.size,
                        allCrudNames?.update
                      ) && (
                        <span
                          onClick={() => {
                            setModalShow(!modalShow)
                            setInitialValues(type)
                            // handleEditData(type.id)
                          }}
                        >
                          <EditIcon bg={'bg'} />
                        </span>
                      )}
                      {checkPageAccess(
                        pageAccess,
                        allPages?.size,
                        allCrudNames?.delete
                      ) && (
                        <span
                          onClick={() => {
                            Swal.fire({
                              title: _SwalDelete.title,
                              text: _SwalDelete.text,
                              icon: _SwalDelete.icon,
                              showCancelButton: _SwalDelete.showCancelButton,
                              confirmButtonColor:
                                _SwalDelete.confirmButtonColor,
                              cancelButtonColor: _SwalDelete.cancelButtonColor,
                              confirmButtonText: _SwalDelete.confirmButtonText,
                              cancelButtonText: _SwalDelete.cancelButtonText
                            }).then((result) => {
                              if (result.isConfirmed) {
                                handleDelete(type.id)
                              }
                            })
                          }}
                        >
                          <DeleteIcon bg={'bg'} />
                        </span>
                      )}
                    </div>
                  </td>
                )}
              </tr>
            ))
          ) : (
            <tr>
              <td colSpan={2} className='text-center'>
                {data?.data?.message}
              </td>
            </tr>
          )}
        </tbody>
      </Table>

      <ReactPaginate
        className='list-inline m-cst--pagination d-flex justify-content-end gap-1'
        breakLabel='...'
        nextLabel=''
        onPageChange={handlePageClick}
        pageRangeDisplayed={pageRangeDisplayed}
        pageCount={data?.data?.pagination?.pageCount ?? 0}
        previousLabel=''
        renderOnZeroPageCount={null}
        forcePage={filterDetails?.pageIndex - 1}
      />
    </>
  )
}

export default SizeType
