import { ErrorMessage } from 'formik'
import React, { useEffect, useRef } from 'react'
import FormikControl from '../../components/FormikControl'
import { Button, Form as frm, InputGroup, Table } from 'react-bootstrap'
import TextError from '../../components/TextError'
import { isAllowExpiryDate } from '../../lib/GetBaseUrl'
import { _productImg_, _tempImg_ } from '../../lib/ImagePath'
import DeleteIcon from '../../components/AllSvgIcon/DeleteIcon'
import * as Yup from 'yup'
import Swal from 'sweetalert2'
import { _SwalDelete } from '../../lib/exceptionMessage'

const ImagesAndLink = ({ values, setFieldValue, uploadFile }) => {
  const SUPPORTED_FORMATS = ['image/jpg', 'image/jpeg', 'image/png']

  const validateImage = Yup.object().shape(
    {
      filename: Yup.mixed().when('filename', {
        is: (value) => value?.name,
        then: (schema) =>
          schema
            .test(
              'fileFormat',
              'File formate is not supported, Please use .jpg/.png/.jpeg format support',
              (value) => value && SUPPORTED_FORMATS.includes(value.type)
            )
            .test('fileSize', 'File must be less than 2MB', (value) => {
              return value !== undefined && value && value.size <= 2000000
            }),
        otherwise: (schema) => schema.nullable()
      })
    },
    ['filename', 'filename']
  )

  return (
    <div className='card'>
      <div className='card-body'>
        {isAllowExpiryDate && (
          <div className='row'>
            <div className='col-md-6 col-xxl-5 mb-3'>
              <FormikControl
                control='input'
                label='Manufacture Date'
                id='date'
                type='date'
                name='date'
              />
            </div>
            <div className='col-md-6 col-xxl-5 mb-3'>
              <FormikControl
                control='input'
                label='Expiry Date'
                id='date'
                type='date'
                name='date'
              />
            </div>
          </div>
        )}

        <h5 className='mb-3 head_h3'>Product Images</h5>
        <p>Product Image - Size: Width = 1080px, Height = 1080px</p>
        <div className='row justify-content-start mb-3'>
          {values?.productImage?.length === 0 && (
            <div className='col-md-2'>
              <div className='input-file-wrapper m--cst-filetype mb-3 m-auto'>
                {/* <label className='form-label d-block' htmlFor='categoryimg'>
                Image
              </label> */}
                <input
                  id='logo'
                  className='form-control'
                  name='logo'
                  type='file'
                  accept='image/jpg, image/png, image/jpeg'
                  onChange={async (event) => {
                    const file = event.target.files[0]

                    try {
                      await validateImage.validate({ filename: file })
                      setFieldValue('validation', {
                        ...values?.validation,
                        file: ''
                      })
                      uploadFile(values, 1, file, setFieldValue)
                      const objectUrl = URL.createObjectURL(file)
                      if (file.type !== '') {
                        setFieldValue('logoUrl', objectUrl)
                      }
                      setFieldValue('logo', file)
                    } catch (error) {
                      setFieldValue('validation', {
                        ...values?.validation,
                        file: error?.message
                      })
                    }
                  }}
                  hidden
                />
                {values?.logoUrl ? (
                  <div className='position-relative m--img-preview d-flex rounded-2 overflow-hidden m-auto'>
                    <img
                      src={values?.logoUrl}
                      alt='Preview Logo'
                      title={values?.logoUrl && values?.logo?.name}
                      className='rounded-2'
                    ></img>
                    <span
                      onClick={(e) => {
                        setFieldValue('logoUrl', null)
                        setFieldValue('logo', null)
                        document.getElementById('logo').value = null
                      }}
                    >
                      <i className='m-icon m-icon--close'></i>
                    </span>
                  </div>
                ) : (
                  <>
                    <label
                      className='m__image_default d-flex align-items-center justify-content-center rounded-2 m-auto'
                      htmlFor='logo'
                    >
                      <i className='m-icon m-icon--defaultpreview'></i>
                    </label>
                  </>
                )}
                {values?.validation?.file && (
                  <p className='pt-1 text-danger'>{values?.validation?.file}</p>
                )}
                <p className='small-pa pt-1'>Product Image - 1</p>
              </div>
            </div>
          )}
          {values?.productImage?.length > 0 &&
            values?.productImage?.map((data, index) => (
              <div
                className='col-md-2'
                key={data?.sequence * Math.floor(Math.random() * 10000)}
              >
                <div className='input-file-wrapper m--cst-filetype mb-3 m-auto'>
                  <input
                    id={data?.sequence}
                    className='form-control'
                    name={data?.sequence}
                    type='file'
                    accept='image/jpg, image/png, image/jpeg'
                    onChange={(event) => {
                      if (event.target.files[0].type !== '') {
                        uploadFile(
                          values,
                          data?.sequence,
                          event?.target?.files[0],
                          setFieldValue
                        )
                        setFieldValue('validation', {
                          ...values?.validation,
                          image: ''
                        })
                      }
                    }}
                    hidden
                  />
                  {data?.imageUrl || data?.image ? (
                    <div className='position-relative m--img-preview d-flex rounded-2 overflow-hidden m-auto'>
                      <img
                        src={
                          data?.imageUrl?.includes('blob')
                            ? data?.imageUrl
                            : data?.id
                            ? `${process.env.REACT_APP_IMG_URL}${_productImg_}${data?.image}`
                            : `${process.env.REACT_APP_IMG_URL}${_tempImg_}${data?.image}`
                        }
                        // alt='Preview Image'
                        title={`Preview Image ${data?.sequence}`}
                        className='rounded-2 w-100 h-100 overflow-hidden pv-product-img'
                      ></img>
                      <span
                        onClick={() => {
                          setFieldValue('logoUrl', null)
                          setFieldValue('logo', null)
                          if (document.getElementById('logo')) {
                            document.getElementById('logo').value = null
                          }
                          let productImage = values?.productImage ?? []
                          productImage = productImage?.filter((item) => {
                            if (item?.id) {
                              return item.id !== data?.id
                            } else {
                              return item?.sequence !== index + 1
                            }
                          })
                          productImage = productImage.map((item, index) => {
                            return { ...item, sequence: index + 1 }
                          })
                          setFieldValue('productImage', productImage)
                        }}
                      >
                        <i className='m-icon m-icon--close'></i>
                      </span>
                    </div>
                  ) : (
                    <>
                      <label
                        className='m__image_default d-flex align-items-center justify-content-center rounded-2 m-auto'
                        htmlFor={data?.sequence}
                      >
                        <i className='m-icon m-icon--defaultpreview'></i>
                      </label>
                    </>
                  )}
                  <ErrorMessage
                    name={data?.sequence}
                    component={TextError}
                    customclass={'cfz-12 lh-sm'}
                  />
                  <p className='small-pa pt-1'>
                    Product Image - {data?.sequence}
                  </p>
                </div>
              </div>
            ))}

          {values?.productImage?.length <= 9 && (
            <div className='col-md-2'>
              <div className='input-file-wrapper m--cst-filetype mb-3 m-auto'>
                <input
                  id='logo'
                  className='form-control'
                  name='logo'
                  type='file'
                  accept='image/jpg, image/png, image/jpeg'
                  onChange={(event) => {
                    let productImage = values?.productImage ?? []
                    if (
                      productImage.length > 0 &&
                      productImage.every(
                        (item) =>
                          typeof item.image === 'string' &&
                          item.image.length > 0
                      )
                    ) {
                      setFieldValue('validation', {
                        ...values?.validation,
                        image: ''
                      })
                      let lastSequence =
                        productImage[productImage.length - 1].sequence
                      let newSequence = lastSequence + 1
                      uploadFile(
                        values,
                        newSequence,
                        event?.target?.files[0],
                        setFieldValue
                      )
                      // let newItem = { sequence: newSequence, image: '' }
                      // productImage.push(newItem)
                      // setFieldValue('productImage', productImage)
                    } else {
                      setFieldValue('validation', {
                        ...values?.validation,
                        image: 'Please add previous image'
                      })
                    }
                  }}
                  hidden
                />
                <label
                  className='m__image_default d-flex align-items-center justify-content-center rounded-2 m-auto'
                  htmlFor='logo'
                >
                  <i className='m-icon m-icon--plusblack'></i>
                </label>
              </div>
            </div>
          )}
          {values?.validation?.image && (
            <span className='text-danger'>{values?.validation?.image}</span>
          )}
        </div>
        <div className='row'>
          <div className='col-md-5'>
            <FormikControl
              control='input'
              label='Video Id'
              id='videoLink'
              type='text'
              name='videoLink'
              placeholder='Video Id'
              onChange={(e) => {
                setFieldValue('videoLink', e?.target?.value)
              }}
            />
          </div>
          <div className='col-md-5'>
            <FormikControl
              control='input'
              label='Video Id Title'
              id='title'
              type='text'
              name='title'
              placeholder='Video Id Title'
              onChange={(e) => {
                setFieldValue('title', e?.target?.value)
              }}
            />
          </div>
          <div className='col-md-2'>
            <Button
              variant='primary'
              className='fw-semibold w-100 mt-4'
              onClick={() => {
                if (values?.videoLink && values?.title) {
                  setFieldValue('validation', {
                    ...values?.validation,
                    productVideoLinks: ''
                  })
                  if (values?.productVideoLinks?.length < 5) {
                    let videoLinkArray = values?.productVideoLinks ?? []
                    let list = {
                      link: values?.videoLink,
                      key: Math.floor(Math.random() * 100000),
                      title: values?.title
                    }
                    setFieldValue('videoLink', '')
                    setFieldValue('title', '')
                    setFieldValue('productVideoLinks', [
                      ...videoLinkArray,
                      list
                    ])
                  }
                } else {
                  if (!values?.videoLink) {
                    setFieldValue('validation', {
                      ...values?.validation,
                      productVideoLinks: 'Please enter link'
                    })
                  } else if (!values?.title) {
                    setFieldValue('validation', {
                      ...values?.validation,
                      productVideoLinks: 'Please enter title'
                    })
                  }
                }
              }}
            >
              Add
            </Button>
          </div>
          {values?.validation?.productVideoLinks && (
            <span className='text-danger'>
              {values?.validation?.productVideoLinks}
            </span>
          )}
          <p className='default_col text-muted'>
            <small>
              Example: https://www.youtube.com/watch?v=<b>[Your Video ID]</b>{' '}
              (MAX link -5)
            </small>
          </p>
        </div>
        {values?.productVideoLinks?.length > 0 && (
          <Table responsive hover className='align-middle table-list'>
            <thead className='align-middle'>
              <tr>
                <th className='text-nowrap'>Video Link</th>
                <th className='text-nowrap'>Title</th>
                <th className='text-center'>Action</th>
              </tr>
            </thead>
            <tbody>
              {values?.productVideoLinks?.map((data) => (
                <tr key={Math.floor(Math.random() * 1000000)}>
                  <td>{data?.link}</td>
                  <td>{data?.title}</td>
                  <td className='d-flex justify-content-center align-items-center'>
                    <span
                      id='button-addon2'
                      onClick={() => {
                        Swal.fire({
                          title: _SwalDelete.title,
                          text: _SwalDelete.text,
                          icon: _SwalDelete.icon,
                          showCancelButton: _SwalDelete.showCancelButton,
                          confirmButtonColor: _SwalDelete.confirmButtonColor,
                          cancelButtonColor: _SwalDelete.cancelButtonColor,
                          confirmButtonText: _SwalDelete.confirmButtonText,
                          cancelButtonText: _SwalDelete.cancelButtonText
                        }).then((result) => {
                          if (result.isConfirmed) {
                            let productVideoLinkArray =
                              values?.productVideoLinks ?? []
                            productVideoLinkArray =
                              productVideoLinkArray?.filter((videoLink) => {
                                if (videoLink?.id) {
                                  return videoLink?.id !== data?.id
                                } else {
                                  return videoLink?.key !== data?.key
                                }
                              })

                            setFieldValue(
                              'productVideoLinks',
                              productVideoLinkArray
                            )
                          } else if (result.isDenied) {
                          }
                        })
                      }}
                    >
                      <DeleteIcon bg={'bg'} />
                    </span>
                  </td>
                </tr>
              ))}
            </tbody>
          </Table>
        )}
      </div>
    </div>
  )
}

export default ImagesAndLink
