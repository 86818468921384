import React, { useState, useEffect } from 'react'
import { Button, Table } from 'react-bootstrap'
import Select from 'react-select'
import SearchBox from '../../../../components/Searchbox'
import TooltipComponent from '../../../../components/Tooltip'
import { Link, useLocation } from 'react-router-dom'
import ReactPaginate from 'react-paginate'
import ModelComponent from '../../../../components/Modal'
import { Formik, Form, ErrorMessage } from 'formik'
import * as Yup from 'yup'
import axiosProvider from '../../../../lib/AxiosProvider'
import FormikControl from '../../../../components/FormikControl'
import useDebounce from '../../../../lib/useDebounce'
import Loader from '../../../../components/Loader'
import { customStyles } from '../../../../components/customStyles'
import CustomToast from '../../../../components/Toast/CustomToast'
import Swal from 'sweetalert2'
import { _SwalDelete, _exception } from '../../../../lib/exceptionMessage'
import EditIcon from '../../../../components/AllSvgIcon/EditIcon'
import DeleteIcon from '../../../../components/AllSvgIcon/DeleteIcon'
import TextError from '../../../../components/TextError'
import { useSelector } from 'react-redux'
import { pageRangeDisplayed, showToast } from '../../../../lib/GetBaseUrl'
import {
  allCrudNames,
  allPages,
  checkPageAccess
} from '../../../../lib/AllPageNames'
import { useImmer } from 'use-immer'

const SizeValue = () => {
  const [searchText, setSearchText] = useState()
  const [data, setData] = useState()
  const [modalShow, setModalShow] = useState(false)
  const [parentData, setParentData] = useState()
  const [loading, setLoading] = useState(false)
  const [toast, setToast] = useState({
    show: false,
    text: null,
    variation: null
  })
  const initVal = {
    typeName: '',
    parentId: ''
  }
  const [initialValues, setInitialValues] = useState(initVal)
  const { pageAccess } = useSelector((state) => state.user)
  const { userId } = useSelector((state) => state?.user?.userInfo)
  const location = useLocation()
  const debounceSearchText = useDebounce(searchText, 500)
  const [filterDetails, setFilterDetails] = useImmer({
    pageSize: 10,
    pageIndex: 1,
    searchText: ''
  })

  const fetchData = async () => {
    try {
      setLoading(true)
      const response = await axiosProvider({
        method: 'GET',
        endpoint: 'SizeValue/search',
        queryString: `?searchText=${filterDetails?.searchText}&pageIndex=${filterDetails?.pageIndex}&pageSize=${filterDetails?.pageSize}`
      })
      setLoading(false)
      if (response?.status === 200) {
        setData(response)
      }
    } catch {
      setLoading(false)
    }
  }

  const fetchParentData = async () => {
    await axiosProvider({
      method: 'GET',
      endpoint: 'SizeType',
      queryString: `?pageindex=0&PageSize=0`
    })
      .then((res) => {
        if (res.status === 200) {
          setParentData(res.data)
        }
      })
      .catch((err) => {})
  }

  const handlePageClick = (event) => {
    setFilterDetails((draft) => {
      draft.pageIndex = event.selected + 1
    })
  }

  const validationSchema = Yup.object().shape({
    typeName: Yup.string()
      .min(1, 'Your Name must consist of at least 3 characters')
      .max(50, 'Your Name is to long')
      .required('Please enter size name'),
    parentId: Yup.string()
      .test('nonull', 'Please select the Title', (value) => value !== undefined)
      .required('Please select size type')
  })

  const onSubmit = async (values) => {
    try {
      setLoading(true)
      let method = 'POST'
      let endpoint = 'SizeValue/CreateSizeValue'
      if (values?.id) {
        method = 'PUT'
        endpoint = 'SizeValue'
      }
      const response = await axiosProvider({
        method,
        endpoint,
        data: values,
        location: location?.pathname,
        userId,
        oldData: initialValues
      })
      setLoading(false)
      if (response?.data?.code === 200) {
        setModalShow(!modalShow)
        fetchData()
      }
      showToast(toast, setToast, response)
    } catch {
      setLoading(false)
      showToast(toast, setToast, {
        data: {
          message: _exception?.message,
          code: 204
        }
      })
    }
  }

  const handleDelete = async (id) => {
    try {
      setLoading(true)
      const response = await axiosProvider({
        method: 'DELETE',
        endpoint: 'SizeValue',
        queryString: `?Id=${id}`,
        userId,
        location: location.pathname
      })
      setLoading(false)
      if (response?.data?.code === 200) {
        if (filterDetails?.pageIndex > 1 && data?.data?.data?.length === 1) {
          setFilterDetails((draft) => {
            draft.pageIndex = filterDetails?.pageIndex - 1
          })
        } else {
          fetchData()
        }
      }
      showToast(toast, setToast, response)
    } catch {
      setLoading(false)

      showToast(toast, setToast, {
        data: {
          message: _exception?.message,
          code: 204
        }
      })
    }
  }

  useEffect(() => {
    if (debounceSearchText) {
      setFilterDetails((draft) => {
        draft.searchText = debounceSearchText
        draft.pageIndex = 1
      })
    } else {
      setFilterDetails((draft) => {
        draft.searchText = ''
        draft.pageIndex = 1
      })
    }
  }, [debounceSearchText])

  useEffect(() => {
    fetchData()
  }, [filterDetails])

  useEffect(() => {
    if (modalShow === true) {
      !parentData && fetchParentData()
    }
  }, [modalShow])

  return (
    <>
      <div className='d-flex align-items-center mb-3 gap-3 flex-row-reverse'>
        {checkPageAccess(pageAccess, allPages?.size, allCrudNames?.write) && (
          <Button
            variant='warning'
            className='d-flex align-items-center gap-2 fw-semibold btn btn-warning ms-auto'
            onClick={() => {
              setInitialValues(initVal)
              setModalShow(true)
            }}
          >
            <i className='m-icon m-icon--plusblack'></i>
            Create
          </Button>
        )}
        <div className='d-flex align-items-center me-2'>
          <label className='me-1'>Show</label>
          <select
            name='dataget'
            className='form-select me-1'
            onChange={(e) => {
              setFilterDetails((draft) => {
                draft.pageSize = e?.target?.value
                draft.pageIndex = 1
              })
            }}
          >
            <option value='10'>10</option>
            <option value='25'>25</option>
            <option value='50'>50</option>
            <option value='100'>100</option>
            <option value='200'>200</option>
            <option value='500'>500</option>
          </select>
        </div>

        <SearchBox
          placeholderText='Search'
          searchclassnamewrapper={'searchbox-wrapper'}
          value={searchText}
          onChange={(e) => {
            setSearchText(e?.target?.value)
          }}
        />
      </div>

      {toast?.show && (
        <CustomToast text={toast?.text} variation={toast?.variation} />
      )}

      <ModelComponent
        show={modalShow}
        modalsize={'md'}
        modalheaderclass={''}
        modeltitle={'Size Value'}
        onHide={() => {
          setInitialValues(initVal)
          setModalShow(false)
        }}
        btnclosetext={''}
        closebtnvariant={''}
        backdrop={'static'}
        formbuttonid={'value-size'}
        submitname={!initialValues?.id === true ? 'Create' : 'Update'}
      >
        <Formik
          enableReinitialize
          initialValues={initialValues}
          validationSchema={validationSchema}
          onSubmit={onSubmit}
        >
          {({ values, setFieldValue, errors }) => (
            <Form id='value-size'>
              <div className='row align-items-center'>
                <div className='col-md-12'>
                  {loading && <Loader />}

                  <div className='input-wrapper mb-3'>
                    <label className='form-label required'>Select Type</label>
                    <Select
                      id='parentId'
                      isClearable={true}
                      isDisabled={values?.id ? true : false}
                      menuPosition={'fixed'}
                      styles={customStyles}
                      menuPortalTarget={document.body}
                      placeholder={'Select type'}
                      value={
                        values?.parentId && {
                          value: values.parentId,
                          label: values.parentName
                        }
                      }
                      options={
                        parentData &&
                        parentData?.data.map(({ id, typeName }) => ({
                          value: id,
                          label: typeName
                        }))
                      }
                      onChange={(e) => {
                        setFieldValue('parentId', e?.value)
                        setFieldValue('parentName', e?.label)
                      }}
                    />
                    <ErrorMessage name='parentId' component={TextError} />
                  </div>
                </div>
                <div className='col-md-12'>
                  <div className='input-wrapper mb-3'>
                    <FormikControl
                      control='input'
                      isRequired
                      label='Add Size'
                      type='text'
                      name='typeName'
                      placeholder='Enter type'
                    />
                  </div>
                </div>
              </div>
            </Form>
          )}
        </Formik>
      </ModelComponent>

      {loading && !modalShow && <Loader />}

      <Table responsive className='align-middle table-list'>
        <thead>
          <tr>
            <th className='text-center'>Size Type</th>
            <th className='text-center'>Size</th>
            {checkPageAccess(pageAccess, allPages?.size, [
              allCrudNames?.update,
              allCrudNames?.delete
            ]) && <th className='text-center'>Action</th>}
          </tr>
        </thead>
        <tbody>
          {data?.data?.data?.length > 0 ? (
            data?.data?.data?.map((sizevalue) => (
              <tr key={sizevalue.id}>
                <td>{sizevalue.parentName}</td>
                <td>{sizevalue.typeName}</td>
                {checkPageAccess(pageAccess, allPages?.size, [
                  allCrudNames?.update,
                  allCrudNames?.delete
                ]) && (
                  <td className='text-center'>
                    <div className='d-flex gap-2 justify-content-center'>
                      {checkPageAccess(
                        pageAccess,
                        allPages?.size,
                        allCrudNames?.update
                      ) && (
                        <span
                          onClick={() => {
                            setModalShow(!modalShow)
                            setInitialValues(sizevalue)
                          }}
                        >
                          <EditIcon bg={'bg'} />
                        </span>
                      )}
                      {checkPageAccess(
                        pageAccess,
                        allPages?.size,
                        allCrudNames?.delete
                      ) && (
                        <span
                          onClick={() => {
                            Swal.fire({
                              title: _SwalDelete.title,
                              text: _SwalDelete.text,
                              icon: _SwalDelete.icon,
                              showCancelButton: _SwalDelete.showCancelButton,
                              confirmButtonColor:
                                _SwalDelete.confirmButtonColor,
                              cancelButtonColor: _SwalDelete.cancelButtonColor,
                              confirmButtonText: _SwalDelete.confirmButtonText,
                              cancelButtonText: _SwalDelete.cancelButtonText
                            }).then((result) => {
                              if (result.isConfirmed) {
                                handleDelete(sizevalue.id)
                              } else if (result.isDenied) {
                              }
                            })
                          }}
                        >
                          <DeleteIcon bg={'bg'} />
                        </span>
                      )}
                    </div>
                  </td>
                )}
              </tr>
            ))
          ) : (
            <tr>
              <td colSpan={3} className='text-center'>
                {data?.data?.message ? data?.data?.message : 'No Data Found'}
              </td>
            </tr>
          )}
        </tbody>
      </Table>

      <ReactPaginate
        className='list-inline m-cst--pagination d-flex justify-content-end gap-1'
        breakLabel='...'
        nextLabel=''
        onPageChange={handlePageClick}
        pageRangeDisplayed={pageRangeDisplayed}
        pageCount={data?.data?.pagination?.pageCount ?? 0}
        previousLabel=''
        renderOnZeroPageCount={null}
        forcePage={filterDetails?.pageIndex - 1}
      />
    </>
  )
}

export default SizeValue
